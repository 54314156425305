import { toast } from 'react-toastify'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handlerErrorSimulacao(error: any, defaultMessage?: string) {
  if (
    (error?.message || (error?.messages?.length > 0 && error?.messages)) &&
    !error?.stack
  ) {
    if (error?.messages?.length > 0) {
      error?.messages.map((e: string) => {
        toast.warn(e, {
          bodyClassName: 'text-break',
          autoClose: false
        })
      })
    } else {
      toast.warn(error?.message, {
        bodyClassName: 'text-break',
        autoClose: false
      })
    }
  } else {
    defaultMessage
      ? toast.warn(defaultMessage, {
          bodyClassName: 'text-break',
          autoClose: false
        })
      : toast.warn('Não foi possível!', {
          bodyClassName: 'text-break',
          autoClose: false
        })
  }
}
