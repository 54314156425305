import { APICore } from 'helpers/api/apiCore'

const api = new APICore()

export function getEmpresas() {
  return api.get(`Indicadores/empresas`, null)
}

export function getEquipes(lojaMatrizId: string) {
  return api.get(`Indicadores/equipes`, {
    lojaMatrizId
  })
}

export function getConsultores(lojaId: string) {
  return api.get(`Indicadores/consultores`, {
    lojaId
  })
}

export function getBigNumbers(
  empresaId: string,
  equipeId: string,
  consultorId: string
) {
  return api.getWithoutUndefined(`Indicadores/big-numbers`, {
    empresaId,
    equipeId,
    consultorId
  })
}

export function getTopEquipes() {
  return api.get(`Indicadores/top-equipes`, null)
}

export function getVerMaisTopEquipes() {
  return api.get(`Indicadores/ver-mais-equipes`, null)
}

export function getTopEquipesConversao() {
  return api.get(`Indicadores/top-equipes-conversao`, null)
}

export function getVerMaisTopEquipesConversao() {
  return api.get(`Indicadores/ver-mais-equipes-conversao`, null)
}

export function getTopConsultores() {
  return api.get(`Indicadores/top-consultores`, null)
}

export function getVerMaisTopConsultores() {
  return api.get(`Indicadores/ver-mais-consultores`, null)
}

export function getTopConsultoresConversao() {
  return api.get(`Indicadores/top-consultores-conversao`, null)
}

export function getVerMaisTopConsultoresConversao() {
  return api.get(`Indicadores/ver-mais-consultores-conversao`, null)
}

export function getTabulacoes() {
  return api.get(`Indicadores/tabulacoes`, null)
}

export function getMatrizRfm(
  empresaId: string,
  equipeId: string,
  consultorId: string
) {
  return api.get(`Indicadores/matriz`, {
    empresaId,
    equipeId,
    consultorId
  })
}

export function getEsforcoVenda(
  empresaId: string,
  equipeId: string,
  consultorId: string
) {
  return api.get(`Indicadores/esforco-venda`, {
    empresaId,
    equipeId,
    consultorId
  })
}
