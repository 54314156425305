import React from 'react'
import { RiBookOpenLine } from 'react-icons/ri'
import { Modal, ModalBody, ModalHeader, TabContent, TabPane } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import ValorDesejado from './Tabs/ValorDesejado'
import Veiculo from './Tabs/Veiculo'
import Imovel from './Tabs/Imovel'
import Motivo from './Tabs/Motivo'
import { FormularioNecessidadeWrapper } from 'views/inicio/style'
import { useSelector } from 'react-redux'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { ApplicationState } from 'store'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'

interface Tabs {
  id: number
  descricao: string
  component: React.ReactNode
}

const FormularioNecessidade = () => {
  const { formularioNecessidade } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  const tabs: Tabs[] = [
    {
      component: <ValorDesejado />,
      descricao: 'Valor desejado',
      id: 1
    },
    {
      component: <Veiculo />,
      descricao: 'Veículo',
      id: 2
    },
    {
      component: <Imovel />,
      descricao: 'Imóvel',
      id: 3
    },
    {
      component: <Motivo />,
      descricao: 'Motivo',
      id: 4
    }
  ]

  function onToggle() {
    dispatch(
      atendimentoActions.toggleFormularioNecessidade(
        !formularioNecessidade.isOpen
      )
    )
  }

  return (
    <Modal
      size="md"
      isOpen={formularioNecessidade.isOpen}
      toggle={() => onToggle()}
      backdrop="static"
    >
      <ModalHeader toggle={() => onToggle()}>
        <SimpleTitleCard>
          <RiBookOpenLine className="icon" /> Formulário de pesquisa de
          necessidade
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody>
        <FormularioNecessidadeWrapper>
          <TabContent activeTab={formularioNecessidade.tabActive}>
            {tabs?.map((tab, i) => {
              return (
                <TabPane tabId={tab?.id} key={i}>
                  {tab.component}
                </TabPane>
              )
            })}
          </TabContent>
        </FormularioNecessidadeWrapper>
      </ModalBody>
    </Modal>
  )
}

export default FormularioNecessidade
