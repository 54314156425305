import { Paginacao } from 'models/types'
import store from 'store'
import { HistoricoList, TYPES } from './types'

type ActiveTab = 'search' | 'register'

export const setAtiveTab = (value: ActiveTab) => ({
  type: TYPES.SET_ACTIVE_TAB,
  value
})

export const setItemSelectedIdDispatch = (value: string) =>
  store.dispatch({
    type: TYPES.SET_ITEM_SELECTED_ID,
    value
  })

export const setPesquisa = (value: string | number | null, field: string) => ({
  type: TYPES.SET_PESQUISA,
  value,
  field
})
export const cleanPesquisa = () => ({
  type: TYPES.CLEAN_PESQUISA
})

export const setHistoricoList = (value: HistoricoList[]) => ({
  type: TYPES.SET_HISTORICO_LIST,
  value
})

export const setShowColunaLoja = (value: boolean) => ({
  type: TYPES.SET_SHOW_COLUNA_LOJA,
  value
})

export const setPaginacao = (value: Paginacao) => ({
  type: TYPES.SET_PAGINATION,
  value
})

export const getAllHistoricoSaga = () => ({
  type: TYPES.GET_ALL_HISTORICO_SAGA
})
