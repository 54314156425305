import React, { useCallback, useEffect, useState } from 'react'
import { Col, Input, Row } from 'reactstrap'
import { Especie } from 'store/modules/backoffice/campanha/types'

interface Option {
  numero: number
  tipo: number
  invalidez: boolean
}

interface EspeciesProps {
  options: Especie[]
  value: number[]
  disabled?: boolean
  invalid?: boolean
  onChange?: (e: number[]) => void
}

const Especies = ({
  options,
  value,
  disabled,
  invalid,
  onChange
}: EspeciesProps) => {
  const [optionsState, setOptionsState] = useState<Option[]>([])
  const [checkedsState, setCheckedsState] = useState<number[]>([])

  useEffect(() => {
    const optionsFormated: Option[] = []
    options?.map((option) => {
      optionsFormated.push({
        numero: option.numero,
        tipo: option.tipo,
        invalidez: option.invalidez
      })
    })
    setOptionsState(optionsFormated)
  }, [options])

  useEffect(() => {
    setCheckedsState(value)
  }, [value])

  const toggler = useCallback(
    (e: number) => {
      if (checkedsState.some((v) => v === e)) {
        const updatedCheckeds = checkedsState.filter((v) => v !== e)
        return onChange?.([...new Set(updatedCheckeds)])
      }
      const updatedCheckeds = [...checkedsState, e]
      return onChange?.([...new Set(updatedCheckeds)])
    },
    [checkedsState, onChange]
  )

  const toggleTodos = useCallback(() => {
    if (optionsState.length === checkedsState.length) {
      return onChange?.([])
    }
    return onChange?.([
      ...new Set(optionsState?.map((option) => option.numero))
    ])
  }, [onChange, optionsState, checkedsState.length])

  const selecionarOpcoesPorTipo = useCallback(
    (tipo: number) => {
      const optionsDoTipoJaSelecionadas = optionsState.filter(
        (option) =>
          option.tipo === tipo &&
          checkedsState.some((numero) => numero === option.numero)
      )
      const optionsDiferentesDoTipoJaSelecionadas = optionsState.filter(
        (option) =>
          option.tipo !== tipo &&
          checkedsState.some((numero) => numero === option.numero)
      )
      const optionsSelecionadas = optionsState.filter(
        (option) => option.tipo === tipo
      )
      if (optionsDoTipoJaSelecionadas.length === optionsSelecionadas.length) {
        const numerosSelecionados = optionsDiferentesDoTipoJaSelecionadas.map(
          (option) => option.numero
        )
        return onChange?.([...new Set(numerosSelecionados)])
      }
      const numerosSelecionados = optionsSelecionadas.map(
        (option) => option.numero
      )
      return onChange?.([
        ...new Set([...checkedsState, ...numerosSelecionados])
      ])
    },
    [onChange, optionsState, checkedsState]
  )

  const toggleInvalidez = useCallback(() => {
    const optionsDoTipoJaSelecionadas = optionsState.filter(
      (option) =>
        option.invalidez &&
        checkedsState.some((numero) => numero === option.numero)
    )
    const optionsDiferentesDoTipoJaSelecionadas = optionsState.filter(
      (option) =>
        !option.invalidez &&
        checkedsState.some((numero) => numero === option.numero)
    )
    const optionsSelecionadas = optionsState.filter(
      (option) => option.invalidez === true
    )
    if (optionsDoTipoJaSelecionadas.length === optionsSelecionadas.length) {
      const numerosSelecionados = optionsDiferentesDoTipoJaSelecionadas.map(
        (option) => option.numero
      )
      return onChange?.([...new Set(numerosSelecionados)])
    }
    const numerosSelecionados = optionsSelecionadas.map(
      (option) => option.numero
    )
    return onChange?.([...new Set([...checkedsState, ...numerosSelecionados])])
  }, [onChange, optionsState, checkedsState])

  const getOpcoesSelecionadasPorTipo = useCallback(
    (tipo: number) => {
      const opcoesSelecionadas = optionsState.filter((option) => {
        return (
          option.tipo === tipo &&
          checkedsState.some((numero) => numero === option.numero)
        )
      })

      return opcoesSelecionadas
    },
    [optionsState, checkedsState]
  )

  const verificarTodasSelecionadasPorTipo = useCallback(
    (tipo: number) => {
      const opcoesSelecionadas = getOpcoesSelecionadasPorTipo(tipo)
      const opcoesTipo = optionsState.filter((option) => option.tipo === tipo)

      if (opcoesTipo.length > 0) {
        return opcoesSelecionadas.length === opcoesTipo.length
      }
      return false
    },
    [getOpcoesSelecionadasPorTipo, optionsState]
  )

  const getOpcoesSelecionadasPorInvalidez = useCallback(() => {
    const opcoesSelecionadas = optionsState.filter((option) => {
      return (
        option.invalidez &&
        checkedsState.some((numero) => numero === option.numero)
      )
    })

    return opcoesSelecionadas
  }, [optionsState, checkedsState])

  const verificarTodasSelecionadasInvalidez = useCallback(() => {
    const opcoesSelecionadas = getOpcoesSelecionadasPorInvalidez()
    const opcoesTipo = optionsState.filter(
      (option) => option.invalidez === true
    )

    if (opcoesTipo.length > 0) {
      return opcoesSelecionadas.length === opcoesTipo.length
    }
    return false
  }, [getOpcoesSelecionadasPorInvalidez, optionsState])

  return (
    <>
      <Row>
        <label className="label-12">Espécies</label>
      </Row>
      <Row>
        <Col className="d-flex column-gap-10" md={2}>
          <Input
            type="checkbox"
            id="todos"
            onChange={() => {
              toggleTodos()
            }}
            checked={optionsState.length === checkedsState.length}
            disabled={disabled}
          />
          <label htmlFor="todos">Todos</label>
        </Col>
        <Col className="d-flex column-gap-10" md={2}>
          <Input
            type="checkbox"
            id="consignaveis"
            onChange={() => {
              selecionarOpcoesPorTipo(0)
            }}
            checked={verificarTodasSelecionadasPorTipo(0)}
            disabled={disabled}
          />
          <label htmlFor="consignaveis">Todos consignáveis</label>
        </Col>
        <Col className="d-flex column-gap-10" md={2}>
          <Input
            type="checkbox"
            id="naoConsignaveis"
            onChange={() => {
              selecionarOpcoesPorTipo(1)
            }}
            checked={verificarTodasSelecionadasPorTipo(1)}
            disabled={disabled}
          />
          <label htmlFor="naoConsignaveis">Não consignáveis</label>
        </Col>
        {/* <Col className="d-flex column-gap-10" md={2}>
          <Input
            type="checkbox"
            id="tradicionais"
            onChange={() => {
              selecionarOpcoesPorTipo(2)
            }}
            checked={verificarTodasSelecionadasPorTipo(2)}
            disabled={disabled}
          />
          <label htmlFor="tradicionais">Tradicionais</label>
        </Col>
        <Col className="d-flex column-gap-10" md={2}>
          <Input
            type="checkbox"
            id="naoTradicionais"
            onChange={() => {
              selecionarOpcoesPorTipo(3)
            }}
            checked={verificarTodasSelecionadasPorTipo(3)}
            disabled={disabled}
          />
          <label htmlFor="naoTradicionais">Não tradicionais</label>
        </Col> */}
        <Col className="d-flex column-gap-10" md={2}>
          <Input
            type="checkbox"
            id="invalidez"
            onChange={() => {
              toggleInvalidez()
            }}
            checked={verificarTodasSelecionadasInvalidez()}
            disabled={disabled}
          />
          <label htmlFor="invalidez">Invalidez</label>
        </Col>
      </Row>
      <Row>
        {optionsState?.map((option) => {
          return (
            <Col className="d-flex column-gap-10" md={1} key={option.numero}>
              <Input
                type="checkbox"
                id={`checkbox-${option.numero}`}
                onChange={() => {
                  toggler(option.numero)
                }}
                checked={value.some((value) => value === option.numero)}
                disabled={disabled}
                invalid={invalid}
              />
              <label htmlFor={`checkbox-${option.numero}`}>
                {option.numero}
              </label>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default Especies
