import styled from 'styled-components'

export const InputArea = styled.div`
  & .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background-image: none !important;
  }

  & input,
  & textarea {
    border-radius: 15px;
    width: 100%;
  }

  & input[type='text'] {
  }
  & input[type='date'] {
  }
  & input[type='time'] {
  }
  & input[type='checkbox'].checkbox-roxo {
    align-items: center;
    background: transparent;
    border: var(--roxo-riber) solid 2px;
    border-radius: 5px;
    content: '';
    display: flex;
    height: 25px;
    justify-content: center;
    margin: 0;
    width: 25px;
    &:checked:after {
      align-items: center;
      background-color: var(--roxo-riber);
      border-radius: 2px;
      content: '';
      display: flex;
      height: 15px;
      justify-content: center;
      width: 15px;
    }
  }
`
