import {
  Cidade,
  Estado,
  Genero,
  Lojas,
  Paginacao,
  TipoOperacao
} from 'models/types'

export enum TYPES {
  SET_ACTIVE_TAB = '@@campanha/SET_ACTIVE_TAB',
  SET_ACTIVE_TAB_REGISTER = '@@campanha/SET_ACTIVE_TAB_REGISTER',
  SET_ALL_CAMPANHAS = '@@campanha/SET_ALL_CAMPANHAS',
  CLEAN_ALL_CAMPANHAS = '@@campanha/CLEAN_ALL_CAMPANHAS',
  SET_ITEM_SELECTED_ID = '@@campanha/SET_ITEM_SELECTED_ID',
  TOGGLE_MODAL_EXCLUSAO = '@@campanha/TOGGLE_MODAL_EXCLUSAO',
  SET_PESQUISA = '@@campanha/SET_PESQUISA',
  CLEAN_PESQUISA = '@@campanha/CLEAN_PESQUISA',
  SET_REGISTER_FIELDS = '@@campanha/SET_REGISTER_FIELDS',
  SET_REGISTER = '@@campanha/SET_REGISTER',
  SET_VISUALIZACAO = '@@campanha/SET_VISUALIZACAO',
  SET_PAGINATION = '@@campanha/SET_PAGINATION',
  CLEAN_PAGINATION = '@@campanha/CLEAN_PAGINATION',
  CLEAN_REGISTER = '@@campanha/CLEAN_REGISTER',
  SET_OPTIONS_SELECT = '@@campanha/SET_OPTIONS_SELECT',
  SET_INVALIDS_TAB_DADOS_GERAIS = '@@campanha/SET_INVALIDS_TAB_DADOS_GERAIS',
  CLEAN_INVALIDS_TAB_DADOS_GERAIS = '@@campanha/CLEAN_INVALIDS_TAB_DADOS_GERAIS',
  SET_INVALIDS_TAB_PARAMETROS = '@@campanha/SET_INVALIDS_TAB_PARAMETROS',
  CLEAN_INVALIDS_TAB_PARAMETROS = '@@campanha/CLEAN_INVALIDS_TAB_PARAMETROS',
  SET_INVALIDS_TAB_BANCOS = '@@campanha/SET_INVALIDS_TAB_BANCOS',
  CLEAN_INVALIDS_TAB_BANCOS = '@@campanha/CLEAN_INVALIDS_TAB_BANCOS',
  SET_INVALIDS_TAB_REFIN_PORTABILIDADE = '@@campanha/SET_INVALIDS_TAB_REFIN_PORTABILIDADE',
  CLEAN_INVALIDS_TAB_REFIN_PORTABILIDADE = '@@campanha/CLEAN_INVALIDS_TAB_REFIN_PORTABILIDADE',
  CLEAN_QUANTIDADE_EMPRESTIMO = '@@campanha/CLEAN_QUANTIDADE_EMPRESTIMO',
  CLEAN_SALARIO = '@@campanha/CLEAN_SALARIO',
  CLEAN_MARGEM_LIVRE = '@@campanha/CLEAN_MARGEM_LIVRE',
  CLEAN_MARGEM_CARTAO = '@@campanha/CLEAN_MARGEM_CARTAO',
  CLEAN_PARCELAS_PAGAS = '@@campanha/CLEAN_PARCELAS_PAGAS',
  CLEAN_VALOR_PARCELA = '@@campanha/CLEAN_VALOR_PARCELA',
  SET_STATUS_CAMPANHA_GRID = '@@campanha/SET_STATUS_CAMPANHA_GRID',
  SET_LOJAS = '@@campanha/SET_LOJAS',
  SET_CAMPANHAS_SELECTED_IDS = '@@campanha/SET_CAMPANHAS_SELECTED_IDS',
  TOGGLE_MODAL_EXCLUSAO_SELECIONADOS = '@@campanha/TOGGLE_MODAL_EXCLUSAO_SELECIONADOS',
  CLEAN_CAMPANHAS_SELECTED_IDS = '@@campanha/CLEAN_CAMPANHAS_SELECTED_IDS',

  GET_ALL_CAMPANHAS_SAGA = '@@campanha/GET_ALL_TABULACOES_SAGA',
  GET_CAMPANHA_SAGA = '@@campanha/GET_CAMPANHA_SAGA',
  VISUALIZAR_CAMPANHA_SAGA_DISPATCH = '@@campanha/VISUALIZAR_CAMPANHA_SAGA_DISPATCH',
  EDITAR_CAMPANHA_SAGA_DISPATCH = '@@campanha/EDITAR_CAMPANHA_SAGA_DISPATCH',
  PUT_CAMPANHA_SAGA = '@@campanha/PUT_CAMPANHA_SAGA',
  POST_CAMPANHA_SAGA = '@@campanha/POST_CAMPANHA_SAGA',
  DELETE_CAMPANHA_SAGA = '@@campanha/DELETE_CAMPANHA_SAGA',
  GET_CIDADES_SAGA = '@@campanha/GET_CIDADES_SAGA',
  GET_ESTADOS_SAGA = '@@campanha/GET_ESTADOS_SAGA',
  GET_GENEROS_SAGA = '@@campanha/GET_GENEROS_SAGA',
  GET_TIPOS_OPERACO_SAGA = '@@campanha/GET_TIPOS_OPERACO_SAGA',
  GET_BANCOS_SAGA = '@@campanha/GET_BANCOS_SAGA',
  GET_GRUPOS_LOJAS_SAGA = '@@campanha/GET_GRUPOS_LOJAS_SAGA',
  GET_BANCOS_CARTAO_SAGA = '@@campanha/GET_BANCOS_CARTAO_SAGA',
  ALTERAR_STATUS_CAMPANHA_DISPATCH_SAGA = '@@campanha/ALTERAR_STATUS_CAMPANHA_DISPATCH_SAGA',
  ABASTECIMENTO_CLIENTES_SAGA = '@@campanha/ABASTECIMENTO_CLIENTES_SAGA',
  REABASTECIMENTO_CLIENTES_SAGA = '@@campanha/REABASTECIMENTO_CLIENTES_SAGA',
  GET_ESPECIES_BENEFICIO_SAGA = '@@campanha/GET_ESPECIES_BENEFICIO_SAGA',
  GET_ALL_LOJAS_SAGA = '@@campanha/GET_ALL_LOJAS_SAGA',
  DELETE_CAMPANHAS_SELECIONADAS_SAGA = '@@campanha/DELETE_CAMPANHAS_SELECIONADAS_SAGA'
}

interface Search {
  descricao: string
  dataInicio: string
  dataFim: string
  lojaId: string | null
}

export interface CAMPANHA {
  activeTab: string
  activeTabRegister: number
  search: Search
  register: ParametrizacaoCampanha
  invalidsTabDadosGerais: InvalidsTabDadosGerais
  invalidsTabParametros: InvalidsTabParametros
  invalidsTabBancos: InvalidsTabBancos
  invalidsTabRefinPortabilidade: InvalidsTabRefinPortabilidade
  pagination: Paginacao
  campanhas: CampanhaList[]
  visualizacao: boolean
  itemSelectedId: string
  showConfirmacaoExclusao: boolean
  optionsSelect: OptionsSelect
  lojas: Lojas[]
  campanhasSelectedIds: string[]
  showConfirmacaoExclusaoSelecionados: boolean
}

interface CampanhaList {
  id: string
  descricao: string
  dataInicioCampanha: string
  dataFimCampanha: string
  descricaoTipoOperacao: string
  nomeLoja: string
  discador: boolean
  totalCpfs: number
  totalCpfsDisponiveis: number
  codigo: number
  habilitado: boolean
}

interface ParametrizacaoCampanha {
  id: string | null
  codigo?: number | null
  descricao: string
  empresasGrupo: string[]
  tipoOperacaoId: string | null
  dataInicioCampanha: string
  dataFimCampanha: string
  quantidadeClientes: number
  ufCampanha: string[]
  cidadeCampanha: string[]
  redudancia: boolean
  indisponibilidade: boolean
  naoPertube: boolean
  discadorAutomatico: boolean
  telefone: string | null
  idadeInicial: number | null
  idadeFinal: number | null
  formaRecebimentoBeneficio: number | null
  cartaoInss: boolean | null
  bancosCartaoCampanha: string[]
  despachoAnoInicial: string | null
  despachoAnoFinal: string | null
  especies: number[]
  bancosPagadoresConsiderarCampanha: string[]
  quantidadeEmprestimo: boolean
  quantidadeInicialEmprestimo: number | null
  quantidadeFinalEmprestimo: number | null
  salario: boolean
  quantidadeInicialSalario: string | null
  quantidadeFinalSalario: string | null
  margemLivre: boolean
  quantidadeInicialMargem: string | null
  quantidadeFinalMargem: string | null
  margemCartao: boolean
  quantidadeInicialMargemCartao: string | null
  quantidadeFinalMargemCartao: string | null
  instituicaoFinanceiraCampanha: string[]
  parcelas: boolean
  quantidadeInicialParcelas: number | null
  quantidadeFinalParcelas: number | null
  valorParcela: boolean
  quantidadeInicialValorParcela: string | null
  quantidadeFinalValorParcela: string | null
}

export interface ParametrizacaoCampanhaReq {
  id: string | null
  codigo?: number | null
  descricao: string
  grupos: string[]
  lojas: string[]
  tipoOperacaoId: string | null
  dataInicioCampanha: string
  dataFimCampanha: string
  quantidadeClientes: number
  ufCampanha: string[]
  cidadeCampanha: string[]
  redudancia: boolean
  indisponibilidade: boolean
  naoPertube: boolean
  discadorAutomatico: boolean
  telefone: string | null
  idadeInicial: number | null
  idadeFinal: number | null
  formaRecebimentoBeneficio: number | null
  cartaoInss: boolean | null
  bancosCartaoCampanha: string[]
  despachoAnoInicial: Date | null
  despachoAnoFinal: Date | null
  especies: number[]
  bancosPagadoresConsiderarCampanha: string[]
  quantidadeEmprestimo: boolean
  quantidadeInicialEmprestimo: number | null
  quantidadeFinalEmprestimo: number | null
  salario: boolean
  quantidadeInicialSalario: number | null
  quantidadeFinalSalario: number | null
  margemLivre: boolean
  quantidadeInicialMargem: number | null
  quantidadeFinalMargem: number | null
  margemCartao: boolean
  quantidadeInicialMargemCartao: number | null
  quantidadeFinalMargemCartao: number | null
  instituicaoFinanceiraCampanha: string[]
  parcelas: boolean
  quantidadeInicialParcelas: number | null
  quantidadeFinalParcelas: number | null
  valorParcela: boolean
  quantidadeInicialValorParcela: number | null
  quantidadeFinalValorParcela: number | null
}

export interface TabDadosGerais {
  codigo: ParametrizacaoCampanhaReq['codigo']
  descricao: ParametrizacaoCampanhaReq['descricao']
  empresasGrupo: ParametrizacaoCampanha['empresasGrupo']
  tipoOperacaoId: ParametrizacaoCampanhaReq['tipoOperacaoId']
  dataInicioCampanha: ParametrizacaoCampanhaReq['dataInicioCampanha']
  dataFimCampanha: ParametrizacaoCampanhaReq['dataFimCampanha']
  quantidadeClientes: ParametrizacaoCampanhaReq['quantidadeClientes']
  ufCampanha: ParametrizacaoCampanhaReq['ufCampanha']
  cidadeCampanha: ParametrizacaoCampanhaReq['cidadeCampanha']
}

type InvalidsTabDadosGerais = {
  [k in keyof TabDadosGerais]: boolean
}

export interface TabParametros {
  redudancia: ParametrizacaoCampanhaReq['redudancia']
  indisponibilidade: ParametrizacaoCampanhaReq['indisponibilidade']
  naoPertube: ParametrizacaoCampanhaReq['naoPertube']
  discadorAutomatico: ParametrizacaoCampanhaReq['discadorAutomatico']
  telefone: ParametrizacaoCampanhaReq['telefone']
  idadeInicial: ParametrizacaoCampanhaReq['idadeInicial']
  idadeFinal: ParametrizacaoCampanhaReq['idadeFinal']
  formaRecebimentoBeneficio: ParametrizacaoCampanhaReq['formaRecebimentoBeneficio']
  cartaoInss: ParametrizacaoCampanhaReq['cartaoInss']
  bancosCartaoCampanha: ParametrizacaoCampanhaReq['bancosCartaoCampanha']
  despachoAnoInicial: ParametrizacaoCampanhaReq['despachoAnoInicial']
  despachoAnoFinal: ParametrizacaoCampanhaReq['despachoAnoFinal']
  especies: ParametrizacaoCampanhaReq['especies']
  quantidadeEmprestimo: ParametrizacaoCampanhaReq['quantidadeEmprestimo']
  quantidadeInicialEmprestimo: ParametrizacaoCampanhaReq['quantidadeInicialEmprestimo']
  quantidadeFinalEmprestimo: ParametrizacaoCampanhaReq['quantidadeFinalEmprestimo']
  salario: ParametrizacaoCampanhaReq['salario']
  quantidadeInicialSalario: ParametrizacaoCampanhaReq['quantidadeInicialSalario']
  quantidadeFinalSalario: ParametrizacaoCampanhaReq['quantidadeFinalSalario']
  margemLivre: ParametrizacaoCampanhaReq['margemLivre']
  quantidadeInicialMargem: ParametrizacaoCampanhaReq['quantidadeInicialMargem']
  quantidadeFinalMargem: ParametrizacaoCampanhaReq['quantidadeFinalMargem']
  margemCartao: ParametrizacaoCampanhaReq['margemCartao']
  quantidadeInicialMargemCartao: ParametrizacaoCampanhaReq['quantidadeInicialMargemCartao']
  quantidadeFinalMargemCartao: ParametrizacaoCampanhaReq['quantidadeFinalMargemCartao']
}

type InvalidsTabParametros = {
  [k in keyof TabParametros]: boolean
}

export interface TabBancos {
  bancosPagadoresConsiderarCampanha: ParametrizacaoCampanhaReq['bancosPagadoresConsiderarCampanha']
}

type InvalidsTabBancos = {
  [k in keyof TabBancos]: boolean
}

export interface TabRefinPortabilidade {
  instituicaoFinanceiraCampanha: ParametrizacaoCampanhaReq['instituicaoFinanceiraCampanha']
  parcelas: ParametrizacaoCampanhaReq['parcelas']
  quantidadeInicialParcelas: ParametrizacaoCampanhaReq['quantidadeInicialParcelas']
  quantidadeFinalParcelas: ParametrizacaoCampanhaReq['quantidadeFinalParcelas']
  valorParcela: ParametrizacaoCampanhaReq['valorParcela']
  quantidadeInicialValorParcela: ParametrizacaoCampanhaReq['quantidadeInicialValorParcela']
  quantidadeFinalValorParcela: ParametrizacaoCampanhaReq['quantidadeFinalValorParcela']
}

type InvalidsTabRefinPortabilidade = {
  [k in keyof TabRefinPortabilidade]: boolean
}

interface OptionsSelect {
  tiposOperacao: TipoOperacao[]
  empresasGrupos: { idGrupoELoja: string; descricao: string }[]
  ufs: Estado[]
  cidades: Cidade[]
  generos: Genero[]
  opcoesBinarias: { value: boolean; label: string }[]
  formasRecebimento: { value: number; label: string }[]
  meiosComunicacao: { value: string; label: string }[]
  bancos: { id: string; descricao: string; codigoBanco: string }[]
  bancosCartao: { id: string; descricao: string }[]
  qtdeEmprestimos: { value: number; label: string }[]
  especies: Especie[]
}

export interface Especie {
  numero: number
  descricao: string
  tipo: number
  invalidez: boolean
  campanhaCrmEspecies: never
  id: string
  ativo: boolean
  usuario: never
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: never
  idUsuarioUltimaAlteracao: never
  dataUltimaAlteracao: never
}

export type StatusAbastecimentoCampanha = 'SUCESSO' | 'PROCESSANDO' | 'ERRO'
