import { CREDENCIAIS, LoginSenha, TYPES, Usuario } from './types'

export const getUsuariosInstituicaoBmgSaga = () => ({
  type: TYPES.GET_USUARIOS_INSTITUICAO_BMG_SAGA
})

export const getUsuariosInstituicaoItauSaga = () => ({
  type: TYPES.GET_USUARIOS_INSTITUICAO_ITAU_SAGA
})

type LoginFields = keyof LoginSenha
type LoginItauFields = keyof CREDENCIAIS['itau']
export const salvarCredenciaisBmgSaga = (value: {
  usuario: string
  senha: string
}) => ({
  type: TYPES.SALVAR_CREDENCIAIS_BMG_SAGA,
  ...value
})

export const salvarCredenciaisItauSaga = (value: { usuario: string }) => ({
  type: TYPES.SALVAR_CREDENCIAIS_ITAU_SAGA,
  ...value
})

export const setUsuariosInstituicaoBmg = (value: Usuario[]) => ({
  type: TYPES.SET_USUARIOS_INSTITUICAO_BMG,
  value
})

export const setUsuariosInstituicaoItau = (value: Usuario[]) => ({
  type: TYPES.SET_USUARIOS_INSTITUICAO_ITAU,
  value
})

export const setLoginBmg = (value: string, field: LoginFields) => ({
  type: TYPES.SET_LOGIN_BMG,
  field,
  value
})

export const setLoginItau = (value: string, field: LoginItauFields) => ({
  type: TYPES.SET_LOGIN_ITAU,
  field,
  value
})

export const setLoginPan = (value: string, field: LoginFields) => ({
  type: TYPES.SET_LOGIN_PAN,
  field,
  value
})

export const setLoginMaster = (value: string, field: LoginFields) => ({
  type: TYPES.SET_LOGIN_MASTER,
  field,
  value
})

export const cleanLoginBMG = () => ({
  type: TYPES.CLEAN_LOGIN_BMG
})

export const cleanLoginItau = () => ({
  type: TYPES.CLEAN_LOGIN_ITAU
})

export const cleanLoginPAN = () => ({
  type: TYPES.CLEAN_LOGIN_PAN
})

export const cleanLoginMaster = () => ({
  type: TYPES.CLEAN_LOGIN_MASTER
})

export const getUsuarioPrincipalBMGSaga = () => ({
  type: TYPES.GET_USUARIO_PRINCIPAL_BMG_SAGA
})

export const getUsuarioPrincipalItauSaga = () => ({
  type: TYPES.GET_USUARIO_PRINCIPAL_ITAU_SAGA
})

type CredenciaisAgenteFields = keyof CREDENCIAIS['credenciaisAgente']
export const setCredenciaisAgenteFields = <T extends CredenciaisAgenteFields>(
  value: T extends keyof CREDENCIAIS['credenciaisAgente']
    ? CREDENCIAIS['credenciaisAgente'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_CREDENCIAIS_AGENTE_FIELDS,
  value,
  field
})

export const setCredenciaisAgente = (
  value: CREDENCIAIS['credenciaisAgente']
) => ({
  type: TYPES.SET_CREDENCIAIS_AGENTE,
  value
})

export const cleanCredenciaisAgente = () => ({
  type: TYPES.CLEAN_CREDENCIAIS_AGENTE
})

export const getCredenciaisAgenteSaga = () => ({
  type: TYPES.GET_CREDENCIAIS_AGENTE_SAGA
})

export const salvarCredenciaisAgenteSaga = () => ({
  type: TYPES.SALVAR_CREDENCIAIS_AGENTE_SAGA
})
