import { APICore } from 'helpers/api/apiCore'
import { ParametrizacaoUploadArquivo } from 'store/modules/backoffice/uploadArquivos/types'

const api = new APICore()
export interface FilterProps {
  nomeLoja?: string
  dataUpload?: string
  nomeUsuario?: string
  nomeTipoArquivo?: string
}
export function uploadArquivos(params: {
  pagina: number
  registros: number
  field?: string | null
  value: string | number
}) {
  if (params.field) {
    return api.get(`Arquivo/arquivos`, {
      pagina: params.pagina + 1,
      registros: params.registros,
      [params.field]: params.value
    })
  }
  return api.get(`Arquivo/arquivos`, {
    pagina: params.pagina + 1,
    registros: params.registros
  })
}

export function tiposDeArquivos() {
  return api.get(`Generic/tipo-arquivos`, null)
}

export function downloadArquivo(nomeArquivo: string) {
  return api.get(`Arquivo/download-arquivo?nomeArquivo=${nomeArquivo}`)
}

export function criarArquivo(params: ParametrizacaoUploadArquivo) {
  return api.createWithFile(
    `Arquivo/upload-telefones?tipoArquivo=${params.tipoArquivo}`,
    { arquivo: params.uploadedFile }
  )
}

export function excluirArquivo(nomeArquivo: string) {
  return api.delete(`Arquivo/deletar-arquivo?nomeArquivo=${nomeArquivo}`)
}

export function visualizarArquivo(nomeArquivo: string) {
  return api.get(`Arquivo/arquivos`, {
    nomeArquivo: nomeArquivo,
    pagina: 1,
    registros: 1
  })
}
