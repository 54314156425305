import React, { useEffect } from 'react'
import CustomTable from 'components/CustomTable'
import InputCustom from 'components/Inputs'
import PrivateComponent from 'components/PrivateComponent'
import CustomSelect from 'components/Select'
import SimpleCard from 'components/SimpleCard'
import Main from 'layouts/main'
import { CSVLink } from 'react-csv'
import {
  AiOutlineDownload,
  AiOutlineFilter,
  AiOutlinePieChart
} from 'react-icons/ai'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import BlankSpace from './components/BlankSpace'
import {
  BtnExportacao,
  Colors,
  IconEventWrapper,
  LegendasWrapper
} from './style'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import useRedux from 'hooks/useRedux'
import { INDICACAO } from 'store/modules/indicacao/types'
import * as indicacaoActions from 'store/modules/indicacao/actions'
import classNames from 'classnames'
import { maskCPF } from 'util/masks'
import { COLUMNS } from './constants/columns'
import ModalEdicao from './components/Modals/ModalEdicao'
import ModalExclusao from 'components/ModalExclusao'

const Indicacao = () => {
  const {
    filtros,
    tabelaIndicacao,
    optionsSelect,
    pagination,
    isOpenModalEdicao,
    showConfirmacaoExclusao
  } = useSelector<ApplicationState, INDICACAO>((state) => state.indicacao)

  const { dispatch } = useRedux()
  type Fields = keyof INDICACAO['filtros']
  function onChange(value: string, field: Fields) {
    dispatch(indicacaoActions.setFiltros(value, field))
  }

  function aplicarFiltros() {
    dispatch(indicacaoActions.setPaginacao({ ...pagination, page: 0 }))
    dispatch(indicacaoActions.getAllPropostasIndicacaoSaga())
  }

  function limparFiltros() {
    dispatch(indicacaoActions.cleanFiltros())
  }

  function setPage(value: number) {
    dispatch(indicacaoActions.setPaginacao({ ...pagination, page: value }))
    dispatch(indicacaoActions.getAllPropostasIndicacaoSaga())
  }

  function setPageSize(value: number) {
    dispatch(
      indicacaoActions.setPaginacao({ ...pagination, page: 0, pageSize: value })
    )
    dispatch(indicacaoActions.getAllPropostasIndicacaoSaga())
  }

  useEffect(() => {
    dispatch(indicacaoActions.getCentrosCustosSaga())
  }, [dispatch])

  const headers = [
    { label: 'Equipe', key: 'descricaoCentroCusto' },
    { label: 'Nome digitador', key: 'nomeDigitador' },
    { label: 'Data e hora', key: 'dataProposta' },
    { label: 'Nome do cliente', key: 'nomeCliente' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Adesão', key: 'numeroAdesao' },
    { label: 'Banco', key: 'descricaoBanco' },
    { label: 'Status', key: 'descricaoStatusProposta' },
    { label: 'Tipo Operação', key: 'descricaoTipoOperacao' }
  ]

  const csvReport = {
    data: tabelaIndicacao,
    headers: headers,
    filename: 'PlanilhaIndicacao.csv'
  }
  return (
    <Main>
      <SimpleCard>
        <div className="container-fluid d-flex flex-column row-gap-10">
          <SimpleTitleCard>
            <AiOutlineFilter className="icon" />
            Filtro
          </SimpleTitleCard>
          <Row>
            <Col md={3}>
              <label className="label-12">Data inicial</label>
              <InputCustom
                type="date"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'dataInicial')
                }}
                value={filtros.dataInicial}
              />
            </Col>
            <Col md={3}>
              <label className="label-12">Data final</label>
              <InputCustom
                type="date"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'dataFinal')
                }}
                value={filtros.dataFinal}
              />
            </Col>
            <Col md={3}>
              <label className="label-12">Equipe</label>
              <CustomSelect
                options={optionsSelect.centros}
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'centroCustoId')
                }}
                value={filtros.centroCustoId}
                accessorLabel="nome"
                accessorValue="id"
              />
            </Col>
            <Col md={3}>
              <label className="label-12">Adesão</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'numeroAdesao')
                }}
                value={filtros.numeroAdesao}
              />
            </Col>
            <Col md={3}>
              <label className="label-12">CPF</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(maskCPF(e.target.value), 'cpf')
                }}
                value={filtros.cpf}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex gap-3">
              <CustomButton
                letterSpacing={2}
                background="#662d91"
                color="#fff"
                justifyContent="center"
                onClick={() => {
                  limparFiltros()
                  aplicarFiltros()
                }}
              >
                Limpar filtros
              </CustomButton>
              <CustomButton
                letterSpacing={2}
                color="#fff"
                justifyContent="center"
                onClick={() => {
                  aplicarFiltros()
                }}
              >
                Aplicar filtros
              </CustomButton>
            </Col>
          </Row>
        </div>
      </SimpleCard>
      <SimpleCard>
        <div className="container-fluid">
          <SimpleTitleCard>
            <AiOutlinePieChart className="icon" />
            Indicação
          </SimpleTitleCard>
          <PrivateComponent typeActions="exportar" screen="indicacao">
            <Row>
              <LegendasWrapper>
                <div>
                  <IconEventWrapper
                    background={Colors.verde}
                  ></IconEventWrapper>
                  <label>Indicação Manual</label>
                </div>
              </LegendasWrapper>
              <Col>
                <BtnExportacao
                  className={classNames(tabelaIndicacao.length > 0 && 'active')}
                >
                  <CSVLink {...csvReport}>
                    <AiOutlineDownload />
                    Exportar Tabela
                  </CSVLink>
                </BtnExportacao>
              </Col>
            </Row>
          </PrivateComponent>
          {tabelaIndicacao.length > 0 ? (
            <CustomTable
              data={tabelaIndicacao}
              columns={COLUMNS}
              rowCount={pagination.totalRegistros}
              page={pagination.page}
              pageSize={pagination.pageSize}
              setPage={(e) => setPage(e)}
              setPageSize={(e) => setPageSize(e)}
              autoHeight
            />
          ) : (
            <BlankSpace />
          )}
        </div>
        <ModalEdicao
          isOpen={isOpenModalEdicao}
          onToggle={() => {
            indicacaoActions.toggleModalEdicaoIndicacaoDispatch(
              !isOpenModalEdicao
            )
          }}
        />
        <ModalExclusao
          isOpen={showConfirmacaoExclusao}
          toggle={() => {
            indicacaoActions.toggleModalExclusaoDispatch(
              !showConfirmacaoExclusao
            )
          }}
          onRemove={() => {
            dispatch(indicacaoActions.deletarPropostaIndicacaoSaga())
          }}
        />
      </SimpleCard>
    </Main>
  )
}

export default Indicacao
