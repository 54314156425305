import React, { useEffect, useState } from 'react'
import { CgProfile } from 'react-icons/cg'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter
} from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { maskPhoneWithDDD } from 'util/masks'
import InputCustom from 'components/Inputs'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { Checkbox } from 'views/inicio/style'

interface NovoTelefoneProps {
  isOpen: boolean
  onToggle: () => void
  adicionarFunc: (e: string, isWhatsapp: boolean) => void
}

const NovoTelefone = ({
  isOpen,
  onToggle,
  adicionarFunc
}: NovoTelefoneProps) => {
  const [telefone, setTelefone] = useState('')
  const [isWhatsApp, setIsWhatsapp] = useState(false)
  const [invalid, setInvalid] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setTelefone('')
      setIsWhatsapp(false)
      setInvalid(false)
    }
  }, [isOpen])

  const schema = yup.object().shape({
    telefone: yup
      .string()
      .required()
      .test(
        'length',
        'Deve conter entre 14 e 15 caracteres',
        (val) => val?.length === 14 || val?.length === 15
      )
  })

  function onAdicionar() {
    setInvalid(false)
    schema
      .validate(
        {
          telefone: telefone
        },
        { abortEarly: false }
      )
      .then(() => {
        adicionarFunc(telefone, isWhatsApp)
        onToggle()
      })
      .catch(() => {
        setInvalid(true)
        toast.error('Preencha o telefone corretamente para salvar', {
          autoClose: false
        })
      })
  }

  return (
    <Modal size="md" isOpen={isOpen} toggle={() => onToggle()}>
      <ModalHeader
        toggle={() => {
          onToggle()
        }}
      >
        <SimpleTitleCard>
          <CgProfile className="icon" />
          Adicionar novo telefone
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <label className="label-12">Telefone*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                setTelefone(maskPhoneWithDDD(e.target.value))
              }}
              value={telefone}
              invalid={invalid}
            />
          </Col>
          <div className="d-flex align-items-center mt-3">
            <Checkbox
              onChange={(e) => {
                setIsWhatsapp(e.target.checked)
              }}
            />
            <label>WhatsApp</label>
          </div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <CustomButton background="var(--roxo-riber)" onClick={() => onToggle()}>
          Cancelar
        </CustomButton>
        <CustomButton
          onClick={() => {
            onAdicionar()
          }}
        >
          Confirmar
        </CustomButton>
      </ModalFooter>
    </Modal>
  )
}

export default NovoTelefone
