import React from 'react'
import { FiChevronsRight } from 'react-icons/fi'
import { Carteira } from '../style'
import { maskMoney } from 'util/masks'
import useRedux from 'hooks/useRedux'
import * as inicioActions from 'store/modules/inicio/actions'

interface Props {
  nomeConsultor: string
  tipoProduto: number
  produto: string
  totalClientes: number
  totalClientesAcionar: number
  totalClientesNovos: number
  totalClientesPerdidos: number
  valorOportunidade: number
  consultorId: string
}

/**
 * @param nomeConsultor string
 * @param tipoProduto number
 * @param produto string
 * @param totalClientes number
 * @param totalClientesAcionar number
 * @param totalClientesNovos number
 * @param totalClientesPerdidos number
 * @param valorOportunidade number
 * @param consultorId string
 */

function CardCarteira({
  nomeConsultor,
  tipoProduto,
  produto,
  totalClientes,
  totalClientesAcionar,
  totalClientesNovos,
  totalClientesPerdidos,
  valorOportunidade,
  consultorId
}: Props) {
  const { dispatch } = useRedux()

  function iniciarAtendimentoCarteira(tipoProduto: number) {
    dispatch(
      inicioActions.iniciarAtendimentoCarteirasSaga(
        tipoProduto,
        consultorId ?? ''
      )
    )
  }

  switch (tipoProduto) {
    case 1:
      return (
        <Carteira>
          <div className="d-flex">
            <label className="label-12">{nomeConsultor}</label>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <label className="label-12">Total de clientes</label>
              <div>
                <span className="green-text">{totalClientesAcionar}</span>
                <span className="purple-text">/{totalClientes}</span>
              </div>
            </div>
            <div className="d-flex flex-column">
              <label className="label-12">Perdidos</label>
              <span className="red-text">-{totalClientesPerdidos}</span>
            </div>
            <div className="d-flex flex-column">
              <label className="label-12">Esse mês</label>
              <span className="green-text">+{totalClientesNovos}</span>
            </div>
          </div>
          <div className="d-flex flex-column mt-2">
            <label className="label-12">Valor de oportunidade</label>
            <span className="purple-text">
              R$ {maskMoney(valorOportunidade?.toFixed(2))}
            </span>
          </div>
          <div className="d-flex justify-content-center">
            <button
              role="button"
              onClick={() => {
                iniciarAtendimentoCarteira(tipoProduto)
              }}
              disabled={totalClientesAcionar <= 0}
              className="btn-outline-purple"
            >
              Iniciar <FiChevronsRight />
            </button>
          </div>
        </Carteira>
      )
    default:
      return (
        <Carteira>
          <div className="d-flex">
            <label className="label-12">{nomeConsultor}</label>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <label className="label-12">{produto}</label>
              <div>
                <span className="green-text">{totalClientesAcionar}</span>
                <span className="purple-text">/{totalClientes}</span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column mt-2">
            <label className="label-12">Valor de oportunidade</label>
            <span className="purple-text">
              R$ {maskMoney(valorOportunidade?.toFixed(2))}
            </span>
          </div>
          <div className="d-flex justify-content-center">
            <button
              role="button"
              onClick={() => {
                iniciarAtendimentoCarteira(tipoProduto)
              }}
              disabled={totalClientesAcionar <= 0}
              className="btn-outline-purple"
            >
              Iniciar <FiChevronsRight />
            </button>
          </div>
        </Carteira>
      )
  }
}

export default CardCarteira
