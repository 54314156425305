import React from 'react'
import { BsQuestionCircle } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'

const ConfirmacaoCadastro = () => {
  const { isOpenConfirmacaoCadastro } = useSelector<
    ApplicationState,
    ATENDIMENTO
  >((state) => state.atendimento)

  const { dispatch } = useRedux()

  function toggleModal() {
    dispatch(
      atendimentoActions.toggleConfirmacaoCadastro(!isOpenConfirmacaoCadastro)
    )
  }

  function confirmarCadastramento() {
    dispatch(atendimentoActions.toggleCadastroSimplificado(true))
    dispatch(atendimentoActions.cleanInvalidCadastroClienteModal())
    toggleModal()
  }
  return (
    <Modal isOpen={isOpenConfirmacaoCadastro} toggle={() => toggleModal()}>
      <ModalBody>
        <ModalHeader toggle={() => toggleModal()}>
          <SimpleTitleCard>
            <BsQuestionCircle className="icon" />
            Cliente não encontrado
          </SimpleTitleCard>
        </ModalHeader>
        <Row className="d-flex my-4 row-gap-15">
          <Col>
            <label>Deseja cadastrar um novo cliente?</label>
          </Col>
        </Row>
        <ModalFooter className="d-flex justify-content-center">
          <Row className="d-flex column-gap-20">
            <CustomButton onClick={() => toggleModal()} background="#662D91">
              Cancelar
            </CustomButton>
            <CustomButton onClick={() => confirmarCadastramento()}>
              Cadastrar
            </CustomButton>
          </Row>
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmacaoCadastro
