import { Paginacao } from 'models/types'

export enum TYPES {
  SET_ACTIVE_TAB = '@@carteira/SET_ACTIVE_TAB',
  SET_ALL_CARTEIRAS = '@@carteira/SET_ALL_CARTEIRAS',
  SET_DETAILS_CARTEIRAS = '@@carteira/SET_DETAILS_CARTEIRAS',
  SET_VISUALIZACAO = '@@carteira/SET_VISUALIZACAO',
  SET_PESQUISA = '@@carteira/SET_PESQUISA',
  CLEAN_SEARCH = '@@carteira/CLEAN_SEARCH',
  SET_PAGINATION = '@@carteira/SET_PAGINATION',
  SET_IDS_TRANSFERENCIA = '@@carteira/SET_IDS_TRANSFERENCIA',
  SET_ID_NAME = '@@carteira/SET_ID_NAME',
  SET_CONSULTOR = '@@carteira/SET_CONSULTOR',
  SET_OPEN_CLIENT_NO_CONSULTANTS_MODAL = '@@carteira/SET_OPEN_CLIENT_NO_CONSULTANTS_MODAL',
  SET_CLIENT_NO_CONSULTANTS_MODAL = '@@carteira/SET_CLIENT_NO_CONSULTANTS_MODAL',
  CLEAN_CLIENT_NO_CONSULTANTS_MODAL = '@@carteira/CLEAN_CLIENT_NO_CONSULTANTS_MODAL',
  SET_OPEN_CONSULTANTS_LIST_MODAL = '@@carteira/SET_OPEN_CONSULTANTS_LIST_MODAL',
  SET_CONSULTANTS_LIST_MODAL = '@@carteira/SET_CONSULTANTS_LIST_MODAL',
  CLEAN_CONSULTANTS_LIST_MODAL = '@@carteira/CLEAN_CONSULTANTS_LIST_MODAL',
  SET_OPEN_SHOP_LIST_MODAL = '@@carteira/SET_OPEN_SHOP_LIST_MODAL',
  SET_SHOP_LIST_MODAL = '@@carteira/SET_SHOP_LIST_MODAL',
  CLEAN_SHOP_LIST_MODAL = '@@carteira/CLEAN_SHOP_LIST_MODAL',

  GET_ALL_CARTEIRAS_SAGA = '@@carteira/GET_ALL_CARTEIRAS_SAGA',
  VISUALIZAR_CARTEIRA_DISPATCH_SAGA = '@@carteira/VISUALIZAR_CARTEIRA_DISPATCH_SAGA',
  EDITAR_CARTEIRA_DISPATCH_SAGA = '@@carteira/EDITAR_CARTEIRA_DISPATCH_SAGA',
  GET_CLIENTES_POR_LOJA_DISPATCH_SAGA = '@@carteira/GET_CLIENTES_POR_LOJA_DISPATCH_SAGA',
  GET_CLIENTES_POR_CONSULTOR_SAGA = '@@carteira/GET_CLIENTES_POR_CONSULTOR_SAGA',
  DIVIDIR_CARTEIRA_CONSULTOR_SAGA = '@@carteira/DIVIDIR_CARTEIRA_CONSULTOR_SAGA',
  TRANSFERIR_CARTEIRA_CONSULTOR_SAGA = '@@carteira/TRANSFERIR_CARTEIRA_CONSULTOR_SAGA',
  TRANSFERIR_CARTEIRA_LOJA_SAGA = '@@carteira/TRANSFERIR_CARTEIRA_LOJA_SAGA',
  GET_CLIENTES_SEM_CONSULTOR_SAGA = '@@carteira/GET_CLIENTES_SEM_CONSULTOR_SAGA',
  GET_ID_NAME_DISPATCH_SAGA = '@@carteira/GET_ID_NAME_DISPATCH_SAGA',
  GET_CONSULTOR_DISPATCH_SAGA = '@@carteira/GET_CONSULTOR_DISPATCH_SAGA',
  ATUALIZA_CARTEIRAS_SAGA = '@@carteira/ATUALIZA_CARTEIRAS_SAGA'
}

interface Search {
  pesquisa: string
  clientesSemConsultores: string
  ativo: string
}

export type ActiveTab = 'search' | 'edit'

export interface ParametrizacaoCarteira {
  id: string
  descricao: string
}
export interface Carteiras {
  lojaId: string
  nomeLoja: string
  qtdConsultores: number
  qtdClientesTotal: number
  qtdClientesSemConsultor: number
  dataUltimaAlteracao: string
}
export interface DetailsConsultor {
  nomeConsultor: string
  consultorId: string
  qtdClientes: number
  dataVigenciaInicial: string
}
export interface DetailsCarteira {
  visualizacao: boolean
  lojaId: string
  qtdClientesSemConsultor: number
  detalheConsultorDto: DetailsConsultor[]
}
export interface InfoLoja {
  id: string
  lojaId: string
  nomeLoja: string
  qtdConsultores: number
  qtdClientesTotal: number
  qtdClientesSemConsultor: number
  dataUltimaAlteracao: null
}
export interface formatarId {
  id: string
  consultorId: string
}
export interface InfoConsultor {
  consultorId: string
  dataVigenciaInicial: null
  id: string
  nomeConsultor: string
  qtdClientes: number
}

export interface CARTEIRA {
  idsTransferencia: string[]
  dividirClientes: []
  carteiras: Carteiras[]
  previewTranferir: {
    previewLoja: []
    previewConsultores: []
  }
  details: {
    visualizacao: boolean
    lojaId: string
    qtdClientesSemConsultor: number
    detalheConsultorDto: DetailsConsultor[]
  }
  selectedLoja: {
    nome: string
    id: string
  }
  selectedConsultor: {
    nome: string
    id: string
  }
  activeTab: ActiveTab
  search: Search
  register: ParametrizacaoCarteira
  pagination: Paginacao
  visualizacao: boolean
  itemSelectedId: string
  showConfirmacaoExclusao: boolean
  clientNoConsultantsModal: {
    lojaId: string
    consultores: InfoConsultor[]
  }
  consultantsListModal: {
    lojaId: string
    desconsiderarConsultorId: string
    consultores: InfoConsultor[]
  }
  shopListModal: {
    lojaId: string
    lojas: InfoLoja[]
  }
  isOpenClientNoConsultantsModal: boolean
  isOpenConsultantsListModal: boolean
  isOpenShopListModal: boolean
}

export interface Shops {
  id: string
  name: string
}
