import { APICore } from 'helpers/api/apiCore'

const api = new APICore()

export function getQuantidadeLeads() {
  return api.get(`Atendimento/leads`, null)
}

export function iniciarAtendimentoLead() {
  return api.get(`Atendimento/iniciar-atendimento-lead`, null)
}

export function getCarteiras() {
  return api.get(`Home/carteiras`, null)
}

export function iniciarAtendimentoCarteiras(
  tipoProduto: number,
  consultorId: string
) {
  return api.get(`Atendimento/iniciar-atendimento-carteira`, {
    tipoProduto,
    consultorId
  })
}

export function getCampanhas() {
  return api.get(`Home/campanhas`, null)
}

export function iniciarAtendimentoCampanhas(campanhaCrmId: string) {
  return api.get(`Atendimento/iniciar-atendimento-campanha`, {
    campanhaCrmId
  })
}

export function getBigNumbers(dataAgendamentos: string) {
  return api.get(`Agenda/big-numbers`, {
    dataAgendamentos
  })
}
