import { ReactNode } from 'react'
import { CardErrorMessageOfertaProdutoRefin } from '../style'
import { AiOutlineWarning } from 'react-icons/ai'

interface ErrorCardContratoRefinItauProps {
  children: ReactNode
}

export function ErrorCardContratoRefinItau({
  children
}: ErrorCardContratoRefinItauProps) {
  return (
    <CardErrorMessageOfertaProdutoRefin>
      <div style={{ width: '1rem' }}>
        <AiOutlineWarning size={20} />
      </div>
      <div style={{ textAlign: 'start' }}>{children}</div>
    </CardErrorMessageOfertaProdutoRefin>
  )
}
