/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

interface HorizontalTableProps {
  data: any[]
  accessorLabel: string
  accessorFirstValue: string
  accessorSecondValue: string
}

const HorizontalTable = ({
  data,
  accessorLabel,
  accessorFirstValue,
  accessorSecondValue
}: HorizontalTableProps) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )

  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        align: 'end' as const
      }
    }
  }
  const labels = data.map((data) => {
    return data[accessorLabel]
  })

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Quantidade de Usuários',
        data: data.map((data) => data[accessorFirstValue]),
        borderColor: '#13C2C2',
        backgroundColor: '#13C2C2',
        borderRadius: 5
      },
      {
        label: 'Média da empresa',
        data: data.map((data) => data[accessorSecondValue]),
        borderColor: '#662D91',
        backgroundColor: '#662D91',
        borderRadius: 5
      }
    ]
  }

  return <Bar options={options} data={chartData} />
}
export default HorizontalTable
