import { TYPES } from './types'

export const toggleToken = () => ({
  type: TYPES.TOGGLE_TOKEN
})

export const setToken = (value: string) => ({
  type: TYPES.SET_TOKEN,
  value
})

export const cleanToken = () => ({
  type: TYPES.CLEAN_TOKEN
})

export const atualizaTokenSaga = () => ({
  type: TYPES.ATUALIZA_TOKEN_SAGA
})

export const visualizaToken = () => ({
  type: TYPES.SET_VISUALIZACAO_TOKEN
})

export const setNomeUsuario = (value: string) => ({
  type: TYPES.SET_NOME_USUARIO,
  value
})

export const setUsuarioSelected = (value: string) => ({
  type: TYPES.SET_USUARIO_SELECTED,
  value
})

export const cleanUsuarioSelected = () => ({
  type: TYPES.CLEAN_USUARIO_SELECTED
})

export const getUsuariosInstituicaoSaga = () => ({
  type: TYPES.GET_USUARIOS_INSTITUICAO_SAGA
})

export const setUsuariosInstituicao = (value: string[]) => ({
  type: TYPES.SET_USUARIOS_INSTITUICAO,
  value
})
