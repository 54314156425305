import { Reducer } from 'redux'
import { SIDEBAR, TYPES } from './types'

const INITIAL_STATE: SIDEBAR = {
  isOpen: sessionStorage.getItem('PIN_SIDEBAR') === 'true' || false,
  pin: sessionStorage.getItem('PIN_SIDEBAR') === 'true'
}

const reducer: Reducer<SIDEBAR> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.TOGGLE_SIDEBAR:
      return { ...state, isOpen: !state.isOpen }
    case TYPES.SET_PIN:
      return { ...state, pin: action.value }
    default:
      return state
  }
}

export default reducer
