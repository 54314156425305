import React from 'react'
import { FiChevronsRight } from 'react-icons/fi'
import { CampanhaBoard } from '../style'
import { maskDate } from 'util/masks'
import useRedux from 'hooks/useRedux'
import * as inicioActions from 'store/modules/inicio/actions'

interface Props {
  id: string
  nomeCampanha: string
  dataInicial: string
  dataFinal: string
  tipoOperacao: string
  totalClientes: number
  totalClientesAcionar: number
  contato: string
  conversao: string
}

function CardCampanha({
  id,
  nomeCampanha,
  dataInicial,
  dataFinal,
  tipoOperacao,
  totalClientes,
  totalClientesAcionar,
  contato,
  conversao
}: Props) {
  const { dispatch } = useRedux()
  return (
    <CampanhaBoard>
      <section className="informacoes-campanha">
        <div className="d-flex flex-column align-items-lg-start">
          <div className="titulo-campanha">{nomeCampanha}</div>
          <div>
            Inicio:
            <span className="green-text md">
              {maskDate(dataInicial, 'DD/MM/YYYY')}
            </span>
          </div>
          <div>
            Final:
            <span className="green-text md">
              {maskDate(dataFinal, 'DD/MM/YYYY')}
            </span>
          </div>
          <div>
            Tipo operação: <span>{tipoOperacao}</span>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-around">
          <div className="d-flex flex-column align-items-center">
            <span className="purple-text lg">
              {totalClientesAcionar}/{totalClientes}
            </span>
            T. Clientes:
          </div>
          <div className="d-flex flex-column align-items-center">
            <span className="purple-text lg">{contato}</span>
            C. Positivo:
          </div>
          <div className="d-flex flex-column align-items-center">
            <span className="purple-text lg">{conversao}</span>Conversão:
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-center align-content-center m-2">
        <button
          onClick={() => {
            dispatch(inicioActions.iniciarAtendimentoCampanhasSaga(id))
          }}
          role="button"
          disabled={totalClientesAcionar <= 0}
          className="btn-outline-purple"
        >
          Iniciar <FiChevronsRight />
        </button>
      </div>
    </CampanhaBoard>
  )
}

export default CardCampanha
