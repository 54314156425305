import React from 'react'
import CustomTable from 'components/CustomTable'
import { TopData } from 'mocks/datas'
import { FiPieChart } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import { COLUMNS } from '../constants/columns'
import { TituloFunil, CardVendas, Colors } from '../style'
import { INDICADORES } from 'store/modules/indicadores/types'
import LoadingComponent from './LoadingComponent'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { maskDottedNumber } from 'util/masks'

const EsforcoVenda = () => {
  const { loadings, esforcoVendas } = useSelector<
    ApplicationState,
    INDICADORES
  >((state) => state.indicadores)

  return (
    <>
      <SimpleTitleCard>
        <FiPieChart className="icon" /> Esforço de venda
      </SimpleTitleCard>
      <div className="d-flex flex-column row-gap-15">
        <LoadingComponent isLoading={loadings.esforcoVendas}>
          <Row>
            <Col xl>
              <TituloFunil>Ativo</TituloFunil>
              <div className="d-flex flex-column row-gap-15">
                <CardVendas background={Colors.green}>
                  <div className="d-flex flex-column justify-content-between w-100">
                    <label>Acionados</label>
                    <div className="d-flex justify-content-between">
                      <span>
                        {maskDottedNumber(esforcoVendas.ativo?.acionados.valor)}
                      </span>
                      <span className="porcentagem">
                        {esforcoVendas.ativo?.acionados.porcentagem}
                      </span>
                    </div>
                  </div>
                </CardVendas>
                <CardVendas background={Colors.green}>
                  <div className="d-flex flex-column justify-content-between w-100">
                    <label>Convertidos</label>
                    <div className="d-flex justify-content-between">
                      <span>
                        {maskDottedNumber(
                          esforcoVendas.ativo?.convertidos.valor
                        )}
                      </span>
                      <span className="porcentagem">
                        {esforcoVendas.ativo?.convertidos.porcentagem}
                      </span>
                    </div>
                  </div>
                </CardVendas>
              </div>
            </Col>
            <Col xl>
              <TituloFunil>Agendado</TituloFunil>
              <div className="d-flex flex-column row-gap-15">
                <CardVendas background={Colors.lightPurple}>
                  <div className="d-flex flex-column justify-content-between w-100">
                    <label>Agendados</label>
                    <div className="d-flex justify-content-between">
                      <span>
                        {maskDottedNumber(
                          esforcoVendas.agendamentos?.agendados.valor
                        )}
                      </span>
                      <span className="porcentagem">
                        {esforcoVendas.agendamentos?.agendados.porcentagem}
                      </span>
                    </div>
                  </div>
                </CardVendas>
                <CardVendas background={Colors.lightPurple}>
                  <div className="d-flex flex-column justify-content-between w-100">
                    <label>Convertidos</label>
                    <div className="d-flex justify-content-between">
                      <span>
                        {maskDottedNumber(
                          esforcoVendas.agendamentos?.convertidos.valor
                        )}
                      </span>
                      <span className="porcentagem">
                        {esforcoVendas.agendamentos?.convertidos.porcentagem}
                      </span>
                    </div>
                  </div>
                </CardVendas>
              </div>
            </Col>
            <Col xl>
              <TituloFunil>Receptivo</TituloFunil>
              <div className="d-flex flex-column row-gap-15">
                <CardVendas background={Colors.purple}>
                  <div className="d-flex flex-column justify-content-between w-100">
                    <label>Atendidos</label>
                    <div className="d-flex justify-content-between">
                      <span>
                        {maskDottedNumber(
                          esforcoVendas.receptivo?.atendidos.valor
                        )}
                      </span>
                      <span className="porcentagem">
                        {esforcoVendas.receptivo?.atendidos.porcentagem}
                      </span>
                    </div>
                  </div>
                </CardVendas>
                <CardVendas background={Colors.purple}>
                  <div className="d-flex flex-column justify-content-between w-100">
                    <label>Convertidos</label>
                    <div className="d-flex justify-content-between">
                      <span>
                        {maskDottedNumber(
                          esforcoVendas.receptivo?.convertidos.valor
                        )}
                      </span>
                      <span className="porcentagem">
                        {esforcoVendas.receptivo?.convertidos.porcentagem}
                      </span>
                    </div>
                  </div>
                </CardVendas>
              </div>
            </Col>
            <Col xl>
              <TituloFunil>Site</TituloFunil>
              <div className="d-flex flex-column row-gap-15">
                <CardVendas background={Colors.darkCyano}>
                  <div className="d-flex flex-column justify-content-between w-100">
                    <label>Recebidos</label>
                    <div className="d-flex justify-content-between">
                      <span>
                        {maskDottedNumber(esforcoVendas.site?.recebidos.valor)}
                      </span>
                      <span className="porcentagem">
                        {esforcoVendas.site?.recebidos.porcentagem}
                      </span>
                    </div>
                  </div>
                </CardVendas>
                <CardVendas background={Colors.darkCyano}>
                  <div className="d-flex flex-column justify-content-between w-100">
                    <label>Convertidos</label>
                    <div className="d-flex justify-content-between">
                      <span>
                        {maskDottedNumber(
                          esforcoVendas.site?.convertidos.valor
                        )}
                      </span>
                      <span className="porcentagem">
                        {esforcoVendas.site?.convertidos.porcentagem}
                      </span>
                    </div>
                  </div>
                </CardVendas>
              </div>
            </Col>
            <Col xl>
              <TituloFunil>SMS</TituloFunil>
              <div className="d-flex flex-column row-gap-15">
                <CardVendas background={Colors.cyano}>
                  <div className="d-flex flex-column justify-content-between w-100">
                    <label>Enviados</label>
                    <div className="d-flex justify-content-between">
                      <span>
                        {maskDottedNumber(esforcoVendas.sms?.enviados.valor)}
                      </span>
                      <span className="porcentagem">
                        {esforcoVendas.sms?.enviados.porcentagem}
                      </span>
                    </div>
                  </div>
                </CardVendas>
                <CardVendas background={Colors.cyano}>
                  <div className="d-flex flex-column justify-content-between w-100">
                    <label>Convertidos</label>
                    <div className="d-flex justify-content-between">
                      <span>
                        {maskDottedNumber(esforcoVendas.sms?.convertidos.valor)}
                      </span>
                      <span className="porcentagem">
                        {esforcoVendas.sms?.convertidos.porcentagem}
                      </span>
                    </div>
                  </div>
                </CardVendas>
              </div>
            </Col>
          </Row>
          <Row>
            <CustomTable
              data={TopData}
              columns={COLUMNS}
              paginationMode="client"
              checkboxSelection
            />
          </Row>
        </LoadingComponent>
      </div>
    </>
  )
}

export default EsforcoVenda
