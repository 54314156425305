import React from 'react'
import { BsClipboard, BsGenderAmbiguous, BsPatchCheck } from 'react-icons/bs'
import { HiOutlineCake, HiOutlineShieldCheck } from 'react-icons/hi'
import { FaHandHoldingUsd } from 'react-icons/fa'
import { BigBumbersConsigWrapper } from '../style'
import { BiWallet } from 'react-icons/bi'
import { GoCalendar, GoCreditCard } from 'react-icons/go'
import { MdEmojiPeople, MdFastfood } from 'react-icons/md'
import { maskDate, maskMoney } from 'util/masks'
import { RiForbid2Line } from 'react-icons/ri'

interface BigNumbersConsigProps {
  nome?: string | undefined
  idade?: string | undefined
  salario?: number | undefined
  margemEmprestimo?: number | undefined
  margemConsignado?: number | undefined
  margemBeneficio?: number | undefined
  situacao?: string | undefined
  especie?: string | undefined
  possuiRepresentanteLegalProcurador?: string | undefined
  bloqueioEmprestismo?: string | undefined
  beneficioPermiteEmprestimo?: string | undefined
  pensaoAlimenticia?: string | undefined
  dataDespachoBeneficio?: string | undefined
}

const BigNumbersConsig = ({
  nome,
  idade,
  salario,
  margemEmprestimo,
  margemConsignado,
  margemBeneficio,
  especie,
  situacao,
  possuiRepresentanteLegalProcurador,
  bloqueioEmprestismo,
  beneficioPermiteEmprestimo,
  pensaoAlimenticia,
  dataDespachoBeneficio
}: BigNumbersConsigProps) => {
  return (
    <BigBumbersConsigWrapper>
      <div className="bignumber">
        <BsGenderAmbiguous className="icon" />
        <label>Nome</label>
        <span>{nome}</span>
      </div>
      <div className="bignumber">
        <HiOutlineCake className="icon" />
        <label>Idade</label>
        <span>{idade} Anos</span>
      </div>
      <div className="bignumber">
        <BiWallet className="icon" />
        <label>Benefício</label>
        <span>{maskMoney(salario?.toFixed(2))}</span>
      </div>
      <div className="bignumber">
        <FaHandHoldingUsd className="icon" />
        <label>Margem 35</label>
        <span>{maskMoney(margemEmprestimo?.toFixed(2))}</span>
      </div>
      <div className="bignumber">
        <GoCreditCard className="icon" />
        <label>Cartão Consignado</label>
        <span>{maskMoney(margemConsignado?.toFixed(2))}</span>
      </div>
      <div className="bignumber">
        <GoCreditCard className="icon" />
        <label>Cartão Benefício</label>
        <span>{maskMoney(margemBeneficio?.toFixed(2))}</span>
      </div>
      <div className="bignumber">
        <HiOutlineShieldCheck className="icon" />
        <label>Situação</label>
        <span>{situacao}</span>
      </div>
      <div className="bignumber">
        <BsClipboard className="icon" />
        <label>Espécie</label>
        <span>{especie}</span>
      </div>
      <div className="bignumber">
        <MdEmojiPeople className="icon" />
        <label>Representante / Procurador</label>
        <span>{possuiRepresentanteLegalProcurador}</span>
      </div>
      <div className="bignumber">
        <RiForbid2Line className="icon" />
        <label>Bloqueio Empréstimo</label>
        <span>{bloqueioEmprestismo}</span>
      </div>
      <div className="bignumber">
        <BsPatchCheck className="icon" />
        <label>Permite Empréstimo</label>
        <span>{beneficioPermiteEmprestimo}</span>
      </div>
      <div className="bignumber">
        <MdFastfood className="icon" />
        <label>Pensão Alimentícia</label>
        <span>{pensaoAlimenticia}</span>
      </div>
      <div className="bignumber">
        <GoCalendar className="icon" />
        <label>Data Despacho Benefício</label>
        <span>{maskDate(dataDespachoBeneficio ?? '', 'DD/MM/YYYY')}</span>
      </div>
    </BigBumbersConsigWrapper>
  )
}

export default BigNumbersConsig
