import styled from 'styled-components'

export enum Colors {
  green = 'green',
  cyano = 'cyano',
  darkCyano = 'darkCyano',
  purple = 'purple',
  lightPurple = 'lightPurple'
}

function handleColorType(color: Colors | undefined) {
  switch (color) {
    case Colors.green:
      return 'background-color: #21BE51;'
    case Colors.cyano:
      return 'background-color: #40959F;'
    case Colors.darkCyano:
      return 'background-color: #22727B;'
    case Colors.purple:
      return 'background: #662D91'
    case Colors.lightPurple:
      return 'background: #A76BD3'
    default:
      return 'background-color: #FFFFFF;'
  }
}

interface ColorProps {
  background: Colors
}

export const CardBigNumbers = styled.div<ColorProps>`
  color: #fff;
  cursor: pointer;
  margin-top: 15px;
  min-width: 160px;
  width: 30%;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  ${({ background }) => handleColorType(background)};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;

  & div {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    row-gap: 15px;
    width: 100%;

    & span {
      display: block;
      font-size: 28px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }

    & label {
      display: block;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }
  }
`

export const Rank = styled.div`
  height: 340px;
  border: 1px solid #cfcfcf;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  padding: 20px;
  gap: 10px;
  flex-wrap: wrap;
  min-width: 250px;

  & h1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #353535;
  }

  & ul {
    padding-left: unset;
    height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 15px;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      margin: 2px 10px;
    }

    & li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 40px;

      &:nth-of-type(odd) {
        background-color: #f9f9f9;
      }

      & span,
      & label {
        color: #662d91;
        font-size: 13px;
      }

      & span {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 15px;
        width: 100%;
      }

      & label {
        font-weight: 700;
      }
    }
  }

  & .ver-mais {
    color: #662d91;
    font-size: 14px;
    line-height: 24px;
    text-decoration: underline;
  }
`

export const Line = styled.div`
  border: 1px solid #cfcfcf;
  width: 100%;
  margin: 0 auto 5px auto;
`

export const BoxShadow = styled.div`
  border: 1px solid #ebebeb;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
`

export const TituloFunil = styled.label`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #353535;
`

export const CardVendas = styled.div<ColorProps>`
  ${({ background }) => handleColorType(background)};
  border: 1px solid #ebebeb;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 140px;
  min-width: 50px;
  padding: 15px;
  width: 100%;

  label {
    font-weight: 700;
    font-size: 12px;
    line-height: 28px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    font-feature-settings: 'liga';
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  span {
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &.porcentagem {
      text-align: right;
    }
  }
`

export const CampanhaBtn = styled.button`
  background-color: #fff;
  border: 1px solid #662d91;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  color: #662d91;
  font-weight: 700;
  font-size: 14px;
  height: 50px;
  line-height: 18px;
  min-width: 100px;
  width: 15vw;
`
export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`

export const MatrizRFMWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  max-width: 950px;
  max-height: 950px;

  .axis-y-content {
    align-items: center;
    display: flex;
    justify-content: center;
    .label-axis-y {
      align-items: center;
      display: flex;
      font-weight: 700;
      justify-content: center;
      min-width: 0;
      text-align: center;
      transform: rotate(-90deg);
      white-space: nowrap;
    }

    .axis-y {
      border-right: 3px solid #000;
      display: grid;
      grid-template-rows: repeat(5, 1fr);
      height: 100%;
      max-height: 900px;
      width: 25px;

      div {
        align-items: center;
        border-top: 2px solid #000;
        display: flex;
        font-size: 21.5744px;
        font-weight: 700;
        justify-content: center;
        line-height: 17px;
        padding: 5px 2px;
      }
    }
  }

  .axis-x-content {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    .label-axis-x {
      align-items: center;
      display: flex;
      font-weight: 700;
      justify-content: center;
      min-width: 0;
      text-align: center;
      white-space: nowrap;
    }

    .axis-x {
      border-top: 3px solid #000;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      max-width: 900px;
      text-align: end;
      width: 100%;

      div {
        align-items: center;
        border-right: 2px solid #000;
        display: flex;
        justify-content: center;
        font-size: 21.5744px;
        font-weight: 700;
        line-height: 17px;
        padding: 2px 5px;
      }
    }
  }


  .grid {
    aspect-ratio: 1 / 1;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(5, 1fr);
    max-height: 900px;

    .area {
      color: #fff;
      display: flex;
      flex-direction: column;
      padding: 10px;

      .info {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: fit-content;

        span {
          font-weight: 700;
          font-size: 21.5744px;
          line-height: 17px;
        }

        label {
          font-weight: 700;
          font-size: 9.68853px;
          line-height: 12px;
        }
      }
    }

    .area:hover {
      box-shadow: 0 0 10px black;
      position: relative;
    }

    .area-1 {
      background-color: #c8a3e3;
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      position: relative;
      z-index: 1;
    }

    .area-2 {
      background-color: #a76bd3;
      grid-column: 1 / 3;
      grid-row: 2 / 4;
      text-align: right;
    }

    .area-3 {
      background-color: #31a693;
      grid-column: 3 / 6;
      grid-row: 1 / 3;
    }

    .area-4 {
      text-align: right;
      grid-column: 5 / 6;
      grid-row: 1 / 2;
      background-color: #21be51;
      position: relative;
      z-index: 1;
    }

    .area-5 {
      text-align: right;
      grid-column: 3 / 4;
      grid-row: 3 / 4;
      background-color: #22727b;
    }

    .area-6 {
      text-align: right;
      grid-column: 4 / 6;
      grid-row: 3 / 5;
      background-color: #40959f;
    }

    .area-7 {
      grid-column: 1 / 3;
      grid-row: 4 / 6;
      background-color: #8e34e2;
    }

    .area-8 {
      grid-column: 2 / 3;
      grid-row: 4 / 5;
      background-color: #9c4ae8;
      position: relative;
    }

    .area-9 {
      background-color: #7455cc;
      grid-column: 3 / 4;
      grid-row: 4 / 6;
    }

    .area-10 {
      background-color: #6468be;
    }

    .area-11 {
      background-color: #517fae;
    }
  }

  @media (max-width: 767px) {
    .axis-y {
      div {
        font-size: 14px;
        line-height: 12px;
      }
    }

    .axis-x {
      div {
        font-size: 14px;
        line-height: 12px;
      }
    }

    .grid {
      .area {
        .info {
          span {
            font-size: 10px;
            line-height: 12px;
          }
          label {
            font-size: 6px;
            line-height: 8px;
          }
        }
      }
  }
`
