import { INICIO, TYPES } from './types'

export const setCpfAtendimento = (value: string) => ({
  type: TYPES.SET_CPF_ATENDIMENTO,
  value
})

export const cleanCpfAtendimento = () => ({
  type: TYPES.CLEAN_CPF_ATENDIMENTO
})

export const getQuantidadeLeadsSaga = () => ({
  type: TYPES.GET_QUANTIDADE_LEADS_SAGA
})

export const iniciarAtendimentoLeadSaga = () => ({
  type: TYPES.INICIAR_ATENDIENTO_LEAD_SAGA
})

export const setQuantidadeLeads = (value: number) => ({
  type: TYPES.SET_QUANTIDADE_LEADS,
  value
})

export const getCarteirasSaga = () => ({
  type: TYPES.GET_CARTEIRAS_SAGA
})

export const setCarteiras = (value: INICIO['carteiras']) => ({
  type: TYPES.SET_CARTEIRAS,
  value
})

export const iniciarAtendimentoCarteirasSaga = (
  tipoProduto: number,
  consultorId: string
) => ({
  type: TYPES.INICIAR_ATENDIMENTO_CARTEIRAS_SAGA,
  tipoProduto,
  consultorId
})

export const getCampanhasSaga = () => ({
  type: TYPES.GET_CAMPANHAS_SAGA
})

export const setCampanhas = (value: INICIO['campanhas']) => ({
  type: TYPES.SET_CAMPANHAS,
  value
})

export const iniciarAtendimentoCampanhasSaga = (campanhaCrmId: string) => ({
  type: TYPES.INICIAR_ATENDIMENTO_CAMPANHAS_SAGA,
  campanhaCrmId
})

type LoadingFields = keyof INICIO['loading']
export const setLoading = (field: LoadingFields, value: boolean) => ({
  type: TYPES.SET_LOADING,
  value,
  field
})

export const getBigNumbersSaga = (data: string) => ({
  type: TYPES.GET_BIGNUMBERS_SAGA,
  data
})

export const cleanBigNumbers = () => ({
  type: TYPES.CLEAN_BIGNUMBERS
})

export const setBigNumbers = (value: INICIO['bigNumbers']) => ({
  type: TYPES.SET_BIGNUMBERS,
  value
})
