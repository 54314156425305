import React from 'react'
import { BsPencilSquare, BsPlayCircle } from 'react-icons/bs'
import { HiChevronDoubleRight } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { AGENDA } from 'store/modules/agenda/types'
import { AgendaDiariaWrapper, BtnConfirmacao } from '../style'
import TipoAgendamento from './TipoAgendamento'
import useRedux from 'hooks/useRedux'
import * as agendaActions from 'store/modules/agenda/actions'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import { maskCPF, maskDate, maskPhoneWithDDD } from 'util/masks'
import classNames from 'classnames'
import ReactAudioPlayer from 'react-audio-player'
import { IconButton } from '@mui/material'
import Reagendamento from './Modals/Reagendamento'
import moment from 'moment'

const AgendaDiaria = () => {
  const {
    agendaAtendimentosData,
    detalheAgendamento,
    isOpenModalReagendamento
  } = useSelector<ApplicationState, AGENDA>((state) => state.agenda)

  const { dispatch } = useRedux()

  function onEdit() {
    dispatch(agendaActions.cleanModalReagendamento())
    dispatch(agendaActions.cleanModalReagendamentoInvalids())
    dispatch(
      agendaActions.setModalReagendamento({
        atendimentoAgendadoId: detalheAgendamento.id,
        confirmado: detalheAgendamento.confirmado,
        dataAtendimento: maskDate(detalheAgendamento.data, 'YYYY-MM-DD'),
        horario: moment(detalheAgendamento.horario, 'HH:mm:ss').format('HH:mm'),
        tipoAtendimentoId: detalheAgendamento.tipoAtendimentoId,
        confirmarAgendamento: detalheAgendamento.confirmarAgendamento
      })
    )
    dispatch(agendaActions.setIsOpenModalReagendamento(true))
  }

  return (
    <AgendaDiariaWrapper>
      <Row className="d-flex row-gap-10">
        <Col md={7}>
          <div className="lista-atendimento">
            <div className="bloco-ligacao">
              {agendaAtendimentosData?.map((agenda, i) => {
                return (
                  <React.Fragment key={i}>
                    <label className="horario-atendimento">
                      {agenda.horario?.substring(0, 5)}
                    </label>
                    {agenda.agendamentos?.map((agendamento) => {
                      return (
                        <button
                          className={classNames(
                            'info-ligacao',
                            detalheAgendamento.id === agendamento.id && 'active'
                          )}
                          onClick={() => {
                            dispatch(
                              agendaActions.getAgendaAtendimentoDetalheSaga(
                                agendamento.pessoaId,
                                agendamento.id
                              )
                            )
                          }}
                          key={agendamento.id + i}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <TipoAgendamento
                              tipoAgendamento={agendamento.tipoAtendimento}
                              confirmado={agendamento.confirmado}
                              atendimentoConcluidoId={
                                agendamento.atendimentoConcluidoId
                              }
                            />
                            <label className="nome-cliente">
                              {agendamento.nomePessoa}
                            </label>
                          </div>
                          <span className="nome-vendedor">
                            {agendamento.nomeAtendente}
                          </span>
                        </button>
                      )
                    })}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </Col>
        <Col md={5}>
          <div className="detalhamento-agendamento">
            {detalheAgendamento.id && (
              <>
                <div className="d-flex flex-column row-gap-15 w-100">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <h2>Informações do agendamento</h2>
                    <IconButton>
                      <BsPencilSquare
                        role="button"
                        onClick={() => {
                          onEdit()
                        }}
                      />
                    </IconButton>
                  </div>
                  <Row>
                    <Col className="d-flex align-items-center gap-3">
                      <TipoAgendamento
                        tipoAgendamento={detalheAgendamento.tipoAtendimento}
                        confirmado={detalheAgendamento.confirmado}
                        atendimentoConcluidoId={
                          detalheAgendamento.atendimentoConcluidoId
                        }
                      />
                      <label className="label-12">
                        {detalheAgendamento.tipoAtendimento === 'Presencial'
                          ? 'Presencial'
                          : 'Telefônico'}
                      </label>
                    </Col>
                    <Col className="d-flex align-items-center gap-3">
                      <div
                        role="button"
                        onClick={() => {
                          dispatch(
                            agendaActions.getGravacaoSaga(
                              detalheAgendamento.atendimentoId
                            )
                          )
                        }}
                        className="btn-orange"
                      >
                        <BsPlayCircle />
                      </div>
                      <label className="label-12">Ouvir gravação</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column">
                      <label className="label-12">Nome Completo</label>
                      <span>{detalheAgendamento.nomePessoa}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column">
                      <label className="label-12">CPF</label>
                      <span
                        className="link-cpf"
                        onClick={() => {
                          dispatch(
                            atendimentoActions.getFormularioAtendimento30Saga(
                              maskCPF(detalheAgendamento.cpf)
                            )
                          )
                        }}
                      >
                        {maskCPF(detalheAgendamento.cpf)}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column">
                      <label className="label-12">Melhor telefone</label>
                      <span>
                        {maskPhoneWithDDD(detalheAgendamento.melhorTelefone)}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column">
                      <label className="label-12">Horário agendado</label>
                      <span>{detalheAgendamento.horario?.substring(0, 5)}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column">
                      <label className="label-12">Observações</label>
                      <span>{detalheAgendamento.observacoes}</span>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {detalheAgendamento.gravacoes && (
                    <ReactAudioPlayer
                      src={detalheAgendamento.gravacoes ?? ''}
                      autoPlay
                      controls
                    />
                  )}
                </div>
                {detalheAgendamento.confirmarAgendamento && (
                  <div className="d-flex justify-content-center align-items-center">
                    <BtnConfirmacao
                      onClick={() => {
                        dispatch(
                          agendaActions.confirmacaoAgendamentoSaga(
                            detalheAgendamento.id
                          )
                        )
                      }}
                      className="btn-confirmacao"
                      disabled={detalheAgendamento.confirmado}
                    >
                      Confirmar agendamento
                      <HiChevronDoubleRight />
                    </BtnConfirmacao>
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
      <Reagendamento
        isOpen={isOpenModalReagendamento}
        onToggle={() => {
          dispatch(
            agendaActions.setIsOpenModalReagendamento(!isOpenModalReagendamento)
          )
        }}
      />
    </AgendaDiariaWrapper>
  )
}

export default AgendaDiaria
