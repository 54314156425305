import React, { useEffect, useState } from 'react'
import SimpleCard from 'components/SimpleCard'
import { FiServer } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import CustomSelect from 'components/Select'
import * as produtoActions from 'store/modules/backoffice/produto/actions'
import { ApplicationState } from 'store'
import { PRODUTO } from 'store/modules/backoffice/produto/types'
import { useSelector } from 'react-redux'
import useRedux from 'hooks/useRedux'
import CardBancosNaoPortados from '../components/CardBancosNaoPortados'
import CardCartao from '../components/CardCartao'
import CardGruposBeneficio from '../components/CardGruposBeneficio'
import CardIdade from '../components/CardIdade'
import CardPmtPaga from '../components/CardPmtPaga'
import CardSaldoDevedor from '../components/CardSaldoDevedor'
import CardTabelasServicos from '../components/CardTabelasServicos'
import CardTaxaOriginal from '../components/CardTaxaOriginal'
import CardTrocoMinimo from '../components/CardTrocoMinimo'
import CardValorContrato from '../components/CardValorContrato'
import CardValorMargem from '../components/CardValorMargem'
import { BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs'
import { ActionButton } from '../style'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { removeMaskMoney } from 'util/masks'
import BlankSpace from '../components/BlankSpace'
import { ParametrizacaoProduto } from 'models/backoffice/produto/type'

const Register = () => {
  const { register, selects, visualizacao, invalids } = useSelector<
    ApplicationState,
    PRODUTO
  >((state) => state.produto)

  const { dispatch } = useRedux()

  type Field = keyof ParametrizacaoProduto
  function onChange(value: string, field: Field) {
    dispatch(produtoActions.setRegister({ ...register, [field]: value }))
  }

  function voltaParaSearch() {
    dispatch(produtoActions.cleanRegister())
    dispatch(produtoActions.cleanInvalids())
    dispatch(produtoActions.setVisualizacao(false))
    dispatch(produtoActions.setAtiveTab('search'))
  }

  useEffect(() => {
    dispatch(produtoActions.getInstituicoesSaga())
    dispatch(produtoActions.getTaxasSaga())
    dispatch(produtoActions.getBeneficiosSaga())
  }, [dispatch])

  useEffect(() => {
    dispatch(produtoActions.getProdutosSaga())
    dispatch(produtoActions.getConveniosSaga())
    dispatch(produtoActions.getTipoOperacaoSaga())
    dispatch(produtoActions.getTipoFormalizacaoSaga())
  }, [dispatch])

  useEffect(() => {
    if (register.bancoId) {
      dispatch(produtoActions.getTabelasBancoSaga())
    } else {
      dispatch(produtoActions.removeAllTabelaComissao())
    }
  }, [dispatch, register.bancoId])

  function onSave() {
    dispatch(produtoActions.cleanInvalids())
    schema
      .validate(
        {
          id: register.id,
          produtoId: register.produtoId,
          bancoId: register.bancoId,
          convenioId: register.convenioId,
          tipoOperacaoId: register.tipoOperacaoId,
          tipoFormalizacaoId: register.tipoFormalizacaoId,
          margemMin: Number(removeMaskMoney(register.margemMin)),
          margemMax: Number(removeMaskMoney(register.margemMax)),
          idadeDe: register.idadeDe,
          idadeAte: register.idadeAte,
          mesesDe: register.mesesDe,
          mesesAte: register.mesesAte,
          diasDe: register.diasDe,
          diasAte: register.diasAte,
          prazo: register.prazo,
          trocoMin: Number(removeMaskMoney(register.trocoMin)),
          trocoMax: Number(removeMaskMoney(register.trocoMax)),
          pmtPaga: register.pmtPaga,
          saldoDevedorMin: Number(removeMaskMoney(register.saldoDevedorMin)),
          saldoDevedorMax: Number(removeMaskMoney(register.saldoDevedorMax)),
          valorContrato: Number(removeMaskMoney(register.valorContrato)),
          cartaoInstituicaoId: register.cartaoInstituicaoId,
          taxas: register.taxas,
          bancosNaoPortados: register.bancosNaoPortados,
          descricaoGrupoBeneficio: register.descricaoGrupoBeneficio,
          beneficios: register.beneficios,
          tabelas: register.tabelas
        },
        { abortEarly: false }
      )
      .then(() => {
        if (!register.id) {
          dispatch(produtoActions.criarParametrizacaoSaga())
        } else {
          dispatch(produtoActions.atualizarParametrizacaoSaga())
        }
      })
      .catch((err) => {
        setBtnToggle(true)
        dispatch(produtoActions.toggleAllCards(true))
        err.inner.forEach((e: yup.ValidationError) => {
          if (e.path?.includes('[')) {
            const e1 = e.path?.split('[')
            const e2 = e.path?.match(/\[(.*?)\]/) || []
            const e3 = e.path?.split('.')
            if (e.path) {
              dispatch(
                produtoActions.setInvalidsArray(
                  true,
                  e1[0],
                  Number(e2[1]),
                  e3[1]
                )
              )
            }
          } else {
            dispatch(produtoActions.setInvalids(true, e.path || ''))
          }
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  const [btnToggle, setBtnToggle] = useState(false)

  function toggler() {
    setBtnToggle(!btnToggle)
    dispatch(produtoActions.toggleAllCards(!btnToggle))
  }

  const schema = yup.object().shape(
    {
      id: yup.string(),
      produtoId: yup.string().required(),
      bancoId: yup.string().required(),
      convenioId: yup.string().required(),
      tipoOperacaoId: yup.string().required(),
      tipoFormalizacaoId: yup.string().required(),
      margemMin: yup
        .number()
        .when('margemMax', {
          is: (margemMax: number) => !!margemMax,
          then: yup.number().max(yup.ref('margemMax'))
        })
        .nullable(),
      margemMax: yup
        .number()
        .when('margemMin', {
          is: (margemMin: number) => !!margemMin,
          then: yup.number().min(yup.ref('margemMin')).max(1000000000.0)
        })
        .nullable(),
      idadeDe: yup
        .number()
        .when('idadeAte', {
          is: (idadeAte: number) => !!idadeAte || idadeAte === 0,
          then: yup.number().max(yup.ref('idadeAte'))
        })
        .nullable(),
      idadeAte: yup
        .number()
        .when('idadeDe', {
          is: (idadeDe: number) => !!idadeDe || idadeDe === 0,
          then: yup.number().min(yup.ref('idadeDe'))
        })
        .nullable(),
      mesesDe: yup
        .number()
        .when('mesesAte', {
          is: (mesesAte: number) => !!mesesAte || mesesAte === 0,
          then: yup.number().max(yup.ref('mesesAte'))
        })
        .nullable(),
      mesesAte: yup
        .number()
        .when('mesesDe', {
          is: (mesesDe: number) => !!mesesDe || mesesDe === 0,
          then: yup.number().min(yup.ref('mesesDe'))
        })
        .nullable(),
      diasDe: yup
        .number()
        .when('diasAte', {
          is: (diasAte: number) => !!diasAte || diasAte === 0,
          then: yup.number().max(yup.ref('diasAte'))
        })
        .nullable(),
      diasAte: yup
        .number()
        .when('diasDe', {
          is: (diasDe: number) => !!diasDe || diasDe === 0,
          then: yup.number().min(yup.ref('diasDe'))
        })
        .nullable(),
      prazo: yup.number().nullable(),
      trocoMin: yup
        .number()
        .when('trocoMax', {
          is: (trocoMax: number) => !!trocoMax,
          then: yup.number().max(yup.ref('trocoMax'))
        })
        .nullable(),
      trocoMax: yup
        .number()
        .when('trocoMin', {
          is: (trocoMin: number) => !!trocoMin,
          then: yup.number().min(yup.ref('trocoMin')).max(1000000000.0)
        })
        .nullable(),
      pmtPaga: yup.string().nullable(),
      saldoDevedorMin: yup
        .number()
        .when('saldoDevedorMax', {
          is: (saldoDevedorMax: number) => !!saldoDevedorMax,
          then: yup.number().max(yup.ref('saldoDevedorMax'))
        })
        .nullable(),
      saldoDevedorMax: yup
        .number()
        .when('saldoDevedorMin', {
          is: (saldoDevedorMin: number) => !!saldoDevedorMin,
          then: yup.number().min(yup.ref('saldoDevedorMin')).max(1000000000.0)
        })
        .nullable(),
      valorContrato: yup.number().max(1000000000.0).nullable(),
      cartaoInstituicaoId: yup.string().nullable(),
      taxas: yup.array().of(yup.string()).nullable(),
      bancosNaoPortados: yup.array().of(yup.string()).nullable(),
      descricaoGrupoBeneficio: yup
        .string()
        .when('beneficios', {
          is: (beneficios: string[]) => beneficios.length > 0,
          then: yup.string().max(100).required()
        })
        .nullable(),
      beneficios: yup.array().of(yup.string()).nullable(),
      tabelas: yup
        .array()
        .of(
          yup.object().shape(
            {
              id: yup.string(),
              tabelasComissao: yup.array(yup.string()).min(1),
              rangePrazoInicial: yup
                .number()
                .when('rangePrazoFinal', {
                  is: (rangePrazoFinal: number) => !!rangePrazoFinal,
                  then: yup.number().min(yup.ref('rangePrazoFinal'))
                })
                .nullable(),
              rangePrazoFinal: yup
                .number()
                .when('rangePrazoInicial', {
                  is: (rangePrazoInicial: number) => !!rangePrazoInicial,
                  then: yup.number().max(yup.ref('rangePrazoInicial'))
                })
                .nullable()
            },
            [['rangePrazoInicial', 'rangePrazoFinal']]
          )
        )
        .required()
    },
    [
      ['margemMin', 'margemMax'],
      ['trocoMin', 'trocoMax'],
      ['saldoDevedorMin', 'saldoDevedorMax'],
      ['idadeDe', 'idadeAte'],
      ['mesesDe', 'mesesAte'],
      ['diasDe', 'diasAte']
    ]
  )

  return (
    <SimpleCard>
      <div className="container-fluid d-flex flex-column row-gap-10">
        <Row className="mb-2">
          <Col md={6} className="d-flex">
            <CustomButton
              onClick={() => voltaParaSearch()}
              background="#662d90"
              letterSpacing={1}
            >
              Voltar
            </CustomButton>
          </Col>
          {!visualizacao && (
            <Col md={6} className="d-flex justify-content-end">
              <CustomButton onClick={() => onSave()} letterSpacing={1}>
                Salvar parâmetro
              </CustomButton>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <SimpleTitleCard>
              <FiServer className="icon" />
              Informações gerais
            </SimpleTitleCard>
          </Col>
        </Row>
        <Row className="d-flex row-gap-10">
          <Col className="d-flex flex-column" md={3}>
            <label className="label-12">Instituição*</label>
            <CustomSelect
              options={selects.instituicoes}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'bancoId')
              }}
              value={register.bancoId}
              accessorLabel="descricao"
              accessorValue="id"
              disabled={visualizacao}
              invalid={invalids.bancoId}
            />
          </Col>
        </Row>
        <Row className="d-flex row-gap-10">
          <Col className="d-flex flex-column" md={3}>
            <label className="label-12">Convênio*</label>
            <CustomSelect
              options={selects.convenios}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'convenioId')
              }}
              value={register.convenioId}
              accessorLabel="descricao"
              accessorValue="id"
              disabled={visualizacao}
              invalid={invalids.convenioId}
            />
          </Col>
          <Col className="d-flex flex-column" md={3}>
            <label className="label-12">Produto Ribercred*</label>
            <CustomSelect
              options={selects.produtos}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'produtoId')
              }}
              value={register.produtoId}
              accessorLabel="nome"
              accessorValue="id"
              disabled={visualizacao}
              invalid={invalids.produtoId}
            />
          </Col>
          <Col className="d-flex flex-column" md={3}>
            <label className="label-12">Tipo operação Riber*</label>
            <CustomSelect
              options={selects.tipoOperacao}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'tipoOperacaoId')
              }}
              value={register.tipoOperacaoId}
              accessorLabel="descricao"
              accessorValue="id"
              disabled={visualizacao}
              invalid={invalids.tipoOperacaoId}
            />
          </Col>
          <Col className="d-flex flex-column" md={3}>
            <label className="label-12">Tipo de formalização*</label>
            <CustomSelect
              options={selects.tipoFormalizacao}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'tipoFormalizacaoId')
              }}
              value={register.tipoFormalizacaoId}
              accessorLabel="descricao"
              accessorValue="id"
              disabled={visualizacao}
              invalid={invalids.tipoFormalizacaoId}
            />
          </Col>
        </Row>
        <div className="linha-horizontal"></div>
        {register.bancoId &&
        register.convenioId &&
        register.produtoId &&
        register.tipoOperacaoId &&
        register.tipoFormalizacaoId ? (
          <>
            <div className="d-flex justify-content-end">
              <ActionButton onClick={() => toggler()}>
                {btnToggle ? <BsArrowsCollapse /> : <BsArrowsExpand />}
              </ActionButton>
            </div>
            <CardBancosNaoPortados />
            <CardCartao />
            <CardGruposBeneficio />
            <CardIdade />
            <CardPmtPaga />
            <CardSaldoDevedor />
            <CardTabelasServicos />
            <CardTaxaOriginal />
            <CardTrocoMinimo />
            <CardValorContrato />
            <CardValorMargem />
          </>
        ) : (
          <BlankSpace />
        )}
      </div>
    </SimpleCard>
  )
}

export default Register
