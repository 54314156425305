import { ProdutosSimulacao } from 'models/types'
import { Matricula } from 'models/atendimento/types'
import moment from 'moment'
import { Reducer } from 'redux'
import { ATENDIMENTO, TYPES } from './types'
import { maskMoney } from 'util/masks'

const INITIAL_STATE: ATENDIMENTO = {
  atendimentosAbertos: [],
  tabClienteActive: 'novo-atendimento',
  cpfAtendimento: '',
  tipoSaque: 0,
  modalCadastroCliente: {
    convenioId: '',
    cpf: '',
    dataNascimento: '',
    ddd: '',
    matricula: '',
    nome: '',
    telefone: '',
    perfilId: null,
    margem: '',
    margemCartao: ''
  },
  invalidsCadastroCliente: {
    convenioId: false,
    cpf: false,
    dataNascimento: false,
    ddd: false,
    matricula: false,
    nome: false,
    telefone: false,
    perfilId: false,
    margem: false,
    margemCartao: false
  },
  isOpenCadastroCliente: false,
  isOpenConfirmacaoCadastro: false,
  convenios: [],
  perfis: [],
  formularioNecessidade: {
    isOpen: false,
    tabActive: 1,
    answers: {
      valorDesejado: '',
      possuiCarro: false,
      carroQuitado: false,
      possuiImovel: false,
      imovelQuitado: false,
      pagarDividas: false,
      despesasMedicas: false,
      reformas: false,
      adquirirBem: false,
      emprestarFamiliarAmigo: false,
      investirNegocio: false,
      ferias: false,
      outros: false,
      observacao: '',
      cpf: '',
      indexTabCliente: 2
    }
  },
  loadingStartAtendimento: false,
  modalAgregacao: {
    margemDisponivel: 0,
    simulacao: null,
    valorParcelaOrigem: 0,
    valorASerAgregado: maskMoney('0')
  },
  idsContratosRefinItauSelecionados: [],
  modalCoberturasSeguroPrestamista: {
    codigoPlano: 0,
    coberturas: []
  },
  modalSeguroSindnapi: {
    coberturasField: []
  }
}

const reducer: Reducer<ATENDIMENTO> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_LOADING_START_ATENDIMENTO: {
      return {
        ...state,
        loadingStartAtendimento: action.value
      }
    }
    case TYPES.START_ATENDIMENTO: {
      const atendimentos = [...state.atendimentosAbertos]
      const i = atendimentos
        .map((atendimento) => {
          return atendimento.cpf
        })
        .indexOf(action.cpf)
      if (i !== -1) {
        atendimentos[i].ativo = true
        return {
          ...state,
          atendimentosAbertos: atendimentos,
          tabClienteActive: i
        }
      }
      return {
        ...state,
        tabClienteActive: atendimentos.length,
        atendimentosAbertos: [
          ...state.atendimentosAbertos,
          {
            ativo: true,
            cpf: action.cpf,
            tabAtendimento: action.indexTabCliente,
            modalAtendimentoAgendado: false,
            tabContato: {
              nome: '',
              dataNascimento: '',
              cidade: '',
              sexo: '',
              tipoChamada: 0,
              agendamentos: [],
              tabulacoes: [],
              tiposAgendamentos: [],
              clienteRiber: false,
              isOpenEnriquecimento: false,
              enriquecido: false,
              tipoProduto: 0,
              campanhaCrmId: '',
              consultorId: null,
              tipoAtendimento: 0,
              atendimentoAgendadoId: null,
              infosChamada: {
                telefones: [],
                dataAgendamento: moment().add(1, 'days').format('YYYY-MM-DD'),
                horarioAgendamento: moment().format('HH:mm'),
                numeroChamada: '',
                observacoesAgendamento: '',
                tabulacaoChamada: null,
                tipoAgendamento: '',
                emChamada: false,
                statusChamada: 0,
                infosMatriculasLead: []
              },
              infosChamadaInvalid: {
                dataAgendamento: false,
                horarioAgendamento: false,
                tipoAgendamento: false
              },
              tabelaAtendimentos: {
                page: 0,
                pageSize: 10,
                totalRegistros: 0
              },
              atendimentoId: '',
              integracaoUrl: ''
            },
            tabProduto: {
              beneficios: [],
              bancoValidacaoSelected: 'bmg',
              telefonesClienteIN100: [],
              detalhamentoIsOpen: false,
              outrosProdutos: [],
              produtosContratados: [],
              produtosDisponiveis: [],
              produtosSelecionados: [],
              produtoSelected: {
                servico: 0,
                matricula: ''
              },
              somatorioTotalProdutos: 0,
              somatorioResumo: 0,
              infosClienteResumo: {
                bairro: '',
                cep: '',
                cidade: '',
                cidadeNascimento: '',
                complemento: '',
                cpf: '',
                dataEmissao: '',
                dataNascimento: '',
                descricaoBanco: '',
                digitoVerificadorAgencia: '',
                digitoVerificadorConta: '',
                emissorIdentidade: '',
                estadoCivil: '',
                grauInstrucao: '',
                nacionalidade: '',
                nome: '',
                nomeConjuge: '',
                nomeMae: '',
                nomePai: '',
                numeroAgencia: '',
                numeroConta: '',
                numeroEndereco: '',
                numeroIdentidade: '',
                sexo: '',
                tipoContaId: '',
                uf: '',
                ufIdentidade: '',
                ufNascimento: ''
              },
              modalResumoContratacao: false,
              loadingSimulacao: false,
              invalidContratacao: false,
              modalIndicacao: false,
              modalIndicacaoManual: false,
              clienteNaoElegivel: false,
              modalAgregacao: false,
              modalInegibilidade: false,
              mensagemInegibilidade: '',
              modalCoberturasSeguroPrestamista: false,
              modalSeguroSindnapi: false
            },
            tabInformacoes: {
              infoCliente: {
                bairro: '',
                bancoDadosBancariosId: '',
                cep: '',
                cidade: '',
                cidadeNascimento: '',
                complemento: '',
                cpf: '',
                dataEmissao: '',
                dataNascimento: '',
                ddd: '',
                email: '',
                escolaridadeId: '',
                estadoCivil: null,
                logradouro: '',
                matriculas: [],
                nome: '',
                nomeConjuge: '',
                nomeMae: '',
                nomePai: '',
                numero: '',
                numeroEndereco: '',
                numeroIdentidade: '',
                orgaoEmissorId: '',
                profissaoId: '',
                sexo: '',
                siglaEstado: '',
                ufNascimento: '',
                ufOrgaoEmissor: ''
              },
              optionsSelect: {
                genero: [],
                orgaoEmissor: [],
                ufOrgaoEmissor: [],
                escolaridade: [],
                ufNascimento: [],
                cidadeNascimento: [],
                estadoCivil: [],
                uf: [],
                cidade: [],
                tipoConta: [],
                banco: [],
                convenios: [],
                profissoes: []
              },
              invalids: {
                bairro: false,
                cep: false,
                cidade: false,
                cidadeNascimento: false,
                complemento: false,
                cpf: false,
                dataEmissao: false,
                dataNascimento: false,
                ddd: false,
                email: false,
                escolaridadeId: false,
                estadoCivil: false,
                logradouro: false,
                matriculas: [],
                nome: false,
                nomeConjuge: false,
                nomeMae: false,
                nomePai: false,
                numero: false,
                numeroEndereco: false,
                numeroIdentidade: false,
                orgaoEmissorId: false,
                profissaoId: false,
                sexo: false,
                siglaEstado: false,
                ufNascimento: false,
                ufOrgaoEmissor: false
              }
            },
            tabHistorico: []
          }
        ]
      }
    }
    case TYPES.END_ATENDIMENTO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].ativo = false
      return {
        ...state,
        atendimentosAbertos: atendimentos,
        tabClienteActive: 'novo-atendimento'
      }
    }
    case TYPES.SET_MODAL_ATENDIMENTO_AGENDADO: {
      const atendimentos = [...state.atendimentosAbertos]
      const i = atendimentos
        .map((atendimento) => {
          return atendimento.cpf
        })
        .indexOf(action.cpf)
      if (i !== -1) {
        atendimentos[i].modalAtendimentoAgendado = action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INVALID_CADASTRO_CLIENTE_MODAL:
      return {
        ...state,
        invalidsCadastroCliente: {
          ...state.invalidsCadastroCliente,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_INVALID_CADASTRO_CLIENTE_MODAL:
      return {
        ...state,
        invalidsCadastroCliente: INITIAL_STATE.invalidsCadastroCliente
      }
    case TYPES.SET_INFOS_CADASTRO_CLIENTE_MODAL:
      return {
        ...state,
        modalCadastroCliente: action.value
      }
    case TYPES.CLEAN_INFOS_CADASTRO_CLIENTE_MODAL:
      return {
        ...state,
        modalCadastroCliente: {
          ...state.modalCadastroCliente,
          convenioId: '',
          dataNascimento: '',
          ddd: '',
          matricula: '',
          nome: '',
          telefone: '',
          perfilId: null,
          margem: '',
          margemCartao: ''
        }
      }
    case TYPES.SET_CONVENIOS_MODAL:
      return {
        ...state,
        convenios: action.value
      }
    case TYPES.SET_PERFIS_MODAL:
      return {
        ...state,
        perfis: action.value
      }
    case TYPES.TOGGLE_CONFIRMACAO_CADASTRO:
      return {
        ...state,
        isOpenConfirmacaoCadastro: action.value
      }
    case TYPES.SET_ENRIQUECIDO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabContato = {
        ...atendimentos[action.index].tabContato,
        enriquecido: true
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.TOGGLE_ENRIQUECIMENTO: {
      const i = state.atendimentosAbertos
        .map((atendimento) => {
          return atendimento.cpf
        })
        .indexOf(action.cpf)
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[i].tabContato = {
        ...atendimentos[i].tabContato,
        isOpenEnriquecimento: action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.TOGGLE_CADASTRO_SIMPLIFICADO:
      return {
        ...state,
        isOpenCadastroCliente: action.value
      }
    case TYPES.SET_CPF_ATENDIMENTO:
      return {
        ...state,
        cpfAtendimento: action.value
      }
    case TYPES.SET_PAGINATION_ATENDIMENTOS: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabContato.tabelaAtendimentos = {
        ...atendimentos[action.index].tabContato.tabelaAtendimentos,
        [action.field]: action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.CLEAN_CPF_ATENDIMENTO:
      return {
        ...state,
        cpfAtendimento: INITIAL_STATE.cpfAtendimento
      }
    case TYPES.SET_TAB_CLIENTE_ACTIVE:
      return {
        ...state,
        tabClienteActive: action.index
      }
    case TYPES.SET_TAB_ATENDIMENTO_ACTIVE: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabAtendimento = action.value
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.TOGGLE_DETALHAMENTO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabProduto.produtoSelected = {
        servico: 0,
        matricula: ''
      }
      atendimentos[action.index].tabProduto.detalhamentoIsOpen =
        !atendimentos[action.index].tabProduto.detalhamentoIsOpen
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFOS_PRODUTO: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const atendimentos: any = [...state.atendimentosAbertos]
      atendimentos[action.index].tabProduto[action.field] = action.value
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_LOADING_BENEFICIO_IN100: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabProduto.beneficios[action.indexBeneficio] =
        {
          ...atendimentos[action.index].tabProduto.beneficios[
            action.indexBeneficio
          ],
          loading: action.value
        }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_LOADING_SIMULACAO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabProduto.loadingSimulacao = action.value
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFOS_CARTAO_CONSIG: {
      const atendimentos = [...state.atendimentosAbertos]
      const iDisponivel = atendimentos[
        action.index
      ].tabProduto.produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 6 && produto.matricula === action.matricula
      )
      const iSelecionado = atendimentos[
        action.index
      ].tabProduto.produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 6 && produto.matricula === action.matricula
      )
      const iDisponivelBen = atendimentos[
        action.index
      ].tabProduto.produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 7 && produto.matricula === action.matricula
      )
      const iSelecionadoBen = atendimentos[
        action.index
      ].tabProduto.produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 7 && produto.matricula === action.matricula
      )
      if (iSelecionado !== -1) {
        atendimentos[action.index].tabProduto.produtosSelecionados[
          iSelecionado
        ] = {
          ...atendimentos[action.index].tabProduto.produtosSelecionados[
            iSelecionado
          ],
          [action.field]: action.value
        }
      }
      if (iDisponivel !== -1) {
        atendimentos[action.index].tabProduto.produtosDisponiveis[iDisponivel] =
          {
            ...atendimentos[action.index].tabProduto.produtosDisponiveis[
              iDisponivel
            ],
            [action.field]: action.value
          }
      }
      if (
        iDisponivelBen !== -1 &&
        (action.field as keyof ProdutosSimulacao) === 'flagAberturaConta'
      ) {
        atendimentos[action.index].tabProduto.produtosDisponiveis[
          iDisponivelBen
        ] = {
          ...atendimentos[action.index].tabProduto.produtosDisponiveis[
            iDisponivelBen
          ],
          flagAberturaConta: false
        }
      }
      if (
        iSelecionadoBen !== -1 &&
        (action.field as keyof ProdutosSimulacao) === 'flagAberturaConta'
      ) {
        atendimentos[action.index].tabProduto.produtosSelecionados[
          iSelecionadoBen
        ] = {
          ...atendimentos[action.index].tabProduto.produtosSelecionados[
            iSelecionadoBen
          ],
          flagAberturaConta: false
        }
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFOS_CARTAO_BENEFICIO: {
      const atendimentos = [...state.atendimentosAbertos]
      const iDisponivel = atendimentos[
        action.index
      ].tabProduto.produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 7 && produto.matricula === action.matricula
      )
      const iSelecionado = atendimentos[
        action.index
      ].tabProduto.produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 7 && produto.matricula === action.matricula
      )
      const iDisponivelConsig = atendimentos[
        action.index
      ].tabProduto.produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 6 && produto.matricula === action.matricula
      )
      const iSelecionadoConsig = atendimentos[
        action.index
      ].tabProduto.produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 6 && produto.matricula === action.matricula
      )
      if (iSelecionado !== -1) {
        atendimentos[action.index].tabProduto.produtosSelecionados[
          iSelecionado
        ] = {
          ...atendimentos[action.index].tabProduto.produtosSelecionados[
            iSelecionado
          ],
          [action.field]: action.value
        }
      }
      if (iDisponivel !== -1) {
        atendimentos[action.index].tabProduto.produtosDisponiveis[iDisponivel] =
          {
            ...atendimentos[action.index].tabProduto.produtosDisponiveis[
              iDisponivel
            ],
            [action.field]: action.value
          }
      }
      if (
        iDisponivelConsig !== -1 &&
        (action.field as keyof ProdutosSimulacao) === 'flagAberturaConta'
      ) {
        atendimentos[action.index].tabProduto.produtosDisponiveis[
          iDisponivelConsig
        ] = {
          ...atendimentos[action.index].tabProduto.produtosDisponiveis[
            iDisponivelConsig
          ],
          flagAberturaConta: false
        }
      }
      if (
        iSelecionadoConsig !== -1 &&
        (action.field as keyof ProdutosSimulacao) === 'flagAberturaConta'
      ) {
        atendimentos[action.index].tabProduto.produtosSelecionados[
          iSelecionadoConsig
        ] = {
          ...atendimentos[action.index].tabProduto.produtosSelecionados[
            iSelecionadoConsig
          ],
          flagAberturaConta: false
        }
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFOS_SAQUE_COMPLEMENTAR_CONSIGNADO: {
      const atendimentos = [...state.atendimentosAbertos]
      const iDisponivel = atendimentos[
        action.index
      ].tabProduto.produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 5 && produto.matricula === action.matricula
      )
      const iSelecionado = atendimentos[
        action.index
      ].tabProduto.produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 5 && produto.matricula === action.matricula
      )
      if (iDisponivel !== -1) {
        atendimentos[action.index].tabProduto.produtosDisponiveis[iDisponivel] =
          {
            ...atendimentos[action.index].tabProduto.produtosDisponiveis[
              iDisponivel
            ],
            [action.field]: action.value
          }
      }
      if (iSelecionado !== -1) {
        atendimentos[action.index].tabProduto.produtosSelecionados[
          iSelecionado
        ] = {
          ...atendimentos[action.index].tabProduto.produtosSelecionados[
            iSelecionado
          ],
          [action.field]: action.value
        }
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFOS_SAQUE_COMPLEMENTAR_BENEFICIO: {
      const atendimentos = [...state.atendimentosAbertos]
      const iDisponivel = atendimentos[
        action.index
      ].tabProduto.produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 8 && produto.matricula === action.matricula
      )
      const iSelecionado = atendimentos[
        action.index
      ].tabProduto.produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 8 && produto.matricula === action.matricula
      )
      if (iDisponivel !== -1) {
        atendimentos[action.index].tabProduto.produtosDisponiveis[iDisponivel] =
          {
            ...atendimentos[action.index].tabProduto.produtosDisponiveis[
              iDisponivel
            ],
            [action.field]: action.value
          }
      }
      if (iSelecionado !== -1) {
        atendimentos[action.index].tabProduto.produtosSelecionados[
          iSelecionado
        ] = {
          ...atendimentos[action.index].tabProduto.produtosSelecionados[
            iSelecionado
          ],
          [action.field]: action.value
        }
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SELECT_PORTABILIDADE_PRODUTO_PORTABILIDADE: {
      const atendimentos = [...state.atendimentosAbertos]
      const iDisponivel = atendimentos[
        action.index
      ].tabProduto.produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 3 && produto.matricula === action.matricula
      )
      const iSelecionado = atendimentos[
        action.index
      ].tabProduto.produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 3 && produto.matricula === action.matricula
      )
      if (iDisponivel !== -1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        atendimentos[action.index].tabProduto.produtosDisponiveis[
          iDisponivel
        ].contratosPortabilidade![
          action.indexContrato
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ].listProdutosPortabilidade = atendimentos[
          action.index
        ].tabProduto.produtosDisponiveis[iDisponivel].contratosPortabilidade![
          action.indexContrato
        ].listProdutosPortabilidade.map((produtoPortabilidade, i) => ({
          ...produtoPortabilidade,
          selecionado: action.indexPortabilidade === i
        }))
      }
      if (iSelecionado !== -1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        atendimentos[action.index].tabProduto.produtosSelecionados[
          iSelecionado
        ].contratosPortabilidade![
          action.indexContrato
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ].listProdutosPortabilidade = atendimentos[
          action.index
        ].tabProduto.produtosSelecionados[iSelecionado].contratosPortabilidade![
          action.indexContrato
        ].listProdutosPortabilidade.map((produtoPortabilidade, i) => ({
          ...produtoPortabilidade,
          selecionado: action.indexPortabilidade === i
        }))
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SELECT_PORTABILIDADE_PRODUTO_ASSOCIADO: {
      const atendimentos = [...state.atendimentosAbertos]
      const iDisponivel = atendimentos[
        action.index
      ].tabProduto.produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 3 && produto.matricula === action.matricula
      )
      const iSelecionado = atendimentos[
        action.index
      ].tabProduto.produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 3 && produto.matricula === action.matricula
      )
      if (iDisponivel !== -1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        atendimentos[action.index].tabProduto.produtosDisponiveis[
          iDisponivel
        ].contratosPortabilidade![
          action.indexContrato
        ].listProdutosPortabilidade[
          action.indexPortabilidade
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ].produtosAssociados = atendimentos[
          action.index
        ].tabProduto.produtosDisponiveis[iDisponivel].contratosPortabilidade![
          action.indexContrato
        ].listProdutosPortabilidade[
          action.indexPortabilidade
        ].produtosAssociados.map((produtoAssociado, i) => ({
          ...produtoAssociado,
          selecionado: action.indexRefinPortabilidade === i
        }))
      }
      if (iSelecionado !== -1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        atendimentos[action.index].tabProduto.produtosSelecionados[
          iSelecionado
        ].contratosPortabilidade![
          action.indexContrato
        ].listProdutosPortabilidade[
          action.indexPortabilidade
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ].produtosAssociados = atendimentos[
          action.index
        ].tabProduto.produtosSelecionados[iSelecionado].contratosPortabilidade![
          action.indexContrato
        ].listProdutosPortabilidade[
          action.indexPortabilidade
        ].produtosAssociados.map((produtoAssociado, i) => ({
          ...produtoAssociado,
          selecionado: action.indexRefinPortabilidade === i
        }))
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INVALID_CONTRATACAO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabProduto.invalidContratacao = action.value
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SELECT_PRODUTO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabProduto.produtoSelected = action.value
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFOS_CONTATO: {
      const i = state.atendimentosAbertos
        .map((atendimento) => {
          return atendimento.cpf
        })
        .indexOf(action.cpf)
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[i].tabContato = {
        ...atendimentos[i].tabContato,
        ...action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFOS_CONTATO_FIELD: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabContato = {
        ...atendimentos[action.index].tabContato,
        [action.field]: action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFOS_CHAMADA_CONTATO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabContato.infosChamada = {
        ...atendimentos[action.index].tabContato.infosChamada,
        [action.field]: action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFOS_CHAMADA_CONTATO_BY_CPF_DISPATCH: {
      const atendimentos = [...state.atendimentosAbertos]
      const i = atendimentos
        .map((atendimento) => {
          return atendimento.cpf
        })
        .indexOf(action.cpf)
      if (i !== -1) {
        atendimentos[i].tabContato.infosChamada = {
          ...atendimentos[i].tabContato.infosChamada,
          [action.field]: action.value
        }
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }

    case TYPES.SET_INFOS_CHAMADA_INVALID_CONTATO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabContato.infosChamadaInvalid = {
        ...atendimentos[action.index].tabContato.infosChamadaInvalid,
        [action.field]: action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.CLEAN_INFOS_CHAMADA_INVALID_CONTATO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabContato.infosChamadaInvalid = {
        dataAgendamento: false,
        horarioAgendamento: false,
        tipoAgendamento: false
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_PRODUTOS_SELECIONADOS: {
      const atendimentos = state.atendimentosAbertos
      atendimentos[action.index].tabProduto.produtosSelecionados = action.value
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_SOMATORIO_TOTAL_PRODUTOS: {
      const atendimentos = state.atendimentosAbertos
      atendimentos[action.index].tabProduto.somatorioTotalProdutos =
        action.value
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_SOMATORIO_RESUMO: {
      const atendimentos = state.atendimentosAbertos
      atendimentos[action.index].tabProduto.somatorioResumo = action.value
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFORMACOES_ACTIVE_TAB_BENEFICIO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabInformacoes.activeTabBeneficio =
        action.value
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFORMACOES: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabInformacoes.infoCliente = {
        ...atendimentos[action.index].tabInformacoes.infoCliente,
        ...action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.CLEAN_INFORMACOES: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabInformacoes.infoCliente = {
        bairro: '',
        cep: '',
        cidade: '',
        cidadeNascimento: '',
        complemento: '',
        cpf: '',
        dataEmissao: '',
        dataNascimento: '',
        ddd: '',
        email: '',
        escolaridadeId: '',
        estadoCivil: null,
        logradouro: '',
        matriculas: [],
        nome: '',
        nomeConjuge: '',
        nomeMae: '',
        nomePai: '',
        numero: '',
        numeroEndereco: '',
        numeroIdentidade: '',
        orgaoEmissorId: '',
        profissaoId: '',
        sexo: '',
        siglaEstado: '',
        ufNascimento: '',
        ufOrgaoEmissor: ''
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFORMACOES_FIELD: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabInformacoes.infoCliente = {
        ...atendimentos[action.index].tabInformacoes.infoCliente,
        [action.field]: action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INVALID_INFORMACOES_FIELD: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabInformacoes.invalids = {
        ...atendimentos[action.index].tabInformacoes.invalids,
        [action.field]: action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INVALID_INFORMACOES_FIELD_ARRAY: {
      const atendimentos = [...state.atendimentosAbertos]
      const invalids = JSON.parse(
        JSON.stringify({
          ...atendimentos[action.indexTab].tabInformacoes.invalids
        })
      )
      invalids[action.field][action.index][action.fieldComplementary] = true
      atendimentos[action.indexTab].tabInformacoes.invalids = invalids
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.ADICIONAR_PERFIL: {
      const atendimentos = [...state.atendimentosAbertos]
      const perfisCliente: Matricula[] = JSON.parse(
        JSON.stringify([
          ...(atendimentos[action.index].tabInformacoes.infoCliente
            .matriculas || [])
        ])
      )
      const invalids = JSON.parse(
        JSON.stringify([
          ...(atendimentos[action.index].tabInformacoes.invalids.matriculas ||
            [])
        ])
      )
      perfisCliente.push({
        id: '',
        numeroMatricula: '',
        tipoPerfil: action.value.tipoPerfil,
        descricaoPerfil: action.value.descricao,
        tipoPerfilId: action.value.id,
        convenioId: '',
        margemDisponivel: '',
        margemCartaoDisponivel: '',
        margemCartaoBeneficio: '',
        contaCorrenteId: '',
        tipoContaId: '',
        bancoDadosBancariosId: '',
        numeroAgencia: null,
        digitoVerificadorAgencia: null,
        numeroConta: null,
        digitoVerificadorConta: null
      })
      invalids.push({
        id: false,
        numeroMatricula: false,
        tipoPerfil: false,
        descricaoPerfil: false,
        tipoPerfilId: false,
        convenioId: false,
        margemDisponivel: false,
        margemCartaoDisponivel: false,
        margemCartaoBeneficio: false,
        contaCorrenteId: false,
        tipoContaId: false,
        bancoDadosBancariosId: false,
        numeroAgencia: false,
        digitoVerificadorAgencia: false,
        numeroConta: false,
        digitoVerificadorConta: false
      })
      atendimentos[action.index].tabInformacoes.infoCliente.matriculas =
        perfisCliente
      atendimentos[action.index].tabInformacoes.invalids.matriculas = invalids
      atendimentos[action.index].tabInformacoes.activeTabBeneficio =
        perfisCliente?.length - 1
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.REMOVER_PERFIL: {
      const atendimentos = [...state.atendimentosAbertos]
      const perfisCliente: Matricula[] = JSON.parse(
        JSON.stringify([
          ...(atendimentos[action.index].tabInformacoes.infoCliente
            .matriculas || [])
        ])
      )
      const invalids = JSON.parse(
        JSON.stringify([
          ...(atendimentos[action.index].tabInformacoes.invalids.matriculas ||
            [])
        ])
      )
      perfisCliente.splice(action.indexPerfil, 1)
      invalids.splice(action.indexPerfil, 1)
      atendimentos[action.index].tabInformacoes.infoCliente.matriculas =
        perfisCliente
      atendimentos[action.index].tabInformacoes.invalids.matriculas = invalids
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.CLEAN_INVALID_INFORMACOES_FIELD: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabInformacoes.invalids = {
        bairro: false,
        cep: false,
        cidade: false,
        cidadeNascimento: false,
        complemento: false,
        cpf: false,
        dataEmissao: false,
        dataNascimento: false,
        ddd: false,
        email: false,
        escolaridadeId: false,
        estadoCivil: false,
        logradouro: false,
        matriculas: atendimentos[
          action.index
        ].tabInformacoes.invalids.matriculas.map(() => {
          return {
            id: false,
            numeroMatricula: false,
            tipoPerfil: false,
            descricaoPerfil: false,
            tipoPerfilId: false,
            convenioId: false,
            margemDisponivel: false,
            margemCartaoDisponivel: false,
            margemCartaoBeneficio: false,
            contaCorrenteId: false,
            tipoContaId: false,
            bancoDadosBancariosId: false,
            numeroAgencia: false,
            digitoVerificadorAgencia: false,
            numeroConta: false,
            digitoVerificadorConta: false
          }
        }),
        nome: false,
        nomeConjuge: false,
        nomeMae: false,
        nomePai: false,
        numero: false,
        numeroEndereco: false,
        numeroIdentidade: false,
        orgaoEmissorId: false,
        profissaoId: false,
        sexo: false,
        siglaEstado: false,
        ufNascimento: false,
        ufOrgaoEmissor: false
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_OPTIONS_SELECTS_INFORMACOES: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabInformacoes.optionsSelect = {
        ...atendimentos[action.index].tabInformacoes.optionsSelect,
        [action.field]: action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_INFOS_HISTORICO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabHistorico[action.indexBeneficio] = {
        ...atendimentos[action.index].tabHistorico?.[action.indexBeneficio],
        [action.field]: action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_TABS_HISTORICO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabHistorico = atendimentos[
        action.index
      ].tabProduto.beneficios.map(() => {
        return {
          contratos: [],
          tabelaContratos: {
            page: 0,
            pageSize: 10,
            totalRegistros: 0
          },
          infosConsig: {
            nome: '',
            idade: '',
            valorRenda: 0,
            margemDisponivel: 0,
            margemCartaoConsignado: 0,
            margemCartaoBeneficio: 0,
            situacao: '',
            especie: '',
            possuiRepresentanteLegalProcurador: '',
            bloqueioEmprestismo: '',
            beneficioPermiteEmprestimo: '',
            pensaoAlimenticia: '',
            dataDespachoBeneficio: ''
          }
        }
      })
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_PAGINATION_CONTRATOS: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabHistorico[
        action.indexBeneficio
      ].tabelaContratos = {
        ...atendimentos[action.index].tabHistorico?.[action.indexBeneficio]
          .tabelaContratos,
        [action.field]: action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.TOGGLE_FOMULARIO_NECESSIDADE: {
      return {
        ...state,
        formularioNecessidade: {
          ...state.formularioNecessidade,
          isOpen: action.value
        }
      }
    }
    case TYPES.SET_TAB_FORMULARIO: {
      return {
        ...state,
        formularioNecessidade: {
          ...state.formularioNecessidade,
          tabActive: action.value
        }
      }
    }
    case TYPES.SET_INFOS_FORMULARIO: {
      return {
        ...state,
        formularioNecessidade: {
          ...state.formularioNecessidade,
          answers: {
            ...state.formularioNecessidade.answers,
            [action.field]: action.value
          }
        }
      }
    }
    case TYPES.CLEAN_INFOS_FORMULARIO: {
      return {
        ...state,
        formularioNecessidade: {
          ...state.formularioNecessidade,
          answers: INITIAL_STATE.formularioNecessidade.answers,
          tabActive: INITIAL_STATE.formularioNecessidade.tabActive
        }
      }
    }
    case TYPES.SET_MODAL_AGREGACAO: {
      return {
        ...state,
        modalAgregacao: {
          ...state.modalAgregacao,
          [action.field]: action.value
        }
      }
    }
    case TYPES.CLEAN_MODAL_AGREGACAO: {
      return {
        ...state,
        modalAgregacao: INITIAL_STATE.modalAgregacao
      }
    }
    case TYPES.CLEAN_INVALID_INFOS_CEP: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabInformacoes.infoCliente = {
        ...atendimentos[action.index].tabInformacoes.infoCliente,
        bairro: '',
        cep: '',
        cidade: '',
        complemento: '',
        logradouro: '',
        numeroEndereco: '',
        siglaEstado: ''
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    case TYPES.SET_MODAL_COBERTURA_SEGURO_PRESTAMISTA: {
      return {
        ...state,
        modalCoberturasSeguroPrestamista: action.value
      }
    }
    case TYPES.CLEAN_MODAL_COBERTURA_SEGURO_PRESTAMISTA: {
      return {
        ...state,
        modalCoberturasSeguroPrestamista:
          INITIAL_STATE.modalCoberturasSeguroPrestamista
      }
    }
    case TYPES.SET_MODAL_SEGURO_SINDNAPI: {
      return {
        ...state,
        modalSeguroSindnapi: action.value
      }
    }
    case TYPES.CLEAN_MODAL_SEGURO_SINDNAPI: {
      return {
        ...state,
        modalSeguroSindnapi: INITIAL_STATE.modalSeguroSindnapi
      }
    }
    case TYPES.SET_INTEGRACAO: {
      const atendimentos = [...state.atendimentosAbertos]
      atendimentos[action.index].tabContato = {
        ...atendimentos[action.index].tabContato,
        integracaoUrl: action.value
      }
      return {
        ...state,
        atendimentosAbertos: atendimentos
      }
    }
    default:
      return state
  }
}

export default reducer
