import React, { Fragment } from 'react'
import { Perfil } from 'models/types'
import { CgProfile } from 'react-icons/cg'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { SimpleTitleCard } from 'styles/global'
import { ContentPerfisSelecao } from 'views/atendimento/style'

interface EscolhaPerfilProps {
  isOpen: boolean
  onToggle: () => void
  onAdicionar: (e: Perfil) => void
}

const EscolhaPerfil = ({
  isOpen,
  onToggle,
  onAdicionar
}: EscolhaPerfilProps) => {
  const { perfis } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )
  return (
    <Modal size="md" isOpen={isOpen} toggle={() => onToggle()}>
      <ModalHeader
        toggle={() => {
          onToggle()
        }}
      >
        <SimpleTitleCard>
          <CgProfile className="icon" />
          Escolha de perfil
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody>
        <p>Selecione o perfil desejado:</p>
        <ContentPerfisSelecao>
          {perfis.map((perfil, i) => {
            return (
              <Fragment key={i}>
                <div
                  onClick={() => {
                    onAdicionar(perfil)
                    onToggle()
                  }}
                  className="itens-lista"
                >
                  <span>{perfil.descricao}</span>
                </div>
                <div className="linha-horizontal" />
              </Fragment>
            )
          })}
        </ContentPerfisSelecao>
      </ModalBody>
    </Modal>
  )
}

export { EscolhaPerfil }
