import { HISTORICO } from 'store/modules/historico/types'
import { APICore } from 'helpers/api/apiCore'
import { removeMaskCPF } from 'util/masks'

const api = new APICore()

export function getAllHistorico(params: {
  pagina: number
  registros: number
  paramsSearch: HISTORICO['search']
}) {
  return api.get(`Operacao/historico-atendimento`, {
    pagina: params.pagina + 1,
    registros: params.registros,
    nomeAtendente: params.paramsSearch.nomeAtendente,
    cpfCliente: removeMaskCPF(params.paramsSearch.cpfCliente),
    Data: params.paramsSearch.Data
  })
}
