import { ParametrizacaoProduto } from 'models/backoffice/produto/type'
import { Paginacao } from 'models/types'
import store from 'store'
import {
  Selects,
  CardsState,
  TYPES,
  TabelasInvalids,
  ParametrizacaoList
} from './types'

type ActiveTab = 'search' | 'register'
export const setAtiveTab = (value: ActiveTab) => ({
  type: TYPES.SET_ACTIVE_TAB,
  value
})

export const setItemSelectedIdDispatch = (value: string) =>
  store.dispatch({
    type: TYPES.SET_ITEM_SELECTED_ID,
    value
  })

export const toggleModalExclusaoDispatch = (value: boolean) =>
  store.dispatch({
    type: TYPES.TOGGLE_MODAL_EXCLUSAO,
    value
  })

export const setPesquisa = (value: string | number | null, field: string) => ({
  type: TYPES.SET_PESQUISA,
  value,
  field
})

export const setParametrizacaoList = (value: ParametrizacaoList[]) => ({
  type: TYPES.SET_PARAMETRIZACAO_LIST,
  value
})

export const setRegister = (value: ParametrizacaoProduto) => ({
  type: TYPES.SET_REGISTER,
  value
})

export const setVisualizacao = (value: boolean) => ({
  type: TYPES.SET_VISUALIZACAO,
  value
})

export const setPaginacao = (value: Paginacao) => ({
  type: TYPES.SET_PAGINATION,
  value
})

export const setInvalids = (
  value: boolean | TabelasInvalids[],
  field: string
) => ({
  type: TYPES.SET_INVALIDS,
  value,
  field
})

export const setInvalidsArray = (
  value: boolean,
  field: string,
  index: number,
  fieldComplementary: string
) => ({
  type: TYPES.SET_INVALIDS_ARRAY,
  value,
  field,
  index,
  fieldComplementary
})

export const cleanInvalids = () => ({
  type: TYPES.CLEAN_INVALIDS
})

export const cleanRegister = () => ({
  type: TYPES.CLEAN_REGISTER
})

export const getAllParametrizacaoSaga = () => ({
  type: TYPES.GET_ALL_PARAMETRIZACAO_SAGA
})

export const getParametrizacaoSaga = (id: string) => ({
  type: TYPES.GET_PARAMETRIZACAO_SAGA,
  id
})

export const atualizarParametrizacaoSaga = () => ({
  type: TYPES.PUT_PARAMETRIZACAO_SAGA
})

export const criarParametrizacaoSaga = () => ({
  type: TYPES.POST_PARAMETRIZACAO_SAGA
})

type SelectFields = keyof Selects

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setOptionsSelect = (value: any[], field: SelectFields) => ({
  type: TYPES.SET_OPTIONS_SELECT,
  value,
  field
})

export const getProdutosSaga = () => ({
  type: TYPES.GET_PRODUTOS_SAGA
})

export const getInstituicoesSaga = () => ({
  type: TYPES.GET_INSTITUICOES_SAGA
})

export const getConveniosSaga = () => ({
  type: TYPES.GET_CONVENIOS_SAGA
})

export const getTipoOperacaoSaga = () => ({
  type: TYPES.GET_TIPO_OPERACAO_SAGA
})

export const getTipoFormalizacaoSaga = () => ({
  type: TYPES.GET_TIPO_FORMALIZACAO_SAGA
})

export const getTaxasSaga = () => ({
  type: TYPES.GET_TAXAS_SAGA
})

export const getTabelasBancoSaga = () => ({
  type: TYPES.GET_TABELAS_BANCO_SAGA
})

export const getBeneficiosSaga = () => ({
  type: TYPES.GET_BENEFICIOS_SAGA
})

export const addTabelaComissao = () => ({
  type: TYPES.ADD_TABELA_COMISSAO
})

export const removeTabelaComissao = (index: number) => ({
  type: TYPES.REMOVE_TABELA_COMISSAO,
  index
})

type ToggleCardFields = keyof CardsState

export const toggleCard = (value: boolean, field: ToggleCardFields) => ({
  type: TYPES.TOGGLE_CARD,
  value,
  field
})

export const toggleAllCards = (value: boolean) => ({
  type: TYPES.TOGGLE_ALL_CARDS,
  value
})

export const cleanBancosNaoPortados = () => ({
  type: TYPES.CLEAN_BANCOS_NAO_PORTADOS
})

export const cleanCartao = () => ({
  type: TYPES.CLEAN_CARTAO
})

export const cleanGruposBeneficio = () => ({
  type: TYPES.CLEAN_GRUPOS_BENEFICIO
})

export const cleanIdade = () => ({
  type: TYPES.CLEAN_IDADE
})

export const cleanPmtPaga = () => ({
  type: TYPES.CLEAN_PMT_PAGA
})

export const cleanSaldoDevedor = () => ({
  type: TYPES.CLEAN_SALDO_DEVEDOR
})

export const removeAllTabelaComissao = () => ({
  type: TYPES.REMOVE_ALL_TABELA_COMISSAO
})

export const cleanTaxaOriginal = () => ({
  type: TYPES.CLEAN_TAXA_ORIGINAL
})

export const cleanTrocoMinimo = () => ({
  type: TYPES.CLEAN_TROCO_MINIMO
})

export const cleanValorContrato = () => ({
  type: TYPES.CLEAN_VALOR_CONTRATO
})

export const cleanValorMargem = () => ({
  type: TYPES.CLEAN_VALOR_MARGEM
})

/** Actions da tabela */
export const visualizarParametrizacaoDispatchSaga = (id: string) =>
  store.dispatch({
    type: TYPES.VISUALIZAR_PARAMETRIZACAO_DISPATCH_SAGA,
    id
  })

export const editarParametrizacaoDispatchSaga = (id: string) =>
  store.dispatch({
    type: TYPES.EDITAR_PARAMETRIZACAO_DISPATCH_SAGA,
    id
  })

export const deleteParametrizacaoDispatchSaga = () =>
  store.dispatch({
    type: TYPES.DELETE_PARAMETRIZACAO_DISPATCH_SAGA
  })
