import { useEffect } from 'react'
import { UPLOAD_ARQUIVOS } from 'store/modules/backoffice/uploadArquivos/types'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { RiFolderUploadLine } from 'react-icons/ri'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { COLUMNS } from '../constants/columns'
import { setActiveTab } from 'store/modules/backoffice/uploadArquivos/actions'
import CustomTable from 'components/CustomTable'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import SimpleCard from 'components/SimpleCard'
import useRedux from 'hooks/useRedux'
import ModalExclusao from 'components/ModalExclusao'
import * as uploadActions from 'store/modules/backoffice/uploadArquivos/actions'

export default function Search() {
  const {
    search,
    pagination,
    uploads,
    showConfirmacaoExclusao,
    showConfirmacaoParams,
    isCreating
  } = useSelector<ApplicationState, UPLOAD_ARQUIVOS>(
    (state) => state.uploadArquivos
  )
  const { dispatch } = useRedux()

  function onChange(value: string, field: string) {
    dispatch(uploadActions.setPesquisa(value, field))
  }

  function aplicarFiltros() {
    dispatch(uploadActions.setPaginacao({ ...pagination, page: 0 }))
    dispatch(uploadActions.getAllUploadSaga())
  }

  function setPage(value: number) {
    dispatch(uploadActions.setPaginacao({ ...pagination, page: value }))
    dispatch(uploadActions.getAllUploadSaga())
  }

  function setPageSize(value: number) {
    dispatch(
      uploadActions.setPaginacao({ ...pagination, page: 0, pageSize: value })
    )
    dispatch(uploadActions.getAllUploadSaga())
  }

  useEffect(() => {
    dispatch(uploadActions.getAllUploadSaga())
  }, [dispatch])

  useEffect(() => {
    if (isCreating) {
      dispatch(uploadActions.setCreating(false))
      setTimeout(() => {
        dispatch(uploadActions.getAllUploadSaga())
      }, 10000)
    }
  }, [isCreating, dispatch])

  function irParaRegister() {
    dispatch(uploadActions.cleanSearch())
    dispatch(setActiveTab('register'))
  }

  return (
    <>
      <SimpleCard>
        <div className="container-fluid d-flex flex-column row-gap-10">
          <Row>
            <Col md={6}>
              <SimpleTitleCard>
                <RiFolderUploadLine className="icon" />
                Upload de Arquivos
              </SimpleTitleCard>
            </Col>
            <Col className="d-flex justify-content-end" md={6}>
              <CustomButton onClick={() => irParaRegister()}>
                Carregar Arquivos
              </CustomButton>
            </Col>
          </Row>
          <Row className="d-flex row-gap-15">
            <Col md={8}>
              <Row className="d-flex row-gap-15">
                <Col md={5}>
                  <CustomSelect
                    options={[
                      { label: 'Nome do arquivo', value: 'nomeArquivo' },
                      { label: 'Data de Upload', value: 'dataUpload' },
                      { label: 'Usuário', value: 'nomeUsuario' },
                      { label: 'Tipo de Arquivo', value: 'nomeTipoArquivo' }
                    ]}
                    placeholder="Filtrar por"
                    value={search.filtro}
                    onChange={(e) => {
                      onChange(e, 'filtro')
                    }}
                    accessorLabel="label"
                    accessorValue="value"
                  />
                </Col>
                <Col md={7}>
                  <InputCustom
                    type="text"
                    placeholder="Pesquise aqui"
                    onChange={(e) => {
                      onChange(e.target.value, 'pesquisa')
                    }}
                    value={search.pesquisa}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomButton
                onClick={() => aplicarFiltros()}
                background="#662d90"
              >
                Pesquisar
              </CustomButton>
            </Col>
          </Row>
          <Row>
            <CustomTable
              data={uploads}
              columns={COLUMNS}
              paginationMode="server"
              page={pagination.page}
              pageSize={pagination.pageSize}
              rowCount={pagination.totalRegistros}
              setPage={(e) => setPage(e)}
              setPageSize={(e) => setPageSize(e)}
            />
          </Row>
        </div>
        <ModalExclusao
          isOpen={showConfirmacaoExclusao}
          toggle={(e) => uploadActions.setModalExclusaoDispatch(e)}
          onRemove={() => {
            dispatch(uploadActions.excluirUploadSaga(showConfirmacaoParams))
          }}
        />
      </SimpleCard>
    </>
  )
}
