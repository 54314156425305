import CustomTable from 'components/CustomTable'
import React, { useCallback, useEffect } from 'react'
import { FiGlobe } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { SimpleTitleCard } from 'styles/global'
import { COLUMNS_HISTORICO } from '../constants/columns'
import BigNumbersConsig from './BigNumbersConsig'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import LoadingBasic from './LoadingBasic'

interface HistoricoBeneficioProps {
  index: number
  indexBeneficio: number
  tipoPerfil: number
}

const HistoricoBeneficio = ({
  index,
  indexBeneficio,
  tipoPerfil
}: HistoricoBeneficioProps) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  const getContratos = useCallback(() => {
    dispatch(
      atendimentoActions.getContratosSaga(
        atendimentosAbertos[index].cpf,
        index,
        indexBeneficio
      )
    )
  }, [dispatch, atendimentosAbertos, index, indexBeneficio])

  const getInfosClienteConsig = useCallback(() => {
    if (
      tipoPerfil === 1 ||
      tipoPerfil === 3 ||
      tipoPerfil === 4 ||
      tipoPerfil === 5
    ) {
      dispatch(
        atendimentoActions.getInfosClienteConsigSaga(index, indexBeneficio)
      )
    }
  }, [tipoPerfil, dispatch, index, indexBeneficio])

  function setPage(value: number) {
    dispatch(
      atendimentoActions.setPaginationContratos(
        value,
        'page',
        index,
        indexBeneficio
      )
    )
    getContratos()
  }

  function setPageSize(value: number) {
    dispatch(
      atendimentoActions.setPaginationContratos(
        0,
        'page',
        index,
        indexBeneficio
      )
    )
    dispatch(
      atendimentoActions.setPaginationContratos(
        value,
        'pageSize',
        index,
        indexBeneficio
      )
    )
    getContratos()
  }

  useEffect(() => {
    getContratos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabProduto.beneficios])

  useEffect(() => {
    if (!atendimentosAbertos[index].tabProduto.loadingSimulacao) {
      getInfosClienteConsig()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabProduto.loadingSimulacao])

  return (
    <>
      {(tipoPerfil === 1 ||
        tipoPerfil === 3 ||
        tipoPerfil === 4 ||
        tipoPerfil === 5) &&
        (atendimentosAbertos[index].tabProduto.loadingSimulacao ? (
          <LoadingBasic
            mainText="Estamos realizando a atualização dos dados do cliente"
            secondaryText="Aguarde..."
          />
        ) : (
          <BigNumbersConsig
            especie={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.especie
            }
            idade={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.idade
            }
            margemBeneficio={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.margemCartaoBeneficio
            }
            margemConsignado={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.margemCartaoConsignado
            }
            margemEmprestimo={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.margemDisponivel
            }
            nome={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.nome || ''
            }
            salario={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.valorRenda
            }
            situacao={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.situacao
            }
            beneficioPermiteEmprestimo={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.beneficioPermiteEmprestimo
            }
            bloqueioEmprestismo={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.bloqueioEmprestismo
            }
            possuiRepresentanteLegalProcurador={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.possuiRepresentanteLegalProcurador
            }
            pensaoAlimenticia={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.pensaoAlimenticia
            }
            dataDespachoBeneficio={
              atendimentosAbertos[index].tabHistorico[indexBeneficio]
                .infosConsig?.dataDespachoBeneficio
            }
          />
        ))}
      <SimpleTitleCard>
        <FiGlobe className="icon" /> Contratos
      </SimpleTitleCard>
      <div className="container-fluid d-flex flex-column row-gap-10">
        <CustomTable
          data={
            atendimentosAbertos[index].tabHistorico?.[indexBeneficio]?.contratos
          }
          columns={COLUMNS_HISTORICO}
          page={
            atendimentosAbertos[index].tabHistorico?.[indexBeneficio]
              ?.tabelaContratos.page
          }
          pageSize={
            atendimentosAbertos[index].tabHistorico?.[indexBeneficio]
              ?.tabelaContratos.pageSize
          }
          rowCount={
            atendimentosAbertos[index].tabHistorico?.[indexBeneficio]
              ?.tabelaContratos.totalRegistros || 0
          }
          setPage={(e) => setPage(e)}
          setPageSize={(e) => setPageSize(e)}
          getRowHeight={() => 'auto'}
        />
      </div>
    </>
  )
}

export default HistoricoBeneficio
