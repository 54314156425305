import { APICore } from 'helpers/api/apiCore'
import { GrupoReq } from 'models/backoffice/grupos/type'

const api = new APICore()

export function getAllGrupos(params: {
  descricao?: string
  pagina: number
  registros: number
  limiteMaxRegistros: number
}) {
  if (params.descricao) {
    return api.get('GrupoCrm', {
      descricao: params.descricao,
      pagina: params.pagina + 1,
      registros: params.registros,
      limiteMaxRegistros: params.limiteMaxRegistros
    })
  }

  return api.get('GrupoCrm', {
    pagina: params.pagina + 1,
    registros: params.registros,
    limiteMaxRegistros: params.limiteMaxRegistros
  })
}

export function getGrupo(id: string) {
  return api.get(`GrupoCrm/getByid/${id}`)
}

export function criarGrupo(params: GrupoReq) {
  return api.create('GrupoCrm', params)
}

export function atualizarGrupo(params: GrupoReq) {
  return api.update('GrupoCrm', params)
}

export function deletarGrupo(id: string) {
  return api.delete(`GrupoCrm?idGrupo=${id}`)
}

export function getAllLojas() {
  return api.get('Generic/lojas')
}
