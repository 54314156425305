import React from 'react'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import { FiChevronsRight } from 'react-icons/fi'
import { RiChatSmile2Line } from 'react-icons/ri'
import { Blank } from '../style'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { maskCPF, removeMaskCPF } from 'util/masks'
import InputCustom from 'components/Inputs'
import { TabPane } from 'reactstrap'
import useRedux from 'hooks/useRedux'
import { validaCpf } from 'util/validations'
import { toast } from 'react-toastify'
import LoadingIcon from 'assets/svg/loading-icon-purple.svg'

function BlankSpace() {
  const { cpfAtendimento, modalCadastroCliente, loadingStartAtendimento } =
    useSelector<ApplicationState, ATENDIMENTO>((state) => state.atendimento)

  const { dispatch } = useRedux()

  function iniciarAtendimento() {
    if (validaCpf(removeMaskCPF(cpfAtendimento))) {
      dispatch(atendimentoActions.cleanInfosFormulario())
      dispatch(
        atendimentoActions.getFormularioAtendimento30Saga(cpfAtendimento)
      )
      dispatch(
        atendimentoActions.setInfosCadastroClienteModal({
          ...modalCadastroCliente,
          cpf: cpfAtendimento
        })
      )
    } else {
      toast.warn('CPF inválido')
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      iniciarAtendimento()
    }
  }

  return (
    <TabPane tabId={'novo-atendimento'}>
      <Blank className="d-flex flex-column align-items-center pt-5">
        <RiChatSmile2Line className="icon" />
        <h2 className="titulo-18">Iniciar um novo atendimento</h2>
        <div className="row d-flex">
          <label className="label-12">CPF do cliente</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            value={cpfAtendimento}
            onChange={(e) => {
              dispatch(
                atendimentoActions.cpfAtendimento(maskCPF(e.target.value))
              )
            }}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div
          className="btn-outline-purple"
          onClick={() => {
            !loadingStartAtendimento && iniciarAtendimento()
          }}
        >
          {!loadingStartAtendimento ? (
            <>
              Iniciar <FiChevronsRight />
            </>
          ) : (
            <>
              Aguarde... <img height={20} src={LoadingIcon} />
            </>
          )}
        </div>
      </Blank>
    </TabPane>
  )
}

export default BlankSpace
