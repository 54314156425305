import { Reducer } from 'redux'
import { NAVBAR, TYPES } from './types'

const INITIAL_STATE: NAVBAR = {
  token: '',
  openToken: false,
  visualizaToken: false,
  nomeUsuario: '',
  usuarioSelected: '',
  usuariosInstiuicao: []
}

const reducer: Reducer<NAVBAR> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.TOGGLE_TOKEN:
      return { ...state, openToken: !state.openToken }
    case TYPES.SET_TOKEN:
      return { ...state, token: action.value }
    case TYPES.CLEAN_TOKEN:
      return { ...state, token: INITIAL_STATE.token }
    case TYPES.SET_VISUALIZACAO_TOKEN:
      return { ...state, visualizaToken: !state.visualizaToken }
    case TYPES.SET_NOME_USUARIO:
      return { ...state, nomeUsuario: action.value }
    case TYPES.SET_USUARIO_SELECTED:
      return { ...state, usuarioSelected: action.value }
    case TYPES.CLEAN_USUARIO_SELECTED:
      return { ...state, usuarioSelected: INITIAL_STATE.usuarioSelected }
    case TYPES.SET_USUARIOS_INSTITUICAO:
      return { ...state, usuariosInstiuicao: action.value }
    default:
      return state
  }
}

export default reducer
