import { maskCPF } from 'util/masks'
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid'

const headerTheme = 'table-header--purple'

export const COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Nome',
    valueGetter: (params: GridValueGetterParams) =>
      `${maskCPF(params.row.cpfCliente)}`,
    headerClassName: headerTheme,
    flex: 1
  },
  {
    field: 'cpf',
    headerName: 'CPF',
    headerClassName: headerTheme,
    flex: 1
  },
  {
    field: 'product',
    headerName: 'Produto',
    headerClassName: headerTheme,
    flex: 1
  },
  {
    field: 'value',
    headerName: 'Valor',
    headerClassName: headerTheme,
    flex: 1
  }
]
