import { Routes } from 'routes'
import {
  AllowedActions,
  FormLogin,
  FormRecuperacao,
  LOGIN,
  TYPES
} from './types'

export const login = () => ({
  type: TYPES.LOGIN_SAGA
})

export const logout = () => ({
  type: TYPES.LOGOUT_SAGA
})

type LoginFields = keyof FormLogin
export const setLogin = (value: string | boolean, field: LoginFields) => ({
  type: TYPES.SET_LOGIN,
  value,
  field
})

export const cleanLogin = () => ({
  type: TYPES.CLEAN_LOGIN
})

export const setLoading = (value: boolean) => ({
  type: TYPES.SET_LOADING,
  value
})

export const recuperarAcessoSaga = () => ({
  type: TYPES.RECUPERAR_ACESSO_SAGA
})

type RecuperacaoFields = keyof FormRecuperacao
export const setRecuperacao = (value: string, field: RecuperacaoFields) => ({
  type: TYPES.SET_RECUPERACAO,
  value,
  field
})

export const validaGuidRecuperacaoSaga = (id: string) => ({
  type: TYPES.VALIDA_GUID_RECUPERACAO_SAGA,
  id
})

export const redefinirSenhaSaga = (id: string) => ({
  type: TYPES.REDEFINIR_SENHA_SAGA,
  id
})

export const permissoesUsuario = () => ({
  type: TYPES.PERMISSOES_USUARIO_SAGA
})

export const setPermissoesUsuario = (value: Routes[]) => ({
  type: TYPES.SET_PERMISSOES_USUARIO,
  value
})

export const setLoadingPermissoes = (value: boolean) => ({
  type: TYPES.SET_LOADING_PERMISSOES,
  value
})

export const cleanPermissoesUsuario = () => ({
  type: TYPES.CLEAN_PERMISSOES_USUARIO
})

export const setPermissoesActionsUsuario = (value: AllowedActions) => ({
  type: TYPES.SET_PERMISSOES_ACTIONS_USUARIO,
  value
})

export const cleanPermissoesActionsUsuario = () => ({
  type: TYPES.CLEAN_PERMISSOES_ACTIONS_USUARIO
})

export const iniciarNotificacoes = () => ({
  type: TYPES.INICIAR_NOTIFICACOES_SAGA
})

export const iniciarRetornoDiscagemTelecom = () => ({
  type: TYPES.INICIAR_RETORNO_DISCAGEM_TELECOM_SAGA
})

type WebSocketsFields = keyof LOGIN['webSockets']
export const setStatusWebSockets = (
  value: boolean,
  field: WebSocketsFields
) => ({
  type: TYPES.SET_STATUS_WEBSOCKTES,
  value,
  field
})
