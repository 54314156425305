import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid'
import { Row } from 'react-data-grid'
import { FiTrash } from 'react-icons/fi'
import * as blacklistActions from 'store/modules/backoffice/blacklist/actions'
import { maskDate } from 'util/masks'

const headerTheme = 'table-header--purple'

export const COLUMNS: GridColumns<Row> = [
  {
    field: 'cpf',
    headerName: 'CPF',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'nome',
    headerName: 'Nome do Cliente',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'dataInclusao',
    headerName: 'Data de Inclusão',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <span className="table-cell-trucate">
        {params?.value ? maskDate(params?.value, 'DD/MM/YYYY') : ''}
      </span>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'nomePessoaExclusao',
    headerName: 'Usuário Exclusor',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'actions',
    headerName: 'Ações',
    headerClassName: headerTheme,
    type: 'actions',
    align: 'center',
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key={params.row.id}
        icon={<FiTrash />}
        label="Deletar"
        onClick={() => {
          blacklistActions.setItemSelectedIdDispatch(params.row.id)
          blacklistActions.toggleModalExclusaoDispatch(true)
        }}
      />
    ]
  }
]

export const COLUMNSCHECKLIST: GridColumns<Row> = [
  {
    field: 'telefone',
    headerName: 'Telefone',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  }
]
