import { Reducer } from 'redux'
import { maskDate } from 'util/masks'
import { PRODUCAO, TYPES } from './types'

const INITIAL_STATE: PRODUCAO = {
  filtros: {
    adesao: '',
    cpf: '',
    dataInicio: maskDate(Date.now() as unknown as string, 'YYYY-MM-DD'),
    dataFim: '',
    equipeId: '',
    digitadorId: ''
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  tabelaAcompanhamentoProducao: [],
  usuarios: [],
  equipes: []
}

const reducer: Reducer<PRODUCAO> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_FILTROS:
      return {
        ...state,
        filtros: { ...state.filtros, [action.field]: action.value }
      }
    case TYPES.CLEAN_FILTROS:
      return {
        ...state,
        filtros: INITIAL_STATE.filtros
      }
    case TYPES.SET_USUARIOS_OPTIONS:
      return {
        ...state,
        usuarios: action.value
      }
    case TYPES.SET_EQUIPES_OPTIONS:
      return {
        ...state,
        equipes: action.value
      }
    case TYPES.SET_TABELA_ACOMPANHAMENTO_PRODUCAO:
      return {
        ...state,
        tabelaAcompanhamentoProducao: action.value
      }
    case TYPES.CLEAN_TABELA_ACOMPANHAMENTO_PRODUCAO:
      return {
        ...state,
        tabelaAcompanhamentoProducao: INITIAL_STATE.tabelaAcompanhamentoProducao
      }
    case TYPES.SET_PAGINATION:
      return {
        ...state,
        pagination: action.value
      }
    default:
      return state
  }
}

export default reducer
