import moment from 'moment'

export function maskCNPJ(value: string) {
  if (value) {
    const newValue = value
      .toString()
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5')
    return newValue
  }
  return ''
}

export function maskCPF(value: string) {
  if (value) {
    const newValue = value
      .toString()
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
    return newValue
  }
  return ''
}

export function removeMaskCPF(value: string) {
  if (value !== undefined) {
    const newValue = value
      .toString()
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1$2')
      .replace(/(\d{3})(\d)/, '$1$2')
      .replace(/(\d{3})(\d{1,2})/, '$1$2')
      .replace(/(-\d{2})\d+?$/, '$1')
    return newValue
  }
  return ''
}

export function removeMaskCNPJ(value: string) {
  if (value !== undefined) {
    const newValue = value
      .toString()
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1$2$3$4$5')
    return newValue
  }
  return ''
}

export function maskMoney(value?: string | number | null) {
  if (value !== undefined && value !== null) {
    let newValue = value.toString().replace(/\D/g, '')
    newValue = (Number(newValue) / 100).toFixed(2)
    newValue = newValue.replace('.', ',')
    newValue = newValue.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
    newValue = newValue.replace(/(\d)(\d{3}),/g, '$1.$2,')
    return newValue
  }
  return ''
}

export function removeMaskMoney(value?: string | null) {
  if (value !== undefined && value !== null) {
    let newValue = value.toString()
    newValue = newValue.replace(/\./g, '')
    newValue = newValue.replace(',', '.')
    return newValue
  }
  return ''
}

type FormatosData =
  | 'YYYY-MM-DD'
  | 'DD-MM-YYYY'
  | 'YYYY/MM/DD'
  | 'DD/MM/YYYY'
  | 'DD/MM/YYYY HH:mm'
  | 'YYYY-MM-DD HH:mm'
  | 'MM/YY'
  | 'DD/MM'
export function maskDate(value: string, format: FormatosData = 'YYYY-MM-DD') {
  if (value) {
    return moment(value).format(format)
  }
  return ''
}

export function maskPhoneWithDDD(value: string | number) {
  if (value) {
    const newValue = value.toString()
    if (removeMaskPhone(newValue).length < 11) {
      return newValue
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})(\d+?)$/, '$1')
    }

    return newValue
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)$/, '$1')
  }
  return ''
}

export function removeMaskPhone(value: string | null) {
  if (value) {
    return value
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/-/g, '')
      .replace(' ', '')
  }
  return ''
}

export function maskNumber(value: string | number | null) {
  if (value) {
    const newValue = value.toString().replace(/\D/g, '')
    return newValue
  }
  return ''
}

export function maskCEP(value: string | number | null) {
  if (value) {
    let newValue = value.toString().replace(/\D/g, '')
    newValue = newValue.replace(/(\d{5})(\d{3})/, '$1-$2')
    return newValue
  }
  return ''
}

export function removeMaskCEP(value: string | number | null) {
  if (value) {
    let newValue = value.toString()
    newValue = newValue.replace('-', '')
    return newValue
  }
  return ''
}

export function maskMoneyReq(value: string | number | null) {
  if (value !== undefined && value !== null) {
    let newValue = Number(value).toFixed(2).toString().replace(/\D/g, '')
    newValue = (Number(newValue) / 100).toFixed(2)
    newValue = newValue.replace('.', ',')
    newValue = newValue.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
    newValue = newValue.replace(/(\d)(\d{3}),/g, '$1.$2,')
    return newValue
  }
  return null
}

export function maskDottedNumber(value?: string | number | null) {
  if (value !== undefined && value !== null) {
    let newValue = value.toString().replace(/\D/g, '')
    newValue = newValue.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
    return newValue
  }
  return ''
}
