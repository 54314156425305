import React from 'react'
import { FiPieChart } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { INDICADORES } from 'store/modules/indicadores/types'
import { SimpleTitleCard } from 'styles/global'
import { BoxShadow } from '../style'
import HorizontalTable from './barChart'
import LoadingComponent from './LoadingComponent'

const Tabulacao = () => {
  const { tabulacoes, loadings } = useSelector<ApplicationState, INDICADORES>(
    (state) => state.indicadores
  )
  return (
    <div className="d-flex flex-column">
      <SimpleTitleCard>
        <FiPieChart className="icon" /> Tabulação
      </SimpleTitleCard>
      <BoxShadow>
        <LoadingComponent isLoading={loadings.tabulacoes}>
          <HorizontalTable
            data={tabulacoes}
            accessorLabel="descricao"
            accessorFirstValue="quantidadeUsuario"
            accessorSecondValue="mediaRiber"
          />
        </LoadingComponent>
      </BoxShadow>
    </div>
  )
}

export default Tabulacao
