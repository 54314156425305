import React from 'react'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import { FiTrash } from 'react-icons/fi'
import { RiIncreaseDecreaseLine } from 'react-icons/ri'
import { Col, Collapse, Row } from 'reactstrap'
import { SimpleTitleCard, CustomButton } from 'styles/global'
import CardParametrizacao from './CardParametrizacao'
import { BiChevronDown } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { PRODUTO } from 'store/modules/backoffice/produto/types'
import { Tabela } from 'models/backoffice/produto/type'
import * as produtoActions from 'store/modules/backoffice/produto/actions'
import useRedux from 'hooks/useRedux'

const CardTabelasServicos = () => {
  const { register, selects, visualizacao, cardsState, invalids } = useSelector<
    ApplicationState,
    PRODUTO
  >((state) => state.produto)

  const { dispatch } = useRedux()

  type Field = keyof Tabela

  function onChange(value: string | number, field: Field, index: number) {
    const tabelas = [...register.tabelas]
    tabelas[index] = {
      ...tabelas[index],
      [field]: value
    }
    dispatch(produtoActions.setRegister({ ...register, tabelas: tabelas }))
  }

  function adicionarTabela() {
    dispatch(produtoActions.addTabelaComissao())
  }

  function removerTabela(index: number) {
    dispatch(produtoActions.removeTabelaComissao(index))
  }

  function removerTodasTabelas() {
    dispatch(produtoActions.removeAllTabelaComissao())
  }

  function onToggle() {
    dispatch(
      produtoActions.toggleCard(!cardsState.tabelasServicos, 'tabelasServicos')
    )
  }

  return (
    <CardParametrizacao>
      <div className="d-flex justify-content-between">
        <SimpleTitleCard color="#000" colorIcon="#000">
          <RiIncreaseDecreaseLine className="icon" />
          Tabelas ou serviços
        </SimpleTitleCard>
        <BiChevronDown onClick={() => onToggle()} className="toggler" />
      </div>
      <Collapse isOpen={cardsState.tabelasServicos}>
        <div className="d-flex flex-column row-gap-10">
          <Row>
            <Col>
              <label className="info-card">
                Tabelas de acordo com a instituição selecionada
              </label>
            </Col>
          </Row>
          {!visualizacao && (
            <Row>
              <Col>
                <CustomButton
                  onClick={() => adicionarTabela()}
                  letterSpacing={1}
                >
                  Inserir nova tabela
                </CustomButton>
              </Col>
            </Row>
          )}
          {register.tabelas.map((tabela, i) => {
            return (
              <div key={i} className="card-tabela">
                <Row>
                  <Col md={6}>
                    <label className="label-12">
                      Selecione as tabelas ou serviço*
                    </label>
                    <CustomSelect
                      options={selects.tabelas}
                      placeholder="Selecione"
                      onChange={(e) => {
                        onChange(e, 'tabelasComissao', i)
                      }}
                      isMulti
                      value={tabela.tabelasComissao}
                      accessorLabel="descricao"
                      accessorValue="id"
                      disabled={visualizacao}
                      invalid={invalids.tabelas[i]?.tabelasComissao}
                    />
                    <label className="info-card">
                      O range de taxa será exibido apenas para REFIM e
                      PORTABILIDADE (não obrigatório)
                    </label>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <label className="label-12">
                          Range de prazo inicial: (meses)
                        </label>
                        <InputCustom
                          type="number"
                          placeholder="Digite aqui"
                          onChange={(e) => {
                            onChange(e.target.value, 'rangePrazoInicial', i)
                          }}
                          value={tabela.rangePrazoInicial || ''}
                          disabled={visualizacao}
                          min={0}
                          invalid={invalids.tabelas[i]?.rangePrazoInicial}
                        />
                      </Col>
                      <Col md={6}>
                        <label className="label-12">
                          Range de prazo final: (meses)
                        </label>
                        <InputCustom
                          type="number"
                          placeholder="Digite aqui"
                          onChange={(e) => {
                            onChange(e.target.value, 'rangePrazoFinal', i)
                          }}
                          value={tabela.rangePrazoFinal || ''}
                          disabled={visualizacao}
                          min={0}
                          invalid={invalids.tabelas[i]?.rangePrazoFinal}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {!visualizacao && (
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <FiTrash
                        onClick={() => removerTabela(i)}
                        className="icon-trash"
                      />
                    </Col>
                  </Row>
                )}
              </div>
            )
          })}
          <div className="linha-horizontal"></div>
          {!visualizacao && (
            <Row>
              <Col className="d-flex justify-content-end">
                <FiTrash
                  onClick={() => removerTodasTabelas()}
                  className="icon-trash"
                />
              </Col>
            </Row>
          )}
        </div>
      </Collapse>
    </CardParametrizacao>
  )
}

export default CardTabelasServicos
