import React from 'react'
import { LoadingWrapper } from '../style'
import loading from 'assets/svg/loading-icon.svg'

interface LoadingSimulacaoProps {
  mainText: string
  secondaryText?: string
  loadingIcon?: React.ReactElement
}

const LoadingBasic = ({
  mainText,
  secondaryText = 'Aguarde...',
  loadingIcon
}: LoadingSimulacaoProps) => {
  return (
    <LoadingWrapper>
      <div>{mainText}</div>
      <div>{secondaryText}</div>
      {loadingIcon ? (
        loadingIcon
      ) : (
        <img className="my-4" src={loading} alt="Loading sistema" width="100" />
      )}
    </LoadingWrapper>
  )
}

export default LoadingBasic
