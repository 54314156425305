import React from 'react'
import { Route, Routes } from 'react-router-dom'
import RouteGenerator from 'components/RouteGenerator'
import NovaSenha from 'views/login/NovaSenha'
import Login from 'views/login'
import LoadingPermissoes from 'components/Loading/LoadingPermissoes'
import { ApplicationState } from 'store'
import { LOGIN } from 'store/modules/login/types'
import { useSelector } from 'react-redux'
import NotFound from 'views/notFound'

const AppRoutes = () => {
  const { loadingPermissoes } = useSelector<ApplicationState, LOGIN>(
    (state) => state.login
  )
  return (
    <>
      <LoadingPermissoes isLoading={loadingPermissoes} />
      <Routes>
        {RouteGenerator()}
        <Route path="/nova-senha/:id" element={<NovaSenha />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default AppRoutes
