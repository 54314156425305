import { APICore } from 'helpers/api/apiCore'
import { BlacklistReq } from 'models/backoffice/blacklist/types'
import { removeMaskCPF } from 'util/masks'

const api = new APICore()

export function getAllBlacklist(params: {
  pagina: number
  registros: number
  limiteMaximoRegistros: number
  field: string | null
  value: string | number
}) {
  if (params.field) {
    if (params.field === 'cpf') {
      params.value = removeMaskCPF(params.value as string)
    }

    return api.get('BlackList/black-list', {
      pagina: params.pagina + 1,
      registros: params.registros,
      limiteMaximoRegistros: params.limiteMaximoRegistros,
      [params.field]: params.value
    })
  }

  return api.get('BlackList/black-list', {
    pagina: params.pagina + 1,
    registros: params.registros,
    limiteMaximoRegistros: params.limiteMaximoRegistros
  })
}

export function createBlacklist(params: BlacklistReq) {
  const formattedCpf = removeMaskCPF(params.cpf as string)

  return api.create(`BlackList/black-list?cpf=${formattedCpf}`, '')
}

export function deleteBlacklist(id: string) {
  return api.delete(`BlackList/black-list?cpf=${id}`)
}
