import React, { useState } from 'react'
import SimpleCard from 'components/SimpleCard'
import Main from 'layouts/main'
import { FiSliders } from 'react-icons/fi'
import { SimpleTitleCard } from 'styles/global'
import { Row } from 'reactstrap'
import routes from 'routes'
import MenuLink from './components/MenuLink'

const Backoffice = () => {
  const [menus] = useState(routes.find((route) => route.path === '/backoffice'))
  const [menusFiltered] = useState(
    menus?.subPaths?.filter((subPath) => subPath.index !== true)
  )

  return (
    <Main>
      <SimpleCard>
        <SimpleTitleCard>
          <FiSliders className="icon" /> Backoffice
        </SimpleTitleCard>
        <div className="container-fluid">
          <Row className="d-flex row-gap-20">
            {menusFiltered?.map((menu, i) => {
              return (
                <MenuLink
                  key={i}
                  icon={menu.icon}
                  menuTitle={menu.menuTitle}
                  path={menu.path}
                />
              )
            })}
          </Row>
        </div>
      </SimpleCard>
    </Main>
  )
}

export default Backoffice
