import React, { useEffect, useState } from 'react'
import { FiLogOut } from 'react-icons/fi'
import { IoIosClose } from 'react-icons/io'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { SIDEBAR } from 'store/modules/sidebar/types'
import { pinSidebarSaga, toggleSidebar } from 'store/modules/sidebar/actions'
import { useLocation } from 'react-router-dom'
import * as loginActions from 'store/modules/login/actions'
import { Link } from 'react-router-dom'
import useRedux from 'hooks/useRedux'
import { LOGIN } from 'store/modules/login/types'
import {
  CSSObject,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
  Typography,
  styled
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { CustomListItem } from './style'
import { drawerWidth } from 'styles/global'
import config from 'config'
import infoPkg from '../../../package.json'
import { AiFillPushpin, AiOutlinePushpin } from 'react-icons/ai'

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  zIndex: '1050 !important',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  zIndex: '1050 !important',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& ::-webkit-scrollbar': {
    width: '5px'
  },
  '& ::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    borderRadius: '15px'
  },
  '& ::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent'
  },
  '& ::-webkit-scrollbar-track': {
    margin: '10px 10px'
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column'
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column'
    }
  })
}))

function Sidebar() {
  const { isOpen, pin } = useSelector<ApplicationState, SIDEBAR>(
    (state) => state.sidebar
  )

  const { allowedRoutes } = useSelector<ApplicationState, LOGIN>(
    (state) => state.login
  )

  const [rota, setRota] = useState<{ path: string } | undefined>({
    path: ''
  })
  const location = useLocation()

  const getLocation = () => {
    const simpleRoutes: { path: string }[] = []
    allowedRoutes.forEach((route) =>
      simpleRoutes.push({
        path: route.path
      })
    )
    const pathNameSplited = location.pathname.split('/')
    const newRota = simpleRoutes.find(
      (simple) =>
        simple.path.toUpperCase() === '/' + pathNameSplited[1].toUpperCase()
    )
    setRota(newRota)
  }

  const { dispatch } = useRedux()

  useEffect(() => {
    getLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  function logout() {
    dispatch(loginActions.logout())
  }

  return (
    <Drawer variant="permanent" open={isOpen} PaperProps={{ elevation: 1 }}>
      <div className="d-flex flex-column">
        <Toolbar className="d-flex justify-content-center" disableGutters>
          {isOpen ? (
            <div className="d-flex px-2 w-100 justify-content-between">
              <IconButton onClick={() => dispatch(toggleSidebar())}>
                <IoIosClose className="icon" />
              </IconButton>
              <IconButton
                onClick={() => {
                  dispatch(pinSidebarSaga(!pin))
                }}
              >
                {pin ? <AiFillPushpin /> : <AiOutlinePushpin />}
              </IconButton>
            </div>
          ) : (
            <IconButton onClick={() => dispatch(toggleSidebar())}>
              <GiHamburgerMenu className="icon" />
            </IconButton>
          )}
        </Toolbar>
        <Divider />
        <List>
          {allowedRoutes.map((route, i) => {
            return (
              <CustomListItem active={rota?.path === route.path} key={i}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: isOpen ? 'initial' : 'center',
                    px: 2.5
                  }}
                  component={Link}
                  to={route.path}
                  className="list-link"
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isOpen ? 3 : 0,
                      justifyContent: 'center',
                      color: 'inherit'
                    }}
                  >
                    {route.icon}
                  </ListItemIcon>
                  {isOpen && (
                    <ListItemText
                      primary={
                        <Typography className="list-item-text">
                          {route.title}
                        </Typography>
                      }
                    />
                  )}
                </ListItemButton>
              </CustomListItem>
            )
          })}
        </List>
      </div>
      <div className="d-flex flex-column">
        <CustomListItem>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isOpen ? 'initial' : 'center',
              px: 2.5
            }}
            onClick={() => logout()}
            className="list-link"
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isOpen ? 3 : 0,
                justifyContent: 'center',
                color: 'inherit'
              }}
            >
              <FiLogOut className="icon" />
            </ListItemIcon>
            {isOpen && (
              <ListItemText
                primary={
                  <Typography className="list-item-text">Sair</Typography>
                }
              />
            )}
          </ListItemButton>
        </CustomListItem>
        <Version />
      </div>
    </Drawer>
  )
}

export default Sidebar

function Version() {
  switch (config.AMBIENTE) {
    case 'DESENV':
      return (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '12px',
            color: 'var(--cinza-riber)'
          }}
        >
          desenv.
        </Typography>
      )
    case 'HML':
      return (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '12px',
            color: 'var(--cinza-riber)'
          }}
        >
          {`v${infoPkg.versionHml}`}
        </Typography>
      )
    case 'PROD':
      return (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '12px',
            color: 'var(--cinza-riber)'
          }}
        >
          {`v${infoPkg.versionProd}`}
        </Typography>
      )
    default:
      return (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '12px',
            color: 'var(--cinza-riber)'
          }}
        >
          {`v${infoPkg.version}`}
        </Typography>
      )
  }
}
