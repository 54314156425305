import { useEffect } from 'react'
import InputCustom from 'components/Inputs'
import SimpleCard from 'components/SimpleCard'
import CustomTable from 'components/CustomTable'
import useRedux from 'hooks/useRedux'
import ModalExclusao from 'components/ModalExclusao'
import { FiBriefcase } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { SimpleTitleCard, CustomButton } from 'styles/global'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { CAMPANHA } from 'store/modules/backoffice/campanha/types'
import * as campanhaActions from 'store/modules/backoffice/campanha/actions'
import CustomSelect from 'components/Select'
import { COLUMNS } from 'views/backoffice/menus/Campanhas/constants/columns'

const Search = () => {
  const {
    search,
    campanhas,
    pagination,
    showConfirmacaoExclusao,
    showConfirmacaoExclusaoSelecionados,
    activeTab,
    lojas,
    campanhasSelectedIds
  } = useSelector<ApplicationState, CAMPANHA>((state) => state.campanha)
  const { dispatch } = useRedux()

  type Fields = keyof CAMPANHA['search']
  function onChange(value: string, field: Fields) {
    dispatch(campanhaActions.setPesquisa(value, field))
  }

  function setPage(value: number) {
    dispatch(campanhaActions.setPaginacao({ ...pagination, page: value }))
    dispatch(campanhaActions.getAllCampanhasSaga())
  }

  function setPageSize(value: number) {
    dispatch(
      campanhaActions.setPaginacao({ ...pagination, page: 0, pageSize: value })
    )
    dispatch(campanhaActions.getAllCampanhasSaga())
  }

  function getCampanhas() {
    dispatch(campanhaActions.setPaginacao({ ...pagination, page: 0 }))
    dispatch(campanhaActions.cleanCampanhasSelectedIds())
    dispatch(campanhaActions.getAllCampanhasSaga())
  }

  useEffect(() => {
    dispatch(campanhaActions.getAllCampanhasSaga())
    dispatch(campanhaActions.getAllLojasSaga())
  }, [dispatch])

  return (
    <SimpleCard>
      <div className="container-fluid d-flex flex-column row-gap-10">
        <Row>
          <Col md={6}>
            <SimpleTitleCard>
              <FiBriefcase className="icon" />
              Campanha
            </SimpleTitleCard>
          </Col>
          <Col className="d-flex justify-content-end" md={6}>
            <CustomButton
              onClick={() => dispatch(campanhaActions.setActiveTab('register'))}
            >
              Cadastrar nova
            </CustomButton>
          </Col>
        </Row>
        <Row className="d-flex row-gap-15">
          <Col md={3}>
            <label className="label-12">Descrição</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'descricao')
              }}
              value={search.descricao}
            />
          </Col>
          <Col md={3}>
            <label className="label-12">Loja</label>
            <CustomSelect
              options={lojas}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'lojaId')
              }}
              value={search.lojaId}
              accessorLabel="nome"
              accessorValue="id"
            />
          </Col>
          <Col md={3}>
            <label className="label-12">Data início</label>
            <InputCustom
              type="date"
              onChange={(e) => {
                onChange(e.target.value, 'dataInicio')
              }}
              value={search.dataInicio || ''}
            />
          </Col>
          <Col md={3}>
            <label className="label-12">Data fim</label>
            <InputCustom
              type="date"
              onChange={(e) => {
                onChange(e.target.value, 'dataFim')
              }}
              value={search.dataFim || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <div className="d-flex gap-3">
              <CustomButton
                onClick={() => {
                  dispatch(campanhaActions.cleanPesquisa())
                  getCampanhas()
                }}
                background="#662d90"
              >
                Limpar filtros
              </CustomButton>
              <CustomButton onClick={() => getCampanhas()}>
                Pesquisar
              </CustomButton>
            </div>
            <div>
              <CustomButton
                onClick={() => {
                  campanhaActions.toggleModalExclusaoSelecionadosDispatch(true)
                }}
                background="#662d90"
              >
                Excluir selecionados
              </CustomButton>
            </div>
          </Col>
        </Row>
        <Row>
          {activeTab === 'search' && (
            <CustomTable
              data={campanhas}
              columns={COLUMNS}
              rowCount={pagination.totalRegistros}
              paginationMode="server"
              page={pagination.page}
              pageSize={pagination.pageSize}
              setPage={(e) => setPage(e)}
              setPageSize={(e) => setPageSize(e)}
              autoHeight
              checkboxSelection
              selectionModel={(ids) => {
                dispatch(campanhaActions.setCampanhasSelectedIds(ids))
              }}
              selecteds={campanhasSelectedIds}
              keepNonExistentRowsSelected
              getIsRowInactive={(params) => (params.row.vigente ? false : true)}
              style={{ minWidth: '1300px', overflow: 'auto' }}
            />
          )}
        </Row>
      </div>
      <ModalExclusao
        isOpen={showConfirmacaoExclusao}
        toggle={(e) => campanhaActions.toggleModalExclusaoDispatch(e)}
        onRemove={() => {
          campanhaActions.deleteCampanhaSagaDispatch()
        }}
      />
      <ModalExclusao
        isOpen={showConfirmacaoExclusaoSelecionados}
        toggle={(e) =>
          campanhaActions.toggleModalExclusaoSelecionadosDispatch(e)
        }
        onRemove={() => {
          campanhaActions.deleteCampanhasSelecionadasSagaDispatch()
        }}
        customMessage="Deseja realmente excluir os registros selecionados?"
      />
    </SimpleCard>
  )
}

export default Search
