import React from 'react'
import { BsSignpost2 } from 'react-icons/bs'
import { BlankSpaceContainer } from '../style'

const BlankSpace = () => {
  return (
    <BlankSpaceContainer>
      <div>
        <BsSignpost2 className="icon" />
        <label>Nenhum resultado</label>
        <p>Utilize os filtros para iniciar a parametrização de produto.</p>
      </div>
    </BlankSpaceContainer>
  )
}

export default BlankSpace
