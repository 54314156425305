import styled from 'styled-components'
const roxoRiber = '#662d91'

export const CalendarWrapper = styled.div`
  & a {
    text-decoration: none;
    color: inherit;
  }

  & .fc-toolbar-title {
    text-transform: uppercase;
  }

  & .fc-col-header-cell-cushion {
    text-transform: capitalize;
  }

  & tr th .fc-scrollgrid-sync-inner {
    background: ${roxoRiber};
    color: #fff;
  }
`

export const AgendaDiariaWrapper = styled.div`
  & .lista-atendimento {
    overflow-y: auto;
    height: 400px;
    background: #fafafa;
    border: 1px solid #cfcfcf;
    box-sizing: border-box;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 10px 10px 10px 5px;

    & .bloco-ligacao {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    & .info-ligacao {
      align-items: center;
      background: #fff;
      border: 1px solid #cfcfcf;
      box-sizing: border-box;
      border-radius: 15px;
      color: #4f585a;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      cursor: pointer;
    }

    & .info-ligacao:hover {
      background: #dcdcdc;
      border: 1px solid #dcdcdc;
    }

    & .info-ligacao.active {
      background: #662d91;
      border: 1px solid #662d91;
      color: #ffffff !important;
    }

    & .horario-atendimento {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    & .nome-cliente {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }

    & .nome-vendedor {
      font-size: 14px;
      line-height: 22px;
      text-align: right;
    }
  }

  & .detalhamento-agendamento {
    border: 1px solid #cfcfcf;
    box-sizing: border-box;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 400px;
    overflow-y: auto;
    padding: 20px;

    & h2 {
      color: #474a57;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    & span {
      color: #353535;
      font-size: 14px;
      line-height: 24px;
    }

    & .btn-green,
    .btn-orange {
      background: linear-gradient(264.61deg, #87df41 -9.34%, #00ab4f 101.57%);
      color: #fff;
      cursor: pointer;
      display: flex;
      border-radius: 5px;
      padding: 5px;
      width: fit-content;
      height: fit-content;
    }

    & .btn-green {
      background: linear-gradient(264.61deg, #87df41 -9.34%, #00ab4f 101.57%);
    }

    & .btn-orange {
      background: linear-gradient(264.61deg, #f7931e -9.34%, #f15a24 101.57%);
    }

    & .link-cpf {
      color: #0000ff;
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: #000080;
      }
    }
  }
`

interface TipoAgendamentoIconProps {
  background: Colors
}

export const TipoAgendamentoIcon = styled.div<TipoAgendamentoIconProps>`
  background: ${({ background }) => handleColorType(background)};
  color: #fff;
  display: flex;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
  height: fit-content;
`
export const CardDiaSemana = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  cursor: pointer;
  width: 120px;
  height: fit-content;
  border-radius: 15px;

  &:hover {
    color: #fff;
    background-color: #b39acb;
  }

  &.today {
    background: #662d91;
    color: #fff;
  }
`
export const CarouselWrapper = styled.div`
  display: flex;
  align-items: center;

  & .carousel {
    width: calc(100% - 80px);
    margin-inline: auto;
  }

  & .btn-icon {
    font-size: 20px;
    cursor: pointer;
  }
`

export const EventWrapper = styled.div`
  align-items: center;
  display: flex;
  font-family: 'Open Sans';
  gap: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  & span {
    font-size: 0.85em;
  }

  & label {
    font-size: 0.85em;
    font-weight: 700;
  }
`
function handleColorType(color: Colors | undefined) {
  switch (color) {
    case Colors.branco:
      return '#fff'
    case Colors.preto:
      return '#000'
    case Colors.cinzaRiber:
      return '#8e8e8e'
    case Colors.cinzaClaro:
      return '#dfdfdf'
    case Colors.verdeGradienteRiber:
      return 'linear-gradient(264.61deg, #87DF41 -9.34%, #00AB4F 101.57%)'
    case Colors.laranjaGradienteRiber:
      return 'linear-gradient(264.61deg, #F7931E -9.34%, #F15A24 101.57%)'
    default:
      return '#fff'
  }
}

export enum Colors {
  branco = '#fff',
  preto = '#000',
  cinzaRiber = '#8e8e8e',
  cinzaClaro = '#dfdfdf',
  verdeGradienteRiber = 'linear-gradient(264.61deg, #87DF41 -9.34%, #00AB4F 101.57%)',
  laranjaGradienteRiber = 'linear-gradient(264.61deg, #F7931E -9.34%, #F15A24 101.57%)'
}

interface ColorProps {
  background: Colors
  color?: Colors
}

export const IconEventWrapper = styled.i<ColorProps>`
  align-items: center;
  background: ${({ background }) => handleColorType(background)};
  border-radius: 5px;
  color: ${({ color }) => handleColorType(color)};
  display: flex;
  justify-content: center;
  height: 18px;
  width: 18px;
  padding: 2px;
`

export const LegendasWrapper = styled.div`
  display: grid;
  grid-row-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 10px;
  width: 300px;

  & div {
    align-items: center;
    display: flex;
    gap: 4px;
  }
`

export const BtnConfirmacao = styled.button`
  color: #ffffff;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 280px;
  height: 40px;
  background: ${(props) =>
    props.disabled
      ? 'var(--cinzaClaro-riber)'
      : 'var(--laranjaGradiente-riber)'};
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  border: none;
`

export const ErrorMessageWrapper = styled.span`
  color: #dc3545;
  display: flex;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  max-width: 100%;
`
