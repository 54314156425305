import React from 'react'
import { BiStore } from 'react-icons/bi'
import { BsTelephone } from 'react-icons/bs'
import { Colors, TipoAgendamentoIcon } from '../style'

interface TipoAgendamentoProps {
  tipoAgendamento: string
  confirmado: boolean
  atendimentoConcluidoId: string | null
}

const TipoAgendamento = ({
  tipoAgendamento,
  confirmado,
  atendimentoConcluidoId
}: TipoAgendamentoProps) => {
  function getBackgroundColor(confirmado: boolean, concluido: string | null) {
    if (concluido) {
      return Colors.cinzaClaro
    }
    if (confirmado) {
      return Colors.verdeGradienteRiber
    }
    return Colors.laranjaGradienteRiber
  }
  switch (tipoAgendamento) {
    case 'Presencial':
      return (
        <TipoAgendamentoIcon
          background={getBackgroundColor(confirmado, atendimentoConcluidoId)}
        >
          <BiStore />
        </TipoAgendamentoIcon>
      )
    case 'Telefone':
      return (
        <TipoAgendamentoIcon
          background={getBackgroundColor(confirmado, atendimentoConcluidoId)}
        >
          <BsTelephone />
        </TipoAgendamentoIcon>
      )
    default:
      return <TipoAgendamentoIcon background={Colors.cinzaRiber} />
  }
}

export default TipoAgendamento
