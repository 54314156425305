import React from 'react'
import { AiOutlineUserAdd } from 'react-icons/ai'
import { RiCloseLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { Ul, Tab } from '../style'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import BlankSpace from './BlankSpace'
import TabsAtendimento from './TabsAtendimento'
import { TabContent, TabPane } from 'reactstrap'
import classNames from 'classnames'
import useRedux from 'hooks/useRedux'

function CustomTab() {
  const { atendimentosAbertos, tabClienteActive } = useSelector<
    ApplicationState,
    ATENDIMENTO
  >((state) => state.atendimento)

  const { dispatch } = useRedux()

  function finalizarAtendimento(index: number) {
    dispatch(atendimentoActions.endAtendimento(index))
  }

  function setTabActive(index: number | string) {
    dispatch(atendimentoActions.setTabClienteActive(index))
  }

  return (
    <>
      <Ul id="tabs" className="nav" data-tabs="tabs">
        <Tab>
          <a
            style={{ cursor: 'pointer' }}
            className={classNames(
              tabClienteActive === 'novo-atendimento' && 'active'
            )}
            onClick={() => {
              setTabActive('novo-atendimento')
            }}
          >
            <AiOutlineUserAdd className="icon" />
            Novo Cliente
          </a>
        </Tab>
        {atendimentosAbertos.map((atendimento, i) => {
          return (
            <React.Fragment key={i}>
              {atendimento.ativo && (
                <Tab>
                  <a className={classNames(tabClienteActive === i && 'active')}>
                    <span
                      onClick={() => {
                        setTabActive(i)
                      }}
                    >
                      {atendimento.tabContato.nome}
                    </span>
                    <RiCloseLine
                      className="close"
                      onClick={() => {
                        finalizarAtendimento(i)
                      }}
                    />
                  </a>
                </Tab>
              )}
            </React.Fragment>
          )
        })}
      </Ul>
      <TabContent activeTab={tabClienteActive}>
        <BlankSpace />
        {atendimentosAbertos.map((atendimento, i) => {
          return (
            <React.Fragment key={i}>
              {atendimento.ativo && (
                <TabPane tabId={i}>
                  <TabsAtendimento index={i} />
                </TabPane>
              )}
            </React.Fragment>
          )
        })}
      </TabContent>
    </>
  )
}

export default CustomTab
