import { ParametrizacaoCampanhaReq } from 'store/modules/backoffice/campanha/types'
import { APICore } from 'helpers/api/apiCore'
import {
  AbastecimentoCampanhaDto,
  ReabastecimentoCampanhaDto
} from 'models/backoffice/campanha/type'

const api = new APICore()

export function getAllCampanhas(params: {
  pagina: number
  registros: number
  descricao: string
  dataInicio: string
  dataFim: string
  lojaId: string | null
}) {
  return api.get(`CampanhasCrm`, {
    ...params,
    pagina: params.pagina + 1,
    registros: params.registros,
    limiteMaxRegistros: 500
  })
}

export function getCampanha(id: string) {
  return api.get(`CampanhasCrm/get-by-id`, {
    id: id
  })
}

export function criarCampanha(params: ParametrizacaoCampanhaReq) {
  return api.create(`CampanhasCrm`, params)
}

export function atualizarCampanha(params: ParametrizacaoCampanhaReq) {
  return api.update(`CampanhasCrm`, params)
}

export function deletarCampanha(id: string) {
  return api.delete(`CampanhasCrm?campanhaCrmId=${id}`)
}

export function deletarCampanhas(ids: string[]) {
  return api.update(`CampanhasCrm/deletar-campanhas`, ids)
}

export function getCidades(siglaEstado: string) {
  return api.get(`Generic/cidades`, {
    siglaEstado: siglaEstado
  })
}

export function getEstados() {
  return api.get(`Generic/estados`, null)
}

export function getGeneros() {
  return api.get(`Generic/generos`, null)
}

export function getTiposOperacao() {
  return api.get(`Generic/tipos-operacao`, null)
}

export function getBancos() {
  return api.get(`Generic/bancos-dados-bancarios`, null)
}

export function getGruposLojas() {
  return api.get(`CampanhasCrm/get-grupo-loja`, null)
}

export function getBancosCartao() {
  return api.get(`Generic/bancos`, {
    flagCartao: true
  })
}

export function alterarStatusCampanha(campanhaCrmId: string, ativo: boolean) {
  return api.update(
    `CampanhasCrm/alterar-status-campanha?campanhaCrmId=${campanhaCrmId}&ativo=${ativo}`,
    null
  )
}

export function abastecimentoClientes(value: AbastecimentoCampanhaDto) {
  return api.create(`CampanhasCrm/abastecimento-clientes`, value)
}

export function reabastecimentoClientes(value: ReabastecimentoCampanhaDto) {
  return api.create(`CampanhasCrm/reabastecimento-clientes`, value)
}

export function getEspecies() {
  return api.get(`Generic/especies-beneficio`, null)
}

export function getAllLojas() {
  return api.get('Generic/lojas')
}
