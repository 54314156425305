export enum TYPES {
  CLEAN_LOGIN_MASTER = '@@credenciais/CLEAN_LOGIN_MASTER',
  CLEAN_LOGIN_PAN = '@@credenciais/CLEAN_LOGIN_PAN',
  CLEAN_LOGIN_BMG = '@@credenciais/CLEAN_LOGIN_BMG',
  CLEAN_LOGIN_ITAU = '@@credenciais/CLEAN_LOGIN_ITAU',
  CLEAN_CREDENCIAIS_AGENTE = '@@credenciais/CLEAN_LOGIN_ITAU',

  SET_USUARIOS_INSTITUICAO_BMG = '@@credenciais/SET_USUARIOS_INSTITUICAO_BMG',
  SET_USUARIOS_INSTITUICAO_ITAU = '@@credenciais/SET_USUARIOS_INSTITUICAO_ITAU',
  SET_LOGIN_BMG = '@@credenciais/SET_LOGIN_BMG',
  SET_LOGIN_ITAU = '@@credenciais/SET_LOGIN_ITAU',
  SET_LOGIN_PAN = '@@credenciais/SET_LOGIN_PAN',
  SET_LOGIN_MASTER = '@@credenciais/SET_LOGIN_MASTER',
  SET_CREDENCIAIS_AGENTE = '@@credenciais/SET_CREDENCIAIS_AGENTE',
  SET_CREDENCIAIS_AGENTE_FIELDS = '@@credenciais/SET_CREDENCIAIS_AGENTE_FIELDS',

  GET_USUARIOS_INSTITUICAO_BMG_SAGA = '@@credenciais/GET_USUARIOS_INSTITUICAO_BMG_SAGA',
  GET_USUARIOS_INSTITUICAO_ITAU_SAGA = '@@credenciais/GET_USUARIOS_INSTITUICAO_ITAU_SAGA',
  SALVAR_CREDENCIAIS_BMG_SAGA = '@@credenciais/SALVAR_CREDENCIAIS_BMG_SAGA',
  SALVAR_CREDENCIAIS_ITAU_SAGA = '@@credenciais/SALVAR_CREDENCIAIS_ITAU_SAGA',
  GET_USUARIO_PRINCIPAL_BMG_SAGA = '@@credenciais/GET_USUARIO_PRINCIPAL_BMG_SAGA',
  GET_USUARIO_PRINCIPAL_ITAU_SAGA = '@@credenciais/GET_USUARIO_PRINCIPAL_ITAU_SAGA',
  GET_CREDENCIAIS_AGENTE_SAGA = '@@credenciais/GET_CREDENCIAIS_AGENTE_SAGA',
  SALVAR_CREDENCIAIS_AGENTE_SAGA = '@@credenciais/SALVAR_CREDENCIAIS_AGENTE_SAGA'
}
export interface CREDENCIAIS {
  usuariosInstituicaoBmg: Usuario[]
  usuariosInstituicaoItau: Usuario[]
  bmg: LoginSenha
  itau: {
    usuario: string
  }
  pan: LoginSenha
  master: LoginSenha
  credenciaisAgente: CredenciaisAgente
}
export interface Usuario {
  usuarioInstituicao: string
}
export interface LoginSenha {
  usuario: string
  senha: string
}

export interface CredenciaisAgente {
  usuarioAgente: string
  senha: string
}
