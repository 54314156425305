import {
  GridActionsCellItem,
  GridColumns,
  GridRowParams
} from '@mui/x-data-grid'
import { Row } from 'react-data-grid'
import { AiOutlineEye } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import * as carteiraActions from 'store/modules/backoffice/carteira/actions'

const headerTheme = 'table-header--purple'

export const COLUMNS: GridColumns<Row> = [
  {
    field: 'nomeLoja',
    headerName: 'Lojas',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'qtdConsultores',
    headerName: 'Qtd. de consultores',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'qtdClientesTotal',
    headerName: 'Qtd. de Clientes',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'actions',
    headerName: 'Ações',
    headerClassName: headerTheme,
    type: 'actions',
    align: 'center',
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key={params.row.id}
        icon={<AiOutlineEye />}
        onClick={() => {
          carteiraActions.getIdAndNameDispatchSaga(
            params.row.nomeLoja,
            params.row.id
          )
          carteiraActions.visualizarCarteiraDispatchSaga(params.row.lojaId)
        }}
        label="Visualizar"
      />,
      <GridActionsCellItem
        key={params.row.id}
        icon={<HiOutlinePencil />}
        onClick={() => {
          carteiraActions.getIdAndNameDispatchSaga(
            params.row.nomeLoja,
            params.row.lojaId
          )
          carteiraActions.editarCarteiraDispatchSaga(params.row.lojaId)
        }}
        label="Editar"
      />
    ]
  }
]
