import React, { useState } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Collapse,
  Tooltip
} from 'reactstrap'
import { FaRegPlusSquare, FaRegMinusSquare, FaInfoCircle } from 'react-icons/fa'
import { MdAttachMoney } from 'react-icons/md'
import { RiComputerLine } from 'react-icons/ri'
import { ContainerInfoLead } from '../style'
import { maskMoney } from 'util/masks'

interface Oferta {
  descricao: string
  valor: string
}

interface InformacoesProps {
  matricula: string
  perfil: string
  step: string
  observacaoLead: string
  produtosLead: Oferta[]
}

const InformacoesSite: React.FC<InformacoesProps> = ({
  matricula,
  perfil,
  step,
  observacaoLead,
  produtosLead
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const formatarValor = (valor: string | number) => {
    if (typeof valor === 'number') {
      return `R$ ${valor.toFixed(2).replace('.', ',')}`
    } else if (typeof valor === 'string') {
      const valorNumerico = parseFloat(valor.replace(/[^\d.-]/g, ''))
      if (isNaN(valorNumerico)) {
        return 'R$ 0,00'
      }
      return `R$ ${valorNumerico.toFixed(2).replace('.', ',')}`
    } else {
      return 'R$ 0,00'
    }
  }

  return (
    <ContainerInfoLead className="container-fluid">
      <Row className="align-items-center  title-content">
        <Col>
          <h5>
            <RiComputerLine size={26} className="icon" />
            Informações Site
          </h5>
        </Col>
        <Col xs="auto">
          <Button color="link" onClick={toggle} className="fs-5 p-0">
            {isOpen ? (
              <FaRegMinusSquare color="#000000" />
            ) : (
              <FaRegPlusSquare color="#000000" />
            )}
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={isOpen}>
        <div className="d-flex flex-column justify-content-between">
          <Row className="mb-4">
            {/* <Col sm="4">
              <CardTitle tag="h6">
                <span className="fw-bold">Matrícula:</span> {matricula}
              </CardTitle>
            </Col> */}
            <Col sm="4">
              <CardTitle tag="h6">
                <span className="fw-bold">Perfil:</span> {perfil}
              </CardTitle>
            </Col>
            <Col sm="4">
              <CardTitle tag="h6">
                <span className="fw-bold">Step Selecionado:</span> {step}
                <FaInfoCircle
                  id="stepInfo"
                  style={{
                    marginLeft: '8px',
                    cursor: 'pointer',
                    color: '#FF532A'
                  }}
                />
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target="stepInfo"
                  toggle={toggleTooltip}
                >
                  {observacaoLead}
                </Tooltip>
              </CardTitle>
            </Col>
          </Row>
          <Row>
            {produtosLead.map((oferta, index) => (
              <Col
                sm="2"
                key={index}
                style={{
                  minWidth: '150px',
                  marginBottom: '1rem',
                  marginRight: '1rem'
                }}
              >
                <Card className="card-produto">
                  <CardBody className="d-flex align-items-center justify-content-center h-100 gap-2">
                    <div className="Icon">
                      <MdAttachMoney size={30} />
                    </div>
                    <div>
                      <CardTitle className="title">
                        {oferta.descricao}
                      </CardTitle>
                      <CardText
                        style={{
                          color: '#28a745',
                          fontSize: '16px',
                          fontWeight: 700
                        }}
                      >
                        {formatarValor(oferta.valor)}
                      </CardText>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Collapse>
    </ContainerInfoLead>
  )
}

export default InformacoesSite
