import { APICore } from 'helpers/api/apiCore'

const api = new APICore()
export interface FilterProps {
  nomeLoja?: string | null
  clientesSemConsultores?: boolean | string | null
  ativo?: boolean | string | null
}
export interface ConsultantProps {
  nomeConsultor?: string
  desconsiderarConsultorId?: string | null
  lojaId: string
}
export interface StoreProps {
  nomeLoja?: string | null
  lojaId: string
}

export function getAllCarteiras(
  params: FilterProps,
  pagina: number,
  registros: number
) {
  return api.getWithoutUndefined(`Carteira/carteiras`, {
    pagina: pagina + 1,
    registros,
    ...params
  })
}

export function getAllCarteirasWithName(
  params: FilterProps,
  pagina: number,
  registros: number
) {
  return api.getWithoutUndefined(`Carteira/carteiras/${params.nomeLoja}`, {
    pagina: pagina + 1,
    registros,
    ...params
  })
}

export function detailWallet(params: { lojaId: string }) {
  return api.get(`Carteira/detalhes-carteira`, { lojaId: params.lojaId })
}

export function clientStore(params: StoreProps) {
  return api.getWithoutUndefined(`Carteira/clientes-por-loja`, params)
}

export function clientStoreWithName(params: StoreProps) {
  return api.getWithoutUndefined(
    `Carteira/clientes-por-loja/${params.nomeLoja}`,
    params
  )
}

export function clientConsultant(params: ConsultantProps) {
  return api.getWithoutUndefined(`Carteira/clientes-por-consultor`, params)
}
export function transferirCarteiraConsultor(params: {
  lojaId: string
  consultorAntigoId: string
  ids: string[]
}) {
  return api.update(
    `Carteira/transferir-carteira-de-consultor?lojaId=${params.lojaId}&consultorAntigoId=${params.consultorAntigoId}`,
    params.ids
  )
}
export function transferirCarteiraLoja(params: {
  lojaId: string
  novaLojaId: string
}) {
  return api.update(
    `Carteira/transferir-carteira-de-loja?lojaId=${params.lojaId}&novaLojaId=${params.novaLojaId}`,
    null
  )
}
export function dividirCarteirasEntreConsultores(params: {
  lojaId: string
  ids: string[]
}) {
  return api.update(
    `Carteira/dividir-clientes-entre-consultores?lojaId=${params.lojaId}`,
    params.ids
  )
}

export function atualizaCarteiras() {
  return api.update(`Carteira/atualiza-oportunidade`, null)
}
