import React from 'react'
import { LoadingEllipsisWrapper } from './style'

interface LoadindEllipsisProps {
  children: React.ReactNode
}

const LoadingEllipsis = ({ children }: LoadindEllipsisProps) => {
  return <LoadingEllipsisWrapper>{children}</LoadingEllipsisWrapper>
}

export default LoadingEllipsis
