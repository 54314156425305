import React from 'react'
import { CardNovaRegra } from '../style'

interface CardParametrizacaoProps {
  children: React.ReactNode
}

const CardParametrizacao = ({ children }: CardParametrizacaoProps) => {
  return (
    <CardNovaRegra>
      <div className="detalhe-lateral"></div>
      <div className="d-flex flex-column row-gap-10 w-100 p-4">{children}</div>
    </CardNovaRegra>
  )
}

export default CardParametrizacao
