import React, { useEffect } from 'react'
import { SimpleTitleCard } from 'styles/global'
import { FiMapPin } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import CustomSelect from 'components/Select'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { CAMPANHA } from 'store/modules/backoffice/campanha/types'
import * as campanhaActions from 'store/modules/backoffice/campanha/actions'
import useRedux from 'hooks/useRedux'

const Localizacao = () => {
  const { register, optionsSelect, invalidsTabDadosGerais, visualizacao } =
    useSelector<ApplicationState, CAMPANHA>((state) => state.campanha)

  const { dispatch } = useRedux()

  type Fields = keyof CAMPANHA['register']
  function onChange<T extends Fields>(
    value: T extends Fields ? CAMPANHA['register'][T] : never,
    field: T
  ) {
    if (field === 'ufCampanha') {
      dispatch(campanhaActions.setRegisterFields([], 'cidadeCampanha'))
    }
    dispatch(campanhaActions.setRegisterFields(value, field))
  }

  useEffect(() => {
    dispatch(campanhaActions.getCidadesSaga())
    dispatch(campanhaActions.getEstadosSaga())
  }, [dispatch])

  useEffect(() => {
    dispatch(campanhaActions.getCidadesSaga())
  }, [dispatch, register.ufCampanha])

  return (
    <div className="d-flex flex-column row-gap-10">
      <SimpleTitleCard>
        <FiMapPin className="icon" /> Localização
      </SimpleTitleCard>
      <Row>
        <Col className="d-flex flex-column justify-content-start" md={3}>
          <label className="label-12">UF</label>
          <CustomSelect
            options={optionsSelect.ufs}
            isMulti
            placeholder="Selecione"
            onChange={(e) => {
              onChange(e, 'ufCampanha')
            }}
            value={register.ufCampanha}
            accessorLabel="nome"
            accessorValue="sigla"
            invalid={invalidsTabDadosGerais.ufCampanha}
            disabled={visualizacao || !!register.id}
          />
        </Col>
        <Col className="d-flex flex-column justify-content-start" md={3}>
          <label className="label-12">Cidade</label>
          <CustomSelect
            options={optionsSelect.cidades}
            isMulti
            placeholder="Selecione"
            onChange={(e) => {
              onChange(e, 'cidadeCampanha')
            }}
            value={register.cidadeCampanha}
            accessorLabel="nome"
            accessorValue="id"
            invalid={invalidsTabDadosGerais.cidadeCampanha}
            disabled={visualizacao || !!register.id}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Localizacao
