import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import { AnswersFormularioNecessidade } from 'models/types'

const Veiculo = () => {
  const { formularioNecessidade } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const [disabled, setDisabled] = useState(false)

  const { dispatch } = useRedux()

  type Fields = keyof AnswersFormularioNecessidade
  function onChange(value: boolean, field: Fields) {
    dispatch(atendimentoActions.setInfosFormulario(value, field))
  }

  function changeTab(tab: number) {
    dispatch(atendimentoActions.setTabFormulario(tab))
  }

  function proximo() {
    changeTab(3)
  }

  useEffect(() => {
    if (!formularioNecessidade.answers.possuiCarro) {
      dispatch(atendimentoActions.setInfosFormulario(false, 'carroQuitado'))
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [dispatch, formularioNecessidade.answers.possuiCarro])

  return (
    <div className="container-fluid">
      <div className="container-formulario">
        <Row>
          <Col className="d-flex flex-column align-items-center">
            <label className="label-12">Possui carro?</label>
            <label className="switch">
              <input
                checked={formularioNecessidade.answers.possuiCarro}
                onChange={(e) => onChange(e.target.checked, 'possuiCarro')}
                type="checkbox"
              />
              <div className="sliderCheck round">
                <span className="on">SIM</span>
                <span className="off">NÃO</span>
              </div>
            </label>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column align-items-center">
            <label className="label-12">Carro quitado?</label>
            <label className="switch">
              <input
                checked={formularioNecessidade.answers.carroQuitado}
                onChange={(e) => onChange(e.target.checked, 'carroQuitado')}
                disabled={disabled}
                type="checkbox"
              />
              <div className="sliderCheck round">
                <span className="on">SIM</span>
                <span className="off">NÃO</span>
              </div>
            </label>
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-center column-gap-20">
        <div
          className="btn-outline-purple justify-content-center"
          onClick={() => changeTab(1)}
        >
          Voltar
        </div>
        <div
          className="btn-purple justify-content-center"
          onClick={() => proximo()}
        >
          Próximo
        </div>
      </div>
    </div>
  )
}

export default Veiculo
