import { ModalBody, ModalHeader } from 'reactstrap'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import CustomTable from 'components/CustomTable'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import { COLUMNS_COBERTURA_SINDNAPI } from 'views/atendimento/constants/columns'
import { maskMoney } from 'util/masks'
import { ModalCoberturasStyled } from 'views/atendimento/style'
interface SeguroSindnapiProps {
  isOpen: boolean
  toggle: () => void
  index: number
}
const SeguroSindnapi = ({ isOpen, toggle }: SeguroSindnapiProps) => {
  const { modalSeguroSindnapi } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  function handleCloseModal() {
    dispatch(atendimentoActions.cleanModalSeguroSindnapi())
  }

  return (
    <ModalCoberturasStyled
      isOpen={isOpen}
      style={{
        overflowX: 'auto',
        maxWidth: '55%'
      }}
      onClosed={handleCloseModal}
    >
      <ModalHeader toggle={() => toggle()}></ModalHeader>
      <ModalBody>
        <div
          style={{ maxHeight: '80vh' }}
          className="d-flex flex-column gap-1 align-items-center"
        >
          <CustomTable
            autoHeight
            style={{ height: '40rem !important' }}
            data={modalSeguroSindnapi.coberturasField?.map((cobertura) => ({
              ...cobertura,
              valorBeneficio:
                cobertura.valorBeneficioField !== null
                  ? `R$ ${maskMoney(cobertura.valorBeneficioField?.toFixed(2))}`
                  : 0
            }))}
            columns={COLUMNS_COBERTURA_SINDNAPI}
            hideFooter={true}
            getRowId={(row) => row.nomeCoberturaField}
          />
        </div>
      </ModalBody>
    </ModalCoberturasStyled>
  )
}

export default SeguroSindnapi
