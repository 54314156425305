import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid'
import { Row } from 'react-data-grid'
import { FiEye, FiTrash } from 'react-icons/fi'
import { maskDate } from 'util/masks'
import * as uploadActions from 'store/modules/backoffice/uploadArquivos/actions'

const headerTheme = 'table-header--purple'

export const COLUMNS: GridColumns<Row> = [
  {
    field: 'nomeArquivo',
    headerName: 'Nome do arquivo',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'dataUpload',
    headerName: 'Data',
    headerClassName: headerTheme,
    type: 'datetime',
    renderCell: (params: GridRenderCellParams) => (
      <span className="table-cell-trucate">
        {params?.value ? maskDate(params?.value, 'DD/MM/YYYY') : ''}
      </span>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'nomeUsuario',
    headerName: 'Usuário',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'descricaoTipoArquivo',
    headerName: 'Tipo do Arquivo',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'actions',
    headerName: 'Ações',
    headerClassName: headerTheme,
    type: 'actions',
    align: 'center',
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key={params.row.id}
        onClick={() => {
          uploadActions.visualizarArquivoSagaDispatch(params.row.nomeArquivo)
        }}
        icon={<FiEye />}
        label="Visualizar"
      />,
      <GridActionsCellItem
        key={params.row.id}
        onClick={() => {
          uploadActions.setItemSelectedIdDispatch(params.row.nomeArquivo)
          uploadActions.setModalExclusaoDispatch(true)
        }}
        icon={<FiTrash />}
        label="Deletar"
        disabled={params.row.status !== 'ERRO'}
      />
    ]
  }
]
