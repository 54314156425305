import React from 'react'
import { Input, InputProps } from 'reactstrap'
import { InputArea } from './style'

function InputCustom({ ...rest }: InputProps) {
  return (
    <InputArea>
      <Input {...rest} />
    </InputArea>
  )
}

export default InputCustom
