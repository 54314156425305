import { IndicacaoPropostaReq } from 'models/indicacao/types'
import { Paginacao } from 'models/types'

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum TYPES {
  SET_FILTROS = '@@indicacao/SET_FILTROS',
  CLEAN_FILTROS = '@@indicacao/CLEAN_FILTROS',
  SET_EQUIPES_OPTIONS = '@@indicacao/SET_EQUIPES_OPTIONS',
  SET_TABELA_INDICACAO = '@@indicacao/SET_TABELA_INDICACAO',
  CLEAN_TABELA_INDICACAO = '@@indicacao/CLEAN_TABELA_INDICACAO',
  SET_PAGINATION = '@@indicacao/SET_PAGINATION',
  TOGGLE_MODAL_EDICAO_INDICACAO_DISPATCH = '@@indicacao/TOGGLE_MODAL_EDICAO_INDICACAO_DISPATCH',
  TOGGLE_MODAL_EDICAO_INDICACAO_MANUAL_DISPATCH = '@@indicacao/TOGGLE_MODAL_EDICAO_INDICACAO_MANUAL_DISPATCH',
  TOGGLE_MODAL_EDICAO_INDICACAO = '@@indicacao/TOGGLE_MODAL_EDICAO_INDICACAO',
  SET_INFOS_MODAL_EDICAO_INDICACAO = '@@indicacao/SET_INFOS_MODAL_EDICAO_INDICACAO',
  CLEAN_INFOS_MODAL_EDICAO_INDICACAO = '@@indicacao/CLEAN_INFOS_MODAL_EDICAO_INDICACAO',
  TOGGLE_MODAL_EXCLUSAO_DISPATCH = '@@indicacao/TOGGLE_MODAL_EXCLUSAO_DISPATCH',
  SET_OPTIONS_SELECTS = '@@indicacao/SET_OPTIONS_SELECTS',
  SET_EQUIPES = '@@indicacao/SET_EQUIPES',
  SET_INVALIDS = '@@indicacao/SET_INVALIDS',
  CLEAN_INVALIDS = '@@indicacao/CLEAN_INVALIDS',
  SET_ITEM_SELECTED_ID_DISPATCH = '@@indicacao/SET_ITEM_SELECTED_ID_DISPATCH',

  GET_ALL_PROPOSTAS_INDICACAO_SAGA = '@@indicacao/GET_ALL_PROPOSTAS_INDICACAO_SAGA',
  GET_PROPOSTA_INDICACAO_DISPATCH_SAGA = '@@indicacao/GET_PROPOSTA_INDICACAO_DISPATCH_SAGA',
  CRIAR_PROPOSTA_INDICACAO_SAGA = '@@indicacao/CRIAR_PROPOSTA_INDICACAO_SAGA',
  CRIAR_PROPOSTA_INDICACAO_MANUAL_SAGA = '@@indicacao/CRIAR_PROPOSTA_INDICACAO_MANUAL_SAGA',
  DUPLICAR_PROPOSTA_INDICACAO_DISPATCH_SAGA = '@@indicacao/DUPLICAR_PROPOSTA_INDICACAO_DISPATCH_SAGA',
  EDITAR_PROPOSTA_INDICACAO_SAGA = '@@indicacao/EDITAR_PROPOSTA_INDICACAO_SAGA',
  DELETAR_PROPOSTA_INDICACAO_SAGA = '@@indicacao/DELETAR_PROPOSTA_INDICACAO_SAGA',
  GET_EQUIPES_SAGA = '@@indicacao/GET_EQUIPES_SAGA',
  GET_USUARIOS_SAGA = '@@indicacao/GET_USUARIOS_SAGA',
  GET_EQUIPES_MODAL_SAGA = '@@indicacao/GET_EQUIPES_MODAL_SAGA',
  GET_BANCOS_SAGA = '@@indicacao/GET_BANCOS_SAGA',
  GET_CENTROS_CUSTOS_SAGA = '@@indicacao/GET_CENTROS_CUSTOS_SAGA',
  GET_CENTROS_CUSTOS_MODAL_SAGA = '@@indicacao/GET_CENTROS_CUSTOS_MODAL_SAGA',
  GET_STATUS_SAGA = '@@indicacao/GET_STATUS_SAGA',
  GET_TIPO_OPERACAO_SAGA = '@@indicacao/GET_TIPO_OPERACAO_SAGA',
  GET_USUARIOS_BANCO_BY_BANCO_LOJA_AND_DIGITADOR_SAGA = '@@indicacao/GET_USUARIOS_BANCO_BY_BANCO_LOJA_AND_DIGITADOR_SAGA'
}

export interface INDICACAO {
  filtros: FiltrosIndicacao
  pagination: Paginacao
  tabelaIndicacao: Indicacoes[]
  equipes: Equipe[]
  centros: Centro[]
  modalEdicaoIndicacao: {
    numeroAdesao: string
    cpf: string
    flagAtualizado?: boolean
    flagManual: boolean
    dataProposta: string
    usuarioBanco?: any
    usuarioBancoId: string
    loja?: any
    lojaId: string
    centroCusto?: any
    centroCustoId: string
    banco?: any
    bancoId: string
    tipoOperacao?: any
    tipoOperacaoId: string
    statusProposta?: any
    statusPropostaId: string
    digitador?: any
    digitadorId: string
    id: string
    ativo: boolean
    usuario?: any
    idUsuarioCriacao: string
    dataCriacao: string
    usuarioAlteracao?: any
    idUsuarioUltimaAlteracao: string
    dataUltimaAlteracao: string
  }
  isOpenModalEdicao: boolean
  showConfirmacaoExclusao: boolean
  optionsSelect: {
    equipes: Equipe[]
    centros: Centro[]
    centrosModal: Centro[]
    bancos: Banco[]
    status: StatusProposta[]
    tiposOperacao: TipoOperacao[]
    usuariosBanco: UsuarioBanco[]
  }
  modalEdicaoIndicacaoInvalids: ModalEdicaoIndicacaoInvalids
  itemSelectedId: string
}

interface FiltrosIndicacao {
  dataInicial: string
  dataFinal: string
  lojaId: string
  centroCustoId: string
  numeroAdesao: string
  cpf: string
}

export interface Indicacoes {
  id: string
  cpf: string
  nomeCliente: string
  numeroAdesao: string
  descricaoStatusProposta: string
  descricaoBanco: string
  descricaoLoja: string
  descricaoCentroCusto: string
  descricaoTipoOperacao: string
  dataProposta: string
  nomeDigitador: string
  dataCriacao: string
  flagAtualizado: boolean
  flagManual: boolean
}

export interface Usuario {
  idUsuario: string
  nomeUsuario: string
}

export interface Equipe {
  id: string
  nome: string
}

export interface Banco {
  id: string
  descricao: string
}

export interface UsuarioBanco {
  id: string
  nome: string
  lojaId: string
  centroCustoId: string
}

export interface Centro {
  id: string
  nome: string
}

export interface StatusProposta {
  descricao: string
  bancoId: string
  banco?: any
  status?: any
  statusId: string
  propostasIndicacao?: any
  id: string
  ativo: boolean
  usuario?: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao?: any
  idUsuarioUltimaAlteracao?: any
  dataUltimaAlteracao?: any
}

export interface TipoOperacao {
  descricao: string
  bancoId: string
  banco?: any
  tipoOperacaoId: string
  tipoOperacao?: any
  parametrizacaoProdutos?: any
  propostasIndicacao?: any
  id: string
  ativo: boolean
  usuario?: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao?: any
  idUsuarioUltimaAlteracao?: any
  dataUltimaAlteracao?: any
}

type ModalEdicaoIndicacaoInvalids = {
  [k in keyof IndicacaoPropostaReq]: boolean
}
