import styled from 'styled-components'

export const CardWallet = styled.div`
  width: 30vw;
  padding: 1vw;
  border: 1px solid #cfcfcf;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-feature-settings: 'liga' off;
  box-sizing: border-box;
  background: #fafafa;
`
export const CardWalletConsultants = styled.div`
  padding: 1vw;
  border: 1px solid #cfcfcf;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  text-align: center;
  font-feature-settings: 'liga' off;
  box-sizing: border-box;
  background: #fafafa;
  width: 220px;
`
export const TitleWallet = styled.h1`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
`
export const NameWallet = styled.h1`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--preto-riber);
`
export const TextWallet = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 10px;
  line-height: 16px;
`
export const ClientsWallet = styled.h1`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: var(--roxo-riber);
`
export const BtnWallet = styled.button`
  color: var(--roxo-riber);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: var(--roxo-riber);
  border-radius: 15px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: var(--branco);
  align-items: center;
  gap: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
`
export const AlignWallets = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-wrap: wrap;
`
export const Line = styled.div`
  border: 1px solid #cfcfcf;
  width: 80%;
  margin-bottom: 32px;
`
export const HeaderModalContainer = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderModalSubTitle = styled.span`
  font-family: 'Open Sans' serif;
  font-size: 14px;
  color: var(--preto-riber);
`

export const HeaderModalTitle = styled.span`
  color: var(--cinza-riber);
  font-family: 'Open Sans', serif;
  font-size: 18px;
  font-weight: 700;

  margin-left: 12px;
`
