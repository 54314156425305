import React from 'react'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import { Switch } from 'components/Switch'
import useRedux from 'hooks/useRedux'
import { RiBookOpenLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap'
import { ApplicationState } from 'store'
import { AGENDA } from 'store/modules/agenda/types'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import * as agendaActions from 'store/modules/agenda/actions'
import { schema } from 'views/agenda/constants/schemas'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import ErrorMessage from '../ErrorMessage'

interface ReagendamentoProps {
  isOpen: boolean
  onToggle: () => void
}

const Reagendamento = ({ isOpen, onToggle }: ReagendamentoProps) => {
  const { modalReagendamento, modalReagendamentoInvalids, tiposAgendamentos } =
    useSelector<ApplicationState, AGENDA>((state) => state.agenda)

  const { dispatch } = useRedux()

  type ModalReagendamentoFields = keyof AGENDA['modalReagendamento']
  function onChange<T extends ModalReagendamentoFields>(
    value: T extends keyof AGENDA['modalReagendamento']
      ? AGENDA['modalReagendamento'][T]
      : never,
    field: T
  ) {
    dispatch(agendaActions.setModalReagendamentoFields(value, field))
  }

  function onSave() {
    dispatch(agendaActions.cleanModalReagendamentoInvalids())
    schema
      .validate(
        {
          atendimentoAgendadoId: modalReagendamento.atendimentoAgendadoId,
          confirmado: modalReagendamento.confirmado,
          dataAtendimento: modalReagendamento.dataAtendimento,
          horario: modalReagendamento.horario,
          tipoAtendimentoId: modalReagendamento.tipoAtendimentoId
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(agendaActions.editarAgendamentoSaga())
      })
      .catch((err) => {
        err.inner.map((e: yup.ValidationError) => {
          dispatch(
            agendaActions.setModalReagendamentoInvalidsFields(
              {
                message: e.message,
                invalid: true
              },
              e.path as keyof AGENDA['modalReagendamentoInvalids']
            )
          )
        })
        toast.warn('Preencha os campos corretamente para salvar')
      })
  }

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => onToggle()}
      backdrop="static"
    >
      <ModalHeader
        toggle={() => {
          onToggle()
        }}
      >
        <SimpleTitleCard>
          <RiBookOpenLine className="icon" />
          Reagendamento
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={modalReagendamento.confirmarAgendamento ? 3 : 4}>
            <label className="label-12">Data do atendimento*</label>
            <InputCustom
              type="date"
              onChange={(e) => {
                onChange(e.target.value, 'dataAtendimento')
              }}
              value={modalReagendamento.dataAtendimento}
              invalid={modalReagendamentoInvalids.dataAtendimento.invalid}
            />
            <ErrorMessage>
              {modalReagendamentoInvalids.dataAtendimento.message}
            </ErrorMessage>
          </Col>
          <Col md={modalReagendamento.confirmarAgendamento ? 3 : 4}>
            <label className="label-12">Horário*</label>
            <InputCustom
              type="time"
              onChange={(e) => {
                onChange(e.target.value, 'horario')
              }}
              value={modalReagendamento.horario}
              invalid={modalReagendamentoInvalids.horario.invalid}
            />
            <ErrorMessage>
              {modalReagendamentoInvalids.horario.message}
            </ErrorMessage>
          </Col>
          <Col md={modalReagendamento.confirmarAgendamento ? 3 : 4}>
            <label className="label-12">Tipo de agendamento*</label>
            <CustomSelect
              options={tiposAgendamentos}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'tipoAtendimentoId')
              }}
              value={modalReagendamento.tipoAtendimentoId}
              invalid={modalReagendamentoInvalids.tipoAtendimentoId.invalid}
              accessorLabel="descricao"
              accessorValue="id"
            />
            <ErrorMessage>
              {modalReagendamentoInvalids.tipoAtendimentoId.message}
            </ErrorMessage>
          </Col>
          {modalReagendamento.confirmarAgendamento && (
            <Col md={3} className="d-flex flex-column">
              <label className="label-12">Confirmado*</label>
              <Switch
                onChange={(e) => {
                  onChange(e.target.checked, 'confirmado')
                }}
                checked={modalReagendamento.confirmado}
                invalid={modalReagendamentoInvalids.confirmado.invalid}
              />
              <ErrorMessage>
                {modalReagendamentoInvalids.confirmado.message}
              </ErrorMessage>
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <CustomButton background="var(--roxo-riber)" onClick={() => onToggle()}>
          Cancelar
        </CustomButton>
        <CustomButton
          onClick={() => {
            onSave()
          }}
        >
          Confirmar
        </CustomButton>
      </ModalFooter>
    </Modal>
  )
}

export default Reagendamento
