import React from 'react'
import Main from 'layouts/main'
import { TabContent, TabPane } from 'reactstrap'
import Calendario from './Tabs/Calendario'
import AgendamentoDiario from './Tabs/AgendamentoDiario'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { AGENDA } from 'store/modules/agenda/types'

const Agenda = () => {
  const { activeTab } = useSelector<ApplicationState, AGENDA>(
    (state) => state.agenda
  )

  return (
    <Main>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="calendario">
          <Calendario />
        </TabPane>
        <TabPane tabId="agendamento-diario">
          <AgendamentoDiario />
        </TabPane>
      </TabContent>
    </Main>
  )
}

export default Agenda
