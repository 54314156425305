import React from 'react'
import { SimpleTitleCard } from 'styles/global'
import { FiTool } from 'react-icons/fi'
import { Col, Collapse, Input, Row } from 'reactstrap'
import CustomSelect from 'components/Select'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { CAMPANHA } from 'store/modules/backoffice/campanha/types'
import * as campanhaActions from 'store/modules/backoffice/campanha/actions'
import useRedux from 'hooks/useRedux'
import InputCustom from 'components/Inputs'
import { maskMoney } from 'util/masks'
import { Switch } from 'components/Switch'

const Parametros = () => {
  const { register, optionsSelect, invalidsTabParametros, visualizacao } =
    useSelector<ApplicationState, CAMPANHA>((state) => state.campanha)

  const { dispatch } = useRedux()

  type Fields = keyof CAMPANHA['register']
  function onChange<T extends Fields>(
    value: T extends Fields ? CAMPANHA['register'][T] : never,
    field: T
  ) {
    dispatch(campanhaActions.setRegisterFields(value, field))
  }

  return (
    <div className="d-flex flex-column row-gap-10">
      <SimpleTitleCard>
        <FiTool className="icon" /> Parâmetros
      </SimpleTitleCard>
      <Row>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">
            Permitir redundância entre campanhas
          </label>
          <Switch
            onChange={(e) => {
              onChange(e.target.checked, 'redudancia')
            }}
            checked={register.redudancia}
            disabled={visualizacao}
            invalid={invalidsTabParametros.redudancia}
          />
        </Col>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">
            Ignorar regra de indisponibilização
          </label>
          <Switch
            onChange={(e) => {
              onChange(e.target.checked, 'indisponibilidade')
            }}
            checked={register.indisponibilidade}
            disabled={visualizacao}
            invalid={invalidsTabParametros.indisponibilidade}
          />
        </Col>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">Não perturbe</label>
          <Switch
            onChange={(e) => {
              onChange(e.target.checked, 'naoPertube')
            }}
            checked={register.naoPertube}
            disabled={visualizacao}
            invalid={invalidsTabParametros.naoPertube}
          />
        </Col>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">Discador automático</label>
          <Switch
            onChange={(e) => {
              onChange(e.target.checked, 'discadorAutomatico')
            }}
            checked={register.discadorAutomatico}
            disabled={visualizacao}
            invalid={invalidsTabParametros.discadorAutomatico}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">Telefone</label>
          <CustomSelect
            options={optionsSelect.meiosComunicacao}
            placeholder="Selecione"
            onChange={(e) => {
              onChange(e, 'telefone')
            }}
            value={register.telefone}
            accessorLabel="label"
            accessorValue="value"
            invalid={invalidsTabParametros.telefone}
            disabled={visualizacao}
          />
        </Col>
      </Row>
      <SimpleTitleCard>
        <FiTool className="icon" /> Parâmetros INSS
      </SimpleTitleCard>
      <Row>
        <Col className="d-flex align-items-center gap-2">
          <Input
            type="checkbox"
            onChange={(e) => {
              if (!e.target.checked) {
                dispatch(campanhaActions.cleanQuantidadeEmprestimo())
              }
              onChange(e.target.checked, 'quantidadeEmprestimo')
            }}
            checked={register.quantidadeEmprestimo}
            disabled={visualizacao}
            invalid={invalidsTabParametros.quantidadeEmprestimo}
          />
          <label className="label-12 mt-1">Quantidade de empréstimos</label>
        </Col>
      </Row>
      <Collapse isOpen={register.quantidadeEmprestimo}>
        <Row>
          <Col className="d-flex flex-column justify-content-end" md={3}>
            <label className="label-12">Quantidade inicial</label>
            <CustomSelect
              options={optionsSelect.qtdeEmprestimos}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'quantidadeInicialEmprestimo')
              }}
              value={register.quantidadeInicialEmprestimo}
              accessorLabel="label"
              accessorValue="value"
              invalid={invalidsTabParametros.quantidadeInicialEmprestimo}
              disabled={visualizacao}
            />
          </Col>
          <Col className="d-flex flex-column justify-content-end" md={3}>
            <label className="label-12">Quantidade final</label>
            <CustomSelect
              options={optionsSelect.qtdeEmprestimos}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'quantidadeFinalEmprestimo')
              }}
              value={register.quantidadeFinalEmprestimo}
              accessorLabel="label"
              accessorValue="value"
              invalid={invalidsTabParametros.quantidadeFinalEmprestimo}
              disabled={visualizacao}
            />
          </Col>
        </Row>
      </Collapse>
      <Row>
        <Col className="d-flex align-items-center gap-2">
          <Input
            type="checkbox"
            onChange={(e) => {
              if (!e.target.checked) {
                dispatch(campanhaActions.cleanSalario())
              }
              onChange(e.target.checked, 'salario')
            }}
            checked={register.salario}
            disabled={visualizacao}
            invalid={invalidsTabParametros.salario}
          />
          <label className="label-12 mt-1">Salário</label>
        </Col>
      </Row>
      <Collapse isOpen={register.salario}>
        <Row>
          <Col className="d-flex flex-column justify-content-end" md={3}>
            <label className="label-12">Quantidade inicial em R$</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskMoney(e.target.value), 'quantidadeInicialSalario')
              }}
              value={maskMoney(register.quantidadeInicialSalario)}
              disabled={visualizacao}
              maxLength={14}
              invalid={invalidsTabParametros.quantidadeInicialSalario}
            />
          </Col>
          <Col className="d-flex flex-column justify-content-end" md={3}>
            <label className="label-12">Quantidade final em R$</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskMoney(e.target.value), 'quantidadeFinalSalario')
              }}
              value={maskMoney(register.quantidadeFinalSalario)}
              disabled={visualizacao}
              maxLength={14}
              invalid={invalidsTabParametros.quantidadeFinalSalario}
            />
          </Col>
        </Row>
      </Collapse>
      <Row>
        <Col className="d-flex align-items-center gap-2">
          <Input
            type="checkbox"
            onChange={(e) => {
              if (!e.target.checked) {
                dispatch(campanhaActions.cleanMargemLivre())
              }
              onChange(e.target.checked, 'margemLivre')
            }}
            checked={register.margemLivre}
            disabled={visualizacao}
            invalid={invalidsTabParametros.margemLivre}
          />
          <label className="label-12 mt-1">Margem livre</label>
        </Col>
      </Row>
      <Collapse isOpen={register.margemLivre}>
        <Row>
          <Col className="d-flex flex-column justify-content-end" md={3}>
            <label className="label-12">Quantidade inicial em R$</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskMoney(e.target.value), 'quantidadeInicialMargem')
              }}
              value={maskMoney(register.quantidadeInicialMargem)}
              disabled={visualizacao}
              maxLength={14}
              invalid={invalidsTabParametros.quantidadeInicialMargem}
            />
          </Col>
          <Col className="d-flex flex-column justify-content-end" md={3}>
            <label className="label-12">Quantidade final em R$</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskMoney(e.target.value), 'quantidadeFinalMargem')
              }}
              value={maskMoney(register.quantidadeFinalMargem)}
              disabled={visualizacao}
              maxLength={14}
              invalid={invalidsTabParametros.quantidadeFinalMargem}
            />
          </Col>
        </Row>
      </Collapse>
      <Row>
        <Col className="d-flex align-items-center gap-2">
          <Input
            type="checkbox"
            onChange={(e) => {
              if (!e.target.checked) {
                dispatch(campanhaActions.cleanMargemCartao())
              }
              onChange(e.target.checked, 'margemCartao')
            }}
            checked={register.margemCartao}
            disabled={visualizacao}
            invalid={invalidsTabParametros.margemCartao}
          />
          <label className="label-12 mt-1">Margem de cartão</label>
        </Col>
      </Row>
      <Collapse isOpen={register.margemCartao}>
        <Row>
          <Col className="d-flex flex-column justify-content-end" md={3}>
            <label className="label-12">Quantidade inicial em R$</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(
                  maskMoney(e.target.value),
                  'quantidadeInicialMargemCartao'
                )
              }}
              value={maskMoney(register.quantidadeInicialMargemCartao)}
              disabled={visualizacao}
              maxLength={14}
              invalid={invalidsTabParametros.quantidadeInicialMargemCartao}
            />
          </Col>
          <Col className="d-flex flex-column justify-content-end" md={3}>
            <label className="label-12">Quantidade final em R$</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(
                  maskMoney(e.target.value),
                  'quantidadeFinalMargemCartao'
                )
              }}
              value={maskMoney(register.quantidadeFinalMargemCartao)}
              disabled={visualizacao}
              maxLength={14}
              invalid={invalidsTabParametros.quantidadeFinalMargemCartao}
            />
          </Col>
        </Row>
      </Collapse>
    </div>
  )
}

export default Parametros
