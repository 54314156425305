import React, { useState, useEffect } from 'react'
import CustomSelect from 'components/Select'
import SimpleCard from 'components/SimpleCard'
import ModalConfirmacao from 'components/ModalConfirmacao'
import useRedux from 'hooks/useRedux'
import { RiFolderUploadLine } from 'react-icons/ri'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import * as yup from 'yup'
import { UPLOAD_ARQUIVOS } from 'store/modules/backoffice/uploadArquivos/types'
import { UploadArquivo } from 'components/UploadArquivosComponent'
import * as uploadActions from 'store/modules/backoffice/uploadArquivos/actions'
import { UploadArquivosReq } from 'models/backoffice/uploadArquvios/types'
import { toast } from 'react-toastify'
import InputCustom from 'components/Inputs'

export default function Register() {
  const { tipos, register, invalids } = useSelector<
    ApplicationState,
    UPLOAD_ARQUIVOS
  >((state) => state.uploadArquivos)

  const { dispatch } = useRedux()

  const [modal, setModal] = useState(false)

  const schema: yup.SchemaOf<UploadArquivosReq> = yup.object().shape({
    tipoArquivo: yup.string().required(),
    uploadedFile: yup.mixed().required('File is required')
  })

  useEffect(() => {
    dispatch(uploadActions.getTiposDeArquivosSaga())
  }, [dispatch])

  type FieldsRegister = keyof UPLOAD_ARQUIVOS['register']

  function onChange(value: string | File, field: FieldsRegister) {
    dispatch(uploadActions.setRegisterFields(value, field))
  }

  async function onSave() {
    dispatch(uploadActions.cleanInvalids())

    schema
      .validate(
        {
          tipoArquivo: register.tipoArquivo,
          uploadedFile: register.uploadedFile
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(uploadActions.setCreating(true))
        dispatch(uploadActions.criarUploadSaga(register))
        dispatch(uploadActions.cleanRegister())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(uploadActions.setInvalids(true, e.path || ''))
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  function voltaParaSearch() {
    dispatch(uploadActions.cleanArquivo())
    dispatch(uploadActions.cleanRegister())
    dispatch(uploadActions.setActiveTab('search'))
  }

  return (
    <>
      <SimpleCard>
        <div className="container-fluid d-flex flex-column row-gap-10">
          <Row>
            <Col>
              <SimpleTitleCard>
                <RiFolderUploadLine className="icon" />
                Upload de Arquivos
              </SimpleTitleCard>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {
                <CustomButton
                  background="#662d90"
                  letterSpacing={1}
                  onClick={() => voltaParaSearch()}
                >
                  Voltar
                </CustomButton>
              }
            </Col>
            {register.nomeArquivo === '' && (
              <Col className="d-flex justify-content-end" md={6}>
                <CustomButton onClick={() => setModal(!modal)}>
                  Carregar Arquivo
                </CustomButton>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={6}>
              <label className="label-12 mb-1">Tipo do Arquivo</label>
              <CustomSelect
                options={tipos}
                placeholder="Selecione o Tipo de Arquivo"
                onChange={(e) => {
                  onChange(e, 'tipoArquivo')
                }}
                accessorLabel="descricao"
                accessorValue="value"
                value={register.tipoArquivo}
                invalid={invalids.tipoArquivo}
                disabled={register.nomeArquivo !== ''}
              />
            </Col>
            {register.nomeArquivo !== '' && (
              <Col md={6}>
                <label className="label-12 mb-1">Nome do Arquivo</label>
                <InputCustom value={register.nomeArquivo} readOnly />
              </Col>
            )}
          </Row>
          <Row className="mt-4">
            <UploadArquivo
              onChange={(e) => {
                onChange(e, 'uploadedFile')
              }}
              value={register.uploadedFile}
              invalid={invalids.uploadedFile}
            />
          </Row>
          <ModalConfirmacao
            isOpen={modal}
            toggle={(e) => setModal(e)}
            onConfirm={() => onSave()}
          />
        </div>
      </SimpleCard>
    </>
  )
}
