import React, { useEffect } from 'react'
import { SimpleTitleCard } from 'styles/global'
import { FiMessageSquare } from 'react-icons/fi'
import CustomSelect from 'components/Select'
import { Col, Row } from 'reactstrap'
import InputCustom from 'components/Inputs'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { CAMPANHA } from 'store/modules/backoffice/campanha/types'
import useRedux from 'hooks/useRedux'
import * as campanhaActions from 'store/modules/backoffice/campanha/actions'
import Especies from './Especies'

const MaisInformacoes = () => {
  const { register, optionsSelect, invalidsTabParametros, visualizacao } =
    useSelector<ApplicationState, CAMPANHA>((state) => state.campanha)

  const { dispatch } = useRedux()

  type Fields = keyof CAMPANHA['register']
  function onChange<T extends Fields>(
    value: T extends Fields ? CAMPANHA['register'][T] : never,
    field: T
  ) {
    dispatch(campanhaActions.setRegisterFields(value, field))
    if (field === 'cartaoInss' && !!value === false) {
      dispatch(campanhaActions.setRegisterFields([], 'bancosCartaoCampanha'))
    }
  }

  useEffect(() => {
    dispatch(campanhaActions.getGenerosSaga())
    dispatch(campanhaActions.getEspeciesBeneficioSaga())
  }, [dispatch])

  return (
    <div className="d-flex flex-column row-gap-10">
      <SimpleTitleCard>
        <FiMessageSquare className="icon" /> Mais informações
      </SimpleTitleCard>
      <Row>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">Idade inicial</label>
          <InputCustom
            type="number"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(Number(e.target.value), 'idadeInicial')
            }}
            value={register.idadeInicial || ''}
            disabled={visualizacao}
            invalid={invalidsTabParametros.idadeInicial}
            max={150}
            min={0}
          />
        </Col>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">Idade final</label>
          <InputCustom
            type="number"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(Number(e.target.value), 'idadeFinal')
            }}
            value={register.idadeFinal || ''}
            disabled={visualizacao}
            invalid={invalidsTabParametros.idadeFinal}
            max={150}
            min={0}
          />
        </Col>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">Forma de recebimento do benefício</label>
          <CustomSelect
            options={optionsSelect.formasRecebimento}
            placeholder="Selecione"
            onChange={(e) => {
              onChange(e, 'formaRecebimentoBeneficio')
            }}
            value={register.formaRecebimentoBeneficio}
            accessorLabel="label"
            accessorValue="value"
            invalid={invalidsTabParametros.formaRecebimentoBeneficio}
            disabled={visualizacao}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">Cartão INSS</label>
          <CustomSelect
            options={optionsSelect.opcoesBinarias}
            placeholder="Selecione"
            onChange={(e) => {
              onChange(e, 'cartaoInss')
            }}
            value={register.cartaoInss}
            accessorLabel="label"
            accessorValue="value"
            invalid={invalidsTabParametros.cartaoInss}
            disabled={visualizacao}
          />
        </Col>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">Banco do cartão</label>
          <CustomSelect
            options={optionsSelect.bancosCartao}
            isMulti
            placeholder="Selecione"
            onChange={(e) => {
              onChange(e, 'bancosCartaoCampanha')
            }}
            value={register.bancosCartaoCampanha}
            accessorLabel="descricao"
            accessorValue="id"
            invalid={invalidsTabParametros.bancosCartaoCampanha}
            disabled={visualizacao || !register.cartaoInss}
          />
        </Col>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">Início despacho</label>
          <InputCustom
            type="date"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(e.target.value, 'despachoAnoInicial')
            }}
            value={register.despachoAnoInicial || ''}
            disabled={visualizacao}
            invalid={invalidsTabParametros.despachoAnoInicial}
          />
        </Col>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">Fim despacho</label>
          <InputCustom
            type="date"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(e.target.value, 'despachoAnoFinal')
            }}
            value={register.despachoAnoFinal || ''}
            disabled={visualizacao}
            invalid={invalidsTabParametros.despachoAnoFinal}
          />
        </Col>
      </Row>
      <Especies
        options={optionsSelect.especies}
        value={register.especies ?? []}
        onChange={(e) => {
          onChange(e, 'especies')
        }}
        disabled={visualizacao}
        invalid={invalidsTabParametros.especies}
      />
    </div>
  )
}

export default MaisInformacoes
