import { combineReducers } from 'redux'
import login from './login'
import sidebar from './sidebar'
import atendimento from './atendimento'
import loading from './loading'
import tabulacao from './backoffice/tabulacao'
import uploadArquivos from './backoffice/uploadArquivos'
import grupos from './backoffice/grupos'
import blacklist from './backoffice/blacklist'
import producao from './producao'
import produto from './backoffice/produto'
import inicio from './inicio'
import historico from './historico'
import carteira from './backoffice/carteira'
import navbar from './navbar'
import agenda from './agenda'
import credenciais from './backoffice/credenciais'
import indicadores from './indicadores'
import campanha from './backoffice/campanha'
import indicacao from './indicacao'

export default combineReducers({
  login,
  sidebar,
  atendimento,
  loading,
  tabulacao,
  producao,
  produto,
  inicio,
  historico,
  carteira,
  grupos,
  blacklist,
  uploadArquivos,
  navbar,
  agenda,
  credenciais,
  indicadores,
  campanha,
  indicacao
})
