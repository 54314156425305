import { maskDate, maskMoneyReq, removeMaskMoney } from 'util/masks'
import { handlerError } from 'util/handlerError'
import { SagaIterator } from 'redux-saga'
import {
  call,
  fork,
  put,
  all,
  takeEvery,
  select,
  CallEffect
} from 'redux-saga/effects'
import { CAMPANHA, ParametrizacaoCampanhaReq, TYPES } from './types'
import * as campanhaController from 'controller/campanhaController'
import * as campanhaActions from 'store/modules/backoffice/campanha/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { ApplicationState } from 'store'
import { toast } from 'react-toastify'
import { Cidade } from 'models/types'
import {
  AbastecimentoCampanhaDto,
  ReabastecimentoCampanhaDto
} from 'models/backoffice/campanha/type'

interface GenericProps {
  type: string
  id: string
}

function* getAllCampanhas(): SagaIterator {
  const { pagination, search }: CAMPANHA = yield select(
    (state: ApplicationState) => state.campanha
  )

  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(campanhaController.getAllCampanhas, {
      pagina: pagination.page,
      registros: pagination.pageSize,
      descricao: search.descricao,
      dataInicio: search.dataInicio,
      dataFim: search.dataFim,
      lojaId: search.lojaId ?? ''
    })
    yield put(
      campanhaActions.setPaginacao({
        ...pagination,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )
    yield put(
      campanhaActions.setAllCampanhas(response?.data?.content?.registros)
    )
  } catch (error) {
    yield put(campanhaActions.cleanPagination())
    yield put(campanhaActions.cleanAllCampanhas())
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getCampanha({ id }: GenericProps): SagaIterator {
  try {
    yield call(campanhaController.getCampanha, id)
  } catch (error) {
    handlerError(error)
  }
}

function* visualizarCampanha({ id }: GenericProps): SagaIterator {
  yield put(loadingActions.setLoading(true))
  try {
    const response = yield call(campanhaController.getCampanha, id)
    const campanha: CAMPANHA['register'] = {
      bancosCartaoCampanha: response?.data?.content.bancosCartao || [],
      id: response?.data?.content.id,
      descricao: response?.data?.content.descricao,
      empresasGrupo: [response?.data?.content.lojaId] || [],
      tipoOperacaoId: response?.data?.content.tipoOperacaoId,
      dataInicioCampanha: maskDate(
        response?.data?.content.dataInicioCampanha,
        'YYYY-MM-DD'
      ),
      dataFimCampanha: maskDate(
        response?.data?.content.dataFimCampanha,
        'YYYY-MM-DD'
      ),
      quantidadeClientes: response?.data?.content.quantidadeClientes,
      ufCampanha: [response?.data?.content.uf] || [],
      cidadeCampanha: [response?.data?.content.cidade] || [],
      redudancia: response?.data?.content.redudancia,
      indisponibilidade: response?.data?.content.indisponibilidade,
      naoPertube: response?.data?.content.naoPertube,
      discadorAutomatico: response?.data?.content.discadorAutomatico,
      telefone: response?.data?.content.telefone,
      idadeInicial: response?.data?.content.idadeInicial,
      idadeFinal: response?.data?.content.idadeFinal,
      formaRecebimentoBeneficio:
        response?.data?.content.formaRecebimentoBeneficio,
      cartaoInss: response?.data?.content.cartaoInss,
      despachoAnoInicial: maskDate(
        response?.data?.content.despachoAnoInicial,
        'YYYY-MM-DD'
      ),
      despachoAnoFinal: maskDate(
        response?.data?.content.despachoAnoFinal,
        'YYYY-MM-DD'
      ),
      especies: response?.data?.content.especies || [],
      bancosPagadoresConsiderarCampanha:
        response?.data?.content.bancosPagadoresConsiderar || [],
      quantidadeEmprestimo: response?.data?.content.quantidadeEmprestimo,
      quantidadeInicialEmprestimo:
        response?.data?.content.quantidadeInicialEmprestimo,
      quantidadeFinalEmprestimo:
        response?.data?.content.quantidadeFinalEmprestimo,
      salario: response?.data?.content.salario,
      quantidadeInicialSalario: maskMoneyReq(
        response?.data?.content.quantidadeInicialSalario
      ),
      quantidadeFinalSalario: maskMoneyReq(
        response?.data?.content.quantidadeFinalSalario
      ),
      margemLivre: response?.data?.content.margemLivre,
      quantidadeInicialMargem: maskMoneyReq(
        response?.data?.content.quantidadeInicialMargem
      ),
      quantidadeFinalMargem: maskMoneyReq(
        response?.data?.content.quantidadeFinalMargem
      ),
      margemCartao: response?.data?.content.margemCartao,
      quantidadeInicialMargemCartao: maskMoneyReq(
        response?.data?.content.quantidadeInicialMargemCartao
      ),
      quantidadeFinalMargemCartao: maskMoneyReq(
        response?.data?.content.quantidadeFinalMargemCartao
      ),
      instituicaoFinanceiraCampanha:
        response?.data?.content.instituicaoFinanceira || [],
      parcelas: response?.data?.content.parcelas,
      quantidadeInicialParcelas:
        response?.data?.content.quantidadeInicialParcelas,
      quantidadeFinalParcelas: response?.data?.content.quantidadeFinalParcelas,
      valorParcela: response?.data?.content.valorParcela,
      quantidadeInicialValorParcela: maskMoneyReq(
        response?.data?.content.quantidadeInicialValorParcela
      ),
      quantidadeFinalValorParcela: maskMoneyReq(
        response?.data?.content.quantidadeFinalValorParcela
      )
    }
    yield put(campanhaActions.setRegister(campanha))
    yield put(campanhaActions.setActiveTab('register'))
    yield put(campanhaActions.setVisualizacao(true))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* editarCampanha({ id }: GenericProps): SagaIterator {
  yield put(loadingActions.setLoading(true))
  try {
    const response = yield call(campanhaController.getCampanha, id)
    const campanha: CAMPANHA['register'] = {
      bancosCartaoCampanha: response?.data?.content.bancosCartao || [],
      id: response?.data?.content.id,
      descricao: response?.data?.content.descricao,
      empresasGrupo: [response?.data?.content.lojaId] || [],
      tipoOperacaoId: response?.data?.content.tipoOperacaoId,
      dataInicioCampanha: maskDate(
        response?.data?.content.dataInicioCampanha,
        'YYYY-MM-DD'
      ),
      dataFimCampanha: maskDate(
        response?.data?.content.dataFimCampanha,
        'YYYY-MM-DD'
      ),
      quantidadeClientes: response?.data?.content.quantidadeClientes,
      ufCampanha: [response?.data?.content.uf] || [],
      cidadeCampanha: [response?.data?.content.cidade] || [],
      redudancia: response?.data?.content.redudancia,
      indisponibilidade: response?.data?.content.indisponibilidade,
      naoPertube: response?.data?.content.naoPertube,
      discadorAutomatico: response?.data?.content.discadorAutomatico,
      telefone: response?.data?.content.telefone,
      idadeInicial: response?.data?.content.idadeInicial,
      idadeFinal: response?.data?.content.idadeFinal,
      formaRecebimentoBeneficio:
        response?.data?.content.formaRecebimentoBeneficio,
      cartaoInss: response?.data?.content.cartaoInss,
      despachoAnoInicial: maskDate(
        response?.data?.content.despachoAnoInicial,
        'YYYY-MM-DD'
      ),
      despachoAnoFinal: maskDate(
        response?.data?.content.despachoAnoFinal,
        'YYYY-MM-DD'
      ),
      especies: response?.data?.content.especies || [],
      bancosPagadoresConsiderarCampanha:
        response?.data?.content.bancosPagadoresConsiderar || [],
      quantidadeEmprestimo: response?.data?.content.quantidadeEmprestimo,
      quantidadeInicialEmprestimo:
        response?.data?.content.quantidadeInicialEmprestimo,
      quantidadeFinalEmprestimo:
        response?.data?.content.quantidadeFinalEmprestimo,
      salario: response?.data?.content.salario,
      quantidadeInicialSalario: maskMoneyReq(
        response?.data?.content.quantidadeInicialSalario
      ),
      quantidadeFinalSalario: maskMoneyReq(
        response?.data?.content.quantidadeFinalSalario
      ),
      margemLivre: response?.data?.content.margemLivre,
      quantidadeInicialMargem: maskMoneyReq(
        response?.data?.content.quantidadeInicialMargem
      ),
      quantidadeFinalMargem: maskMoneyReq(
        response?.data?.content.quantidadeFinalMargem
      ),
      margemCartao: response?.data?.content.margemCartao,
      quantidadeInicialMargemCartao: maskMoneyReq(
        response?.data?.content.quantidadeInicialMargemCartao
      ),
      quantidadeFinalMargemCartao: maskMoneyReq(
        response?.data?.content.quantidadeFinalMargemCartao
      ),
      instituicaoFinanceiraCampanha:
        response?.data?.content.instituicaoFinanceira || [],
      parcelas: response?.data?.content.parcelas,
      quantidadeInicialParcelas:
        response?.data?.content.quantidadeInicialParcelas,
      quantidadeFinalParcelas: response?.data?.content.quantidadeFinalParcelas,
      valorParcela: response?.data?.content.valorParcela,
      quantidadeInicialValorParcela: maskMoneyReq(
        response?.data?.content.quantidadeInicialValorParcela
      ),
      quantidadeFinalValorParcela: maskMoneyReq(
        response?.data?.content.quantidadeFinalValorParcela
      )
    }
    yield put(campanhaActions.setRegister(campanha))
    yield put(campanhaActions.setActiveTab('register'))
    yield put(campanhaActions.setVisualizacao(false))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* criarCampanha(): SagaIterator {
  const { register, optionsSelect }: CAMPANHA = yield select(
    (state: ApplicationState) => state.campanha
  )
  try {
    yield put(loadingActions.setLoading(true))
    const data: ParametrizacaoCampanhaReq = {
      id: register.id,
      descricao: register.descricao,
      lojas: register.empresasGrupo.filter((lojaGrupo) => {
        return optionsSelect.empresasGrupos.some(
          (x) =>
            x.idGrupoELoja === lojaGrupo && x.descricao?.startsWith('Loja -')
        )
      }),
      grupos: register.empresasGrupo.filter((lojaGrupo) => {
        return optionsSelect.empresasGrupos.some(
          (x) =>
            x.idGrupoELoja === lojaGrupo && x.descricao?.startsWith('Grupo -')
        )
      }),
      tipoOperacaoId: register.tipoOperacaoId,
      dataInicioCampanha: register.dataInicioCampanha,
      dataFimCampanha: register.dataFimCampanha,
      quantidadeClientes: register.quantidadeClientes,
      ufCampanha: register.ufCampanha,
      cidadeCampanha: register.cidadeCampanha,
      redudancia: register.redudancia,
      indisponibilidade: register.indisponibilidade,
      naoPertube: register.naoPertube,
      discadorAutomatico: register.discadorAutomatico,
      telefone: register.telefone,
      idadeInicial: register.idadeInicial,
      idadeFinal: register.idadeFinal,
      formaRecebimentoBeneficio: register.formaRecebimentoBeneficio,
      cartaoInss: register.cartaoInss,
      bancosCartaoCampanha: register.bancosCartaoCampanha,
      despachoAnoInicial: new Date(
        Date.parse(register.despachoAnoInicial ?? '')
      ),
      especies: register.especies,
      despachoAnoFinal: new Date(Date.parse(register.despachoAnoFinal ?? '')),
      bancosPagadoresConsiderarCampanha:
        register.bancosPagadoresConsiderarCampanha,
      quantidadeEmprestimo: register.quantidadeEmprestimo,
      quantidadeInicialEmprestimo: register.quantidadeInicialEmprestimo,
      quantidadeFinalEmprestimo: register.quantidadeFinalEmprestimo,
      salario: register.salario,
      quantidadeInicialSalario: register.quantidadeInicialSalario
        ? Number(removeMaskMoney(register.quantidadeInicialSalario))
        : null,
      quantidadeFinalSalario: register.quantidadeFinalSalario
        ? Number(removeMaskMoney(register.quantidadeFinalSalario))
        : null,
      margemLivre: register.margemLivre,
      quantidadeInicialMargem: register.quantidadeInicialMargem
        ? Number(removeMaskMoney(register.quantidadeInicialMargem))
        : null,
      quantidadeFinalMargem: register.quantidadeFinalMargem
        ? Number(removeMaskMoney(register.quantidadeFinalMargem))
        : null,
      margemCartao: register.margemCartao,
      quantidadeInicialMargemCartao: register.quantidadeInicialMargemCartao
        ? Number(removeMaskMoney(register.quantidadeInicialMargemCartao))
        : null,
      quantidadeFinalMargemCartao: register.quantidadeFinalMargemCartao
        ? Number(removeMaskMoney(register.quantidadeFinalMargemCartao))
        : null,
      instituicaoFinanceiraCampanha: register.instituicaoFinanceiraCampanha,
      parcelas: register.parcelas,
      quantidadeInicialParcelas: register.quantidadeInicialParcelas,
      quantidadeFinalParcelas: register.quantidadeFinalParcelas,
      valorParcela: register.valorParcela,
      quantidadeInicialValorParcela: register.quantidadeInicialValorParcela
        ? Number(removeMaskMoney(register.quantidadeInicialValorParcela))
        : null,
      quantidadeFinalValorParcela: register.quantidadeFinalValorParcela
        ? Number(removeMaskMoney(register.quantidadeFinalValorParcela))
        : null
    }
    yield call(campanhaController.criarCampanha, data)
    yield put(
      campanhaActions.abastecimentoClientesSaga({
        dataFimCampanha: data.dataFimCampanha,
        dataInicioCampanha: data.dataInicioCampanha,
        descricaoCampanha: data.descricao,
        quantidadeClientes: data.quantidadeClientes
      })
    )
    yield put(campanhaActions.cleanRegister())
    yield put(
      campanhaActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )
    yield put(campanhaActions.cleanPesquisa())
    yield put(campanhaActions.getAllCampanhasSaga())
    yield put(campanhaActions.setActiveTabRegister(1))
    yield put(campanhaActions.setActiveTab('search'))
    toast.success('Campanha salva com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* atualizarCampanha(): SagaIterator {
  const { register, optionsSelect }: CAMPANHA = yield select(
    (state: ApplicationState) => state.campanha
  )
  try {
    yield put(loadingActions.setLoading(true))
    const data: ParametrizacaoCampanhaReq = {
      id: register.id,
      descricao: register.descricao,
      lojas: register.empresasGrupo.filter((lojaGrupo) => {
        return optionsSelect.empresasGrupos.some(
          (x) =>
            x.idGrupoELoja === lojaGrupo && x.descricao?.startsWith('Loja -')
        )
      }),
      grupos: register.empresasGrupo.filter((lojaGrupo) => {
        return optionsSelect.empresasGrupos.some(
          (x) =>
            x.idGrupoELoja === lojaGrupo && x.descricao?.startsWith('Grupo -')
        )
      }),
      tipoOperacaoId: register.tipoOperacaoId,
      dataInicioCampanha: register.dataInicioCampanha,
      dataFimCampanha: register.dataFimCampanha,
      quantidadeClientes: register.quantidadeClientes,
      ufCampanha: register.ufCampanha,
      cidadeCampanha: register.cidadeCampanha,
      redudancia: register.redudancia,
      indisponibilidade: register.indisponibilidade,
      naoPertube: register.naoPertube,
      discadorAutomatico: register.discadorAutomatico,
      telefone: register.telefone,
      idadeInicial: register.idadeInicial,
      idadeFinal: register.idadeFinal,
      formaRecebimentoBeneficio: register.formaRecebimentoBeneficio,
      cartaoInss: register.cartaoInss,
      bancosCartaoCampanha: register.bancosCartaoCampanha,
      despachoAnoInicial: new Date(
        Date.parse(register.despachoAnoInicial ?? '')
      ),
      despachoAnoFinal: new Date(Date.parse(register.despachoAnoFinal ?? '')),
      especies: register.especies,
      bancosPagadoresConsiderarCampanha:
        register.bancosPagadoresConsiderarCampanha,
      quantidadeEmprestimo: register.quantidadeEmprestimo,
      quantidadeInicialEmprestimo: register.quantidadeInicialEmprestimo,
      quantidadeFinalEmprestimo: register.quantidadeFinalEmprestimo,
      salario: register.salario,
      quantidadeInicialSalario: register.quantidadeInicialSalario
        ? Number(removeMaskMoney(register.quantidadeInicialSalario))
        : null,
      quantidadeFinalSalario: register.quantidadeFinalSalario
        ? Number(removeMaskMoney(register.quantidadeFinalSalario))
        : null,
      margemLivre: register.margemLivre,
      quantidadeInicialMargem: register.quantidadeInicialMargem
        ? Number(removeMaskMoney(register.quantidadeInicialMargem))
        : null,
      quantidadeFinalMargem: Number(
        removeMaskMoney(register.quantidadeFinalMargem)
      ),
      margemCartao: register.margemCartao,
      quantidadeInicialMargemCartao: register.quantidadeInicialMargemCartao
        ? Number(removeMaskMoney(register.quantidadeInicialMargemCartao))
        : null,
      quantidadeFinalMargemCartao: register.quantidadeFinalMargemCartao
        ? Number(removeMaskMoney(register.quantidadeFinalMargemCartao))
        : null,
      instituicaoFinanceiraCampanha: register.instituicaoFinanceiraCampanha,
      parcelas: register.parcelas,
      quantidadeInicialParcelas: register.quantidadeInicialParcelas,
      quantidadeFinalParcelas: register.quantidadeFinalParcelas,
      valorParcela: register.valorParcela,
      quantidadeInicialValorParcela: register.quantidadeInicialValorParcela
        ? Number(removeMaskMoney(register.quantidadeInicialValorParcela))
        : null,
      quantidadeFinalValorParcela: register.quantidadeFinalValorParcela
        ? Number(removeMaskMoney(register.quantidadeFinalValorParcela))
        : null
    }
    yield call(campanhaController.atualizarCampanha, data)
    yield put(
      campanhaActions.reabastecimentoClientesSaga({
        campanhaCrmId: data.id ?? '',
        quantidadeClientes: data.quantidadeClientes
      })
    )
    yield put(campanhaActions.cleanRegister())
    yield put(
      campanhaActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )
    yield put(campanhaActions.cleanPesquisa())
    yield put(campanhaActions.getAllCampanhasSaga())
    yield put(campanhaActions.setActiveTabRegister(1))
    yield put(campanhaActions.setActiveTab('search'))
    toast.success('Campanha atualizada com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* deletarCampanha(): SagaIterator {
  const { itemSelectedId, campanhasSelectedIds }: CAMPANHA = yield select(
    (state: ApplicationState) => state.campanha
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(campanhaController.deletarCampanha, itemSelectedId)

    const newCampanhasSelectedIds = campanhasSelectedIds.filter(
      (id) => id !== itemSelectedId
    )
    yield put(campanhaActions.setCampanhasSelectedIds(newCampanhasSelectedIds))

    yield put(campanhaActions.getAllCampanhasSaga())
    toast.success('Campanha excluída com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* deletarCampanhasSelecionadas(): SagaIterator {
  const { campanhasSelectedIds }: CAMPANHA = yield select(
    (state: ApplicationState) => state.campanha
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(campanhaController.deletarCampanhas, campanhasSelectedIds)
    yield put(campanhaActions.cleanCampanhasSelectedIds())
    yield put(campanhaActions.getAllCampanhasSaga())
    toast.success('Campanhas excluídas com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getCidades(): SagaIterator {
  const { register }: CAMPANHA = yield select(
    (state: ApplicationState) => state.campanha
  )
  try {
    let response: Cidade[] = []
    if (register.ufCampanha && register.ufCampanha.length > 0) {
      const getCidades = function* (): Generator<CallEffect> {
        for (const uf of register.ufCampanha) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const simulacao: any = yield call(campanhaController.getCidades, uf)
          response = response.concat(simulacao?.data?.content)
        }
      }
      yield call(getCidades)
      yield put(campanhaActions.setOptionsSelect(response, 'cidades'))
    } else {
      yield put(campanhaActions.setOptionsSelect([], 'cidades'))
    }
  } catch (error) {
    handlerError(error)
  }
}

function* getEstados(): SagaIterator {
  try {
    const response = yield call(campanhaController.getEstados)
    yield put(campanhaActions.setOptionsSelect(response?.data?.content, 'ufs'))
  } catch (error) {
    handlerError(error)
  }
}

function* getGeneros(): SagaIterator {
  try {
    const response = yield call(campanhaController.getGeneros)
    yield put(
      campanhaActions.setOptionsSelect(response?.data?.content, 'generos')
    )
  } catch (error) {
    handlerError(error)
  }
}

function* getTiposOperacao(): SagaIterator {
  try {
    const response = yield call(campanhaController.getTiposOperacao)
    yield put(
      campanhaActions.setOptionsSelect(response?.data?.content, 'tiposOperacao')
    )
  } catch (error) {
    handlerError(error)
  }
}

function* getBancos(): SagaIterator {
  try {
    const response = yield call(campanhaController.getBancos)
    yield put(
      campanhaActions.setOptionsSelect(response?.data?.content, 'bancos')
    )
  } catch (error) {
    handlerError(error)
  }
}

function* getGruposLojas(): SagaIterator {
  try {
    const response = yield call(campanhaController.getGruposLojas)
    yield put(
      campanhaActions.setOptionsSelect(
        response?.data?.content,
        'empresasGrupos'
      )
    )
  } catch (error) {
    handlerError(error)
  }
}

function* getBancosCartao(): SagaIterator {
  try {
    const response = yield call(campanhaController.getBancosCartao)
    yield put(
      campanhaActions.setOptionsSelect(response?.data?.content, 'bancosCartao')
    )
  } catch (error) {
    handlerError(error)
  }
}

interface AlterarStatusCampanhaProps extends GenericProps {
  campanhaCrmId: string
  ativo: boolean
}

function* alterarStatusCampanha({
  campanhaCrmId,
  ativo
}: AlterarStatusCampanhaProps): SagaIterator {
  try {
    yield call(campanhaController.alterarStatusCampanha, campanhaCrmId, ativo)
    yield put(campanhaActions.setStatusCampanhaGrid(campanhaCrmId, ativo))
    toast.success('Ação realizada com sucesso!')
  } catch (error) {
    handlerError(error)
  }
}

interface AbastecimentoClientesProps extends GenericProps {
  value: AbastecimentoCampanhaDto
}
function* abastecimentoClientes({
  value
}: AbastecimentoClientesProps): SagaIterator {
  try {
    yield call(campanhaController.abastecimentoClientes, value)
    toast.success(
      'Abastecimento de clientes da campanha realizado com sucesso!'
    )
    yield put(campanhaActions.getAllCampanhasSaga())
  } catch (error) {
    handlerError(
      error,
      'Não foi possível realizar o abastecimento da campanha. Por favor, volte mais tarde para realizar um novo abastecimento.'
    )
  }
}

interface ReabastecimentoClientesProps extends GenericProps {
  value: ReabastecimentoCampanhaDto
}
function* reabastecimentoClientes({
  value
}: ReabastecimentoClientesProps): SagaIterator {
  try {
    yield call(campanhaController.reabastecimentoClientes, value)
    toast.success(
      'Reabastecimento de clientes da campanha realizado com sucesso!'
    )
    yield put(campanhaActions.getAllCampanhasSaga())
  } catch (error) {
    handlerError(
      error,
      'Não foi possível realizar o reabastecimento da campanha. Por favor, volte mais tarde para realizar um novo reabastecimento.'
    )
  }
}

function* getEspeciesBeneficio(): SagaIterator {
  try {
    const response = yield call(campanhaController.getEspecies)
    yield put(
      campanhaActions.setOptionsSelect(response?.data?.content, 'especies')
    )
  } catch (error) {
    handlerError(error)
  }
}

function* getAllLojas(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))

    const response = yield call(campanhaController.getAllLojas)

    yield put(campanhaActions.setLojas(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetAllCampanhas() {
  yield takeEvery(TYPES.GET_ALL_CAMPANHAS_SAGA, getAllCampanhas)
}

export function* watchGetCampanha() {
  yield takeEvery(TYPES.GET_CAMPANHA_SAGA, getCampanha)
}

export function* watchVisualizarCampanha() {
  yield takeEvery(TYPES.VISUALIZAR_CAMPANHA_SAGA_DISPATCH, visualizarCampanha)
}

export function* watchEditarCampanha() {
  yield takeEvery(TYPES.EDITAR_CAMPANHA_SAGA_DISPATCH, editarCampanha)
}

export function* watchCriarCampanha() {
  yield takeEvery(TYPES.POST_CAMPANHA_SAGA, criarCampanha)
}

export function* watchAtualizarCampanha() {
  yield takeEvery(TYPES.PUT_CAMPANHA_SAGA, atualizarCampanha)
}

export function* watchDeletarCampanha() {
  yield takeEvery(TYPES.DELETE_CAMPANHA_SAGA, deletarCampanha)
}

export function* watchGetCidades() {
  yield takeEvery(TYPES.GET_CIDADES_SAGA, getCidades)
}

export function* watchGetEstados() {
  yield takeEvery(TYPES.GET_ESTADOS_SAGA, getEstados)
}

export function* watchGetGeneros() {
  yield takeEvery(TYPES.GET_GENEROS_SAGA, getGeneros)
}

export function* watchGetTiposOperacao() {
  yield takeEvery(TYPES.GET_TIPOS_OPERACO_SAGA, getTiposOperacao)
}

export function* watchGetBancos() {
  yield takeEvery(TYPES.GET_BANCOS_SAGA, getBancos)
}

export function* watchGetGruposLojas() {
  yield takeEvery(TYPES.GET_GRUPOS_LOJAS_SAGA, getGruposLojas)
}

export function* watchGetBancosCartao() {
  yield takeEvery(TYPES.GET_BANCOS_CARTAO_SAGA, getBancosCartao)
}

export function* watchAlterarStatusCampanha() {
  yield takeEvery(
    TYPES.ALTERAR_STATUS_CAMPANHA_DISPATCH_SAGA,
    alterarStatusCampanha
  )
}

export function* watchAbastecimentoClientes() {
  yield takeEvery(TYPES.ABASTECIMENTO_CLIENTES_SAGA, abastecimentoClientes)
}

export function* watchReabastecimentoClientes() {
  yield takeEvery(TYPES.REABASTECIMENTO_CLIENTES_SAGA, reabastecimentoClientes)
}

export function* watchGetEspeciesBeneficio() {
  yield takeEvery(TYPES.GET_ESPECIES_BENEFICIO_SAGA, getEspeciesBeneficio)
}

export function* watchGetAllLojas() {
  yield takeEvery(TYPES.GET_ALL_LOJAS_SAGA, getAllLojas)
}

export function* watchDeletarCampanhasSelecionadas() {
  yield takeEvery(
    TYPES.DELETE_CAMPANHAS_SELECIONADAS_SAGA,
    deletarCampanhasSelecionadas
  )
}

function* campanhaSaga() {
  yield all([
    fork(watchGetAllCampanhas),
    fork(watchGetCampanha),
    fork(watchVisualizarCampanha),
    fork(watchEditarCampanha),
    fork(watchCriarCampanha),
    fork(watchAtualizarCampanha),
    fork(watchDeletarCampanha),
    fork(watchGetCidades),
    fork(watchGetEstados),
    fork(watchGetGeneros),
    fork(watchGetTiposOperacao),
    fork(watchGetBancos),
    fork(watchGetGruposLojas),
    fork(watchGetBancosCartao),
    fork(watchAlterarStatusCampanha),
    fork(watchAbastecimentoClientes),
    fork(watchReabastecimentoClientes),
    fork(watchGetEspeciesBeneficio),
    fork(watchGetAllLojas),
    fork(watchDeletarCampanhasSelecionadas)
  ])
}

export default campanhaSaga
