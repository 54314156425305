import React from 'react'
import { BlurContentPermissoes } from './style'
import loadingIcon from 'assets/svg/loading-icon.svg'

interface LoadingPermissoesProps {
  isLoading: boolean
}

const LoadingPermissoes = ({ isLoading }: LoadingPermissoesProps) => {
  return (
    <>
      {isLoading && (
        <BlurContentPermissoes>
          <img src={loadingIcon} alt="Loading sistema" width="100" />
          <span>Aguarde, enquanto configuramos suas permissões...</span>
        </BlurContentPermissoes>
      )}
    </>
  )
}

export default LoadingPermissoes
