import React, { useEffect } from 'react'
import SimpleCard from 'components/SimpleCard'
import {
  RiBookOpenLine,
  RiChatCheckLine,
  RiChatDeleteLine,
  RiFeedbackLine,
  RiQuestionnaireLine
} from 'react-icons/ri'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { CardBigNumbers, Colors } from 'views/inicio/style'
import AgendaDiaria from '../components/AgendaDiaria'
import CustomTable from 'components/CustomTable'
import {
  COLUMNS_VISAO_COORDENADOR,
  COLUMNS_VISAO_SUPERVISOR
} from '../constants/columns'
import { CardDiaSemana } from '../style'
import CarouselWeek from '../components/CarouselWeek'
import useRedux from 'hooks/useRedux'
import * as agendaActions from 'store/modules/agenda/actions'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { AGENDA } from 'store/modules/agenda/types'
import { maskDate } from 'util/masks'
import classNames from 'classnames'

const AgendamentoDiario = () => {
  const {
    diasSemana,
    diaDetalhamento,
    filtroBigNumbers,
    bigNumbers,
    visaoSupervisor,
    visaoCoordenador,
    paginacaoVisaoSupervisor,
    paginacaoVisaoCoordenador
  } = useSelector<ApplicationState, AGENDA>((state) => state.agenda)

  const { dispatch } = useRedux()

  function voltarCalendario() {
    dispatch(agendaActions.setAtiveTab('calendario'))
  }

  useEffect(() => {
    if (diaDetalhamento) {
      dispatch(agendaActions.getDiasSemanaSaga(diaDetalhamento))
      dispatch(agendaActions.getBigNumbersSaga(diaDetalhamento))
      dispatch(agendaActions.getVisaoSupervisorSaga(diaDetalhamento))
      dispatch(agendaActions.getVisaoCoordenadorSaga(diaDetalhamento))
      dispatch(agendaActions.getAgendaAtendimentosDataSaga())
      dispatch(agendaActions.getTiposAgendamentosSaga())
    }
  }, [dispatch, diaDetalhamento, filtroBigNumbers])

  function setPageVisaoSupervisor(value: number) {
    dispatch(
      agendaActions.setPaginacaoVisaoSupervisor({
        ...paginacaoVisaoSupervisor,
        page: value
      })
    )
    dispatch(agendaActions.getVisaoSupervisorSaga(diaDetalhamento))
  }

  function setPageSizeVisaoSupervisor(value: number) {
    dispatch(
      agendaActions.setPaginacaoVisaoSupervisor({
        ...paginacaoVisaoSupervisor,
        page: 0,
        pageSize: value
      })
    )
    dispatch(agendaActions.getVisaoSupervisorSaga(diaDetalhamento))
  }

  function setPageVisaoCoordenador(value: number) {
    dispatch(
      agendaActions.setPaginacaoVisaoCoordenador({
        ...paginacaoVisaoCoordenador,
        page: value
      })
    )
    dispatch(agendaActions.getVisaoCoordenadorSaga(diaDetalhamento))
  }

  function setPageSizeVisaoCoordenador(value: number) {
    dispatch(
      agendaActions.setPaginacaoVisaoCoordenador({
        ...paginacaoVisaoCoordenador,
        page: 0,
        pageSize: value
      })
    )
    dispatch(agendaActions.getVisaoCoordenadorSaga(diaDetalhamento))
  }

  return (
    <SimpleCard className="d-flex flex-column row-gap-10">
      <div className="container-fluid">
        <Row>
          <Col md={6}>
            <SimpleTitleCard>
              <RiBookOpenLine className="icon" />
              Agendamento
            </SimpleTitleCard>
          </Col>
          <Col className="d-flex justify-content-end">
            <CustomButton
              background="#662d90"
              onClick={() => {
                voltarCalendario()
              }}
              letterSpacing={1}
            >
              VOLTAR
            </CustomButton>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          {diasSemana.diasDaSemanas.length > 0 && (
            <CarouselWeek
              onChangeWeek={(e) => {
                dispatch(
                  agendaActions.setDiaDetalhamento(maskDate(e, 'YYYY-MM-DD'))
                )
              }}
              prev={diasSemana.anterior}
              next={diasSemana.proximo}
            >
              {diasSemana.diasDaSemanas?.map((dia, i) => {
                return (
                  <CardDiaSemana
                    key={i}
                    role="button"
                    className={classNames(
                      maskDate(dia.dia, 'YYYY-MM-DD') === diaDetalhamento &&
                        'today'
                    )}
                    onClick={() => {
                      dispatch(
                        agendaActions.setDiaDetalhamento(
                          maskDate(dia.dia, 'YYYY-MM-DD')
                        )
                      )
                    }}
                  >
                    <span>{maskDate(dia.dia, 'DD/MM')}</span>
                    <span>{dia.diaSemana}</span>
                  </CardDiaSemana>
                )
              })}
            </CarouselWeek>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="label-12">Filtre por status:</label>
          <div className="row d-flex justify-content-evenly">
            <CardBigNumbers
              onClick={() => {
                dispatch(agendaActions.setFiltroBigNumbers('agendados'))
              }}
              background={Colors.blue}
              active={filtroBigNumbers === 'agendados'}
            >
              <RiFeedbackLine className="icon" />
              <div>
                <span>{bigNumbers.agendamentos}</span>
                <label>Agendados</label>
              </div>
            </CardBigNumbers>
            <CardBigNumbers
              onClick={() => {
                dispatch(agendaActions.setFiltroBigNumbers('confirmados'))
              }}
              background={Colors.green}
              active={filtroBigNumbers === 'confirmados'}
            >
              <RiChatCheckLine className="icon" />
              <div>
                <span>{bigNumbers.confirmados}</span>
                <label>Confirmados</label>
              </div>
            </CardBigNumbers>
            <CardBigNumbers
              onClick={() => {
                dispatch(agendaActions.setFiltroBigNumbers('nao-confirmado'))
              }}
              background={Colors.gray}
              active={filtroBigNumbers === 'nao-confirmado'}
            >
              <RiQuestionnaireLine className="icon" />
              <div>
                <span>{bigNumbers.naoConfirmados}</span>
                <label>Não confirmados</label>
              </div>
            </CardBigNumbers>
            <CardBigNumbers
              onClick={() => {
                dispatch(agendaActions.setFiltroBigNumbers('expirado'))
              }}
              background={Colors.orange}
              active={filtroBigNumbers === 'expirado'}
            >
              <RiChatDeleteLine className="icon" />
              <div>
                <span>{bigNumbers.expirados}</span>
                <label>Expirados</label>
              </div>
            </CardBigNumbers>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <AgendaDiaria />
        </Col>
      </Row>
      {visaoCoordenador !== null && (
        <Row>
          <Col>
            <h5>Visão coordenador</h5>
            <CustomTable
              data={visaoCoordenador}
              columns={COLUMNS_VISAO_COORDENADOR}
              paginationMode="server"
              rowCount={paginacaoVisaoCoordenador.totalRegistros}
              page={paginacaoVisaoCoordenador.page}
              pageSize={paginacaoVisaoCoordenador.pageSize}
              setPage={(e) => {
                setPageVisaoCoordenador(e)
              }}
              setPageSize={(e) => {
                setPageSizeVisaoCoordenador(e)
              }}
            />
          </Col>
        </Row>
      )}
      {visaoSupervisor !== null && (
        <Row>
          <Col>
            <h5>Visão supervisor</h5>
            <CustomTable
              data={visaoSupervisor}
              columns={COLUMNS_VISAO_SUPERVISOR}
              paginationMode="server"
              rowCount={paginacaoVisaoSupervisor.totalRegistros}
              page={paginacaoVisaoSupervisor.page}
              pageSize={paginacaoVisaoSupervisor.pageSize}
              setPage={(e) => {
                setPageVisaoSupervisor(e)
              }}
              setPageSize={(e) => {
                setPageSizeVisaoSupervisor(e)
              }}
            />
          </Col>
        </Row>
      )}
    </SimpleCard>
  )
}

export default AgendamentoDiario
