import { handlerError } from 'util/handlerError'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { ApplicationState } from 'store'
import { INDICACAO, TYPES } from './types'
import * as indicacaoController from 'controller/indicacaoController'
import * as indicacaoActions from 'store/modules/indicacao/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { removeMaskCPF } from 'util/masks'
import { toast } from 'react-toastify'

interface GenericProps {
  type: string
  id: string
}

function* getAllPropostasIndicacao(): SagaIterator {
  const { filtros, pagination }: INDICACAO = yield select(
    (state: ApplicationState) => state.indicacao
  )
  try {
    yield put(indicacaoActions.cleanTabelaIndicacao())
    yield put(loadingActions.setLoading(true))
    const response = yield call(
      indicacaoController.getAllPropostasIndicacao,
      {
        numeroAdesao: filtros.numeroAdesao,
        cpf: removeMaskCPF(filtros.cpf),
        dataInicial: filtros.dataInicial,
        dataFinal: filtros.dataFinal,
        centroCustoId: filtros.centroCustoId
      },
      pagination.page,
      pagination.pageSize
    )
    yield put(
      indicacaoActions.setTabelaIndicacao(response?.data?.content?.registros)
    )
    yield put(
      indicacaoActions.setPaginacao({
        ...pagination,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getPropostaIndicacao({ id }: GenericProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(indicacaoController.getPropostaIndicacao, id)
    yield put(
      indicacaoActions.setInfosModalEdicaoIndicacao(response?.data?.content)
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface CriarPropostaIndicacaoProps extends GenericProps {
  cpf: string
}

interface EquipeIndicacaoProps extends GenericProps {
  digitadorId: string | undefined
}

interface EquipesProps extends GenericProps {
  digitadorId?: string
  usuarioBancoId?: string
}
interface CriarPropostaIndicacaoManualProps extends GenericProps {
  cpf: string
}

function* criarPropostaIndicacao({
  cpf
}: CriarPropostaIndicacaoProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    yield call(indicacaoController.criarPropostaIndicacao, cpf)
    toast.success('Indicação salva com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* criarPropostaIndicacaoManual({
  cpf
}: CriarPropostaIndicacaoManualProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    yield call(indicacaoController.criarPropostaIndicacaoManual, cpf)
    toast.success('Indicação salva com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface DuplicarPropostaIndicacaoProps extends GenericProps {
  idProposta: string
}

function* duplicarPropostaIndicacao({
  idProposta
}: DuplicarPropostaIndicacaoProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    yield call(indicacaoController.duplicarPropostaIndicacao, idProposta)
    yield put(indicacaoActions.getAllPropostasIndicacaoSaga())
    toast.success('Indicação duplicada com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* editarPropostaIndicacao(): SagaIterator {
  const { modalEdicaoIndicacao }: INDICACAO = yield select(
    (state: ApplicationState) => state.indicacao
  )
  try {
    yield put(indicacaoActions.cleanTabelaIndicacao())
    yield put(loadingActions.setLoading(true))
    yield call(indicacaoController.editarPropostaIndicacao, {
      bancoId: modalEdicaoIndicacao.bancoId,
      cpf: modalEdicaoIndicacao.cpf,
      dataProposta: modalEdicaoIndicacao.dataProposta,
      numeroAdesao: modalEdicaoIndicacao.numeroAdesao,
      propostaIndicacaoId: modalEdicaoIndicacao.id,
      statusPropostaId: modalEdicaoIndicacao.statusPropostaId,
      tipoOperacaoId: modalEdicaoIndicacao.tipoOperacaoId,
      usuarioBancoId: modalEdicaoIndicacao.usuarioBancoId,
      lojaId: modalEdicaoIndicacao.lojaId,
      centroCustoId: modalEdicaoIndicacao.centroCustoId
    })
    yield put(indicacaoActions.getAllPropostasIndicacaoSaga())
    yield put(indicacaoActions.toggleModalEdicaoIndicacao(false))
    toast.success('Indicação atualizada com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* deletarPropostaIndicacao(): SagaIterator {
  const { itemSelectedId }: INDICACAO = yield select(
    (state: ApplicationState) => state.indicacao
  )
  try {
    yield put(indicacaoActions.cleanTabelaIndicacao())
    yield put(loadingActions.setLoading(true))
    yield call(indicacaoController.deletarPropostaIndicacao, itemSelectedId)
    yield put(indicacaoActions.getAllPropostasIndicacaoSaga())
    toast.success('Indicação excluída com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getEquipes({
  digitadorId,
  usuarioBancoId
}: EquipesProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(
      indicacaoController.getEquipes,
      digitadorId,
      usuarioBancoId
    )
    yield put(
      indicacaoActions.setOptionsSelects(response?.data?.content, 'equipes')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getBancos({ digitadorId }: EquipeIndicacaoProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(indicacaoController.getBancos, digitadorId)
    yield put(
      indicacaoActions.setOptionsSelects(response?.data?.content, 'bancos')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getCentrosCustos(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(indicacaoController.getCentrosCustos)
    yield put(
      indicacaoActions.setOptionsSelects(response?.data?.content, 'centros')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getCentrosCustosModal(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(indicacaoController.getCentrosCustos)
    yield put(
      indicacaoActions.setOptionsSelects(
        response?.data?.content,
        'centrosModal'
      )
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getStatus(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(indicacaoController.getStatus)
    yield put(
      indicacaoActions.setOptionsSelects(response?.data?.content, 'status')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getTipoOperacao(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(indicacaoController.getTipoOperacao)
    yield put(
      indicacaoActions.setOptionsSelects(
        response?.data?.content,
        'tiposOperacao'
      )
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface ByBancoAndLojaProps extends GenericProps {
  bancoId: string
  lojaId: string
  digitadorId: string
}

function* getUsuariosBancoByBancoLojaAndDigitador({
  bancoId,
  digitadorId
}: ByBancoAndLojaProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(
      indicacaoController.getUsuariosBancoByBancoLojaAndDigitador,
      bancoId,
      digitadorId
    )
    yield put(
      indicacaoActions.setOptionsSelects(
        response?.data?.content,
        'usuariosBanco'
      )
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetAllPropostasIndicacao() {
  yield takeEvery(
    TYPES.GET_ALL_PROPOSTAS_INDICACAO_SAGA,
    getAllPropostasIndicacao
  )
}

export function* watchGetPropostaIndicacao() {
  yield takeEvery(
    TYPES.GET_PROPOSTA_INDICACAO_DISPATCH_SAGA,
    getPropostaIndicacao
  )
}

export function* watchCriarPropostaIndicacao() {
  yield takeEvery(TYPES.CRIAR_PROPOSTA_INDICACAO_SAGA, criarPropostaIndicacao)
}

export function* watchCriarPropostaIndicacaoManual() {
  yield takeEvery(
    TYPES.CRIAR_PROPOSTA_INDICACAO_MANUAL_SAGA,
    criarPropostaIndicacaoManual
  )
}

export function* watchDuplicarPropostaIndicacao() {
  yield takeEvery(
    TYPES.DUPLICAR_PROPOSTA_INDICACAO_DISPATCH_SAGA,
    duplicarPropostaIndicacao
  )
}

export function* watchEditarPropostaIndicacao() {
  yield takeEvery(TYPES.EDITAR_PROPOSTA_INDICACAO_SAGA, editarPropostaIndicacao)
}

export function* watchDeletarPropostaIndicacao() {
  yield takeEvery(
    TYPES.DELETAR_PROPOSTA_INDICACAO_SAGA,
    deletarPropostaIndicacao
  )
}

export function* watchGetEquipes() {
  yield takeEvery(TYPES.GET_EQUIPES_SAGA, getEquipes)
}

export function* watchGetBancos() {
  yield takeEvery(TYPES.GET_BANCOS_SAGA, getBancos)
}

export function* watchGetCentrosCustos() {
  yield takeEvery(TYPES.GET_CENTROS_CUSTOS_SAGA, getCentrosCustos)
}

export function* watchGetCentrosCustosModal() {
  yield takeEvery(TYPES.GET_CENTROS_CUSTOS_MODAL_SAGA, getCentrosCustosModal)
}

export function* watchGetStatus() {
  yield takeEvery(TYPES.GET_STATUS_SAGA, getStatus)
}

export function* watchGetTipoOperacao() {
  yield takeEvery(TYPES.GET_TIPO_OPERACAO_SAGA, getTipoOperacao)
}

export function* watchGetUsuariosBancoByBancoLojaAndDigitador() {
  yield takeEvery(
    TYPES.GET_USUARIOS_BANCO_BY_BANCO_LOJA_AND_DIGITADOR_SAGA,
    getUsuariosBancoByBancoLojaAndDigitador
  )
}

function* inidicacaoSaga() {
  yield all([
    fork(watchGetAllPropostasIndicacao),
    fork(watchGetPropostaIndicacao),
    fork(watchCriarPropostaIndicacao),
    fork(watchCriarPropostaIndicacaoManual),
    fork(watchDuplicarPropostaIndicacao),
    fork(watchEditarPropostaIndicacao),
    fork(watchDeletarPropostaIndicacao),
    fork(watchGetEquipes),
    fork(watchGetBancos),
    fork(watchGetCentrosCustos),
    fork(watchGetCentrosCustosModal),
    fork(watchGetStatus),
    fork(watchGetTipoOperacao),
    fork(watchGetUsuariosBancoByBancoLojaAndDigitador)
  ])
}

export default inidicacaoSaga
