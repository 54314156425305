import React from 'react'
import Main from 'layouts/main'
import ConsigScreen from './Components/ConsigScreen'

const Credenciais = () => {
  return (
    <Main>
      <ConsigScreen />
    </Main>
  )
}

export default Credenciais
