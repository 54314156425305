import React, { useCallback, useEffect, useState } from 'react'
import InputCustom from 'components/Inputs'
import SimpleCard from 'components/SimpleCard'
import { FiChevronsRight, FiPhone, FiPhoneOutgoing } from 'react-icons/fi'
import { RiPhoneLine, RiWhatsappLine } from 'react-icons/ri'
import { Col, Collapse, Row, TabPane } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import { COLUMNS_ATENDIMENTO } from 'views/atendimento/constants/columns'
import logoRiber from 'assets/svg/logo-riber.svg'
import {
  BtnAgendar,
  BtnChamadaGreen,
  BtnChamadaOrange,
  Chamada
} from 'views/atendimento/style'
import CustomTable from 'components/CustomTable'
import ButtonsTabulacoes from '../ButtonsTabulacoes'
import { Tabulacao } from 'models/types'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import CustomSelect from 'components/Select'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import { InfosChamada } from 'models/atendimento/types'
import EnriquecimentoLemit from '../Modals/EnriquecimentoLemit'
import * as yup from 'yup'
import moment from 'moment'
import { toast } from 'react-toastify'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import NovoTelefone from '../Modals/NovoTelefone'
import ModalDiscagem from '../Modals/ModalDiscagem'
import SimpleTab, { Tab } from 'components/SimpleTab/SimpleTab'
import InformacoesSite from '../InfoLead'

/**
 * Contato
 * TabId = 1
 * **/
interface Props {
  index: number
}

const Contato = ({ index }: Props) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  const [toggle, setToggle] = useState(false)
  const [openModalNovoTelefone, setOpenModalNovoTelefone] = useState(false)
  const [infosMatriculaLead, setInfosMatriculaLead] = useState<Tab[]>([])

  function setTabulacoes(e: Tabulacao) {
    onChangeChamada(e, 'tabulacaoChamada')
    if (e?.personalizado) {
      setToggle(true)
    } else {
      setToggle(false)
      dispatch(atendimentoActions.tabularAtendimentoSaga(index))
    }
  }

  type InfosContatoField =
    keyof ATENDIMENTO['atendimentosAbertos'][number]['tabContato']

  function onChange<T extends InfosContatoField>(
    value: T extends keyof ATENDIMENTO['atendimentosAbertos'][number]['tabContato']
      ? ATENDIMENTO['atendimentosAbertos'][number]['tabContato'][T]
      : never,
    field: T
  ) {
    dispatch(atendimentoActions.setInfosContatoField(value, field, index))
  }

  type InfosChamadaField = keyof InfosChamada

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onChangeChamada(value: any, field: InfosChamadaField) {
    dispatch(atendimentoActions.setInfosChamadaContato(value, field, index))
  }

  const getAtendimentos = useCallback(() => {
    dispatch(
      atendimentoActions.getAtendimentosSaga(
        atendimentosAbertos[index].cpf,
        index
      )
    )
  }, [atendimentosAbertos, index, dispatch])

  function setPage(value: number) {
    dispatch(atendimentoActions.setPaginationAtendimentos(value, 'page', index))
    getAtendimentos()
  }

  function setPageSize(value: number) {
    dispatch(atendimentoActions.setPaginationAtendimentos(0, 'page', index))
    dispatch(
      atendimentoActions.setPaginationAtendimentos(value, 'pageSize', index)
    )
    getAtendimentos()
  }

  useEffect(() => {
    getAtendimentos()
    dispatch(atendimentoActions.getTabulacoesSaga(index))
    dispatch(atendimentoActions.getTiposAtendimentoSaga(index))
    dispatch(atendimentoActions.getIntegracaoSaga(index))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const schema = yup.object().shape({
    dataAgendamento: yup
      .date()
      .min(moment().format('YYYY-MM-DD'))
      .required()
      .transform((v) => (v instanceof Date ? v : null)),
    horarioAgendamento: yup
      .string()
      .required()
      .test(
        'e-maior',
        'O horário deve ser posterior ao atual',
        function (value) {
          const { dataAgendamento } = this.parent
          const data = moment(dataAgendamento).format('YYYY-MM-DD')
          const dataHora = moment(data + ' ' + value).format('YYYY-MM-DD HH:mm')
          return moment(dataHora).isSameOrAfter(moment())
        }
      ),
    tipoAgendamento: yup
      .string()
      .required()
      .transform((v) => (v ? v : ''))
  })

  function tabularAtendimento() {
    dispatch(atendimentoActions.cleanInfosChamadaInvalidContato(index))
    schema
      .validate(
        {
          dataAgendamento:
            atendimentosAbertos[index].tabContato?.infosChamada
              ?.dataAgendamento,
          horarioAgendamento:
            atendimentosAbertos[index].tabContato?.infosChamada
              ?.horarioAgendamento,
          tipoAgendamento:
            atendimentosAbertos[index].tabContato?.infosChamada?.tipoAgendamento
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(atendimentoActions.tabularAtendimentoSaga(index))
      })
      .catch((err) => {
        const fieldsInvalids: InfosChamadaField[] = []
        err.inner.forEach((e: yup.ValidationError) => {
          fieldsInvalids.push(e.path as InfosChamadaField)
          dispatch(
            atendimentoActions.setInfosChamadaInvalidContato(
              true,
              e.path || '',
              index
            )
          )
        })
        ;[...new Set(fieldsInvalids)]?.map((field) => {
          switch (field) {
            case 'dataAgendamento':
              return toast.warn(
                'Preencha a Data do Atendimento corretamente. O Data deve ser posterior a atual.'
              )
            case 'horarioAgendamento':
              return toast.warn(
                'Preencha o Horário do Atendimento corretamente. O Horário deve ser posterior ao atual.'
              )
            case 'tipoAgendamento':
              return toast.warn('Preencha o Tipo de Agendamento')
            default:
              return toast.warn('Preencha os campos corretamente para salvar')
          }
        })
      })
  }

  function discarCliente(tipoChamada: number) {
    if (
      atendimentosAbertos[index].tabContato?.infosChamada?.telefones &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      atendimentosAbertos[index].tabContato?.infosChamada?.telefones?.length > 0
    ) {
      onChange(tipoChamada, 'tipoChamada')
      dispatch(atendimentoActions.discarClienteSaga(index))
    } else {
      dispatch(
        atendimentoActions.toggleEnriquecimento(
          true,
          atendimentosAbertos[index].cpf
        )
      )
    }
  }
  useEffect(() => {
    const matriculas = atendimentosAbertos[
      index
    ].tabInformacoes.infoCliente.matriculas?.map(
      (matricula) => matricula.numeroMatricula ?? ''
    )
    dispatch(
      atendimentoActions.getInformacoesLeadSaga(
        atendimentosAbertos[index].cpf,
        matriculas ?? [],
        index
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabInformacoes.infoCliente.matriculas, index])

  interface OpenChatProps {
    nome?: string
    numero?: string
    cpf?: string
    cidade?: string
    sexo?: string
  }

  function replaceVariables(url: string, props: OpenChatProps): string {
    return url.replace(/{{(.*?)}}/g, (_, key) => {
      return props[key.trim() as keyof OpenChatProps] || ''
    })
  }

  function openChat(props: OpenChatProps) {
    const urlTemplate = atendimentosAbertos[index].tabContato?.integracaoUrl
    const finalUrl = replaceVariables(urlTemplate, props)
    window.open(finalUrl, '_blank')
  }

  useEffect(() => {
    const matriculas: Tab[] = []
    atendimentosAbertos[index].tabContato.infosChamada.infosMatriculasLead?.map(
      (infoMatriculaLead, i) => {
        const descricao = infoMatriculaLead.descricaoPerfil
          ? infoMatriculaLead.matricula
            ? `${infoMatriculaLead.descricaoPerfil}: ${infoMatriculaLead.matricula}`
            : infoMatriculaLead.descricaoPerfil
          : 'Descrição indisponível'
        matriculas.push({
          id: i,
          descricao,
          component: (
            <InformacoesSite
              matricula={infoMatriculaLead.matricula}
              perfil={infoMatriculaLead.descricaoPerfil}
              step={infoMatriculaLead.descricaoStepLead}
              observacaoLead={infoMatriculaLead.observacaoLead}
              produtosLead={infoMatriculaLead.produtosLead}
            />
          )
        })
      }
    )
    setInfosMatriculaLead(matriculas)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabContato.infosChamada.infosMatriculasLead])

  return (
    <TabPane tabId={1}>
      <Row className="mb-2">
        <Col className="d-flex justify-content-end">
          <div
            onClick={() => {
              setOpenModalNovoTelefone(true)
            }}
            className="btn-outline-purple"
          >
            <AiOutlinePlusCircle /> Adicionar telefone
          </div>
        </Col>
      </Row>
      <SimpleCard className="d-flex flex-column gap-3">
        <SimpleTab tabs={infosMatriculaLead} />
        <SimpleTitleCard>
          <RiPhoneLine className="icon" /> Contato
        </SimpleTitleCard>
        <div className="container-fluid">
          <Row className="row-gap-10">
            <Col md={3} className="d-flex flex-column">
              <label className="label-12">Nome Completo</label>
              <span>{atendimentosAbertos[index].tabContato.nome}</span>
            </Col>
            <Col md={3} className="d-flex flex-column">
              <label className="label-12">Data de nascimento</label>
              <span>
                {atendimentosAbertos[index].tabContato.dataNascimento}
              </span>
            </Col>
            <Col md={3} className="d-flex flex-column">
              <label className="label-12">Cidade</label>
              <span>{atendimentosAbertos[index].tabContato.cidade}</span>
            </Col>
            <Col md={3} className="d-flex flex-column">
              <label className="label-12">Sexo</label>
              <span>{atendimentosAbertos[index].tabContato.sexo}</span>
            </Col>
          </Row>
        </div>
        {atendimentosAbertos[index].tabContato.clienteRiber ? (
          <div className="container-fluid">
            <Row>
              <div className="d-flex align-items-center gap-3">
                <img src={logoRiber} alt="Logo Ribercred" />
                <label className="label-12 green">Já é cliente Riber</label>
              </div>
            </Row>
          </div>
        ) : (
          <></>
        )}
        <div className="container-fluid">
          <Row className="d-flex row-gap-15">
            <Col md={5} className="d-flex flex-column justify-content-end">
              <label className="label-12">Melhor telefone*</label>
              <div className="d-flex column-gap-10">
                <span style={{ fontWeight: '700' }}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    atendimentosAbertos[index].tabContato.infosChamada
                      ?.telefones[0]?.numero
                      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        atendimentosAbertos[index].tabContato.infosChamada
                          ?.telefones[0]?.numero
                      : ''
                  }
                </span>

                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  atendimentosAbertos[index]?.tabContato?.infosChamada
                    ?.telefones[0]?.whatsapp ? (
                    <RiWhatsappLine
                      onClick={() => {
                        openChat({
                          nome: atendimentosAbertos[index].tabContato.nome,
                          numero:
                            atendimentosAbertos[index]?.tabContato?.infosChamada
                              ?.telefones?.[0]?.numero,
                          cpf: atendimentosAbertos[index]?.cpf,
                          cidade:
                            atendimentosAbertos[index]?.tabContato?.cidade,
                          sexo: atendimentosAbertos[index]?.tabContato?.sexo
                        })
                      }}
                      style={{
                        fontSize: '25px',
                        color: '#00ab4f',
                        cursor: 'pointer'
                      }}
                    />
                  ) : (
                    <></>
                  )
                }

                <span style={{ fontWeight: '700', color: '#00ab4f' }}>
                  +
                  {` ${
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    atendimentosAbertos[index].tabContato.infosChamada
                      ?.telefones?.length -
                      1 >
                    0
                      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        atendimentosAbertos[index].tabContato.infosChamada
                          ?.telefones?.length - 1
                      : 0
                  } `}
                  números
                </span>
              </div>
            </Col>
            <Col md={7}>
              <div className="d-flex gap-3 justify-content-end flex-wrap">
                <BtnChamadaGreen onClick={() => discarCliente(1)}>
                  <span>Única</span> <FiPhone className="icon" />
                </BtnChamadaGreen>
                <BtnChamadaOrange onClick={() => discarCliente(2)}>
                  <span>Sequencial</span> <FiPhoneOutgoing className="icon" />
                </BtnChamadaOrange>
              </div>
            </Col>
          </Row>
        </div>
        <Collapse
          isOpen={atendimentosAbertos[index].tabContato.infosChamada.emChamada}
        >
          <Chamada>
            {atendimentosAbertos[index].tabContato.infosChamada.numeroChamada
              .length !== 0 && (
              <Row className="d-flex justify-content-between">
                <Col
                  md={4}
                  className="d-flex flex-column info-numero text-white fw-bolder"
                >
                  {atendimentosAbertos[index].tabContato.infosChamada
                    .statusChamada === 2 && <label>Em chamada</label>}
                  {atendimentosAbertos[index].tabContato.infosChamada
                    .statusChamada === 3 && <label>Chamada finalizada</label>}
                  <div className="d-flex flex-column row-gap-10">
                    {
                      atendimentosAbertos[index].tabContato.infosChamada
                        .numeroChamada
                    }
                  </div>
                </Col>
              </Row>
            )}
            {atendimentosAbertos[index].tabContato.infosChamada
              .statusChamada === 3 && (
              <>
                <Row>
                  <div className="d-flex flex-column row-gap-10">
                    <label className="label-12 text-white">
                      Nova tabulação*
                    </label>
                    <div className="d-flex flex-wrap gap-2">
                      <ButtonsTabulacoes
                        tabulacoes={
                          atendimentosAbertos[index].tabContato.tabulacoes
                        }
                        onClick={(e) => {
                          setTabulacoes(e)
                        }}
                        value={
                          atendimentosAbertos[index].tabContato.infosChamada
                            .tabulacaoChamada
                        }
                      />
                    </div>
                  </div>
                </Row>
                <Collapse isOpen={toggle}>
                  <label className="text-white fw-bolder">
                    Agendar Atendimento
                  </label>
                  <Row className="d-flex row-gap-10">
                    <Col md={4}>
                      <label className="label-12 text-white">
                        Data do atendimento*
                      </label>
                      <InputCustom
                        type="date"
                        onChange={(e) => {
                          onChangeChamada(e.target.value, 'dataAgendamento')
                        }}
                        value={
                          atendimentosAbertos[index].tabContato.infosChamada
                            ?.dataAgendamento
                        }
                        invalid={
                          atendimentosAbertos[index].tabContato
                            .infosChamadaInvalid?.dataAgendamento
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <label className="label-12 text-white">Horário*</label>
                      <InputCustom
                        type="time"
                        onChange={(e) => {
                          onChangeChamada(e.target.value, 'horarioAgendamento')
                        }}
                        value={
                          atendimentosAbertos[index].tabContato.infosChamada
                            ?.horarioAgendamento
                        }
                        invalid={
                          atendimentosAbertos[index].tabContato
                            .infosChamadaInvalid?.horarioAgendamento
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <label className="label-12 text-white">
                        Tipo de agendamento*
                      </label>
                      <CustomSelect
                        options={
                          atendimentosAbertos[index].tabContato
                            .tiposAgendamentos
                        }
                        placeholder="Selecione"
                        onChange={(e) => {
                          onChangeChamada(e, 'tipoAgendamento')
                        }}
                        value={
                          atendimentosAbertos[index].tabContato.infosChamada
                            .tipoAgendamento
                        }
                        invalid={
                          atendimentosAbertos[index].tabContato
                            .infosChamadaInvalid?.tipoAgendamento
                        }
                        accessorLabel="descricao"
                        accessorValue="id"
                      />
                    </Col>
                    <Col md={8}>
                      <label className="label-12 text-white">Observações</label>
                      <InputCustom
                        type="textarea"
                        onChange={(e) => {
                          onChangeChamada(
                            e.target.value,
                            'observacoesAgendamento'
                          )
                        }}
                        value={
                          atendimentosAbertos[index].tabContato.infosChamada
                            ?.observacoesAgendamento
                        }
                      />
                    </Col>
                    <Col
                      md={4}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <BtnAgendar
                        className="text-white fw-bolder"
                        onClick={() => tabularAtendimento()}
                      >
                        Agendar <FiChevronsRight />
                      </BtnAgendar>
                    </Col>
                  </Row>
                </Collapse>
              </>
            )}
          </Chamada>
        </Collapse>
        <CustomTable
          data={atendimentosAbertos[index].tabContato.agendamentos}
          columns={COLUMNS_ATENDIMENTO}
          rowCount={
            atendimentosAbertos[index].tabContato.tabelaAtendimentos
              .totalRegistros || 0
          }
          page={atendimentosAbertos[index].tabContato.tabelaAtendimentos.page}
          pageSize={
            atendimentosAbertos[index].tabContato.tabelaAtendimentos.pageSize
          }
          setPage={(e) => setPage(e)}
          setPageSize={(e) => setPageSize(e)}
        />
      </SimpleCard>
      <EnriquecimentoLemit
        isOpen={atendimentosAbertos[index].tabContato.isOpenEnriquecimento}
        onToggle={() =>
          dispatch(
            atendimentoActions.toggleEnriquecimento(
              !atendimentosAbertos[index].tabContato.isOpenEnriquecimento,
              atendimentosAbertos[index].cpf
            )
          )
        }
        onEnriquecer={() => {
          dispatch(
            atendimentoActions.enriquecerDadosSaga(
              atendimentosAbertos[index].cpf,
              index
            )
          )
        }}
      />
      <NovoTelefone
        isOpen={openModalNovoTelefone}
        onToggle={() => setOpenModalNovoTelefone(!openModalNovoTelefone)}
        adicionarFunc={(e, isWhatsApp) => {
          dispatch(
            atendimentoActions.adicionarNovoTelefoneSaga(e, isWhatsApp, index)
          )
        }}
      />
      <ModalDiscagem
        isOpen={
          atendimentosAbertos[index].tabContato.infosChamada.modalDiscagem
        }
        onToggle={() => {
          dispatch(
            atendimentoActions.setInfosChamadaContato(
              !atendimentosAbertos[index].tabContato.infosChamada.modalDiscagem,
              'modalDiscagem',
              index
            )
          )
        }}
        index={index}
      />
    </TabPane>
  )
}

export default Contato
