import { Lojas, Paginacao } from 'models/types'
import { Grupo } from 'models/backoffice/grupos/type'

interface Search {
  pesquisa: string
  filtro: string | null
}

export enum TYPES {
  SET_ACTIVE_TAB = '@@grupos/SET_ACTIVE_TAB',
  SET_ITEM_SELECTED_ID = '@@grupos/SET_ITEM_SELECTED_ID',
  SET_PESQUISA = '@@grupos/SET_PESQUISA',
  SET_PAGINATION = '@@grupos/SET_PAGINATION',
  SET_GRUPOS = '@@grupos/SET_GRUPOS',
  SET_LOJAS = '@@grupos/SET_LOJAS',
  SET_REGISTER_FIELDS = '@@grupos/SET_REGISTER_FIELDS',
  SET_REGISTER = '@@grupos/SET_REGISTER',
  GET_ALL_GRUPOS_SAGA = '@@grupos/GET_ALL_GRUPOS_SAGA',
  GET_ALL_LOJAS_SAGA = '@@grupos/GET_ALL_LOJAS_SAGA',
  CLEAN_REGISTER = '@@grupos/CLEAN_REGISTER',
  SET_INVALIDS = '@@grupos/SET_INVALIDS',
  CLEAN_INVALIDS = '@@grupos/CLEAN_INVALIDS',
  CLEAN_SEARCH = '@@grupos/CLEAN_SEARCH',
  POST_GRUPO_SAGA = '@@grupos/POST_GRUPO_SAGA',
  PUT_GRUPO_SAGA = '@@grupos/PUT_GRUPO_SAGA',
  TOGGLE_MODAL_EXCLUSAO = '@@grupos/TOGGLE_MODAL_EXCLUSAO',
  DELETE_GRUPO_SAGA = '@@grupos/DELETE_GRUPO_SAGA',
  VISUALIZAR_GRUPO_SAGA_DISPATCH = '@@grupos/VISUALIZAR_GRUPO_SAGA_DISPATCH',
  EDITAR_GRUPO_SAGA_DISPATCH = '@@grupos/EDITAR_GRUPO_SAGA_DISPATCH',
  SET_VISUALIZACAO = '@@grupos/SET_VISUALIZACAO'
}
export interface GRUPOS {
  activeTab: string
  pagination: Paginacao
  grupos: Grupo[]
  limiteMaxRegistros: number
  search: Search
  register: ParametrizacaoGrupo
  invalids: InvalidsParametrizacaoGrupos
  itemSelectedId: string
  showConfirmacaoExclusao: boolean
  lojas: Lojas[]
  visualizacao: boolean
}
export interface ParametrizacaoGrupo {
  id: string
  descricao: string
  lojas: string[]
}

export interface InvalidsParametrizacaoGrupos {
  id: boolean
  descricao: boolean
  lojas: boolean
}

export interface GrupoCRMLojas {
  ativo: boolean
  dataCriacao: Date
  dataUltimaAlteracao: Date
  grupoCrmId: string
  id: string
  idUsuarioCriacao: string
  idUsuarioUltimaAlteracao: string
  loja: Loja
  lojaId: string
  usuario: string
  usuarioAlteracao: string
}

interface Loja {
  ativo: boolean
  canalId: string
  dataCriacao: Date
  dataFimVigencia: Date
  dataInicioVigencia: Date
  dataUltimaAlteracao: Date
  grupoCrmLojas: []
  headCountOrcado: number
  id: string
  idUsuarioCriacao: string
  idUsuarioUltimaAlteracao: string
  lojaPessoas: string
  pessoa: Record<string, string>
}
