import { Reducer } from 'redux'
import { LOGIN, TYPES } from './types'

const INITIAL_STATE: LOGIN = {
  login: {
    email: '',
    senha: '',
    lembrar: false
  },
  recuperacao: {
    senha: '',
    confirmacaoSenha: ''
  },
  loading: false,
  loadingPermissoes: false,
  allowedRoutes: [],
  allowedActions: {
    atendimento: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    tabulacao: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    producao: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    produto: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    inicio: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    historico: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    carteira: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    grupos: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    blacklist: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    uploadArquivos: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    agenda: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    credenciais: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    indicadores: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    campanha: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    },
    indicacao: {
      gravar: false,
      estornar: false,
      visualizar: false,
      autorizar: false,
      exportar: false,
      deletar: false
    }
  },
  webSockets: {
    notificacaoInitialized: false,
    retornoDiscagemTelecomInitialized: false
  }
}

const reducer: Reducer<LOGIN> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_LOGIN:
      return {
        ...state,
        login: { ...state.login, [action.field]: action.value }
      }
    case TYPES.CLEAN_LOGIN:
      return {
        ...state,
        login: INITIAL_STATE.login
      }
    case TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.value
      }
    case TYPES.SET_RECUPERACAO:
      return {
        ...state,
        recuperacao: { ...state.recuperacao, [action.field]: action.value }
      }
    case TYPES.SET_PERMISSOES_USUARIO:
      return {
        ...state,
        allowedRoutes: action.value
      }
    case TYPES.SET_LOADING_PERMISSOES:
      return {
        ...state,
        loadingPermissoes: action.value
      }
    case TYPES.CLEAN_PERMISSOES_USUARIO:
      return {
        ...state,
        allowedRoutes: INITIAL_STATE.allowedRoutes
      }
    case TYPES.SET_PERMISSOES_ACTIONS_USUARIO:
      return {
        ...state,
        allowedActions: {
          ...state.allowedActions,
          ...action.value
        }
      }
    case TYPES.CLEAN_PERMISSOES_ACTIONS_USUARIO:
      return {
        ...state,
        allowedActions: INITIAL_STATE.allowedActions
      }
    case TYPES.SET_STATUS_WEBSOCKTES:
      return {
        ...state,
        webSockets: {
          ...state.webSockets,
          [action.field]: action.value
        }
      }
    default:
      return state
  }
}

export default reducer
