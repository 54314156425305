import InputCustom from 'components/Inputs'
import useRedux from 'hooks/useRedux'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { maskMoney, removeMaskMoney } from 'util/masks'
import { ValorParcela } from 'views/atendimento/style'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import { toast } from 'react-toastify'

interface AgregacaoProps {
  isOpen: boolean
  onToggle: () => void
  index: number
}

const Agregacao = ({ isOpen, onToggle, index }: AgregacaoProps) => {
  const { modalAgregacao, atendimentosAbertos } = useSelector<
    ApplicationState,
    ATENDIMENTO
  >((state) => state.atendimento)

  const { dispatch } = useRedux()

  function onChangeValueToBeAggregated(inputValue: string) {
    const inputValueConverted = Number(removeMaskMoney(maskMoney(inputValue)))

    if (modalAgregacao.margemDisponivel === 0) return

    if (inputValueConverted > modalAgregacao.margemDisponivel) {
      return
    }

    const inputValueMasked = maskMoney(inputValue)

    dispatch(
      atendimentoActions.setModalAgregacao(
        inputValueMasked,
        'valorASerAgregado'
      )
    )
  }

  function handleCloseModal() {
    dispatch(atendimentoActions.cleanModalAgregacao())
    onToggle()
  }

  function recalcularSimulacaoRefinItau() {
    if (isSimulacaoDisabled) return

    const codigoTabelaSelecionado = atendimentosAbertos[
      index
    ].tabProduto.listCodigoTabelaSelectedByCodigoSimulacao?.find(
      (item) => item.id === modalAgregacao.simulacao?.codigoSimulacao
    )?.value
    const parcelasSelecionado = atendimentosAbertos[
      index
    ].tabProduto.listParcelasSelectedByCodigoSimulacao?.find(
      (item) => item.id === modalAgregacao.simulacao?.codigoSimulacao
    )?.value

    if (
      modalAgregacao.simulacao &&
      modalAgregacao.simulacao.contratoRefinanciamento &&
      codigoTabelaSelecionado !== undefined &&
      parcelasSelecionado !== undefined
    ) {
      const {
        cpf,
        contratoRefinanciamento,
        servico,
        bancoSimulacaoEnum,
        matricula,
        codigoSimulacao
      } = modalAgregacao.simulacao

      const valorParcelaAtualizado =
        modalAgregacao.simulacao.listProdutos?.find(
          (produto) => produto.codigo === codigoTabelaSelected
        )?.valorParcela

      const valorParcelaOrigem = modalAgregacao.valorParcelaOrigem

      const valueAlreadyAggregated = parseFloat(
        (
          (valorParcelaAtualizado ?? valorParcelaOrigem) - valorParcelaOrigem
        ).toFixed(2)
      )

      const valorASerAgregado = Number(
        removeMaskMoney(modalAgregacao.valorASerAgregado)
      )

      const valorAgregacaoTotal = valueAlreadyAggregated + valorASerAgregado

      const novaMargem = modalAgregacao.margemDisponivel - valorASerAgregado

      dispatch(
        atendimentoActions.recalculoSimulacaoRefinItauSaga(
          cpf,
          matricula,
          parcelasSelecionado,
          modalAgregacao.valorParcelaOrigem,
          servico,
          codigoTabelaSelecionado,
          bancoSimulacaoEnum,
          contratoRefinanciamento?.numeroOperacaoCreditoExterno,
          valorAgregacaoTotal,
          index,
          modalAgregacao.simulacao.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          codigoSimulacao!,
          novaMargem
        )
      )
    } else {
      toast.error('Não foi possível realizar a simulação com o novo valor')
    }
    handleCloseModal()
  }

  const isSimulacaoDisabled =
    modalAgregacao.margemDisponivel === 0 ||
    Number(removeMaskMoney(maskMoney(modalAgregacao.valorASerAgregado))) >
      modalAgregacao.margemDisponivel

  const codigoTabelaSelected = atendimentosAbertos[
    index
  ].tabProduto.listCodigoTabelaSelectedByCodigoSimulacao?.find(
    (item) => item.id === modalAgregacao.simulacao?.codigoSimulacao
  )?.value

  const valorParcelaAtual = modalAgregacao.simulacao?.listProdutos?.find(
    (produto) => produto.codigo === codigoTabelaSelected
  )?.valorParcela

  const valorNovaParcela =
    (valorParcelaAtual ?? 0) +
    Number(removeMaskMoney(modalAgregacao.valorASerAgregado))

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={() => onToggle()}
      backdrop="static"
    >
      <ModalHeader
        toggle={() => {
          onToggle()
        }}
      >
        <SimpleTitleCard>
          <RiMoneyDollarCircleLine className="icon" />
          Agregar valor
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody style={{ gap: 20 }}>
        <div>
          <label className="label-12">Valor a ser agregado</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            value={modalAgregacao.valorASerAgregado}
            disabled={isSimulacaoDisabled}
            onChange={(e) => {
              onChangeValueToBeAggregated(e.target.value)
            }}
          />
        </div>
        <div className="mt-3 mb-3">
          <label className="label-12">
            Margem disponivel de R${' '}
            {maskMoney(modalAgregacao.margemDisponivel.toFixed(2))}
          </label>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-md-auto p-2 text-center">
            <label className="label-12">Valor da parcela de origem</label>
            <ValorParcela>
              R$ {maskMoney(modalAgregacao.valorParcelaOrigem.toFixed(2))}
            </ValorParcela>
          </div>
          <div className="col-md-auto p-2 text-center">
            <label className="label-12">Valor da nova parcela</label>
            <ValorParcela>
              R$ {maskMoney(valorNovaParcela.toFixed(2))}
            </ValorParcela>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-column">
        <CustomButton
          className="w-100"
          background="var(--roxo-riber)"
          onClick={() => handleCloseModal()}
        >
          Voltar
        </CustomButton>
        <CustomButton
          onClick={() => {
            recalcularSimulacaoRefinItau()
          }}
          isDisabled={
            isSimulacaoDisabled ||
            Number(
              removeMaskMoney(maskMoney(modalAgregacao.valorASerAgregado))
            ) === 0
          }
          className="w-100"
        >
          Simular com novo valor
        </CustomButton>
      </ModalFooter>
    </Modal>
  )
}

export default Agregacao
