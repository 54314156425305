import { maskCPF } from 'util/masks'
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams
} from '@mui/x-data-grid'
import moment from 'moment'
import { Tooltip } from '@mui/material'
import { FiCopy, FiTrash } from 'react-icons/fi'
import { HiOutlinePencil } from 'react-icons/hi'
import { Row } from 'react-data-grid'
import * as indicacaoActions from 'store/modules/indicacao/actions'
import PrivateComponent from 'components/PrivateComponent'

const headerTheme = 'table-header--purple'

export const COLUMNS: GridColumns<Row> = [
  {
    field: 'descricaoCentroCusto',
    headerName: 'Equipe',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    flex: 1
  },
  {
    field: 'nomeDigitador',
    headerName: 'Nome digitador',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    flex: 1
  },
  {
    field: 'dataCriacao',
    headerName: 'Data e hora',
    type: 'datetime',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip
        title={
          params?.value
            ? moment(params?.value).format('DD/MM/YYYY HH:mm:ss')
            : ''
        }
      >
        <span className="table-cell-trucate">
          {params?.value
            ? moment(params?.value).format('DD/MM/YYYY HH:mm:ss')
            : ''}
        </span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'nomeCliente',
    headerName: 'Nome do cliente',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span
          className="table-cell-trucate"
          style={params?.row.flagManual ? { backgroundColor: '#90EE90' } : {}}
        >
          {params?.value}
        </span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'cpf',
    headerName: 'CPF',
    valueGetter: (params: GridValueGetterParams) =>
      `${maskCPF(params?.value) || ''}`,
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'numeroAdesao',
    headerName: 'Adesão',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    flex: 1
  },
  {
    field: 'descricaoBanco',
    headerName: 'Banco',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'descricaoStatusProposta',
    headerName: 'Status',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'descricaoTipoOperacao',
    headerName: 'Tipo Operação',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'actions',
    headerName: 'Ações',
    headerClassName: headerTheme,
    type: 'actions',
    align: 'center',
    getActions: (params: GridRowParams) => [
      <PrivateComponent
        key={params.row.id}
        typeActions="gravar"
        screen="indicacao"
      >
        <GridActionsCellItem
          icon={<FiCopy />}
          onClick={() => {
            indicacaoActions.duplicarPropostaIndicacaoDispatchSaga(
              params.row.id
            )
          }}
          disabled={params.row.flagAtualizado}
          label="Duplicar"
        />
      </PrivateComponent>,
      <PrivateComponent
        key={params.row.id}
        typeActions="gravar"
        screen="indicacao"
      >
        <GridActionsCellItem
          icon={<HiOutlinePencil />}
          onClick={() => {
            indicacaoActions.getPropostaIndicacaoDispatchSaga(params.row.id)
            indicacaoActions.toggleModalEdicaoIndicacaoDispatch(true)
          }}
          disabled={params.row.flagAtualizado}
          label="Editar"
        />
      </PrivateComponent>,
      <PrivateComponent
        key={params.row.id}
        typeActions="deletar"
        screen="indicacao"
      >
        <GridActionsCellItem
          icon={<FiTrash />}
          onClick={() => {
            indicacaoActions.setItemSelectedIdDispatch(params.row.id)
            indicacaoActions.toggleModalExclusaoDispatch(true)
          }}
          disabled={params.row.flagAtualizado}
          label="Deletar"
        />
      </PrivateComponent>
    ]
  }
]
