import { GridColDef } from '@mui/x-data-grid'

const headerTheme = 'table-header--purple'

export const COLUMNS_VISAO_COORDENADOR: GridColDef[] = [
  {
    field: 'nomeLoja',
    headerName: 'Lojas',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'agendamentos',
    headerName: 'Agendados',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'confirmados',
    headerName: 'Confirmados',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'naoConfirmados',
    headerName: 'Não confirmados',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'expirados',
    headerName: 'Expirados',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  }
]

export const COLUMNS_VISAO_SUPERVISOR: GridColDef[] = [
  {
    field: 'nomeConsultor',
    headerName: 'Consultor',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'agendamentos',
    headerName: 'Agendados',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'confirmados',
    headerName: 'Confirmados',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'naoConfirmados',
    headerName: 'Não confirmados',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'expirados',
    headerName: 'Expirados',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  }
]
