import React, { useState } from 'react'
import { FiChevronsRight } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { maskMoney } from 'util/masks'
import { ContentCardOutrosProdutos } from '../style'

interface CardOutrosProdutosProps {
  origem: string
  titulo: string
  descricao: string
  valorMinimo: string
  valorMaximo: string
}

function CardOutrosProdutos({
  origem,
  titulo,
  descricao,
  valorMinimo,
  valorMaximo
}: CardOutrosProdutosProps) {
  const [valorDesejado, setValorDesejado] = useState('0,00')

  return (
    <ContentCardOutrosProdutos>
      <div className="header d-flex flex-column">
        <label>{origem}</label>
        <h2>{titulo}</h2>
      </div>
      <p>{descricao}</p>
      <div className="d-flex flex-column">
        <label className="label-12">Eu preciso de:</label>
        <span className="mb-3">R$ {valorDesejado}</span>
        <input
          type="range"
          step={0.01}
          min={valorMinimo}
          max={valorMaximo}
          onChange={(e) => {
            setValorDesejado(maskMoney(Number(e.target.value).toFixed(2)))
          }}
        />
      </div>
      <Row className="range-valores">
        <Col className="d-flex flex-column" md={6}>
          Min:
          <label>{maskMoney(valorMinimo)}</label>
        </Col>
        <Col className="d-flex flex-column" md={6}>
          Max:
          <label>{maskMoney(valorMaximo)}</label>
        </Col>
      </Row>
      <div className="d-flex justify-content-center align-items-center">
        <div className="btn-purple">
          Contratar <FiChevronsRight className="icon" />
        </div>
      </div>
    </ContentCardOutrosProdutos>
  )
}

export default CardOutrosProdutos
