import { APICore } from 'helpers/api/apiCore'
import { CredenciaisAgenteDto } from 'models/backoffice/credenciais/types'

const api = new APICore()

export function usuarioInstituicaoBmg() {
  return api.get(`Credencial/usuario-instituicao-bmg`, null)
}

export function usuarioInstituicaoItau() {
  return api.get(`Credencial/usuario-instituicao-itau`, null)
}

export function credenciaisBmg(usuarioInstituicao: string, senha: string) {
  return api.create(`Credencial/credenciais-bmg`, {
    usuarioInstituicao,
    senha
  })
}

export function credenciaisItau(usuarioInstituicao: string) {
  return api.create(`Credencial/credenciais-itau`, {
    usuarioInstituicao
  })
}

export function getUsuarioPrincipalBmg() {
  return api.get(`Credencial/usuario-principal-bmg`, null)
}

export function getUsuarioPrincipalItau() {
  return api.get(`Credencial/usuario-principal-itau`, null)
}

export function getCredenciaisAgente() {
  return api.get(`Credencial/informacoes-agente`, null)
}

export function salvarCredenciaisAgente(value: CredenciaisAgenteDto) {
  return api.create(`Credencial/informacoes-agente`, value)
}
