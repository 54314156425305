import React, { useEffect } from 'react'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import useRedux from 'hooks/useRedux'
import { HiOutlinePencil } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { ApplicationState } from 'store'
import { INDICACAO } from 'store/modules/indicacao/types'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { maskCPF, maskDate } from 'util/masks'
import * as indicacaoActions from 'store/modules/indicacao/actions'
import { schema } from 'views/indicacao/constants/schema'
import { toast } from 'react-toastify'
import * as yup from 'yup'

interface ModalEdicaoProps {
  isOpen: boolean
  onToggle: () => void
}

const ModalEdicao = ({ isOpen, onToggle }: ModalEdicaoProps) => {
  const { modalEdicaoIndicacao, optionsSelect, modalEdicaoIndicacaoInvalids } =
    useSelector<ApplicationState, INDICACAO>((state) => state.indicacao)

  const { dispatch } = useRedux()

  type FieldsModal = keyof INDICACAO['modalEdicaoIndicacao']
  const onChange = <T extends FieldsModal>(
    value: T extends FieldsModal ? INDICACAO['modalEdicaoIndicacao'][T] : never,
    field: T
  ) => {
    if (field === 'bancoId') {
      dispatch(
        indicacaoActions.setInfosModalEdicaoIndicacao({
          ...modalEdicaoIndicacao,
          usuarioBancoId: '',
          [field]: value
        })
      )
    } else if (field === 'usuarioBancoId') {
      dispatch(
        indicacaoActions.setInfosModalEdicaoIndicacao({
          ...modalEdicaoIndicacao,
          lojaId:
            optionsSelect.usuariosBanco.find((usuario) => usuario.id === value)
              ?.lojaId ?? '',
          centroCustoId:
            optionsSelect.usuariosBanco.find((usuario) => usuario.id === value)
              ?.centroCustoId ?? '',
          [field]: value
        })
      )
    } else {
      dispatch(
        indicacaoActions.setInfosModalEdicaoIndicacao({
          ...modalEdicaoIndicacao,
          [field]: value
        })
      )
    }
  }

  useEffect(() => {
    if (isOpen) {
      dispatch(indicacaoActions.cleanInvalids())
      dispatch(indicacaoActions.getBancosSaga(modalEdicaoIndicacao.digitadorId))
      dispatch(indicacaoActions.getEquipesSaga())
      dispatch(indicacaoActions.getCentrosCustosModalSaga())
      dispatch(indicacaoActions.getStatusSaga())
      dispatch(indicacaoActions.getTipoOperacaoSaga())
    }
  }, [dispatch, isOpen, modalEdicaoIndicacao])

  useEffect(() => {
    if (
      isOpen &&
      modalEdicaoIndicacao.bancoId &&
      modalEdicaoIndicacao.lojaId &&
      modalEdicaoIndicacao.digitadorId
    ) {
      dispatch(
        indicacaoActions.getUsuariosBancoByBancoLojaAndDigitadorSaga(
          modalEdicaoIndicacao.bancoId,
          modalEdicaoIndicacao.lojaId,
          modalEdicaoIndicacao.digitadorId
        )
      )
    } else {
      dispatch(indicacaoActions.setOptionsSelects([], 'usuariosBanco'))
    }
  }, [
    dispatch,
    isOpen,
    modalEdicaoIndicacao.bancoId,
    modalEdicaoIndicacao.lojaId,
    modalEdicaoIndicacao.digitadorId
  ])

  useEffect(() => {
    if (isOpen && modalEdicaoIndicacao.digitadorId) {
      dispatch(
        indicacaoActions.getEquipesModalSaga(modalEdicaoIndicacao.digitadorId)
      )
    }
  }, [dispatch, isOpen, modalEdicaoIndicacao.digitadorId])

  function onSave() {
    dispatch(indicacaoActions.cleanInvalids())
    schema
      .validate(
        {
          propostaIndicacaoId: modalEdicaoIndicacao.id,
          dataProposta: maskDate(
            modalEdicaoIndicacao.dataProposta,
            'YYYY-MM-DD'
          ),
          cpf: modalEdicaoIndicacao.cpf,
          numeroAdesao: modalEdicaoIndicacao.numeroAdesao,
          bancoId: modalEdicaoIndicacao.bancoId,
          statusPropostaId: modalEdicaoIndicacao.statusPropostaId,
          tipoOperacaoId: modalEdicaoIndicacao.tipoOperacaoId,
          usuarioBancoId: modalEdicaoIndicacao.usuarioBancoId,
          lojaId: modalEdicaoIndicacao.lojaId,
          centroCustoId: modalEdicaoIndicacao.centroCustoId
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(indicacaoActions.editarPropostaIndicacaoSaga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(indicacaoActions.setInvalids(true, e.path || ''))
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={() => onToggle()}>
      <ModalHeader
        toggle={() => {
          onToggle()
        }}
      >
        <SimpleTitleCard>
          <HiOutlinePencil className="icon" />
          Edição indicação
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <Row>
            <Col md={4}>
              <label className="label-12">Equipe</label>
              <CustomSelect
                options={optionsSelect.centrosModal}
                onChange={(e) => {
                  onChange(e.target.value, 'centroCustoId')
                }}
                accessorLabel="nome"
                accessorValue="id"
                value={modalEdicaoIndicacao.centroCustoId}
                invalid={modalEdicaoIndicacaoInvalids.centroCustoId}
                disabled
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Loja</label>
              <CustomSelect
                options={optionsSelect.equipes}
                onChange={(e) => {
                  onChange(e.target.value, 'lojaId')
                }}
                accessorLabel="nome"
                accessorValue="id"
                value={modalEdicaoIndicacao.lojaId}
                invalid={modalEdicaoIndicacaoInvalids.lojaId}
                disabled
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Data digitação</label>
              <InputCustom
                type="datetime-local"
                onChange={(e) => {
                  onChange(e.target.value, 'dataProposta')
                }}
                value={maskDate(
                  modalEdicaoIndicacao.dataProposta,
                  'YYYY-MM-DD HH:mm'
                )}
                invalid={modalEdicaoIndicacaoInvalids.dataProposta}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">CPF do cliente</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(maskCPF(e.target.value), 'cpf')
                }}
                value={maskCPF(modalEdicaoIndicacao.cpf)}
                invalid={modalEdicaoIndicacaoInvalids.cpf}
                disabled
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Adesão</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value.trim(), 'numeroAdesao')
                }}
                value={modalEdicaoIndicacao.numeroAdesao || ''}
                invalid={modalEdicaoIndicacaoInvalids.numeroAdesao}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Banco</label>
              <CustomSelect
                options={optionsSelect.bancos}
                onChange={(e) => {
                  onChange(e, 'bancoId')
                }}
                accessorLabel="descricao"
                accessorValue="id"
                value={modalEdicaoIndicacao.bancoId}
                invalid={modalEdicaoIndicacaoInvalids.bancoId}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Usuário banco</label>
              <CustomSelect
                options={optionsSelect.usuariosBanco}
                onChange={(e) => {
                  onChange(e, 'usuarioBancoId')
                }}
                accessorLabel="nome"
                accessorValue="id"
                value={modalEdicaoIndicacao.usuarioBancoId}
                invalid={modalEdicaoIndicacaoInvalids.usuarioBancoId}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Tipo Operação</label>
              <CustomSelect
                options={optionsSelect.tiposOperacao}
                onChange={(e) => {
                  onChange(e, 'tipoOperacaoId')
                }}
                accessorLabel="descricao"
                accessorValue="id"
                value={modalEdicaoIndicacao.tipoOperacaoId}
                invalid={modalEdicaoIndicacaoInvalids.tipoOperacaoId}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Status</label>
              <CustomSelect
                options={optionsSelect.status}
                onChange={(e) => {
                  onChange(e, 'statusPropostaId')
                }}
                accessorLabel="descricao"
                accessorValue="id"
                value={modalEdicaoIndicacao.statusPropostaId}
                invalid={modalEdicaoIndicacaoInvalids.statusPropostaId}
              />
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Row className="d-flex column-gap-20">
          <CustomButton
            onClick={() => onToggle()}
            background="var(--roxo-riber)"
          >
            Cancelar
          </CustomButton>
          <CustomButton
            onClick={() => {
              onSave()
            }}
          >
            Salvar
          </CustomButton>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default ModalEdicao
