import React from 'react'
import { Tabulacao as ITabulacao } from 'models/types'
import { Tabulacao } from '../style'

interface ButtonTabulacaoProps {
  tabulacoes: ITabulacao[]
  value?: ITabulacao | null
  onClick?: (e: ITabulacao) => void
}

const ButtonsTabulacoes = ({
  tabulacoes,
  value,
  onClick
}: ButtonTabulacaoProps) => {
  return (
    <>
      {tabulacoes.map((tabulacao, i) => {
        return (
          <Tabulacao
            onClick={() => {
              if (onClick) {
                onClick(tabulacao)
              }
            }}
            active={value === tabulacao}
            key={i}
          >
            {tabulacao.descricao}
          </Tabulacao>
        )
      })}
    </>
  )
}

export default ButtonsTabulacoes
