import { handlerError } from 'util/handlerError'
import { SagaIterator } from 'redux-saga'
import { call, fork, put, all, takeEvery, select } from 'redux-saga/effects'
import { ParametrizacaoTabulacao, TABULACAO, TYPES } from './types'
import * as tabulacaoController from 'controller/tabulacaoController'
import * as tabulacaoActions from 'store/modules/backoffice/tabulacao/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { ApplicationState } from 'store'
import { TabulacaoReq } from 'models/backoffice/tabulacao/type'
import { toast } from 'react-toastify'

interface GenericProps {
  type: string
  id: string
}

function* getAllTabulacoes(): SagaIterator {
  const { pagination, search }: TABULACAO = yield select(
    (state: ApplicationState) => state.tabulacao
  )

  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(tabulacaoController.getAllTabulacoes, {
      pagina: pagination.page,
      registros: pagination.pageSize,
      field: search.filtro,
      value: search.pesquisa
    })
    yield put(
      tabulacaoActions.setPaginacao({
        ...pagination,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )

    yield put(
      tabulacaoActions.setTabulacoes(response?.data?.content?.registros)
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getTabulacao({ id }: GenericProps): SagaIterator {
  try {
    yield call(tabulacaoController.getTabulacao, id)
  } catch (error) {
    handlerError(error)
  }
}

function* visualizarTabulacao({ id }: GenericProps): SagaIterator {
  yield put(loadingActions.setLoading(true))
  try {
    const response = yield call(tabulacaoController.getTabulacao, id)
    const tabulacao: ParametrizacaoTabulacao = {
      id: response?.data?.content?.id,
      descricao: response?.data?.content?.descricao,
      retirarClienteCampanha: response?.data?.content?.retirarClienteCampanha,
      pularParaProximoTelefone:
        response?.data?.content?.pularParaProximoTelefone,
      condenarTelefone: response?.data?.content?.condenarTelefone,
      finalizarAtendimento: response?.data?.content?.finalizarAtendimento,
      polaridadeId: response?.data?.content?.polaridadeId,
      localizacaoId: response?.data?.content?.localizacaoId,
      periodoAgendamentoId: response?.data?.content?.periodoAgendamentoId,
      observacoes: response?.data?.content?.observacoes
    }
    yield put(tabulacaoActions.setRegister(tabulacao))
    yield put(tabulacaoActions.setAtiveTab('register'))
    yield put(tabulacaoActions.setVisualizacao(true))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* editarTabulacao({ id }: GenericProps): SagaIterator {
  yield put(loadingActions.setLoading(true))
  try {
    const response = yield call(tabulacaoController.getTabulacao, id)
    const tabulacao: ParametrizacaoTabulacao = {
      id: response?.data?.content?.id,
      descricao: response?.data?.content?.descricao,
      retirarClienteCampanha: response?.data?.content?.retirarClienteCampanha,
      pularParaProximoTelefone:
        response?.data?.content?.pularParaProximoTelefone,
      condenarTelefone: response?.data?.content?.condenarTelefone,
      finalizarAtendimento: response?.data?.content?.finalizarAtendimento,
      polaridadeId: response?.data?.content?.polaridadeId,
      localizacaoId: response?.data?.content?.localizacaoId,
      periodoAgendamentoId: response?.data?.content?.periodoAgendamentoId,
      observacoes: response?.data?.content?.observacoes
    }
    yield put(tabulacaoActions.setRegister(tabulacao))
    yield put(tabulacaoActions.setAtiveTab('register'))
    yield put(tabulacaoActions.setVisualizacao(false))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getPolaridades(): SagaIterator {
  try {
    const response = yield call(tabulacaoController.getPolaridades)
    yield put(tabulacaoActions.setPolaridades(response.data.content))
  } catch (error) {
    handlerError(error)
  }
}

function* getLocalizacoes(): SagaIterator {
  try {
    const response = yield call(tabulacaoController.getLocalizacoes)
    yield put(tabulacaoActions.setLocalizacoes(response.data.content))
  } catch (error) {
    handlerError(error)
  }
}

function* getAgendamentos(): SagaIterator {
  try {
    const response = yield call(tabulacaoController.getAgendamentos)
    yield put(tabulacaoActions.setAgendamentos(response.data.content))
  } catch (error) {
    handlerError(error)
  }
}

function* criarTabulacao(): SagaIterator {
  const { register }: TABULACAO = yield select(
    (state: ApplicationState) => state.tabulacao
  )
  try {
    yield put(loadingActions.setLoading(true))
    const data: TabulacaoReq = {
      id: register.id,
      descricao: register.descricao,
      retirarClienteCampanha: register.retirarClienteCampanha,
      condenarTelefone: register.condenarTelefone,
      pularParaProximoTelefone: register.pularParaProximoTelefone,
      finalizarAtendimento: register.finalizarAtendimento,
      polaridadeId: register.polaridadeId,
      localizacaoId: register.localizacaoId,
      periodoAgendamentoId: register.periodoAgendamentoId,
      observacoes: register.observacoes
    }
    yield call(tabulacaoController.criarTabulacao, data)

    yield put(tabulacaoActions.cleanRegister())
    yield put(
      tabulacaoActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )
    yield put(tabulacaoActions.setPesquisa(null, 'filtro'))
    yield put(tabulacaoActions.setPesquisa('', 'pesquisa'))
    yield put(tabulacaoActions.getAllTabulacoesSaga())
    toast.success('Tabulação salva com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* atualizarTabulacao(): SagaIterator {
  const { register }: TABULACAO = yield select(
    (state: ApplicationState) => state.tabulacao
  )
  try {
    yield put(loadingActions.setLoading(true))
    const data: TabulacaoReq = {
      id: register.id,
      descricao: register.descricao,
      retirarClienteCampanha: register.retirarClienteCampanha,
      condenarTelefone: register.condenarTelefone,
      pularParaProximoTelefone: register.pularParaProximoTelefone,
      finalizarAtendimento: register.finalizarAtendimento,
      polaridadeId: register.polaridadeId,
      localizacaoId: register.localizacaoId,
      periodoAgendamentoId: register.periodoAgendamentoId,
      observacoes: register.observacoes
    }
    yield call(tabulacaoController.atualizarTabulacao, data)
    yield put(tabulacaoActions.cleanRegister())
    yield put(
      tabulacaoActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )
    yield put(tabulacaoActions.setPesquisa(null, 'filtro'))
    yield put(tabulacaoActions.setPesquisa('', 'pesquisa'))
    yield put(tabulacaoActions.getAllTabulacoesSaga())
    yield put(tabulacaoActions.setAtiveTab('search'))
    toast.success('Tabulação atualizada com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* deletarTabulacao(): SagaIterator {
  const { itemSelectedId }: TABULACAO = yield select(
    (state: ApplicationState) => state.tabulacao
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(tabulacaoController.deletarTabulacao, itemSelectedId)
    yield put(
      tabulacaoActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )
    yield put(tabulacaoActions.setPesquisa(null, 'filtro'))
    yield put(tabulacaoActions.setPesquisa('', 'pesquisa'))
    yield put(tabulacaoActions.getAllTabulacoesSaga())
    toast.success('Tabulação excluída com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetAllTabulacoes() {
  yield takeEvery(TYPES.GET_ALL_TABULACOES_SAGA, getAllTabulacoes)
}

export function* watchGetPolaridades() {
  yield takeEvery(TYPES.GET_POLARIDADES_SAGA, getPolaridades)
}

export function* watchGetLocalizacoes() {
  yield takeEvery(TYPES.GET_LOCALIZACOES_SAGA, getLocalizacoes)
}

export function* watchGetAgendamentos() {
  yield takeEvery(TYPES.GET_AGENDAMENTOS_SAGA, getAgendamentos)
}

export function* watchGetTabulacao() {
  yield takeEvery(TYPES.GET_TABULACAO_SAGA, getTabulacao)
}

export function* watchVisualizarTabulacao() {
  yield takeEvery(TYPES.VISUALIZAR_TABULACAO_SAGA_DISPATCH, visualizarTabulacao)
}

export function* watchEditarTabulacao() {
  yield takeEvery(TYPES.EDITAR_TABULACAO_SAGA_DISPATCH, editarTabulacao)
}

export function* watchCriarTabulacao() {
  yield takeEvery(TYPES.POST_TABULACAO_SAGA, criarTabulacao)
}

export function* watchAtualizarTabulacao() {
  yield takeEvery(TYPES.PUT_TABULACAO_SAGA, atualizarTabulacao)
}

export function* watchDeletarTabulacao() {
  yield takeEvery(TYPES.DELETE_TABULACAO_SAGA, deletarTabulacao)
}

function* tabulacaoSaga() {
  yield all([
    fork(watchGetAllTabulacoes),
    fork(watchGetPolaridades),
    fork(watchGetLocalizacoes),
    fork(watchGetAgendamentos),
    fork(watchGetTabulacao),
    fork(watchVisualizarTabulacao),
    fork(watchEditarTabulacao),
    fork(watchCriarTabulacao),
    fork(watchAtualizarTabulacao),
    fork(watchDeletarTabulacao)
  ])
}

export default tabulacaoSaga
