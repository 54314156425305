import { TabulacaoReq } from './../models/backoffice/tabulacao/type'
import { APICore } from 'helpers/api/apiCore'

const api = new APICore()

export function getAllTabulacoes(params: {
  pagina: number
  registros: number
  field?: string | null
  value: string | number
}) {
  if (params.field) {
    return api.get(`Tabulacao/paginacao`, {
      pagina: params.pagina + 1,
      registros: params.registros,
      [params.field]: params.value
    })
  }
  return api.get(`Tabulacao/paginacao`, {
    pagina: params.pagina + 1,
    registros: params.registros
  })
}

export function getTabulacao(id: string) {
  return api.get(`Tabulacao/tabulacao-by-id/${id}`, {
    id: id
  })
}

export function getPolaridades() {
  return api.get(`Generic/polaridades`, null)
}

export function getLocalizacoes() {
  return api.get(`Generic/localizacoes`, null)
}

export function getAgendamentos() {
  return api.get(`Generic/periodo-agendamento`, null)
}

export function criarTabulacao(params: TabulacaoReq) {
  return api.create(`Tabulacao/cadastrar`, params)
}

export function atualizarTabulacao(params: TabulacaoReq) {
  return api.update(`Tabulacao/atualizar`, params)
}

export function deletarTabulacao(id: string) {
  return api.delete(`Tabulacao/deletar?id=${id}`)
}
