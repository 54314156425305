import React from 'react'
import { RiBookOpenLine } from 'react-icons/ri'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'

interface ClienteagendadoProps {
  isOpen: boolean
  onToggle: () => void
  aceitaFunc: () => void
}

const Clienteagendado = ({
  isOpen,
  onToggle,
  aceitaFunc
}: ClienteagendadoProps) => {
  function onAdicionar() {
    aceitaFunc()
  }
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={() => onToggle()}
      backdrop="static"
    >
      <ModalHeader
        toggle={() => {
          onToggle()
        }}
      >
        <SimpleTitleCard>
          <RiBookOpenLine className="icon" />
          Agendamento encontrado
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody>
        <p>Cliente possui agendamento vigente. Deseja finalizar agendamento?</p>
      </ModalBody>
      <ModalFooter>
        <CustomButton background="var(--roxo-riber)" onClick={() => onToggle()}>
          Não
        </CustomButton>
        <CustomButton
          onClick={() => {
            onAdicionar()
          }}
        >
          Sim
        </CustomButton>
      </ModalFooter>
    </Modal>
  )
}

export default Clienteagendado
