import { handlerError } from 'util/handlerError'
import { maskMoney, removeMaskMoney } from 'util/masks'
import {
  ParametrizacaoProduto,
  ParametrizacaoProdutoReq,
  Tabela
} from 'models/backoffice/produto/type'
import { SagaIterator } from 'redux-saga'
import { call, fork, put, all, takeEvery, select } from 'redux-saga/effects'
import { ParametrizacaoList, PRODUTO, TabelasInvalids, TYPES } from './types'
import * as produtoController from 'controller/produtoController'
import * as produtoActions from 'store/modules/backoffice/produto/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { ApplicationState } from 'store'
import { toast } from 'react-toastify'

interface GenericProps {
  type: string
  id: string
}

function* getAllParametrizacao(): SagaIterator {
  const { pagination, search }: PRODUTO = yield select(
    (state: ApplicationState) => state.produto
  )
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(produtoController.getAllParametrizacao, {
      pagina: pagination.page,
      registros: pagination.pageSize,
      field: search.filtro,
      value: search.pesquisa
    })
    yield put(
      produtoActions.setPaginacao({
        ...pagination,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )
    const parametrizacoes: ParametrizacaoList[] = []
    response?.data?.content?.registros?.map(
      (parametrizacao: ParametrizacaoList) => {
        parametrizacoes.push({
          id: parametrizacao.id,
          descricaoProduto: parametrizacao.descricaoProduto,
          descricaoBanco: parametrizacao.descricaoBanco,
          descricaoConvenio: parametrizacao.descricaoConvenio,
          descricaoTipoOperacao: parametrizacao.descricaoTipoOperacao,
          descricaoTipoFormalizacao: parametrizacao.descricaoTipoFormalizacao
        })
      }
    )
    yield put(produtoActions.setParametrizacaoList(parametrizacoes))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getParametrizacao({ id }: GenericProps): SagaIterator {
  try {
    yield call(produtoController.getParametrizacao, id)
  } catch (error) {
    handlerError(error)
  }
}

function* visualizarParametrizacao({ id }: GenericProps): SagaIterator {
  yield put(loadingActions.setLoading(true))
  try {
    const response = yield call(produtoController.getParametrizacao, id)
    const tabelas: Tabela[] = []
    response?.data?.content?.parametrizacaoProdutosValoresTabelas.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (tabela: any) => {
        tabelas.push({
          id: tabela.id,
          rangePrazoFinal: tabela.rangePrazoFinal,
          rangePrazoInicial: tabela.rangePrazoInicial,
          tabelasComissao: tabela.tabelasComissao
        })
      }
    )
    const produto: ParametrizacaoProduto = {
      id: response?.data?.content?.id,
      produtoId: response?.data?.content?.produtoId,
      bancoId: response?.data?.content?.bancoId,
      convenioId: response?.data?.content?.convenioId,
      tipoOperacaoId: response?.data?.content?.tipoOperacaoId,
      tipoFormalizacaoId: response?.data?.content?.tipoFormalizacaoId,
      margemMin: maskMoney(response?.data?.content?.margemMin.toFixed(2)),
      margemMax: maskMoney(response?.data?.content?.margemMax.toFixed(2)),
      idadeDe: response?.data?.content?.idadeDe,
      idadeAte: response?.data?.content?.idadeAte,
      mesesDe: response?.data?.content?.mesesDe,
      mesesAte: response?.data?.content?.mesesAte,
      diasDe: response?.data?.content?.diasDe,
      diasAte: response?.data?.content?.diasAte,
      prazo: response?.data?.content?.prazo,
      trocoMin: maskMoney(response?.data?.content?.trocoMin.toFixed(2)),
      trocoMax: maskMoney(response?.data?.content?.trocoMax.toFixed(2)),
      pmtPaga: response?.data?.content?.pmtPaga,
      saldoDevedorMin: maskMoney(
        response?.data?.content?.saldoDevedorMin.toFixed(2)
      ),
      saldoDevedorMax: maskMoney(
        response?.data?.content?.saldoDevedorMax.toFixed(2)
      ),
      valorContrato: maskMoney(
        response?.data?.content?.valorContrato.toFixed(2)
      ),
      cartaoInstituicaoId: response?.data?.content?.cartaoInstituicaoId,
      taxas: response?.data?.content?.taxas,
      bancosNaoPortados: response?.data?.content?.bancosNaoPortados,
      descricaoGrupoBeneficio:
        response?.data?.content?.parametrizacaoProdutoGrupo.descricao,
      beneficios:
        response?.data?.content?.parametrizacaoProdutoGrupo.beneficios,
      tabelas: tabelas
    }
    yield put(produtoActions.setRegister(produto))
    yield put(produtoActions.setVisualizacao(true))
    yield put(produtoActions.toggleAllCards(true))
    yield put(produtoActions.setAtiveTab('register'))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* editarParametrizacao({ id }: GenericProps): SagaIterator {
  yield put(loadingActions.setLoading(true))
  try {
    const response = yield call(produtoController.getParametrizacao, id)
    const tabelas: Tabela[] = []
    response?.data?.content?.parametrizacaoProdutosValoresTabelas.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (tabela: any) => {
        tabelas.push({
          id: tabela.id,
          rangePrazoFinal: tabela.rangePrazoFinal,
          rangePrazoInicial: tabela.rangePrazoInicial,
          tabelasComissao: tabela.tabelasComissao
        })
      }
    )
    const produto: ParametrizacaoProduto = {
      id: response?.data?.content?.id,
      produtoId: response?.data?.content?.produtoId,
      bancoId: response?.data?.content?.bancoId,
      convenioId: response?.data?.content?.convenioId,
      tipoOperacaoId: response?.data?.content?.tipoOperacaoId,
      tipoFormalizacaoId: response?.data?.content?.tipoFormalizacaoId,
      margemMin: maskMoney(response?.data?.content?.margemMin.toFixed(2)),
      margemMax: maskMoney(response?.data?.content?.margemMax.toFixed(2)),
      idadeDe: response?.data?.content?.idadeDe,
      idadeAte: response?.data?.content?.idadeAte,
      mesesDe: response?.data?.content?.mesesDe,
      mesesAte: response?.data?.content?.mesesAte,
      diasDe: response?.data?.content?.diasDe,
      diasAte: response?.data?.content?.diasAte,
      prazo: response?.data?.content?.prazo,
      trocoMin: maskMoney(response?.data?.content?.trocoMin.toFixed(2)),
      trocoMax: maskMoney(response?.data?.content?.trocoMax.toFixed(2)),
      pmtPaga: response?.data?.content?.pmtPaga,
      saldoDevedorMin: maskMoney(
        response?.data?.content?.saldoDevedorMin.toFixed(2)
      ),
      saldoDevedorMax: maskMoney(
        response?.data?.content?.saldoDevedorMax.toFixed(2)
      ),
      valorContrato: maskMoney(
        response?.data?.content?.valorContrato.toFixed(2)
      ),
      cartaoInstituicaoId: response?.data?.content?.cartaoInstituicaoId,
      taxas: response?.data?.content?.taxas,
      bancosNaoPortados: response?.data?.content?.bancosNaoPortados,
      descricaoGrupoBeneficio:
        response?.data?.content?.parametrizacaoProdutoGrupo.descricao,
      beneficios:
        response?.data?.content?.parametrizacaoProdutoGrupo.beneficios,
      tabelas: tabelas
    }
    const tabelasInvalids: TabelasInvalids[] = tabelas.map(() => {
      return {
        id: false,
        rangePrazoFinal: false,
        rangePrazoInicial: false,
        tabelasComissao: false
      }
    })
    yield put(produtoActions.setInvalids(tabelasInvalids, 'tabelas'))
    yield put(produtoActions.setRegister(produto))
    yield put(produtoActions.setVisualizacao(false))
    yield put(produtoActions.toggleAllCards(true))
    yield put(produtoActions.setAtiveTab('register'))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* criarParametrizacao(): SagaIterator {
  const { register }: PRODUTO = yield select(
    (state: ApplicationState) => state.produto
  )
  try {
    yield put(loadingActions.setLoading(true))
    const data: ParametrizacaoProdutoReq = {
      id: '',
      produtoId: register.produtoId || '',
      bancoId: register.bancoId || '',
      convenioId: register.convenioId || '',
      tipoOperacaoId: register.tipoOperacaoId || '',
      tipoFormalizacaoId: register.tipoFormalizacaoId || '',
      margemMin: Number(removeMaskMoney(register.margemMin)),
      margemMax: Number(removeMaskMoney(register.margemMax)),
      idadeDe: register.idadeDe,
      idadeAte: register.idadeAte,
      mesesDe: register.mesesDe,
      mesesAte: register.mesesAte,
      diasDe: register.diasDe,
      diasAte: register.diasAte,
      prazo: register.prazo,
      trocoMin: Number(removeMaskMoney(register.trocoMin)),
      trocoMax: Number(removeMaskMoney(register.trocoMax)),
      pmtPaga: register.pmtPaga,
      saldoDevedorMin: Number(removeMaskMoney(register.saldoDevedorMin)),
      saldoDevedorMax: Number(removeMaskMoney(register.saldoDevedorMax)),
      valorContrato: Number(removeMaskMoney(register.valorContrato)),
      cartaoInstituicaoId: register.cartaoInstituicaoId,
      taxas: register.taxas,
      bancosNaoPortados: register.bancosNaoPortados,
      descricaoGrupoBeneficio: register.descricaoGrupoBeneficio,
      beneficios: register.beneficios,
      tabelas: register.tabelas
    }
    yield call(produtoController.criarParametrizacao, data)
    yield put(produtoActions.cleanRegister())
    yield put(
      produtoActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )
    yield put(produtoActions.setPesquisa(null, 'filtro'))
    yield put(produtoActions.setPesquisa('', 'pesquisa'))
    yield put(produtoActions.getAllParametrizacaoSaga())
    toast.success('Paramatrização de produto salva com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* atualizarParametrizacao(): SagaIterator {
  const { register }: PRODUTO = yield select(
    (state: ApplicationState) => state.produto
  )
  try {
    yield put(loadingActions.setLoading(true))
    const data: ParametrizacaoProdutoReq = {
      id: register.id,
      produtoId: register.produtoId || '',
      bancoId: register.bancoId || '',
      convenioId: register.convenioId || '',
      tipoOperacaoId: register.tipoOperacaoId || '',
      tipoFormalizacaoId: register.tipoFormalizacaoId || '',
      margemMin: Number(removeMaskMoney(register.margemMin)),
      margemMax: Number(removeMaskMoney(register.margemMax)),
      idadeDe: register.idadeDe,
      idadeAte: register.idadeAte,
      mesesDe: register.mesesDe,
      mesesAte: register.mesesAte,
      diasDe: register.diasDe,
      diasAte: register.diasAte,
      prazo: register.prazo,
      trocoMin: Number(removeMaskMoney(register.trocoMin)),
      trocoMax: Number(removeMaskMoney(register.trocoMax)),
      pmtPaga: register.pmtPaga,
      saldoDevedorMin: Number(removeMaskMoney(register.saldoDevedorMin)),
      saldoDevedorMax: Number(removeMaskMoney(register.saldoDevedorMax)),
      valorContrato: Number(removeMaskMoney(register.valorContrato)),
      cartaoInstituicaoId: register.cartaoInstituicaoId,
      taxas: register.taxas,
      bancosNaoPortados: register.bancosNaoPortados,
      descricaoGrupoBeneficio: register.descricaoGrupoBeneficio,
      beneficios: register.beneficios,
      tabelas: register.tabelas
    }
    yield call(produtoController.atualizarParametrizacao, data)
    yield put(produtoActions.cleanRegister())
    yield put(
      produtoActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )
    yield put(produtoActions.setPesquisa(null, 'filtro'))
    yield put(produtoActions.setPesquisa('', 'pesquisa'))
    yield put(produtoActions.getAllParametrizacaoSaga())
    yield put(produtoActions.setAtiveTab('search'))
    toast.success('Paramatrização de produto atualizada com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* deletarParametrizacao(): SagaIterator {
  const { itemSelectedId }: PRODUTO = yield select(
    (state: ApplicationState) => state.produto
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(produtoController.deletarParametrizacao, itemSelectedId)
    yield put(
      produtoActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )
    yield put(produtoActions.setPesquisa(null, 'filtro'))
    yield put(produtoActions.setPesquisa('', 'pesquisa'))
    yield put(produtoActions.getAllParametrizacaoSaga())
    toast.success('Paramatrização de produto excluída com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getProdutos(): SagaIterator {
  try {
    const response = yield call(produtoController.getProdutos)
    yield put(
      produtoActions.setOptionsSelect(response.data.content, 'produtos')
    )
  } catch (error) {
    handlerError(error)
  }
}

function* getInstituicoes(): SagaIterator {
  try {
    const response = yield call(produtoController.getInstituicoes)
    yield put(
      produtoActions.setOptionsSelect(response.data.content, 'instituicoes')
    )
  } catch (error) {
    handlerError(error)
  }
}

function* getConvenios(): SagaIterator {
  try {
    const response = yield call(produtoController.getConvenios)
    yield put(
      produtoActions.setOptionsSelect(response.data.content, 'convenios')
    )
  } catch (error) {
    handlerError(error)
  }
}

function* getTipoOperacao(): SagaIterator {
  try {
    const response = yield call(produtoController.getTipoOperacao)
    yield put(
      produtoActions.setOptionsSelect(response.data.content, 'tipoOperacao')
    )
  } catch (error) {
    handlerError(error)
  }
}

function* getTipoFormalizacao(): SagaIterator {
  try {
    const response = yield call(produtoController.getTipoFormalizacao)
    yield put(
      produtoActions.setOptionsSelect(response.data.content, 'tipoFormalizacao')
    )
  } catch (error) {
    handlerError(error)
  }
}

function* getTaxas(): SagaIterator {
  try {
    const response = yield call(produtoController.getTaxas)
    yield put(produtoActions.setOptionsSelect(response.data.content, 'taxas'))
  } catch (error) {
    handlerError(error)
  }
}

function* getTabelasBanco(): SagaIterator {
  const { register }: PRODUTO = yield select(
    (state: ApplicationState) => state.produto
  )
  try {
    const response = yield call(
      produtoController.getTabelasBanco,
      register.bancoId || ''
    )
    yield put(produtoActions.setOptionsSelect(response.data.content, 'tabelas'))
  } catch (error) {
    handlerError(error)
  }
}

function* getBeneficios(): SagaIterator {
  try {
    const response = yield call(produtoController.getBeneficios)
    yield put(
      produtoActions.setOptionsSelect(response.data.content, 'beneficios')
    )
  } catch (error) {
    handlerError(error)
  }
}

export function* watchGetAllParametrizacao() {
  yield takeEvery(TYPES.GET_ALL_PARAMETRIZACAO_SAGA, getAllParametrizacao)
}

export function* watchGetParametrizacao() {
  yield takeEvery(TYPES.GET_PARAMETRIZACAO_SAGA, getParametrizacao)
}

export function* watchVisualizarParametrizacao() {
  yield takeEvery(
    TYPES.VISUALIZAR_PARAMETRIZACAO_DISPATCH_SAGA,
    visualizarParametrizacao
  )
}

export function* watchEditarParametrizacao() {
  yield takeEvery(
    TYPES.EDITAR_PARAMETRIZACAO_DISPATCH_SAGA,
    editarParametrizacao
  )
}

export function* watchCriarParametrizacao() {
  yield takeEvery(TYPES.POST_PARAMETRIZACAO_SAGA, criarParametrizacao)
}

export function* watchAtualizarParametrizacao() {
  yield takeEvery(TYPES.PUT_PARAMETRIZACAO_SAGA, atualizarParametrizacao)
}

export function* watchDeletarParametrizacao() {
  yield takeEvery(
    TYPES.DELETE_PARAMETRIZACAO_DISPATCH_SAGA,
    deletarParametrizacao
  )
}

export function* watchGetProdutos() {
  yield takeEvery(TYPES.GET_PRODUTOS_SAGA, getProdutos)
}

export function* watchGetInstituicoes() {
  yield takeEvery(TYPES.GET_INSTITUICOES_SAGA, getInstituicoes)
}

export function* watchGetConvenios() {
  yield takeEvery(TYPES.GET_CONVENIOS_SAGA, getConvenios)
}

export function* watchGetTipoOperacao() {
  yield takeEvery(TYPES.GET_TIPO_OPERACAO_SAGA, getTipoOperacao)
}

export function* watchGetTipoFormalizacao() {
  yield takeEvery(TYPES.GET_TIPO_FORMALIZACAO_SAGA, getTipoFormalizacao)
}

export function* watchGetTaxas() {
  yield takeEvery(TYPES.GET_TAXAS_SAGA, getTaxas)
}

export function* watchGetTabelasBanco() {
  yield takeEvery(TYPES.GET_TABELAS_BANCO_SAGA, getTabelasBanco)
}

export function* watchGetBeneficios() {
  yield takeEvery(TYPES.GET_BENEFICIOS_SAGA, getBeneficios)
}

function* produtoSaga() {
  yield all([
    fork(watchGetAllParametrizacao),
    fork(watchGetParametrizacao),
    fork(watchVisualizarParametrizacao),
    fork(watchEditarParametrizacao),
    fork(watchCriarParametrizacao),
    fork(watchAtualizarParametrizacao),
    fork(watchDeletarParametrizacao),
    fork(watchGetProdutos),
    fork(watchGetInstituicoes),
    fork(watchGetConvenios),
    fork(watchGetTipoOperacao),
    fork(watchGetTipoFormalizacao),
    fork(watchGetTaxas),
    fork(watchGetTabelasBanco),
    fork(watchGetBeneficios)
  ])
}

export default produtoSaga
