import { Routes } from 'routes'

export enum TYPES {
  SET_LOGIN = '@@login/SET_LOGIN',
  CLEAN_LOGIN = '@@login/CLEAN_LOGIN',
  SET_LOADING = '@@login/SET_LOADING',
  SET_RECUPERACAO = '@@login/SET_RECUPERACAO',
  SET_PERMISSOES_USUARIO = '@@login/SET_PERMISSOES_USUARIO',
  SET_LOADING_PERMISSOES = '@@login/SET_LOADING_PERMISSOES',
  CLEAN_PERMISSOES_USUARIO = '@@login/CLEAN_PERMISSOES_USUARIO',
  SET_PERMISSOES_ACTIONS_USUARIO = '@@login/SET_PERMISSOES_ACTIONS_USUARIO',
  CLEAN_PERMISSOES_ACTIONS_USUARIO = '@@login/CLEAN_PERMISSOES_ACTIONS_USUARIO',
  SET_STATUS_WEBSOCKTES = '@@login/SET_STATUS_WEBSOCKTES',

  LOGIN_SAGA = '@@login/LOGIN_SAGA',
  LOGOUT_SAGA = '@@login/LOGOUT_SAGA',
  RECUPERAR_ACESSO_SAGA = '@@login/RECUPERAR_ACESSO_SAGA',
  VALIDA_GUID_RECUPERACAO_SAGA = '@@login/VALIDA_GUID_RECUPERACAO_SAGA',
  REDEFINIR_SENHA_SAGA = '@@login/REDEFINIR_SENHA_SAGA',
  PERMISSOES_USUARIO_SAGA = '@@login/PERMISSOES_USUARIO_SAGA',
  INICIAR_NOTIFICACOES_SAGA = '@@login/INICIAR_NOTIFICACOES_SAGA',
  INICIAR_RETORNO_DISCAGEM_TELECOM_SAGA = '@@login/INICIAR_RETORNO_DISCAGEM_TELECOM_SAGA'
}

export interface FormLogin {
  email: string
  senha: string
  lembrar: boolean
}

export interface FormRecuperacao {
  senha: string
  confirmacaoSenha: string
}

export interface LOGIN {
  login: FormLogin
  recuperacao: FormRecuperacao
  loading: boolean
  loadingPermissoes: boolean
  allowedRoutes: Routes[]
  allowedActions: AllowedActions
  webSockets: WebSockets
}

export interface AllowedActions {
  atendimento: Actions
  tabulacao: Actions
  producao: Actions
  produto: Actions
  inicio: Actions
  historico: Actions
  carteira: Actions
  grupos: Actions
  blacklist: Actions
  uploadArquivos: Actions
  agenda: Actions
  credenciais: Actions
  indicadores: Actions
  campanha: Actions
  indicacao: Actions
}

export interface Actions {
  gravar: boolean
  estornar: boolean
  visualizar: boolean
  autorizar: boolean
  exportar: boolean
  deletar: boolean
}

interface WebSockets {
  notificacaoInitialized: boolean
  retornoDiscagemTelecomInitialized: boolean
}
