import { Paginacao } from 'models/types'

export enum TYPES {
  SET_ACTIVE_TAB = '@@uploadArquivos/SET_ACTIVE_TAB',
  SET_ALL_UPLOAD = '@@uploadArquivos/SET_ALL_UPLOAD',
  SET_PESQUISA = '@@uploadArquivos/SET_PESQUISA',
  SET_PAGINACAO = '@@uploadArquivos/SET_PAGINACAO',
  SET_TIPOS_DE_ARQUIVOS = '@@uploadArquivos/SET_TIPOS_DE_ARQUIVOS',
  SET_IMAGE_SELECTED = '@@uploadArquivos/SET_IMAGE_SELECTED',
  SET_ITEM_SELECTED_ID_DISPATCH = '@@uploadArquivos/SET_ITEM_SELECTED_ID_DISPATCH',

  TOGGLE_MODAL_EXCLUSAO_DISPATCH = '@@uploadArquivos/TOGGLE_MODAL_EXCLUSAO_DISPATCH',
  EXCLUIR_UPLOAD_SAGA = '@@uploadArquivos/EXCLUIR_UPLOAD_SAGA',
  UPLOAD_SAGA = '@@uploadArquivos/UPLOAD_SAGA',

  GET_ALL_UPLOAD_SAGA = '@@uploadArquivos/GET_ALL_UPLOAD_SAGA',
  GET_TIPOS_DE_ARQUIVOS_SAGA = '@@uploadArquivos/GET_TIPOS_DE_ARQUIVOS_SAGA',
  DOWNLOAD_ARQUIVO_SAGA_DISPATCH = '@@uploadArquivos/DOWNLOAD_ARQUIVO_SAGA_DISPATCH',

  SET_REGISTER_FIELDS = '@@uploadArquivos/SET_REGISTER_FIELDS',
  SET_REGISTER = '@@uploadArquivos/SET_REGISTER',
  CLEAN_REGISTER = '@@uploadArquivos/CLEAN_REGISTER',
  SET_INVALIDS = '@@uploadArquivos/SET_INVALIDS',
  CLEAN_INVALIDS = '@@uploadArquivos/CLEAN_INVALIDS',
  CLEAN_SEARCH = '@@uploadArquivos/CLEAN_SEARCH',

  VISUALIZAR_ARQUIVO = '@@uploadArquivos/VISUALIZAR_ARQUIVO',
  SET_ARQUIVO = '@@uploadArquivos/SET_ARQUIVO',
  CLEAN_ARQUIVO = '@@uploadArquivos/CLEAN_ARQUIVO',
  SET_CREATING = '@@uploadArquivos/SET_CREATING'
}
export interface UploadsFormat {
  id: string
  nomeArquivo: string
  descricaoTipoArquivo: string
  nomeUsuario: string
  dataUpload: string
  status: string
}
interface Search {
  pesquisa: string
  filtro: string | null
}
export interface UPLOAD_ARQUIVOS {
  activeTab: string
  search: Search
  isCreating: boolean
  pagination: Paginacao
  uploads: UploadsFormat[]
  tipos: TipoArquivo[]
  showConfirmacaoExclusao: boolean
  showConfirmacaoParams: string
  register: ParametrizacaoUploadArquivo
  invalids: InvalidsParametrizacaoUploadArquivos
  arquivo: UploadsFormat
}
export interface TipoArquivo {
  value: string
  descricao?: string
}

export interface ParametrizacaoUploadArquivo {
  tipoArquivo: string
  uploadedFile: File | null
  nomeArquivo: string
}

export interface InvalidsParametrizacaoUploadArquivos {
  tipoArquivo: boolean
  uploadedFile: boolean
}
