import { Tooltip } from '@mui/material'
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams
} from '@mui/x-data-grid'
import { Switch } from 'components/Switch'
import { Row } from 'react-data-grid'
import { AiOutlineEye } from 'react-icons/ai'
import { FiTrash } from 'react-icons/fi'
import { HiOutlinePencil } from 'react-icons/hi'
import * as campanhaActions from 'store/modules/backoffice/campanha/actions'
import { maskDate } from 'util/masks'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { StatusAbastecimentoCampanha } from 'store/modules/backoffice/campanha/types'

const headerTheme = 'table-header--purple'

const getIconReabastecimentoColor = (status: StatusAbastecimentoCampanha) => {
  switch (status) {
    case 'ERRO':
      return 'red'
    case 'SUCESSO':
      return 'green'
    case 'PROCESSANDO':
      return '#e1e100'
  }
}

export const COLUMNS: GridColumns<Row> = [
  {
    field: 'codigo',
    headerName: 'Código',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 2
  },
  {
    field: 'descricao',
    headerName: 'Descrição',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 2
  },
  {
    field: 'dataInicioCampanha',
    headerName: 'Data início',
    headerClassName: headerTheme,
    type: 'datetime',
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={maskDate(params?.value, 'DD/MM/YYYY') || ''}>
        <span className="table-cell-trucate">
          {maskDate(params?.value, 'DD/MM/YYYY')}
        </span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 3
  },
  {
    field: 'dataFimCampanha',
    headerName: 'Data fim',
    headerClassName: headerTheme,
    type: 'datetime',
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={maskDate(params?.value, 'DD/MM/YYYY') || ''}>
        <span className="table-cell-trucate">
          {maskDate(params?.value, 'DD/MM/YYYY')}
        </span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 3
  },
  {
    field: 'descricaoTipoOperacao',
    headerName: 'Tipo Operação',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 2
  },
  {
    field: 'nomeLoja',
    headerName: 'Loja',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 3
  },
  {
    field: 'discador',
    headerName: 'Discador',
    headerClassName: headerTheme,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.value ? 'Sim' : 'Não'}`,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 0
  },
  {
    field: 'totalCpfs',
    headerName: 'Total',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 0
  },
  {
    field: 'quantidadeClientesCarteiraCampanha',
    headerName: 'Indisponíveis',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    )
  },
  {
    field: 'totalCpfsDisponiveis',
    headerName: 'Disponível',
    headerClassName: headerTheme,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    ),
    align: 'left',
    headerAlign: 'left',
    flex: 0
  },
  {
    field: 'actions',
    headerName: 'Ações',
    headerClassName: headerTheme,
    type: 'actions',
    align: 'center',
    minWidth: 200,
    getActions: (params: GridRowParams) => {
      return [
        <GridActionsCellItem
          key={params.row.id}
          icon={
            <Switch
              size="small"
              checked={
                params.row.vigente === false ? false : params.row.habilitado
              }
            />
          }
          onClick={() => {
            campanhaActions.alterarStatusCampanhaDispatchSaga(
              params.row.id,
              !params.row.habilitado
            )
          }}
          disabled={!params.row.vigente}
          label="Ativar/Inativar"
        />,
        <GridActionsCellItem
          key={params.row.id}
          icon={
            <AiOutlineClockCircle
              color={getIconReabastecimentoColor(params.row.statusCampanhaCrm)}
            />
          }
          label="Status Reabastecimento"
        />,
        <GridActionsCellItem
          key={params.row.id}
          icon={<AiOutlineEye />}
          onClick={() => {
            campanhaActions.visualizarCampanhaSagaDispatch(params.row.id)
          }}
          label="Visualizar"
        />,
        <GridActionsCellItem
          key={params.row.id}
          icon={<HiOutlinePencil />}
          onClick={() => {
            campanhaActions.editarCampanhaSagaDispatch(params.row.id)
          }}
          label="Editar"
        />,
        <GridActionsCellItem
          key={params.row.id}
          icon={<FiTrash />}
          onClick={() => {
            campanhaActions.setItemSelectedIdDispatch(params.row.id)
            campanhaActions.toggleModalExclusaoDispatch(true)
          }}
          label="Deletar"
        />
      ]
    }
  }
]
