import { CheckboxContrato, ContentCardContratoRefinItau } from '../style'
import { ProdutosSimulacao } from 'models/types'
import useRedux from 'hooks/useRedux'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { HiOutlinePencil } from 'react-icons/hi'
import { ErrorCardContratoRefinItau } from './ErrorCardContratoRefinItau'
import CustomSelect from 'components/Select'
import { maskCPF, maskMoney } from 'util/masks'
import { Col } from 'reactstrap'
import { toast } from 'react-toastify'
import { AiOutlineClose } from 'react-icons/ai'

interface CardContratoRefinItauProps {
  id: string
  numeroOperacaoCreditoExterno: string
  permiteAgregacaoRefin: boolean
  valorParcela: number
  saldoDevedor: number
  quantidadeTotalParcelas: number
  quantidadeParcelaPaga: number
  listProdutos: ProdutosSimulacao['listProdutos'] | null
  taxaOrigem: number
  clienteElegivel: boolean
  index: number
  errors: string[]
  simulacao: ProdutosSimulacao
  codigoTabelaSelected: number
  parcelasSelected: number
}

export function CardContratoRefinItau({
  id,
  numeroOperacaoCreditoExterno,
  permiteAgregacaoRefin,
  valorParcela,
  saldoDevedor,
  quantidadeTotalParcelas,
  quantidadeParcelaPaga,
  listProdutos,
  taxaOrigem,
  clienteElegivel,
  index,
  errors,
  simulacao,
  codigoTabelaSelected,
  parcelasSelected
}: CardContratoRefinItauProps) {
  const { dispatch } = useRedux()
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )
  function toggleModalAgregacao() {
    if (simulacao === undefined || simulacao === null) return

    dispatch(
      atendimentoActions.setModalAgregacao(maskCPF('0'), 'valorASerAgregado')
    )

    dispatch(
      atendimentoActions.setModalAgregacao(
        margemDisponivel ?? 0,
        'margemDisponivel'
      )
    )
    dispatch(atendimentoActions.setModalAgregacao(simulacao, 'simulacao'))
    dispatch(
      atendimentoActions.setModalAgregacao(valorParcela, 'valorParcelaOrigem')
    )
    dispatch(
      atendimentoActions.setInfosProduto(
        !atendimentosAbertos[index].tabProduto.modalAgregacao,
        'modalAgregacao',
        index
      )
    )
  }

  function removeAgregacao() {
    const codigoTabelaSelecionado = atendimentosAbertos[
      index
    ].tabProduto.listCodigoTabelaSelectedByCodigoSimulacao?.find(
      (item) => item.id === simulacao.codigoSimulacao
    )?.value

    const parcelasSelecionado = atendimentosAbertos[
      index
    ].tabProduto.listProdutosRefinQtdDeParcelasOriginaisByContrato?.find(
      (item) =>
        item.contrato ===
        simulacao?.contratoRefinanciamento?.numeroOperacaoCreditoExterno
    )?.value

    if (
      simulacao &&
      simulacao.codigoSimulacao &&
      simulacao.contratoRefinanciamento &&
      codigoTabelaSelecionado !== undefined &&
      parcelasSelecionado !== undefined
    ) {
      const {
        id,
        cpf,
        contratoRefinanciamento,
        servico,
        bancoSimulacaoEnum,
        matricula,
        codigoSimulacao
      } = simulacao

      const novaMargem = (margemDisponivel ?? 0) + valorAgregado

      dispatch(
        atendimentoActions.recalculoSimulacaoRefinItauSaga(
          cpf,
          matricula,
          parcelasSelecionado,
          valorParcelaOrigem,
          servico,
          codigoTabelaSelecionado,
          bancoSimulacaoEnum,
          contratoRefinanciamento?.numeroOperacaoCreditoExterno,
          0,
          index,
          id,
          codigoSimulacao,
          novaMargem
        )
      )
    } else {
      toast.error('Não foi possível realizar a simulação com o novo valor')
    }
  }

  function onCheck() {
    let produtosSelecionados = [
      ...atendimentosAbertos[index].tabProduto.produtosSelecionados
    ]

    if (isChecked) {
      produtosSelecionados = produtosSelecionados.filter(
        (produto) => produto.id !== id
      )
    } else {
      produtosSelecionados.push(simulacao)
    }

    dispatch(
      atendimentoActions.setInfosProduto(
        produtosSelecionados,
        'produtosSelecionados',
        index
      )
    )
  }

  function updateCodigoTabelaSelected(newCodigoTabelaSelecionado: number) {
    const newListCodigoTabelaSelectedByCodigoSimulacao = [
      ...atendimentosAbertos[index].tabProduto
        .listCodigoTabelaSelectedByCodigoSimulacao
    ]
    const newListParcelasSelectedByCodigoSimulacao = [
      ...atendimentosAbertos[index].tabProduto
        .listParcelasSelectedByCodigoSimulacao
    ]

    const { codigoSimulacao } = simulacao
    const indexCodigoTabelaSelected =
      newListCodigoTabelaSelectedByCodigoSimulacao.findIndex(
        (item) => item.id === codigoSimulacao
      )
    const indexParcelasSelected =
      newListParcelasSelectedByCodigoSimulacao.findIndex(
        (item) => item.id === codigoSimulacao
      )

    if (
      indexCodigoTabelaSelected === -1 ||
      codigoSimulacao === undefined ||
      codigoSimulacao === null
    ) {
      toast.error('Não foi possível alterar a tabela')
      return
    }

    newListCodigoTabelaSelectedByCodigoSimulacao[indexCodigoTabelaSelected] = {
      id: codigoSimulacao,
      value: newCodigoTabelaSelecionado
    }

    const produtoDisponivelIndex = atendimentosAbertos[
      index
    ].tabProduto.produtosDisponiveis.findIndex((p) => p.id === id)
    const newParcelasSelecionado =
      atendimentosAbertos[index].tabProduto.produtosDisponiveis[
        produtoDisponivelIndex
      ].listProdutos?.[indexParcelaSelected]?.listParcelas[0].parcelas

    newListParcelasSelectedByCodigoSimulacao[indexParcelasSelected] = {
      id: codigoSimulacao,
      value: newParcelasSelecionado
    }

    dispatch(
      atendimentoActions.setInfosProduto(
        newListCodigoTabelaSelectedByCodigoSimulacao,
        'listCodigoTabelaSelectedByCodigoSimulacao',
        index
      )
    )

    dispatch(
      atendimentoActions.setInfosProduto(
        newListParcelasSelectedByCodigoSimulacao,
        'listParcelasSelectedByCodigoSimulacao',
        index
      )
    )
  }

  function updateParcelasSelected(newParcelasSelecionado: number) {
    if (
      atendimentosAbertos[index].tabProduto
        ?.listParcelasSelectedByCodigoSimulacao
    ) {
      const newListParcelasSelectedByCodigoSimulacao = [
        ...atendimentosAbertos[index].tabProduto
          .listParcelasSelectedByCodigoSimulacao
      ]

      const { codigoSimulacao } = simulacao
      const indexParcelasSelected =
        newListParcelasSelectedByCodigoSimulacao.findIndex(
          (item) => item.id === codigoSimulacao
        )

      if (
        indexParcelasSelected === -1 ||
        codigoSimulacao === undefined ||
        codigoSimulacao === null
      ) {
        toast.error('Não foi possível alterar a tabela')
        return
      }

      newListParcelasSelectedByCodigoSimulacao[indexParcelasSelected] = {
        id: codigoSimulacao,
        value: newParcelasSelecionado
      }

      dispatch(
        atendimentoActions.setInfosProduto(
          newListParcelasSelectedByCodigoSimulacao,
          'listParcelasSelectedByCodigoSimulacao',
          index
        )
      )
    }
  }

  const indexTabelaSelected =
    listProdutos?.findIndex((p) => p.codigo === codigoTabelaSelected) ?? 0

  const indexParcelaSelected =
    listProdutos?.[indexTabelaSelected]?.listParcelas.findIndex(
      (p) => p.parcelas === parcelasSelected
    ) ?? 0

  const isChecked = atendimentosAbertos[
    index
  ].tabProduto.produtosSelecionados.some((produto) => produto?.id === id)

  const margemDisponivel = atendimentosAbertos[
    index
  ].tabProduto.margemDisponivelByMatricula.find(
    (item) => item.matricula === simulacao.matricula
  )?.value

  const valorParcelaAtualizado = listProdutos?.find(
    (produto) => produto.codigo === codigoTabelaSelected
  )?.valorParcela

  const valorParcelaOrigem = valorParcela

  const valorAgregado =
    (valorParcelaAtualizado ?? valorParcelaOrigem) - valorParcelaOrigem

  return (
    <ContentCardContratoRefinItau>
      <div className="d-flex flex-column align-items-start w-100">
        <div className="d-flex w-100">
          <div className="d-flex flex-column align-items-start column-gap-20 w-100">
            <label className="fw-bold">Contrato</label>
            <label>{numeroOperacaoCreditoExterno}</label>
          </div>
          <div className="d-flex flex-column align-items-start column-gap-20 w-100">
            <label className="fw-bold">Valor parcela</label>
            <div className="d-flex align-items-center justify-content-center">
              <label>{`R$ ${maskMoney(valorParcela.toFixed(2))}`}</label>
              {permiteAgregacaoRefin && (
                <button
                  className="button-abrir-modal-agregacao"
                  onClick={toggleModalAgregacao}
                >
                  <HiOutlinePencil />
                </button>
              )}
            </div>
          </div>
          {valorAgregado > 0 && (
            <div className="d-flex flex-column align-items-start column-gap-20 w-100">
              <label className="fw-bold">Agregado</label>
              <div className="d-flex align-items-center justify-content-center">
                <label>{`R$ ${maskMoney(valorAgregado.toFixed(2))}`}</label>
                {permiteAgregacaoRefin && (
                  <button
                    className="button-remove-agregacao"
                    onClick={removeAgregacao}
                  >
                    <AiOutlineClose />
                  </button>
                )}
              </div>
            </div>
          )}
          <div className="d-flex flex-column align-items-start column-gap-20 w-100">
            <>
              <label className="fw-bold">Pagas</label>
              <div>
                <label>
                  {quantidadeParcelaPaga} de {quantidadeTotalParcelas}
                </label>
              </div>
            </>
          </div>
          <div className="d-flex flex-column align-items-start column-gap-20 w-100">
            <label className="fw-bold">Taxa origem</label>
            <div>
              <label>{taxaOrigem.toFixed(2)}% a.m</label>
            </div>
          </div>
          {clienteElegivel && (
            <div className="d-flex flex-column align-items-start column-gap-20 w-100">
              <label className="fw-bold">Saldo devedor</label>
              <div>
                <label>{`R$ ${maskMoney(saldoDevedor.toFixed(2))}`}</label>
              </div>
            </div>
          )}
        </div>

        <Col className="d-flex w-100">
          {clienteElegivel && (
            <div
              className="d-flex flex-column align-items-start column-gap-20 w-100"
              style={{ flex: 3 }}
            >
              <label className="fw-bold">Tabela</label>
              <CustomSelect
                isClearable={false}
                options={listProdutos ?? []}
                placeholder="Selecione a tabela"
                value={codigoTabelaSelected}
                onChange={(e) => {
                  updateCodigoTabelaSelected(e)
                }}
                accessorLabel="descricao"
                accessorValue="codigo"
              />
            </div>
          )}

          {clienteElegivel && (
            <div className="d-flex flex-1 flex-column align-items-start column-gap-20 w-100">
              <label className="fw-bold">Prazo</label>
              <CustomSelect
                isClearable={false}
                options={
                  listProdutos?.[indexTabelaSelected]?.listParcelas ?? []
                }
                placeholder={
                  listProdutos?.[indexTabelaSelected]?.listParcelas?.find(
                    (parcela) => parcela.parcelas === 84
                  )?.parcelas
                }
                value={
                  parcelasSelected ||
                  listProdutos?.[indexTabelaSelected]?.listParcelas?.find(
                    (parcela) => parcela.parcelas === 84
                  )
                }
                onChange={(e) => {
                  updateParcelasSelected(e)
                }}
                accessorLabel="parcelas"
                accessorValue="parcelas"
              />
            </div>
          )}

          {clienteElegivel && (
            <div className="d-flex flex-1 flex-column align-items-start justify-content-center column-gap-20 w-100">
              <label className="fw-bold">Troco liberado</label>

              <span className="valor-liberado">
                R$
                {maskMoney(
                  listProdutos?.[indexTabelaSelected]?.listParcelas
                    .find(
                      (parcela) =>
                        parcela.parcelas === parcelasSelected ||
                        parcela.parcelas === 84
                    )
                    ?.valorLiberado.toFixed(2)
                )}
              </span>
            </div>
          )}
        </Col>
        {errors?.map((error) => (
          <ErrorCardContratoRefinItau key={error}>
            {error}
          </ErrorCardContratoRefinItau>
        ))}
      </div>
      <div className="mt-2">
        {clienteElegivel && id && (
          <CheckboxContrato
            checked={isChecked}
            onChange={() => {
              onCheck()
            }}
          />
        )}
      </div>
    </ContentCardContratoRefinItau>
  )
}
