export enum TYPES {
  TOGGLE_SIDEBAR = '@@sidebar/TOGGLE_SIDEBAR',
  SET_PIN = '@@sidebar/SET_PIN',

  PIN_SIDEBAR_SAGA = '@@sidebar/PIN_SIDEBAR_SAGA'
}

export interface SIDEBAR {
  isOpen: boolean
  pin: boolean
}
