import Main from 'layouts/main'
import Search from './Tabs/Search'
import Register from './Tabs/Register'
import { TabContent, TabPane } from 'reactstrap'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { UPLOAD_ARQUIVOS } from 'store/modules/backoffice/uploadArquivos/types'

const UploadArquivos = () => {
  const { activeTab } = useSelector<ApplicationState, UPLOAD_ARQUIVOS>(
    (state) => state.uploadArquivos
  )

  return (
    <Main>
      {
        <TabContent activeTab={activeTab}>
          <TabPane tabId="search">
            <Search />
          </TabPane>
          <TabPane tabId="register">
            <Register />
          </TabPane>
        </TabContent>
      }
    </Main>
  )
}

export default UploadArquivos
