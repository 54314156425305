import React from 'react'
import * as campanhaActions from 'store/modules/backoffice/campanha/actions'
import SelecaoMultipla from 'components/SelecaoMultipla'
import useRedux from 'hooks/useRedux'
import { SimpleTitleCard } from 'styles/global'
import { AiOutlineBank } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { CAMPANHA } from 'store/modules/backoffice/campanha/types'

const BancosPagadores = () => {
  const {
    register,
    optionsSelect,
    invalidsTabBancos,
    visualizacao,
    activeTabRegister
  } = useSelector<ApplicationState, CAMPANHA>((state) => state.campanha)

  const { dispatch } = useRedux()

  type Fields = keyof CAMPANHA['register']
  function onChange<T extends Fields>(
    value: T extends Fields ? CAMPANHA['register'][T] : never,
    field: T
  ) {
    dispatch(campanhaActions.setRegisterFields(value, field))
  }

  return (
    <div className="d-flex flex-column row-gap-10">
      <SimpleTitleCard>
        <AiOutlineBank className="icon" /> Bancos pagadores
      </SimpleTitleCard>
      {activeTabRegister && (
        <SelecaoMultipla
          accessorLabel="descricao"
          accessorValue="id"
          options={optionsSelect.bancos}
          value={register.bancosPagadoresConsiderarCampanha}
          setterValue={(e) => {
            onChange(e, 'bancosPagadoresConsiderarCampanha')
          }}
          invalid={invalidsTabBancos.bancosPagadoresConsiderarCampanha}
          disabled={visualizacao}
          rerenderParam={activeTabRegister}
        />
      )}
    </div>
  )
}

export default BancosPagadores
