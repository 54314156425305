import { ModalBody, ModalHeader } from 'reactstrap'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import CustomTable from 'components/CustomTable'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import { COLUMNS_COBERTURAS } from 'views/atendimento/constants/columns'
import { maskMoney } from 'util/masks'
import { ModalCoberturasStyled } from 'views/atendimento/style'
interface CoberturaSeguroPrestamistaProps {
  isOpen: boolean
  toggle: () => void
  index: number
}

const CoberturaSeguroPrestamista = ({
  isOpen,
  toggle
}: CoberturaSeguroPrestamistaProps) => {
  const { modalCoberturasSeguroPrestamista } = useSelector<
    ApplicationState,
    ATENDIMENTO
  >((state) => state.atendimento)

  const { dispatch } = useRedux()

  function handleCloseModal() {
    dispatch(atendimentoActions.cleanModalCoberturaSeguroPrestamista())
  }

  return (
    <ModalCoberturasStyled
      isOpen={isOpen}
      style={{ overflowX: 'auto', maxWidth: '55%' }}
      onClosed={handleCloseModal}
    >
      <ModalHeader toggle={() => toggle()}></ModalHeader>
      <ModalBody>
        <div
          style={{ maxHeight: '80vh' }}
          className="d-flex flex-column gap-1 align-items-center"
        >
          <CustomTable
            autoHeight
            data={modalCoberturasSeguroPrestamista.coberturas?.map(
              (cobertura) => ({
                ...cobertura,
                valorBeneficio:
                  cobertura.valorBeneficio === 0
                    ? 'Ilimitado'
                    : cobertura.valorBeneficio === 0.01
                    ? 'Limitada'
                    : `R$ ${maskMoney(cobertura.valorBeneficio?.toFixed(2))}`
              })
            )}
            columns={COLUMNS_COBERTURAS}
            hideFooter={true}
            autoPageSize={false}
            getRowId={(row) => row.nomeCobertura}
          />
        </div>
      </ModalBody>
    </ModalCoberturasStyled>
  )
}

export default CoberturaSeguroPrestamista
