import styled from 'styled-components'
const roxoRiber = '#662D91'

export const CardLogin = styled.div`
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  min-width: 300px;
  margin: 20px 0;

  @media (min-width: 460px) {
    min-width: 350px;
  }
`
export const Label = styled.label`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
`

export const Input = styled.input.attrs({ type: 'text' })`
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  border-radius: 15px;
  font-size: 14px;
  line-height: 24px;
  padding: 10.5px 10px;
  &:focus {
    outline: 1px solid #007fff;
  }
`
export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  cursor: pointer;
  transform: scale(0.8);

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -2px;
    left: -2px;
    background-color: #ffffff;
    border: solid 2px #662d91;
    border-radius: 5px;
  }
  &:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -2px;
    left: -2px;
    border: solid 2px #662d91;
  }
  &:checked:after {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 10px;
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    background-color: #662d91;
    position: absolute;
    top: 3px;
    left: 3px;
  }
`

export const Link = styled.a`
  color: #474a57;
  font-family: Manrope;
  font-size: 14px;
  line-height: 16px;
  &:hover {
    color: #007fff;
  }
`

export const Button = styled.button`
  background-color: ${roxoRiber};
  border: none;
  border-radius: 15px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 18px;
  padding: 10px;
  text-aling: center;
  text-transform: uppercase;
`

export const Signup = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 15px;
  color: ${roxoRiber};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 18px;
  padding: 10px;
  text-aling: center;
  text-transform: uppercase;
`
