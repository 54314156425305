/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import InputCustom from 'components/Inputs'
import {
  FiChevronRight,
  FiChevronsRight,
  FiChevronLeft,
  FiChevronsLeft
} from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import classNames from 'classnames'

interface Option {
  id: string | number
  nome: string
  incluso: boolean
  active: boolean
  filtered: boolean
}

interface SelecaoMultiplaProps {
  options: any[]
  accessorLabel: string
  accessorValue: string
  setterValue: (e: string[]) => any
  value: any[]
  disabled?: boolean
  invalid?: boolean
  rerenderParam?: any
}

const SelecaoMultipla = ({
  options,
  accessorLabel = 'descricao',
  accessorValue = 'id',
  value,
  setterValue,
  disabled,
  invalid,
  rerenderParam
}: SelecaoMultiplaProps) => {
  const [optionsState, setOptionsState] = useState<Option[]>([])
  const [remetentes, setRemetentes] = useState<Option[]>([])
  const [receptores, setReceptores] = useState<Option[]>([])
  const [remetentesPesquisa, setRemetentesPesquisa] = useState('')
  const [receptoresPesquisa, setReceptoresPesquisa] = useState('')

  useEffect(() => {
    pesquisaRemetentes('')
    pesquisaReceptores('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rerenderParam])

  useEffect(() => {
    const optionsFormated: Option[] = []
    options?.map((option) => {
      optionsFormated.push({
        id: option[accessorValue],
        nome: option[accessorLabel],
        incluso: false,
        active: false,
        filtered: false
      })
    })
    setOptionsState(optionsFormated)
  }, [accessorLabel, accessorValue, options])

  useEffect(() => {
    setRemetentes(optionsState?.filter((e) => !e.incluso))
    setReceptores(optionsState?.filter((e) => e.incluso))
  }, [optionsState])

  useEffect(() => {
    const optionsProcessed = optionsState?.map((option) => {
      if (value.find((e) => e === option.id)) {
        return {
          ...option,
          incluso: true
        }
      }
      return {
        ...option,
        incluso: false
      }
    })
    setRemetentes([...optionsProcessed.filter((e) => !e.incluso)])
    setReceptores([...optionsProcessed.filter((e) => e.incluso)])
  }, [optionsState, value])

  function selecionaRemetente(index: number) {
    const remetenteCopy = [...remetentes]
    remetenteCopy[index].active = !remetenteCopy[index].active
    setRemetentes(remetenteCopy)
  }

  function selecionaReceptor(index: number) {
    const receptorCopy = [...receptores]
    receptorCopy[index].active = !receptorCopy[index].active
    setReceptores(receptorCopy)
  }

  function toRight() {
    const remetenteCopy = [...remetentes]
    remetenteCopy.forEach((remetente) => {
      if (remetente.active) {
        remetente.incluso = true
        remetente.active = false
        remetente.filtered = false
      }
    })
    setRemetentes(remetenteCopy.filter((e) => !e.incluso))
    setReceptores((prev) => [
      ...prev,
      ...remetenteCopy.filter((e) => e.incluso)
    ])
    const recep = [...receptores, ...remetenteCopy.filter((e) => e.incluso)]
    const valueSetter: any[] = []
    recep.map((e) => {
      if (e.incluso) {
        valueSetter.push(e.id)
      }
    })
    setterValue(valueSetter)
    pesquisaRemetentes('')
    pesquisaReceptores('')
  }

  function toAllRight() {
    const remetenteCopy = [...remetentes]
    remetenteCopy.forEach((remetente) => {
      remetente.incluso = true
      remetente.active = false
      remetente.filtered = false
    })
    setRemetentes(remetenteCopy.filter((e) => !e.incluso))
    setReceptores((prev) => [
      ...prev,
      ...remetenteCopy.filter((e) => e.incluso)
    ])
    const recep = [...receptores, ...remetenteCopy.filter((e) => e.incluso)]
    const valueSetter: any[] = []
    recep.map((e) => {
      if (e.incluso) {
        valueSetter.push(e.id)
      }
    })
    setterValue(valueSetter)
    pesquisaRemetentes('')
    pesquisaReceptores('')
  }

  function toLeft() {
    const receptoresCopy = [...receptores]
    receptoresCopy.forEach((receptores) => {
      if (receptores.active) {
        receptores.incluso = false
        receptores.active = false
        receptores.filtered = false
      }
    })
    setReceptores(receptoresCopy.filter((e) => e.incluso))
    setRemetentes((prev) => [
      ...prev,
      ...receptoresCopy.filter((e) => !e.incluso)
    ])
    const recep = [...receptoresCopy.filter((e) => e.incluso)]
    const valueSetter: any[] = []
    recep.map((e) => {
      if (e.incluso) {
        valueSetter.push(e.id)
      }
    })
    setterValue(valueSetter)
    pesquisaRemetentes('')
    pesquisaReceptores('')
  }

  function toAllLeft() {
    const receptoresCopy = [...receptores]
    receptoresCopy.forEach((receptores) => {
      receptores.incluso = false
      receptores.active = false
      receptores.filtered = false
    })
    setReceptores(receptoresCopy.filter((e) => e.incluso))
    setRemetentes((prev) => [
      ...prev,
      ...receptoresCopy.filter((e) => !e.incluso)
    ])
    const recep = [...receptoresCopy.filter((e) => e.incluso)]
    const valueSetter: any[] = []
    recep.map((e) => {
      if (e.incluso) {
        valueSetter.push(e.id)
      }
    })
    setterValue(valueSetter)
    pesquisaRemetentes('')
    pesquisaReceptores('')
  }

  function pesquisaRemetentes(value: string) {
    setRemetentesPesquisa(value)
    const valueLowerCase = value.toLowerCase()
    const remetenteCopy = [...remetentes]
    remetenteCopy.forEach((remetente) => {
      if (!remetente.nome.toLowerCase().includes(valueLowerCase)) {
        remetente.filtered = true
      } else {
        remetente.filtered = false
      }
    })
    setRemetentes(remetenteCopy)
  }

  function pesquisaReceptores(value: string) {
    setReceptoresPesquisa(value)
    const valueLowerCase = value.toLowerCase()
    const receptoresCopy = [...receptores]
    receptoresCopy.forEach((receptores) => {
      if (!receptores.nome.toLowerCase().includes(valueLowerCase)) {
        receptores.filtered = true
      } else {
        receptores.filtered = false
      }
    })
    setReceptores(receptoresCopy)
  }

  return (
    <div className="selecao-multipla">
      <Row>
        <Col md={5}>
          <div className="d-flex flex-column  w-100">
            <label className="label-12">Não fazem parte do grupo</label>
            <InputCustom
              type="text"
              placeholder="Procurar"
              onChange={(e) => {
                pesquisaRemetentes(e.target.value)
              }}
              value={remetentesPesquisa}
            />
            <div
              className={classNames(
                'remetente',
                disabled && 'disabled',
                invalid && 'invalid'
              )}
            >
              <ul id="elements-left">
                {remetentes?.map((list, i) => {
                  if (!list.filtered) {
                    return (
                      <li
                        className={classnames({
                          active: list.active && 'active'
                        })}
                        onClick={() => {
                          !disabled && selecionaRemetente(i)
                        }}
                        key={i}
                      >
                        {list.nome}
                      </li>
                    )
                  }
                })}
              </ul>
            </div>
          </div>
        </Col>
        <Col md={2}>
          <div className="btns-selecao">
            <div
              className={classNames(disabled && 'disabled')}
              onClick={() => {
                !disabled && toRight()
              }}
            >
              <FiChevronRight />
            </div>
            <div
              className={classNames(disabled && 'disabled')}
              onClick={() => {
                !disabled && toAllRight()
              }}
            >
              <FiChevronsRight />
            </div>
            <div
              className={classNames(disabled && 'disabled')}
              onClick={() => {
                !disabled && toLeft()
              }}
            >
              <FiChevronLeft />
            </div>
            <div
              className={classNames(disabled && 'disabled')}
              onClick={() => {
                !disabled && toAllLeft()
              }}
            >
              <FiChevronsLeft />
            </div>
          </div>
        </Col>
        <Col md={5}>
          <div className="d-flex flex-column w-100">
            <label className="label-12">Fazem parte do grupo</label>
            <InputCustom
              type="text"
              placeholder="Procurar"
              onChange={(e) => {
                pesquisaReceptores(e.target.value)
              }}
              value={receptoresPesquisa}
            />
            <div
              className={classNames(
                'receptor',
                disabled && 'disabled',
                invalid && 'invalid'
              )}
            >
              <ul id="elements-left">
                {receptores?.map((list, i) => {
                  if (!list.filtered) {
                    return (
                      <li
                        className={classnames({
                          active: list.active && 'active'
                        })}
                        onClick={() => {
                          !disabled && selecionaReceptor(i)
                        }}
                        key={i}
                      >
                        {list.nome}
                      </li>
                    )
                  }
                })}
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SelecaoMultipla
