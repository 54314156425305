import React, { useEffect, useState } from 'react'
import OwlCarousel, { Options } from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { CarouselWrapper } from '../style'

interface Props {
  children: JSX.Element[] | string[]
  autoplay?: boolean
  onChangeWeek?: (e: string) => void
  prev: string
  next: string
}

function CarouselWeek({ children, autoplay, prev, next, onChangeWeek }: Props) {
  const [childrenState, setchildrenState] = useState<JSX.Element[] | string[]>()

  useEffect(() => {
    setchildrenState(children)
  }, [children])

  const options: Options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: autoplay,
    dots: false,
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
      700: {
        items: 4
      },
      1000: {
        items: 5
      },
      1100: {
        items: 6
      },
      1200: {
        items: 7
      }
    }
  }

  return (
    <CarouselWrapper>
      <BsChevronLeft
        onClick={() => {
          onChangeWeek?.(prev)
        }}
        role="button"
        className="btn-icon"
      />
      {childrenState && (
        <OwlCarousel
          className="owl-theme carousel"
          loop
          margin={10}
          {...options}
        >
          {childrenState?.map((item, i) => {
            return (
              <div className="item" key={i}>
                {item}
              </div>
            )
          })}
        </OwlCarousel>
      )}
      <BsChevronRight
        onClick={() => {
          onChangeWeek?.(next)
        }}
        role="button"
        className="btn-icon"
      />
    </CarouselWrapper>
  )
}

export default CarouselWeek
