import { Reducer } from 'redux'
import { INICIO, TYPES } from './types'

const INITIAL_STATE: INICIO = {
  cpfAtendimento: '',
  quantidadeLeads: 0,
  carteiras: [],
  campanhas: [],
  loading: {
    bigNumbers: false,
    campanhas: false,
    carteiras: false
  },
  bigNumbers: {
    agendamentos: 0,
    confirmados: 0,
    naoConfirmados: 0,
    expirados: 0
  }
}

const reducer: Reducer<INICIO> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_CPF_ATENDIMENTO:
      return {
        ...state,
        cpfAtendimento: action.value
      }
    case TYPES.CLEAN_CPF_ATENDIMENTO:
      return {
        ...state,
        cpfAtendimento: INITIAL_STATE.cpfAtendimento
      }
    case TYPES.SET_QUANTIDADE_LEADS:
      return {
        ...state,
        quantidadeLeads: action.value
      }
    case TYPES.SET_CARTEIRAS:
      return {
        ...state,
        carteiras: action.value
      }
    case TYPES.SET_CAMPANHAS:
      return {
        ...state,
        campanhas: action.value
      }
    case TYPES.SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.field]: action.value
        }
      }
    case TYPES.SET_BIGNUMBERS:
      return {
        ...state,
        bigNumbers: action.value
      }
    case TYPES.CLEAN_BIGNUMBERS:
      return {
        ...state,
        bigNumbers: INITIAL_STATE.bigNumbers
      }
    default:
      return state
  }
}

export default reducer
