import styled from 'styled-components'

export const BlankSpaceContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;

  & div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    row-gap: 10px;
    width: 300px;
  }

  & .icon {
    color: #662d91;
    font-size: 60px;
  }

  & label {
    color: #353535;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  }

  & p {
    color: #353535;
    font-size: 12px;
    line-height: 16px;
  }
`
export const BtnExportacao = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  fill: #e8d4ec;
  flex-direction: row;
  justify-content: right;
  margin: 10px 0;
  line-height: 20px;
  stroke: #e8d4ec;
  text-align: right;

  & a {
    align-items: center;
    column-gap: 10px;
    display: flex;
    color: #e8d4ec;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    pointer-events: none;
  }

  &.active,
  &.active a {
    color: #662d91;
    cursor: pointer;
    fill: #662d91 !important;
    stroke: #662d91;
    pointer-events: auto;
  }

  &.active:hover,
  &.active:hover a {
    color: #816997;
    cursor: pointer;
    fill: #816997 !important;
    stroke: #816997;
    & label,
    pointer-events: auto;
    svg {
      pointer-events: none;
    }
  }
`
export const IconEventWrapper = styled.i<ColorProps>`
  align-items: center;
  background: ${({ background }) => handleColorType(background)};
  border-radius: 5px;
  color: ${({ color }) => handleColorType(color)};
  display: flex;
  justify-content: center;
  height: 18px;
  width: 18px;
  padding: 2px;
`

export const LegendasWrapper = styled.div`
  display: flex;
  grid-row-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 10px;
  width: 300px;

  & div {
    align-items: center;
    display: flex;
    gap: 4px;
  }
`

function handleColorType(color: Colors | undefined) {
  switch (color) {
    case Colors.verde:
      return 'rgb(144, 238, 144)'
    default:
      return '#fff'
  }
}

export enum Colors {
  verde = 'rgb(144, 238, 144)'
}

interface ColorProps {
  background: Colors
  color?: Colors
}
