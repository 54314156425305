import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react'
import { InvisibleInput } from '../style'

interface InvisibleInputDebouncedProps {
  value?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onDebounce: (debouncedInputValue: string) => void
  delay: number
}

export function InvisibleInputDebounced({
  value,
  onChange,
  onDebounce,
  delay
}: InvisibleInputDebouncedProps) {
  const [inputValue, setInputValue] = useState('')
  const [debouncedInputValue, setDebouncedInputValue] = useState('')
  const [isMounted, setIsMounted] = useState(false)

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value)
  }

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted) {
      const timeoutId = setTimeout(() => {
        setDebouncedInputValue(inputValue)
      }, delay)
      return () => clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  useEffect(() => {
    if (isMounted) {
      onDebounce(debouncedInputValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue])

  return (
    <InvisibleInput
      onChange={(e) => {
        handleInputChange(e)
        onChange(e)
      }}
      value={value}
    />
  )
}
