import Main from 'layouts/main'
import { TabContent, TabPane } from 'reactstrap'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { CARTEIRA } from 'store/modules/backoffice/carteira/types'

import Edit from './Tabs/Edit'
import Search from './Tabs/Search'

const Carteira = () => {
  const { activeTab } = useSelector<ApplicationState, CARTEIRA>(
    (state) => state.carteira
  )
  return (
    <Main>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="edit">
          <Edit />
        </TabPane>
        <TabPane tabId="search">
          <Search />
        </TabPane>
      </TabContent>
    </Main>
  )
}

export default Carteira
