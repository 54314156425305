import { INDICADORES, TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import * as loadingActions from 'store/modules/loading/actions'
import * as indicadoresActions from 'store/modules/indicadores/actions'
import * as indicadoresController from 'controller/indicadoresController'

function* getEmpresas(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(indicadoresController.getEmpresas)
    yield put(
      indicadoresActions.setOptionsSelect(response?.data?.content, 'empresas')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getEquipes(): SagaIterator {
  const { search }: INDICADORES = yield select(
    (state: ApplicationState) => state.indicadores
  )
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(
      indicadoresController.getEquipes,
      search.empresaId
    )
    yield put(
      indicadoresActions.setOptionsSelect(response?.data?.content, 'equipes')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getConsultores(): SagaIterator {
  const { search }: INDICADORES = yield select(
    (state: ApplicationState) => state.indicadores
  )
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(
      indicadoresController.getConsultores,
      search.equipeId
    )
    yield put(
      indicadoresActions.setOptionsSelect(
        response?.data?.content,
        'consultores'
      )
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getBigNumbers(): SagaIterator {
  const { search }: INDICADORES = yield select(
    (state: ApplicationState) => state.indicadores
  )
  try {
    yield put(indicadoresActions.setLoading(true, 'bigNumbers'))
    const response = yield call(
      indicadoresController.getBigNumbers,
      search.empresaId,
      search.equipeId,
      search.consultorId
    )
    yield put(indicadoresActions.setBigNumbers(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(indicadoresActions.setLoading(false, 'bigNumbers'))
  }
}

function* getTopEquipes(): SagaIterator {
  try {
    yield put(indicadoresActions.setLoading(true, 'topEquipes'))
    const response = yield call(indicadoresController.getTopEquipes)
    yield put(indicadoresActions.setTopEquipes(response?.data?.content))
    yield put(indicadoresActions.setVerMaisTopEquipes(false))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(indicadoresActions.setLoading(false, 'topEquipes'))
  }
}

function* getVerMaisTopEquipes(): SagaIterator {
  try {
    yield put(indicadoresActions.setLoading(true, 'topEquipes'))
    const response = yield call(indicadoresController.getVerMaisTopEquipes)
    yield put(indicadoresActions.setTopEquipes(response?.data?.content))
    yield put(indicadoresActions.setVerMaisTopEquipes(true))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(indicadoresActions.setLoading(false, 'topEquipes'))
  }
}

function* getTopEquipesConversao(): SagaIterator {
  try {
    yield put(indicadoresActions.setLoading(true, 'topEquipesConversao'))
    const response = yield call(indicadoresController.getTopEquipesConversao)
    yield put(
      indicadoresActions.setTopEquipesConversao(response?.data?.content)
    )
    yield put(indicadoresActions.setVerMaisTopEquipesConversao(false))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(indicadoresActions.setLoading(false, 'topEquipesConversao'))
  }
}

function* getVerMaisTopEquipesConversao(): SagaIterator {
  try {
    yield put(indicadoresActions.setLoading(true, 'topEquipesConversao'))
    const response = yield call(
      indicadoresController.getVerMaisTopEquipesConversao
    )
    yield put(
      indicadoresActions.setTopEquipesConversao(response?.data?.content)
    )
    yield put(indicadoresActions.setVerMaisTopEquipesConversao(true))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(indicadoresActions.setLoading(false, 'topEquipesConversao'))
  }
}

function* getTopConsultores(): SagaIterator {
  try {
    yield put(indicadoresActions.setLoading(true, 'topConsultores'))
    const response = yield call(indicadoresController.getTopConsultores)
    yield put(indicadoresActions.setTopConsultores(response?.data?.content))
    yield put(indicadoresActions.setVerMaisTopConsultores(false))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(indicadoresActions.setLoading(false, 'topConsultores'))
  }
}

function* getVerMaisTopConsultores(): SagaIterator {
  try {
    yield put(indicadoresActions.setLoading(true, 'topConsultores'))
    const response = yield call(indicadoresController.getVerMaisTopConsultores)
    yield put(indicadoresActions.setTopConsultores(response?.data?.content))
    yield put(indicadoresActions.setVerMaisTopConsultores(true))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(indicadoresActions.setLoading(false, 'topConsultores'))
  }
}

function* getTopConsultoresConversao(): SagaIterator {
  try {
    yield put(indicadoresActions.setLoading(true, 'topConsultoresConversao'))
    const response = yield call(
      indicadoresController.getTopConsultoresConversao
    )
    yield put(
      indicadoresActions.setTopConsultoresConversao(response?.data?.content)
    )
    yield put(indicadoresActions.setVerMaisTopConsultoresConversao(false))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(indicadoresActions.setLoading(false, 'topConsultoresConversao'))
  }
}

function* getVerMaisTopConsultoresConversao(): SagaIterator {
  try {
    yield put(indicadoresActions.setLoading(true, 'topConsultoresConversao'))
    const response = yield call(
      indicadoresController.getVerMaisTopConsultoresConversao
    )
    yield put(
      indicadoresActions.setTopConsultoresConversao(response?.data?.content)
    )
    yield put(indicadoresActions.setVerMaisTopConsultoresConversao(true))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(indicadoresActions.setLoading(false, 'topConsultoresConversao'))
  }
}

function* getTabulacoes(): SagaIterator {
  try {
    yield put(indicadoresActions.setLoading(true, 'tabulacoes'))
    const response = yield call(indicadoresController.getTabulacoes)
    yield put(indicadoresActions.setTabulacoes(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(indicadoresActions.setLoading(false, 'tabulacoes'))
  }
}

function* getMatrizRfm(): SagaIterator {
  const { search }: INDICADORES = yield select(
    (state: ApplicationState) => state.indicadores
  )
  try {
    yield put(indicadoresActions.setLoading(true, 'matrizRfm'))
    const response = yield call(
      indicadoresController.getMatrizRfm,
      search.empresaId,
      search.equipeId,
      search.consultorId
    )
    yield put(indicadoresActions.setMatrizRfm(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(indicadoresActions.setLoading(false, 'matrizRfm'))
  }
}

function* getEsforcoVenda(): SagaIterator {
  const { search }: INDICADORES = yield select(
    (state: ApplicationState) => state.indicadores
  )
  try {
    yield put(indicadoresActions.setLoading(true, 'esforcoVendas'))
    const response = yield call(
      indicadoresController.getEsforcoVenda,
      search.empresaId,
      search.equipeId,
      search.consultorId
    )
    yield put(indicadoresActions.setEsforcoVendas(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(indicadoresActions.setLoading(false, 'esforcoVendas'))
  }
}

export function* watchGetEmpresas() {
  yield takeEvery(TYPES.GET_EMPRESAS_SAGA, getEmpresas)
}

export function* watchGetEquipes() {
  yield takeEvery(TYPES.GET_EQUIPES_SAGA, getEquipes)
}

export function* watchGetConsultores() {
  yield takeEvery(TYPES.GET_CONSULTORES_SAGA, getConsultores)
}

export function* watchGetBigNumbers() {
  yield takeEvery(TYPES.GET_BIG_NUMBERS_SAGA, getBigNumbers)
}

export function* watchGetTopEquipes() {
  yield takeEvery(TYPES.GET_TOP_EQUIPES_SAGA, getTopEquipes)
}

export function* watchGetVerMaisTopEquipes() {
  yield takeEvery(TYPES.GET_VER_MAIS_TOP_EQUIPES_SAGA, getVerMaisTopEquipes)
}

export function* watchGetTopEquipesConversao() {
  yield takeEvery(TYPES.GET_TOP_EQUIPES_CONVERSAO_SAGA, getTopEquipesConversao)
}

export function* watchGetVerMaisTopEquipesConversao() {
  yield takeEvery(
    TYPES.GET_VER_MAIS_TOP_EQUIPES_CONVERSAO_SAGA,
    getVerMaisTopEquipesConversao
  )
}

export function* watchGetTopConsultores() {
  yield takeEvery(TYPES.GET_TOP_CONSULTORES_SAGA, getTopConsultores)
}

export function* watchGetVerMaisTopConsultores() {
  yield takeEvery(
    TYPES.GET_VER_MAIS_TOP_CONSULTORES_SAGA,
    getVerMaisTopConsultores
  )
}

export function* watchGetTopConsultoresConversao() {
  yield takeEvery(
    TYPES.GET_TOP_CONSULTORES_CONVERSAO_SAGA,
    getTopConsultoresConversao
  )
}

export function* watchGetVerMaisTopConsultoresConversao() {
  yield takeEvery(
    TYPES.GET_VER_MAIS_TOP_CONSULTORES_CONVERSAO_SAGA,
    getVerMaisTopConsultoresConversao
  )
}

export function* watchGetTabulacoes() {
  yield takeEvery(TYPES.GET_TABULACOES_SAGA, getTabulacoes)
}

export function* watchGetMatrizRfm() {
  yield takeEvery(TYPES.GET_TABULACOES_SAGA, getMatrizRfm)
}

export function* watchGetEsforcoVenda() {
  yield takeEvery(TYPES.GET_ESFORCO_VENDA_SAGA, getEsforcoVenda)
}

function* indicadores() {
  yield all([
    fork(watchGetEmpresas),
    fork(watchGetEquipes),
    fork(watchGetConsultores),
    fork(watchGetBigNumbers),
    fork(watchGetTopEquipes),
    fork(watchGetVerMaisTopEquipes),
    fork(watchGetTopEquipesConversao),
    fork(watchGetVerMaisTopEquipesConversao),
    fork(watchGetTopConsultores),
    fork(watchGetVerMaisTopConsultores),
    fork(watchGetTopConsultoresConversao),
    fork(watchGetVerMaisTopConsultoresConversao),
    fork(watchGetTabulacoes),
    fork(watchGetMatrizRfm),
    fork(watchGetEsforcoVenda)
  ])
}

export default indicadores
