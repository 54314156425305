import React from 'react'
import SimpleCard from 'components/SimpleCard'
import InputCustom from 'components/Inputs'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import CustomSelect from 'components/Select'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import {
  CREDENCIAIS,
  LoginSenha
} from 'store/modules/backoffice/credenciais/types'
import * as credenciaisActions from 'store/modules/backoffice/credenciais/actions'
import useRedux from 'hooks/useRedux'

const Bmg = () => {
  const { usuariosInstituicaoBmg, bmg } = useSelector<
    ApplicationState,
    CREDENCIAIS
  >((state) => state.credenciais)
  const { dispatch } = useRedux()

  type LoginFields = keyof LoginSenha
  function onChange(value: string, field: LoginFields) {
    dispatch(credenciaisActions.setLoginBmg(value, field))
  }

  return (
    <SimpleCard>
      <SimpleTitleCard>Usuário e senha consig BMG </SimpleTitleCard>
      <Row className="d-flex flex-column align-items-center">
        <Col md={8}>
          <label className="label-12">Usuário</label>
          <CustomSelect
            options={usuariosInstituicaoBmg}
            placeholder="Selecione o usuário"
            onChange={(e) => onChange(e, 'usuario')}
            value={bmg.usuario}
            accessorLabel="usuarioInstituicao"
            accessorValue="usuarioInstituicao"
          />
        </Col>
        <Col md={8} className="mb-3">
          <label className="label-12">Senha</label>
          <InputCustom
            type="password"
            placeholder="Digite sua senha"
            onChange={(e) => onChange(e.target.value, 'senha')}
            value={bmg.senha}
          />
        </Col>
        <Col md={4} className="d-flex justify-content-center">
          <CustomButton
            background="#662d90"
            onClick={() => {
              dispatch(
                credenciaisActions.salvarCredenciaisBmgSaga({
                  usuario: bmg.usuario,
                  senha: bmg.senha
                })
              )
              dispatch(credenciaisActions.cleanLoginBMG())
            }}
          >
            Salvar
          </CustomButton>
        </Col>
      </Row>
    </SimpleCard>
  )
}

export default Bmg
