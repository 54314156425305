import React from 'react'
import { AiOutlineHistory, AiOutlineHome } from 'react-icons/ai'
import {
  FiClipboard,
  FiBox,
  FiSliders,
  FiServer,
  FiBriefcase,
  FiBook,
  FiSend
} from 'react-icons/fi'
import { IoMdKey } from 'react-icons/io'
import {
  RiChatSmile2Line,
  RiBookOpenLine,
  RiFolderLine,
  RiFolderUploadLine,
  RiChatOffLine
} from 'react-icons/ri'
import Agenda from 'views/agenda'
import Atendimento from 'views/atendimento'
import Backoffice from 'views/backoffice'
import Produto from 'views/backoffice/menus/Produto'
import Tabulacao from 'views/backoffice/menus/Tabulacao'
import Carteira from 'views/backoffice/menus/Carteira'
import UploadArquivos from 'views/backoffice/menus/UploadArquivos'
import Historico from 'views/historico'
import Inicio from 'views/inicio'
import Producao from 'views/producao'
import Indicadores from 'views/indicadores'
import Grupos from 'views/backoffice/menus/Grupos'
import Campanhas from 'views/backoffice/menus/Campanhas'
import Blacklist from 'views/backoffice/menus/Blacklist'
import Credenciais from 'views/backoffice/menus/Credenciais'
import Indicacao from 'views/indicacao'

export interface Routes {
  title: string
  path: string
  component?: JSX.Element
  icon: JSX.Element
  subPaths?: SubPath[]
}

interface SubPath {
  index?: boolean
  title: string
  menuTitle?: string
  path?: string
  component?: JSX.Element
  icon?: JSX.Element
}

const routes: Routes[] = [
  {
    title: 'Início',
    path: '/inicio',
    component: <Inicio />,
    icon: <AiOutlineHome className="icon" />
  },
  {
    title: 'Atendimento',
    path: '/atendimento',
    component: <Atendimento />,
    icon: <RiChatSmile2Line className="icon" />
  },
  {
    title: 'Histórico',
    path: '/historico-atendimentos',
    component: <Historico />,
    icon: <AiOutlineHistory className="icon" />
  },
  {
    title: 'Agenda',
    path: '/agenda',
    component: <Agenda />,
    icon: <RiBookOpenLine className="icon" />
  },
  {
    title: 'Indicadores',
    path: '/indicadores',
    component: <Indicadores />,
    icon: <FiClipboard className="icon" />
  },
  {
    title: 'Produção',
    path: '/producao',
    component: <Producao />,
    icon: <FiBox className="icon" />
  },
  {
    title: 'Indicação',
    path: '/indicacao',
    component: <Indicacao />,
    icon: <FiSend className="icon" />
  },
  {
    title: 'Backoffice',
    path: '/backoffice',
    subPaths: [
      {
        title: 'Backoffice',
        index: true,
        component: <Backoffice />,
        icon: <RiBookOpenLine className="icon" />
      },
      {
        title: 'Backoffice/ Parametrização da tabulação',
        menuTitle: 'Parametrização da tabulação',
        path: 'tabulacao',
        component: <Tabulacao />,
        icon: <FiServer className="icon" />
      },
      {
        title: 'Backoffice/ Campanhas',
        menuTitle: 'Campanhas',
        path: 'campanhas',
        component: <Campanhas />,
        icon: <FiBriefcase className="icon" />
      },
      {
        title: 'Backoffice/ Carteira',
        menuTitle: 'Gerenciamento de carteira',
        path: 'carteira',
        component: <Carteira />,
        icon: <FiBook className="icon" />
      },
      {
        title: 'Backoffice/ Parametrização de produtos',
        menuTitle: 'Parametrização de produtos/instituições',
        path: 'produtos',
        component: <Produto />,
        icon: <FiBox className="icon" />
      },
      {
        title: 'Backoffice/ Grupos',
        menuTitle: 'Grupos',
        path: 'grupos',
        component: <Grupos />,
        icon: <RiFolderLine className="icon" />
      },
      {
        title: 'Backoffice/ Upload de arquivos',
        menuTitle: 'Upload de arquivos',
        path: 'uploadArquivos',
        component: <UploadArquivos />,
        icon: <RiFolderUploadLine className="icon" />
      },
      {
        title: 'Backoffice/ Blacklist',
        menuTitle: 'Blacklist',
        path: 'blacklist',
        component: <Blacklist />,
        icon: <RiChatOffLine className="icon" />
      },
      {
        title: 'Backoffice/ Credenciais',
        menuTitle: 'Credenciais',
        path: 'credenciais',
        component: <Credenciais />,
        icon: <IoMdKey className="icon" />
      }
    ],
    icon: <FiSliders className="icon" />
  }
]

export default routes
