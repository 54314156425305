import React from 'react'
import InputCustom from 'components/Inputs'
import { FiTrash } from 'react-icons/fi'
import { RiIncreaseDecreaseLine } from 'react-icons/ri'
import { Col, Collapse, Row } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import CardParametrizacao from './CardParametrizacao'
import { BiChevronDown } from 'react-icons/bi'
import { ApplicationState } from 'store'
import { PRODUTO } from 'store/modules/backoffice/produto/types'
import { useSelector } from 'react-redux'
import { ParametrizacaoProdutoReq } from 'models/backoffice/produto/type'
import * as produtoActions from 'store/modules/backoffice/produto/actions'
import useRedux from 'hooks/useRedux'
import { maskMoney } from 'util/masks'

const CardTrocoMinimo = () => {
  const { register, visualizacao, cardsState, invalids } = useSelector<
    ApplicationState,
    PRODUTO
  >((state) => state.produto)

  const { dispatch } = useRedux()

  type Field = keyof ParametrizacaoProdutoReq

  function onChange(value: string | number, field: Field) {
    dispatch(produtoActions.setRegister({ ...register, [field]: value }))
  }

  function onToggle() {
    dispatch(produtoActions.toggleCard(!cardsState.trocoMinimo, 'trocoMinimo'))
  }

  return (
    <CardParametrizacao>
      <div className="d-flex justify-content-between">
        <SimpleTitleCard color="#000" colorIcon="#000">
          <RiIncreaseDecreaseLine className="icon" />
          Troco mínimo
        </SimpleTitleCard>
        <BiChevronDown onClick={() => onToggle()} className="toggler" />
      </div>
      <Collapse isOpen={cardsState.trocoMinimo}>
        <div className="d-flex flex-column row-gap-10">
          <Row>
            <Col md={4}>
              <label className="label-12">De: (em R$)</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(maskMoney(e.target.value), 'trocoMin')
                }}
                value={register.trocoMin || ''}
                disabled={visualizacao}
                maxLength={14}
                invalid={invalids.trocoMin}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Até: (em R$)</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(maskMoney(e.target.value), 'trocoMax')
                }}
                value={register.trocoMax || ''}
                disabled={visualizacao}
                maxLength={14}
                invalid={invalids.trocoMax}
              />
            </Col>
          </Row>
          <div className="linha-horizontal"></div>
          {!visualizacao && (
            <Row>
              <Col className="d-flex justify-content-end">
                <FiTrash
                  onClick={() => dispatch(produtoActions.cleanTrocoMinimo())}
                  className="icon-trash"
                />
              </Col>
            </Row>
          )}
        </div>
      </Collapse>
    </CardParametrizacao>
  )
}

export default CardTrocoMinimo
