export enum TYPES {
  TOGGLE_TOKEN = '@@navbar/TOGGLE_TOKEN',
  SET_TOKEN = '@@navbar/SET_TOKEN',
  CLEAN_TOKEN = '@@navbar/CLEAN_TOKEN',
  SET_VISUALIZACAO_TOKEN = '@@navbar/SET_VISUALIZACAO_TOKEN',
  SET_NOME_USUARIO = '@@navbar/SET_NOME_USUARIO',
  SET_USUARIO_SELECTED = '@@navbar/SET_USUARIO_SELECTED',
  CLEAN_USUARIO_SELECTED = '@@navbar/CLEAN_USUARIO_SELECTED',
  SET_USUARIOS_INSTITUICAO = '@@navbar/SET_USUARIOS_INSTITUICAO',

  ATUALIZA_TOKEN_SAGA = '@@navbar/ATUALIZA_TOKEN_SAGA',
  GET_USUARIOS_INSTITUICAO_SAGA = '@@navbar/GET_USUARIOS_INSTITUICAO_SAGA'
}

export interface NAVBAR {
  token: string
  openToken: boolean
  visualizaToken: boolean
  nomeUsuario: string
  usuarioSelected: string
  usuariosInstiuicao: string[]
}
