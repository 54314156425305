import styled, { keyframes } from 'styled-components'

export const ldsEllipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`

export const ldsEllipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`

export const ldsEllipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

export const BlurContent = styled.div`
  background: rgba(255, 255, 255, 0.4);
  position: fixed;
  z-index: 100000000000;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BlurContentPermissoes = styled.div`
  background: rgba(255, 255, 255);
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    font-size: 30px;
    font-weight: 700;
  }
`

export const TopLoadingContent = styled.div`
  & .lds-ellipsis {
    display: flex;
    position: absolute;
    top: 0;
    left: calc(50% - 40px);
    z-index: 99;
    width: 80px;
    height: 80px;
  }

  & .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #662d91;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  & .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: ${ldsEllipsis1} 0.6s infinite;
  }

  & .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  & .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  & .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: ${ldsEllipsis3} 0.6s infinite;
  }
`

export const LoadingEllipsisWrapper = styled.div`
  @keyframes ellipsis {
    to {
      width: 1em;
    }
  }

  & .ellipsis:after {
    content: '...';
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 1s infinite;
    width: 0px;
  }
`
