import config from 'config'
import * as signalR from '@microsoft/signalr'

const AUTH_SESSION_KEY = 'TOKEN_KEY'

const token = sessionStorage.getItem(AUTH_SESSION_KEY)

const configuration = {
  baseURL: config.API_CRM,
  authorization: token
}

class APICoreWebSocket {
  private connection: signalR.HubConnection | undefined

  connectToHub(hubUrl: string, eventName: string) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${configuration.baseURL}${hubUrl}`, {
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: async () => {
          const token = await new Promise<string>((resolve) => {
            const item = sessionStorage.getItem(AUTH_SESSION_KEY)
            resolve(item || '')
          })
          return token
        }
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.connection.on(eventName, (body: any) => {
      return body
    })

    this.connection
      .start()
      .then(() => console.log('SignalR connection established.'))
      .catch((error) => console.log(`SignalR connection failed: ${error}`))

    return this.connection
  }
}

export { APICoreWebSocket }
