import {
  BtnWallet,
  CardWallet,
  CardWalletConsultants,
  ClientsWallet,
  NameWallet,
  TextWallet,
  TitleWallet,
  AlignWallets,
  Line
} from '../style'
import SimpleCard from 'components/SimpleCard'
import { FiServer, FiShuffle } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { setActiveTab } from 'store/modules/backoffice/carteira/actions'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import {
  CARTEIRA,
  DetailsConsultor
} from 'store/modules/backoffice/carteira/types'
import useRedux from 'hooks/useRedux'
import { BiChevronsRight } from 'react-icons/bi'
import * as carteiraActions from 'store/modules/backoffice/carteira/actions'
import { ShopListModal } from '../components/Modals/ShopListModal'
import { ClientNoConsultantsModal } from '../components/Modals/ClientNoConsultantsModal'
import { ConsultantsListModal } from '../components/Modals/ConsultantsListModal'

const Edit = () => {
  const {
    details,
    visualizacao,
    selectedLoja,
    isOpenClientNoConsultantsModal,
    isOpenConsultantsListModal,
    isOpenShopListModal
  } = useSelector<ApplicationState, CARTEIRA>((state) => state.carteira)

  const { dispatch } = useRedux()

  function voltaParaSearch() {
    dispatch(setActiveTab('search'))
    dispatch(carteiraActions.setVisualizacao(false))
  }

  function handleOpenConsultantsList(
    lojaId: string,
    consultorId: string,
    nomeConsultor: string
  ) {
    dispatch(
      carteiraActions.getClientesPorConsultorSaga({
        lojaId: lojaId,
        consultorId: consultorId
      })
    )

    dispatch(
      carteiraActions.getConsultorDispatchSaga(nomeConsultor, consultorId)
    )

    dispatch(carteiraActions.setOpenConsultantsListModal(true))
  }

  function handleOpenClientNoConsultantsModal(lojaId: string) {
    dispatch(carteiraActions.getClientesSemConsultor({ lojaId }))

    dispatch(carteiraActions.setOpenClientNoConsultantsModal(true))
  }

  function handleOpenShopListModal(lojaId: string) {
    dispatch(carteiraActions.getClientesPorLojaDispachSaga(lojaId))

    dispatch(carteiraActions.setOpenShopListModal(true))
  }

  function transferirCarteiraDeConsultor() {
    dispatch(carteiraActions.transferirCarteiraConsultor())
  }

  function dividirCarteiraEntreConsultores() {
    dispatch(carteiraActions.dividirCarteiraConsultorSaga())
  }

  function transferirCarteiraDeLoja() {
    dispatch(carteiraActions.transferirCarteiraLojaSaga())
  }

  return (
    <SimpleCard>
      <Row className="mb-4">
        <Col className="d-flex">
          <CustomButton
            background="var(--roxo-riber)"
            onClick={() => {
              voltaParaSearch()
            }}
            letterSpacing={1}
          >
            Voltar
          </CustomButton>
        </Col>
        {!visualizacao && (
          <Col className="d-flex justify-content-end">
            <BtnWallet
              onClick={() => handleOpenShopListModal(details.lojaId)}
              disabled={
                details.qtdClientesSemConsultor <= 0 &&
                !details.detalheConsultorDto.some(
                  (consultor) => consultor.qtdClientes > 0
                )
              }
            >
              <FiShuffle size={24} />
              Transferir Carteira para outra loja
            </BtnWallet>
          </Col>
        )}
      </Row>
      <ShopListModal
        isOpen={isOpenShopListModal}
        onToggle={() => {
          dispatch(carteiraActions.setOpenShopListModal(!isOpenShopListModal))
        }}
        onSelect={() => {
          transferirCarteiraDeLoja()
        }}
      />
      <div className="container-fluid d-flex flex-column row-gap-10">
        <Row>
          <Col>
            <SimpleTitleCard>
              <FiServer className="icon" />
              Gerenciamento de carteira
            </SimpleTitleCard>
          </Col>
        </Row>
        <Row className="d-flex row-gap-10">
          <Col>
            <SimpleTitleCard>*{selectedLoja.nome}</SimpleTitleCard>
          </Col>
        </Row>
        <div>
          <CardWallet>
            <NameWallet>Clientes sem consultor</NameWallet>
            <Line />
            <ClientsWallet>{details?.qtdClientesSemConsultor}</ClientsWallet>

            {!visualizacao && (
              <BtnWallet
                onClick={() =>
                  handleOpenClientNoConsultantsModal(details.lojaId)
                }
                disabled={details?.qtdClientesSemConsultor <= 0}
              >
                Dividir entre os consultores
                <BiChevronsRight size={24} />
              </BtnWallet>
            )}

            <ClientNoConsultantsModal
              isOpen={isOpenClientNoConsultantsModal}
              onToggle={() => {
                dispatch(
                  carteiraActions.setOpenClientNoConsultantsModal(
                    !isOpenClientNoConsultantsModal
                  )
                )
              }}
              onSelect={() => dividirCarteiraEntreConsultores()}
            />

            <TextWallet>
              Os clientes serão divididos igualmente entre os selecionados
            </TextWallet>
          </CardWallet>
        </div>
        <TitleWallet>Carteiras por consultor</TitleWallet>
        <AlignWallets>
          {details?.detalheConsultorDto?.map((c: DetailsConsultor) => (
            <CardWalletConsultants key={c.nomeConsultor + Math.random()}>
              <NameWallet>{c.nomeConsultor}</NameWallet>
              <Line />
              <TextWallet>Total de clientes na carteira</TextWallet>
              <ClientsWallet>{c.qtdClientes}</ClientsWallet>

              {!visualizacao && (
                <BtnWallet
                  onClick={() =>
                    handleOpenConsultantsList(
                      details.lojaId,
                      c.consultorId,
                      c.nomeConsultor
                    )
                  }
                  disabled={c?.qtdClientes <= 0}
                >
                  Transferir carteira
                  <BiChevronsRight size={24} />
                </BtnWallet>
              )}
            </CardWalletConsultants>
          ))}
          <ConsultantsListModal
            isOpen={isOpenConsultantsListModal}
            onToggle={() => {
              dispatch(
                carteiraActions.setOpenConsultantsListModal(
                  !isOpenConsultantsListModal
                )
              )
            }}
            onSelect={() => transferirCarteiraDeConsultor()}
          />
        </AlignWallets>
      </div>
    </SimpleCard>
  )
}

export default Edit
