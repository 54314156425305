import styled from 'styled-components'

export const Background = styled.div`
  background: linear-gradient(264.61deg, #87df41 -9.34%, #00ab4f 101.57%);
  box-shadow: 0px 1px 0px #e5e9f2;
  border-radius: 0 0 30px 0;
  display: flex;
  align-items: center;
  height: 50px;
  position: relative;
  top: 62px;
  width: 100%;
  position: absolute;

  span {
    align-items: center;
    color: #ffffff;
    display: flex;
    font-weight: 700;
    font-size: 14px;
    gap: 10px;
    line-height: 20px;
    position: relative;
  }

  & .icon {
    height: 20px;
    width: 20px;
  }
`
