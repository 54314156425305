import { removeMaskCPF } from 'util/masks'
import { APICore } from 'helpers/api/apiCore'
import { IndicacaoPropostaReq } from 'models/indicacao/types'

const api = new APICore()

export interface GetProps {
  dataInicial: string | null
  dataFinal: string | null
  centroCustoId: string | null
  numeroAdesao: string
  cpf: string
}

export function getAllPropostasIndicacao(
  params: GetProps,
  pagina: number,
  registros: number
) {
  return api.getWithoutUndefined(`PropostaIndicacao/propostas-indicacao`, {
    pagina: pagina + 1,
    registros,
    ...params
  })
}

export function getPropostaIndicacao(id: string) {
  return api.get(`PropostaIndicacao/${id}`, { id })
}

export function criarPropostaIndicacao(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(
    `PropostaIndicacao/inserir-proposta_indicacao?cpf=${cpfFormated}`,
    null
  )
}

export function criarPropostaIndicacaoManual(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(
    `PropostaIndicacao/inserir-proposta_indicacao_manual?cpf=${cpfFormated}`,
    null
  )
}

export function duplicarPropostaIndicacao(idProposta: string) {
  return api.create(
    `PropostaIndicacao/duplicar-proposta_indicacao?idProposta=${idProposta}`,
    null
  )
}

export function editarPropostaIndicacao(data: IndicacaoPropostaReq) {
  return api.update(`PropostaIndicacao/editar-proposta_indicacao`, data)
}

export function deletarPropostaIndicacao(idProposta: string) {
  return api.delete(
    `PropostaIndicacao/deletar-proposta_indicacao?idProposta=${idProposta}`
  )
}

export function getEquipes(digitadorId?: string, usuarioBancoId?: string) {
  return api.getWithoutUndefined(`PropostaIndicacao/lojas`, {
    digitadorId,
    usuarioBancoId
  })
}

export function getBancos(digitadorId: string | undefined) {
  return api.get(`PropostaIndicacao/bancos`, { digitadorId })
}

export function getStatus() {
  return api.get(`PropostaIndicacao/status-proposta`, null)
}

export function getCentrosCustos() {
  return api.getWithoutUndefined(`PropostaIndicacao/centros-custo`, null)
}

export function getTipoOperacao() {
  return api.get(`PropostaIndicacao/tipos-operacao`, null)
}

export function getUsuariosBancoByBancoLojaAndDigitador(
  bancoId: string,
  digitadorId: string
) {
  return api.get(`PropostaIndicacao/usuarios-banco`, {
    bancoId,
    digitadorId
  })
}
