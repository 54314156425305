import React, { useEffect } from 'react'
import { APICore } from 'helpers/api/apiCore'
import { Navigate, Route } from 'react-router-dom'
import * as loginActions from 'store/modules/login/actions'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { LOGIN } from 'store/modules/login/types'
import useRedux from 'hooks/useRedux'

interface PrivateRouteProps {
  children: JSX.Element
  redirectTo: string
}

const api = new APICore()

const PrivateRoute = ({ children, redirectTo }: PrivateRouteProps) => {
  const isAuthenticated = api.isAuthenticated()
  return isAuthenticated ? children : <Navigate to={redirectTo} />
}

function RouteGenerator() {
  const { allowedRoutes } = useSelector<ApplicationState, LOGIN>(
    (state) => state.login
  )

  const { dispatch } = useRedux()

  useEffect(() => {
    dispatch(loginActions.permissoesUsuario())
  }, [dispatch])

  const rotasPrivadas: JSX.Element[] = []

  allowedRoutes.map((route, i) => {
    rotasPrivadas.push(
      <Route
        key={i}
        path={route.path}
        element={
          route.component && (
            <PrivateRoute redirectTo="/Login">
              {<>{route.component}</>}
            </PrivateRoute>
          )
        }
      >
        {route.subPaths?.map((subPath, i) => {
          return (
            <Route
              key={i}
              index={subPath.index}
              path={subPath.path && subPath.path}
              element={
                <PrivateRoute redirectTo="/Login">
                  {<>{subPath.component}</>}
                </PrivateRoute>
              }
            />
          )
        })}
      </Route>
    )
  })

  return rotasPrivadas
}

export default RouteGenerator
