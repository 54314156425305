import { Paginacao } from 'models/types'
import {
  AcompanhamentoProducaoFieldsTabela,
  Equipe,
  PRODUCAO,
  TYPES,
  Usuario
} from './types'

type Fields = keyof PRODUCAO['filtros']
export const setFiltros = (value: string, field: Fields) => ({
  type: TYPES.SET_FILTROS,
  value,
  field
})

export const cleanFiltros = () => ({
  type: TYPES.CLEAN_FILTROS
})

export const findSaga = () => ({
  type: TYPES.FIND_SAGA
})

export const setPaginacao = (value: Paginacao) => ({
  type: TYPES.SET_PAGINATION,
  value
})

export const getEquipesSaga = (flagUsuario?: boolean) => ({
  type: TYPES.GET_EQUIPES_SAGA,
  flagUsuario
})

export const getUsuariosSaga = (lojaId?: string) => ({
  type: TYPES.GET_USUARIOS_SAGA,
  lojaId
})

export const setUsuariosOptions = (value: Usuario[]) => ({
  type: TYPES.SET_USUARIOS_OPTIONS,
  value
})

export const setEquipesOptions = (value: Equipe[]) => ({
  type: TYPES.SET_EQUIPES_OPTIONS,
  value
})

export const setTabelaAcompanhamentoProducao = (
  value: AcompanhamentoProducaoFieldsTabela[]
) => ({
  type: TYPES.SET_TABELA_ACOMPANHAMENTO_PRODUCAO,
  value
})

export const cleanTabelaAcompanhamentoProducao = () => ({
  type: TYPES.CLEAN_TABELA_ACOMPANHAMENTO_PRODUCAO
})
