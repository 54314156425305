import styled from 'styled-components'

export const CardNovaRegra = styled.div`
  background: #f9f9f9;
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;

  & .detalhe-lateral {
    background: #00ab4f;
    border-radius: 5px 0 0 5px;
    height: auto;
    width: 12px;
  }

  & .info-card {
    color: #8c8c8c;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
  }

  & .icon-trash {
    font-size: 24px;
    color: #f15a24;
    cursor: pointer;
  }

  & .card-tabela {
    border: 1px solid #cfcfcf;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    row-gap: 10px;
  }
`

export const ActionButton = styled.div`
  align-items: center;
  background-color: #662d90;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  justify-content: center;
  height: 45px;
  width: 45px;
`
export const BlankSpaceContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;

  & div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    row-gap: 10px;
    width: 300px;
  }

  & .icon {
    color: #662d91;
    font-size: 60px;
  }

  & label {
    color: #353535;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  }

  & p {
    color: #353535;
    font-size: 12px;
    line-height: 16px;
  }
`
