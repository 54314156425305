import { handlerError } from 'util/handlerError'
import { SagaIterator } from 'redux-saga'
import { call, fork, put, all, takeEvery, select } from 'redux-saga/effects'
import { HISTORICO, TYPES } from './types'
import * as historicoController from 'controller/historicoController'
import * as historicoActions from 'store/modules/historico/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { ApplicationState } from 'store'

function* getAllHistorico(): SagaIterator {
  const { pagination, search }: HISTORICO = yield select(
    (state: ApplicationState) => state.historico
  )
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(historicoController.getAllHistorico, {
      pagina: pagination.page,
      registros: pagination.pageSize,
      paramsSearch: search
    })
    yield put(
      historicoActions.setPaginacao({
        ...pagination,
        totalRegistros:
          response?.data?.content?.historicoAtendimentoPaginate?.quantidadeTotal
      })
    )
    yield put(
      historicoActions.setHistoricoList(
        response?.data?.content?.historicoAtendimentoPaginate?.registros
      )
    )
    yield put(
      historicoActions.setShowColunaLoja(response?.data?.content?.mostrarLoja)
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetAllParametrizacao() {
  yield takeEvery(TYPES.GET_ALL_HISTORICO_SAGA, getAllHistorico)
}

function* historicoSaga() {
  yield all([fork(watchGetAllParametrizacao)])
}

export default historicoSaga
