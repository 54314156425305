import React, { useEffect, useState } from 'react'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import { BsPencilFill } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import {
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { CustomButton, InfosInputs, SimpleTitleCard } from 'styles/global'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import { maskCPF, maskMoney, maskNumber, removeMaskMoney } from 'util/masks'
import { CadastroSimplificadoReq } from 'models/atendimento/types'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { validaCpf } from 'util/validations'

const CadastroSimplificado = () => {
  const {
    modalCadastroCliente,
    isOpenCadastroCliente,
    convenios,
    perfis,
    invalidsCadastroCliente,
    cpfAtendimento
  } = useSelector<ApplicationState, ATENDIMENTO>((state) => state.atendimento)

  const { dispatch } = useRedux()

  const [sessaoServidor, setSessaoServidor] = useState(false)
  const [hasMatricula, setHasMatricula] = useState(false)

  function onChange(
    value: string,
    field: atendimentoActions.CadastroClienteField
  ) {
    if (field === 'perfilId') {
      const perfil = perfis.find((perfil) => perfil.id === value)
      if (!perfil?.descricao.toUpperCase().includes('SERVIDOR')) {
        return dispatch(
          atendimentoActions.setInfosCadastroClienteModal({
            ...modalCadastroCliente,
            margem: '',
            margemCartao: '',
            convenioId: '',
            [field]: value
          })
        )
      }
    }
    if (field === 'cpf' && maskCPF(value).length === 14) {
      dispatch(atendimentoActions.getInformacoesSimplificadasSaga(value))
    }
    return dispatch(
      atendimentoActions.setInfosCadastroClienteModal({
        ...modalCadastroCliente,
        [field]: value
      })
    )
  }

  function toggleModal() {
    dispatch(
      atendimentoActions.toggleCadastroSimplificado(!isOpenCadastroCliente)
    )
  }

  useEffect(() => {
    dispatch(atendimentoActions.getConveniosSaga())
    dispatch(atendimentoActions.getPerfisSaga())
  }, [dispatch])

  useEffect(() => {
    const perfilSelecionado = perfis.find(
      (perfil) => perfil.id === modalCadastroCliente.perfilId
    )
    setSessaoServidor(
      perfilSelecionado?.descricao.toUpperCase().includes('SERVIDOR') || false
    )
    if (
      !perfilSelecionado?.descricao.toUpperCase().includes('SERVIDOR') &&
      !perfilSelecionado?.descricao.toUpperCase().includes('INSS')
    ) {
      setHasMatricula(true)
      dispatch(
        atendimentoActions.setInfosCadastroClienteModal({
          ...modalCadastroCliente,
          matricula: ''
        })
      )
    } else {
      setHasMatricula(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalCadastroCliente.perfilId, perfis])

  useEffect(() => {
    if (maskCPF(cpfAtendimento).length === 14 && isOpenCadastroCliente) {
      dispatch(
        atendimentoActions.getInformacoesSimplificadasSaga(cpfAtendimento)
      )
    }
  }, [dispatch, cpfAtendimento, isOpenCadastroCliente])

  const schema: yup.SchemaOf<CadastroSimplificadoReq> = yup.object().shape({
    convenioId: yup
      .string()
      .when('sessaoServidor', {
        is: true,
        then: yup.string().required()
      })
      .nullable(),
    cpf: yup
      .string()
      .test('test-invalid-cpf', 'cpf inválido', (cpf) => validaCpf(cpf || ''))
      .required(),
    dataNascimento: yup.string().required(),
    ddd: yup.string().required().min(2).max(3),
    margem: yup
      .number()
      .when('sessaoServidor', {
        is: true,
        then: yup.number().required().moreThan(0).lessThan(1000000000.0)
      })
      .nullable(),
    margemCartao: yup
      .number()
      .when('sessaoServidor', {
        is: true,
        then: yup.number().required().moreThan(0).lessThan(1000000000.0)
      })
      .nullable(),
    matricula: yup
      .string()
      .when('perfilId', {
        is: (perfilId: string) => {
          const perfisSemMatricula = perfis.filter(
            (perfil) =>
              !perfil.descricao.includes('SERVIDOR') &&
              !perfil.descricao.includes('INSS')
          )
          return !perfisSemMatricula.some((perfil) => perfil.id === perfilId)
        },
        then: yup.string().required()
      })
      .nullable(),
    nome: yup.string().required().min(3).max(100),
    perfilId: yup.string().required(),
    telefone: yup.string().required().min(8).max(9),
    sessaoServidor: yup.boolean().required()
  })

  function onSave() {
    dispatch(atendimentoActions.cleanInvalidCadastroClienteModal())
    schema
      .validate(
        {
          convenioId: modalCadastroCliente.convenioId,
          cpf: modalCadastroCliente.cpf,
          dataNascimento: modalCadastroCliente.dataNascimento,
          ddd: modalCadastroCliente.ddd,
          margem: Number(removeMaskMoney(modalCadastroCliente.margem)),
          margemCartao: Number(
            removeMaskMoney(modalCadastroCliente.margemCartao)
          ),
          matricula: modalCadastroCliente.matricula,
          nome: modalCadastroCliente.nome,
          perfilId: modalCadastroCliente.perfilId,
          telefone: modalCadastroCliente.telefone,
          sessaoServidor: sessaoServidor
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(atendimentoActions.salvarCadastroSimplificadoSaga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            atendimentoActions.setInvalidCadastroClienteModal(
              true,
              e.path || ''
            )
          )
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  return (
    <Modal
      size="xl"
      isOpen={isOpenCadastroCliente}
      toggle={() => toggleModal()}
    >
      <ModalBody>
        <ModalHeader toggle={() => toggleModal()}>
          <SimpleTitleCard>
            <BsPencilFill className="icon" />
            Cadastro simplificado cliente
          </SimpleTitleCard>
        </ModalHeader>
        <Row className="d-flex my-4 row-gap-15">
          <Col md={6}>
            <label className="label-12">Nome completo*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'nome')
              }}
              value={modalCadastroCliente.nome}
              invalid={invalidsCadastroCliente.nome}
              maxLength={100}
            />
          </Col>
          <Col md={6}>
            <label className="label-12">CPF*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskCPF(e.target.value), 'cpf')
              }}
              value={modalCadastroCliente.cpf}
              invalid={invalidsCadastroCliente.cpf}
              maxLength={14}
              disabled
            />
          </Col>
          <Col md={3}>
            <label className="label-12">DDD*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskNumber(e.target.value), 'ddd')
              }}
              value={modalCadastroCliente.ddd}
              invalid={invalidsCadastroCliente.ddd}
              maxLength={3}
            />
          </Col>
          <Col md={9}>
            <label className="label-12">Telefone*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskNumber(e.target.value), 'telefone')
              }}
              value={modalCadastroCliente.telefone}
              invalid={invalidsCadastroCliente.telefone}
              maxLength={9}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Data nascimento*</label>
            <InputCustom
              type="date"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'dataNascimento')
              }}
              value={modalCadastroCliente.dataNascimento}
              invalid={invalidsCadastroCliente.dataNascimento}
              maxLength={15}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Perfil*</label>
            <CustomSelect
              options={perfis}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'perfilId')
              }}
              value={modalCadastroCliente.perfilId}
              accessorLabel="descricao"
              accessorValue="id"
              invalid={invalidsCadastroCliente.perfilId}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Matrícula</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskNumber(e.target.value), 'matricula')
              }}
              value={modalCadastroCliente.matricula}
              invalid={invalidsCadastroCliente.matricula}
              maxLength={15}
              disabled={hasMatricula}
            />
            <InfosInputs>Obrigatório se for Servidor ou Inss</InfosInputs>
          </Col>
          <Collapse isOpen={sessaoServidor}>
            <Row>
              <Col md={4}>
                <label className="label-12">Convênio*</label>
                <CustomSelect
                  options={convenios}
                  placeholder="Selecione"
                  onChange={(e) => {
                    onChange(e, 'convenioId')
                  }}
                  value={modalCadastroCliente.convenioId}
                  accessorLabel="descricao"
                  accessorValue="id"
                  invalid={invalidsCadastroCliente.convenioId}
                />
              </Col>
              <Col md={4}>
                <label className="label-12">Margem*</label>
                <InputCustom
                  type="text"
                  placeholder="Digite aqui"
                  onChange={(e) => {
                    onChange(maskMoney(e.target.value), 'margem')
                  }}
                  value={modalCadastroCliente.margem}
                  invalid={invalidsCadastroCliente.margem}
                  maxLength={14}
                />
              </Col>
              <Col md={4}>
                <label className="label-12">Margem Cartão*</label>
                <InputCustom
                  type="text"
                  placeholder="Digite aqui"
                  onChange={(e) => {
                    onChange(maskMoney(e.target.value), 'margemCartao')
                  }}
                  value={modalCadastroCliente.margemCartao}
                  invalid={invalidsCadastroCliente.margemCartao}
                  maxLength={14}
                />
              </Col>
            </Row>
          </Collapse>
        </Row>
        <ModalFooter>
          <Row className="d-flex justify-content-end column-gap-20">
            <CustomButton onClick={() => toggleModal()} background="#662D91">
              Voltar
            </CustomButton>
            <CustomButton onClick={() => onSave()}>Salvar</CustomButton>
          </Row>
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}

export default CadastroSimplificado
