import { AGENDA } from 'store/modules/agenda/types'
import { Evento, TYPES } from './types'
import { Paginacao } from 'models/types'

type ActiveTab = AGENDA['activeTab']
export const setAtiveTab = (value: ActiveTab) => ({
  type: TYPES.SET_ACTIVE_TAB,
  value
})

export const setDiaDetalhamento = (value: string) => ({
  type: TYPES.SET_DIA_DETALHAMENTO,
  value
})

export const cleanDiaDetalhamento = () => ({
  type: TYPES.CLEAN_DIA_DETALHAMENTO
})

export const setAgendamentoSelecionado = (
  value: AGENDA['agendamentoSelecionado']
) => ({
  type: TYPES.SET_AGENDAMENTO_SELECIONADO,
  value
})

export const cleanAgendamentoSelecionado = () => ({
  type: TYPES.CLEAN_AGENDAMENTO_SELECIONADO
})

export const setAgendaAtendimentosEventos = (value: Evento[]) => ({
  type: TYPES.SET_AGENDA_ATENDIMENTOS_EVENTOS,
  value
})

export const cleanAgendaAtendimentosEventos = () => ({
  type: TYPES.CLEAN_AGENDA_ATENDIMENTOS_EVENTOS
})

export const setAgendaAtendimentosData = (
  value: AGENDA['agendaAtendimentosData']
) => ({
  type: TYPES.SET_AGENDA_ATENDIMENTOS_DATA,
  value
})

export const cleanAgendaAtendimentosData = () => ({
  type: TYPES.CLEAN_AGENDA_ATENDIMENTOS_DATA
})

export const getAgendaAtendimentosEventosSaga = (
  dataInicio: string,
  dataFim: string
) => ({
  type: TYPES.GET_AGENDA_ATENDIMENTOS_EVENTOS_SAGA,
  dataInicio,
  dataFim
})

export const getAgendaAtendimentosDataSaga = () => ({
  type: TYPES.GET_AGENDA_ATENDIMENTOS_DATA_SAGA
})

export const getAgendaAtendimentoDetalheSaga = (
  pessoaId: string,
  atendimentoId: string
) => ({
  type: TYPES.GET_AGENDA_ATENDIMENTO_DETALHE_SAGA,
  pessoaId,
  atendimentoId
})

export const setDetalheAgendamento = (value: AGENDA['detalheAgendamento']) => ({
  type: TYPES.SET_DETALHE_AGENDAMENTO,
  value
})

export const cleanDetalheAgendamento = () => ({
  type: TYPES.CLEAN_DETALHE_AGENDAMENTO
})

export const getDiasSemanaSaga = (data: string) => ({
  type: TYPES.GET_DIAS_SEMANA_SAGA,
  data
})

export const setDiasSemana = (value: AGENDA['diasSemana']) => ({
  type: TYPES.SET_DIAS_SEMANA,
  value
})

export const cleanDiasSemana = () => ({
  type: TYPES.CLEAN_DIAS_SEMANA
})

export const setFiltroBigNumbers = (value: AGENDA['filtroBigNumbers']) => ({
  type: TYPES.SET_FILTRO_BIGNUMBERS,
  value
})

export const cleanFiltroBigNumbers = () => ({
  type: TYPES.CLEAN_FILTRO_BIGNUMBERS
})

export const getBigNumbersSaga = (data: string) => ({
  type: TYPES.GET_BIGNUMBERS_SAGA,
  data
})

export const setBigNumbers = (value: AGENDA['bigNumbers']) => ({
  type: TYPES.SET_BIGNUMBERS,
  value
})

export const cleanBigNumbers = () => ({
  type: TYPES.CLEAN_BIGNUMBERS
})

export const getVisaoSupervisorSaga = (data: string) => ({
  type: TYPES.GET_VISAO_SUPERVISOR_SAGA,
  data
})

export const setVisaoSupervisor = (value: AGENDA['visaoSupervisor']) => ({
  type: TYPES.SET_VISAO_SUPERVISOR,
  value
})

export const setPaginacaoVisaoSupervisor = (value: Paginacao) => ({
  type: TYPES.SET_PAGINACAO_VISAO_SUPERVISOR,
  value
})

export const cleanVisaoSupervisor = () => ({
  type: TYPES.CLEAN_VISAO_SUPERVISOR
})

export const getVisaoCoordenadorSaga = (data: string) => ({
  type: TYPES.GET_VISAO_COORDENADOR_SAGA,
  data
})

export const setVisaoCoordenador = (value: AGENDA['visaoCoordenador']) => ({
  type: TYPES.SET_VISAO_COORDENADOR,
  value
})

export const setPaginacaoVisaoCoordenador = (value: Paginacao) => ({
  type: TYPES.SET_PAGINACAO_VISAO_COORDENADOR,
  value
})

export const cleanVisaoCoordenador = () => ({
  type: TYPES.CLEAN_VISAO_COORDENADOR
})

export const confirmacaoAgendamentoSaga = (agendamentoId: string) => ({
  type: TYPES.CONFIRMACAO_AGENDAMENTO_SAGA,
  agendamentoId
})

export const setDataReferencia = (value: AGENDA['dataReferencia']) => ({
  type: TYPES.SET_DATA_REFERENCIA,
  value
})

export const cleanDataReferencia = () => ({
  type: TYPES.CLEAN_DATA_REFERENCIA
})

export const getGravacaoSaga = (atendimentoId: string) => ({
  type: TYPES.GET_GRAVACAO_SAGA,
  atendimentoId
})

export const setIsOpenModalReagendamento = (value: boolean) => ({
  type: TYPES.SET_IS_OPEN_MODAL_REAGENDAMENTO,
  value
})

export const setModalReagendamento = (value: AGENDA['modalReagendamento']) => ({
  type: TYPES.SET_MODAL_REAGENDAMENTO,
  value
})

type ModalReagendamentoFields = keyof AGENDA['modalReagendamento']
export const setModalReagendamentoFields = <T extends ModalReagendamentoFields>(
  value: T extends keyof AGENDA['modalReagendamento']
    ? AGENDA['modalReagendamento'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_MODAL_REAGENDAMENTO_FIELDS,
  value,
  field
})

export const cleanModalReagendamento = () => ({
  type: TYPES.CLEAN_MODAL_REAGENDAMENTO
})

type ModalReagendamentoInvalidsFields =
  keyof AGENDA['modalReagendamentoInvalids']
export const setModalReagendamentoInvalidsFields = <
  T extends ModalReagendamentoInvalidsFields
>(
  value: T extends keyof AGENDA['modalReagendamentoInvalids']
    ? AGENDA['modalReagendamentoInvalids'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_MODAL_REAGENDAMENTO_INVALIDS_FIELDS,
  value,
  field
})

export const cleanModalReagendamentoInvalids = () => ({
  type: TYPES.CLEAN_MODAL_REAGENDAMENTO_INVALIDS
})

export const setTiposAgendamentos = (value: AGENDA['tiposAgendamentos']) => ({
  type: TYPES.SET_TIPOS_AGENDAMENTOS,
  value
})

export const getTiposAgendamentosSaga = () => ({
  type: TYPES.GET_TIPOS_AGENDAMENTOS_SAGA
})

export const editarAgendamentoSaga = () => ({
  type: TYPES.EDITAR_AGENDAMENTO_SAGA
})
