import { INDICADORES, TYPES } from './types'

type Fields = keyof INDICADORES['search']
export const setPesquisa = (value: string, field: Fields) => ({
  type: TYPES.SET_PESQUISA,
  value,
  field
})

export const cleanPesquisa = () => ({
  type: TYPES.CLEAN_PESQUISA
})

type FieldsOptionsSelect = keyof INDICADORES['optionsSelect']
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setOptionsSelect = (value: any, field: FieldsOptionsSelect) => ({
  type: TYPES.SET_OPTIONS_SELECT,
  value,
  field
})

type FieldsLoading = keyof INDICADORES['loadings']
export const setLoading = (value: boolean, field: FieldsLoading) => ({
  type: TYPES.SET_LOADINGS,
  value,
  field
})

export const getEmpresasSaga = () => ({
  type: TYPES.GET_EMPRESAS_SAGA
})

export const getEquipesSaga = () => ({
  type: TYPES.GET_EQUIPES_SAGA
})

export const getConsultoresSaga = () => ({
  type: TYPES.GET_CONSULTORES_SAGA
})

export const getBigNumbersSaga = () => ({
  type: TYPES.GET_BIG_NUMBERS_SAGA
})

export const setBigNumbers = (value: INDICADORES['bigNumbers']) => ({
  type: TYPES.SET_BIG_NUMBERS,
  value
})

export const cleanBigNumbers = () => ({
  type: TYPES.CLEAN_BIG_NUMBERS
})

export const getTopEquipesSaga = () => ({
  type: TYPES.GET_TOP_EQUIPES_SAGA
})

export const getVerMaisTopEquipesSaga = () => ({
  type: TYPES.GET_VER_MAIS_TOP_EQUIPES_SAGA
})

export const setVerMaisTopEquipes = (value: boolean) => ({
  type: TYPES.SET_VER_MAIS_TOP_EQUIPES,
  value
})

export const setTopEquipes = (value: INDICADORES['topEquipes']) => ({
  type: TYPES.SET_TOP_EQUIPES,
  value
})

export const getTopEquipesConversaoSaga = () => ({
  type: TYPES.GET_TOP_EQUIPES_CONVERSAO_SAGA
})

export const getVerMaisTopEquipesConversaoSaga = () => ({
  type: TYPES.GET_VER_MAIS_TOP_EQUIPES_CONVERSAO_SAGA
})

export const setVerMaisTopEquipesConversao = (value: boolean) => ({
  type: TYPES.SET_VER_MAIS_TOP_EQUIPES_CONVERSAO,
  value
})

export const setTopEquipesConversao = (
  value: INDICADORES['topEquipesConversao']
) => ({
  type: TYPES.SET_TOP_EQUIPES_CONVERSAO,
  value
})

export const getTopConsultoresSaga = () => ({
  type: TYPES.GET_TOP_CONSULTORES_SAGA
})

export const getVerMaisTopConsultoresSaga = () => ({
  type: TYPES.GET_VER_MAIS_TOP_CONSULTORES_SAGA
})

export const setVerMaisTopConsultores = (value: boolean) => ({
  type: TYPES.SET_VER_MAIS_TOP_CONSULTORES,
  value
})

export const setTopConsultores = (value: INDICADORES['topConsultores']) => ({
  type: TYPES.SET_TOP_CONSULTORES,
  value
})

export const getTopConsultoresConversaoSaga = () => ({
  type: TYPES.GET_TOP_CONSULTORES_CONVERSAO_SAGA
})

export const getVerMaisTopConsultoresConversaoSaga = () => ({
  type: TYPES.GET_VER_MAIS_TOP_CONSULTORES_CONVERSAO_SAGA
})

export const setVerMaisTopConsultoresConversao = (value: boolean) => ({
  type: TYPES.SET_VER_MAIS_TOP_CONSULTORES_CONVERSAO,
  value
})

export const setTopConsultoresConversao = (
  value: INDICADORES['topConsultoresConversao']
) => ({
  type: TYPES.SET_TOP_CONSULTORES_CONVERSAO,
  value
})

export const getTabulacoesSaga = () => ({
  type: TYPES.GET_TABULACOES_SAGA
})

export const setTabulacoes = (value: INDICADORES['tabulacoes']) => ({
  type: TYPES.SET_TABULACOES,
  value
})

export const getMatrizRfmSaga = () => ({
  type: TYPES.GET_MATRIZ_RFM_SAGA
})

export const setMatrizRfm = (value: INDICADORES['matrizRfm']) => ({
  type: TYPES.SET_MATRIZ_RFM,
  value
})

export const getEsforcoVendasSaga = () => ({
  type: TYPES.GET_ESFORCO_VENDA_SAGA
})

export const setEsforcoVendas = (value: INDICADORES['esforcoVendas']) => ({
  type: TYPES.SET_ESFORCO_VENDAS,
  value
})
