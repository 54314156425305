import { SagaIterator } from 'redux-saga'
import { GrupoCRMLojas, GRUPOS, ParametrizacaoGrupo, TYPES } from './types'
import { call, fork, put, all, takeEvery, select } from 'redux-saga/effects'
import { ApplicationState } from 'store'

import * as gruposController from 'controller/gruposController'
import * as gruposActions from 'store/modules/backoffice/grupos/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { handlerError } from 'util/handlerError'
import { GrupoReq } from 'models/backoffice/grupos/type'
import { toast } from 'react-toastify'

interface GenericProps {
  type: string
  id: string
}

function* getAllGrupos(): SagaIterator {
  const { pagination, limiteMaxRegistros, search }: GRUPOS = yield select(
    (state: ApplicationState) => state.grupos
  )

  try {
    yield put(loadingActions.setLoading(true))

    const response = yield call(gruposController.getAllGrupos, {
      pagina: pagination.page,
      registros: pagination.pageSize,
      limiteMaxRegistros: limiteMaxRegistros,
      descricao: search.pesquisa
    })

    yield put(
      gruposActions.setPaginacao({
        ...pagination,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )

    yield put(gruposActions.setGrupos(response?.data?.content?.registros))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* criarGrupo(): SagaIterator {
  const { register }: GRUPOS = yield select(
    (state: ApplicationState) => state.grupos
  )
  try {
    yield put(loadingActions.setLoading(true))

    const data: GrupoReq = {
      descricao: register.descricao,
      lojas: register.lojas
    }
    yield call(gruposController.criarGrupo, data)

    yield put(gruposActions.cleanRegister())

    yield put(
      gruposActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )

    yield put(gruposActions.setActiveTab('search'))
    yield put(gruposActions.setPesquisa(null, 'filtro'))
    yield put(gruposActions.setPesquisa('', 'pesquisa'))
    yield put(gruposActions.getAllGruposSaga())
    toast.success('Grupo salvo com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* visualizarGrupo({ id }: GenericProps): SagaIterator {
  yield put(loadingActions.setLoading(true))
  try {
    const response = yield call(gruposController.getGrupo, id)

    const lojasIds = response?.data?.content?.grupoCrmLojas?.map(
      (item: GrupoCRMLojas) => item.lojaId
    )

    const grupo: ParametrizacaoGrupo = {
      id: response?.data?.content?.id,
      descricao: response?.data?.content?.descricao,
      lojas: lojasIds
    }

    yield put(gruposActions.setRegister(grupo))
    yield put(gruposActions.setActiveTab('register'))
    yield put(gruposActions.setVisualizacao(true))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* atualizarGrupo(): SagaIterator {
  const { register }: GRUPOS = yield select(
    (state: ApplicationState) => state.grupos
  )
  try {
    yield put(loadingActions.setLoading(true))

    const data: GrupoReq = {
      id: register.id,
      descricao: register.descricao,
      lojas: register.lojas
    }
    yield call(gruposController.atualizarGrupo, data)

    yield put(gruposActions.cleanRegister())
    yield put(
      gruposActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )
    yield put(gruposActions.setPesquisa(null, 'filtro'))
    yield put(gruposActions.setPesquisa('', 'pesquisa'))
    yield put(gruposActions.getAllGruposSaga())
    yield put(gruposActions.setActiveTab('search'))
    toast.success('Grupo atualizado com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* editarGrupo({ id }: GenericProps): SagaIterator {
  yield put(loadingActions.setLoading(true))

  try {
    const response = yield call(gruposController.getGrupo, id)

    const lojasIds = response?.data?.content?.grupoCrmLojas?.map(
      (item: GrupoCRMLojas) => item.lojaId
    )

    const grupo: ParametrizacaoGrupo = {
      id: response?.data?.content?.id,
      descricao: response?.data?.content?.descricao,
      lojas: lojasIds
    }

    yield put(gruposActions.setRegister(grupo))
    yield put(gruposActions.setActiveTab('register'))
    yield put(gruposActions.setVisualizacao(false))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* deletarGrupo(): SagaIterator {
  const { itemSelectedId }: GRUPOS = yield select(
    (state: ApplicationState) => state.grupos
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(gruposController.deletarGrupo, itemSelectedId)
    yield put(
      gruposActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )
    yield put(gruposActions.setPesquisa(null, 'filtro'))
    yield put(gruposActions.setPesquisa('', 'pesquisa'))
    yield put(gruposActions.getAllGruposSaga())
    toast.success('Grupo excluído com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getAllLojas(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))

    const response = yield call(gruposController.getAllLojas)

    yield put(gruposActions.setLojas(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetAllGrupos() {
  yield takeEvery(TYPES.GET_ALL_GRUPOS_SAGA, getAllGrupos)
}

export function* watchCriarGrupo() {
  yield takeEvery(TYPES.POST_GRUPO_SAGA, criarGrupo)
}

export function* watchVisualizarGrupo() {
  yield takeEvery(TYPES.VISUALIZAR_GRUPO_SAGA_DISPATCH, visualizarGrupo)
}

export function* watchAtualizarGrupo() {
  yield takeEvery(TYPES.PUT_GRUPO_SAGA, atualizarGrupo)
}

export function* watchEditarGrupo() {
  yield takeEvery(TYPES.EDITAR_GRUPO_SAGA_DISPATCH, editarGrupo)
}

export function* watchDeletarGrupo() {
  yield takeEvery(TYPES.DELETE_GRUPO_SAGA, deletarGrupo)
}

export function* watchGetAllLojas() {
  yield takeEvery(TYPES.GET_ALL_LOJAS_SAGA, getAllLojas)
}

function* grupoSaga() {
  yield all([
    fork(watchGetAllGrupos),
    fork(watchVisualizarGrupo),
    fork(watchCriarGrupo),
    fork(watchEditarGrupo),
    fork(watchAtualizarGrupo),
    fork(watchDeletarGrupo),
    fork(watchGetAllLojas)
  ])
}

export default grupoSaga
