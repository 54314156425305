export enum TYPES {
  SET_CPF_ATENDIMENTO = '@@inicio/SET_CPF_ATENDIMENTO',
  CLEAN_CPF_ATENDIMENTO = '@@inicio/CLEAN_CPF_ATENDIMENTO',
  SET_QUANTIDADE_LEADS = '@@inicio/SET_QUANTIDADE_LEADS',
  SET_CARTEIRAS = '@@inicio/SET_CARTEIRAS',
  SET_CAMPANHAS = '@@inicio/SET_CAMPANHAS',
  SET_LOADING = '@@inicio/SET_LOADING',
  SET_BIGNUMBERS = '@@inicio/SET_BIGNUMBERS',
  CLEAN_BIGNUMBERS = '@@inicio/CLEAN_BIGNUMBERS',

  INICIAR_ATENDIENTO_LEAD_SAGA = '@@inicio/INICIAR_ATENDIENTO_LEAD_SAGA',
  GET_QUANTIDADE_LEADS_SAGA = '@@inicio/GET_QUANTIDADE_LEADS_SAGA',
  GET_CARTEIRAS_SAGA = '@@inicio/GET_CARTEIRAS_SAGA',
  INICIAR_ATENDIMENTO_CARTEIRAS_SAGA = '@@inicio/INICIAR_ATENDIMENTO_CARTEIRAS_SAGA',
  GET_CAMPANHAS_SAGA = '@@inicio/GET_CAMPANHAS_SAGA',
  INICIAR_ATENDIMENTO_CAMPANHAS_SAGA = '@@inicio/INICIAR_ATENDIMENTO_CAMPANHAS_SAGA',
  GET_BIGNUMBERS_SAGA = '@@inicio/GET_BIGNUMBERS_SAGA'
}

export interface INICIO {
  cpfAtendimento: string
  quantidadeLeads: number
  carteiras: CarteiraAgrupamento[]
  campanhas: Campanha[]
  loading: Loading
  bigNumbers: BigNumbers
}

export interface Carteira {
  consultorId: string
  nomeConsultor: string
  tipoProduto: number
  produto: string
  totalClientes: number
  totalClientesAcionar: number
  totalClientesNovos: number
  totalClientesPerdidos: number
  valorOportunidade: number
}

interface CarteiraAgrupamento {
  consultorId: string
  nomeConsultor: string
  carteirasConsultor: Carteira[]
}

interface Campanha {
  id: string
  nomeCampanha: string
  dataInicio: string
  dataFim: string
  descricaoTipoOperacao: string
  totalClientes: number
  totalClientesAcionar: number
  contatoPositivo: string
  conversao: string
}

interface Loading {
  bigNumbers: boolean
  carteiras: boolean
  campanhas: boolean
}

export interface BigNumbers {
  agendamentos: number
  confirmados: number
  naoConfirmados: number
  expirados: number
}
