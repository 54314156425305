import { toast } from 'react-toastify'

interface Indicacao {
  mensagem: string
  indicacao: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handlerErrorSimulacaoFgts(error: any, defaultMessage?: string) {
  if (error?.messages && !error?.stack) {
    error?.messages.map((e: Indicacao) => {
      toast.warn('FGTS: ' + e?.mensagem, {
        bodyClassName: 'text-break',
        autoClose: false
      })
    })
  } else {
    defaultMessage
      ? toast.warn(defaultMessage, {
          bodyClassName: 'text-break',
          autoClose: false
        })
      : toast.warn('FGTS: Não foi possível!', {
          bodyClassName: 'text-break',
          autoClose: false
        })
  }
}
