import { Paginacao } from 'models/types'

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum TYPES {
  SET_FILTROS = '@@producao/SET_FILTROS',
  CLEAN_FILTROS = '@@producao/CLEAN_FILTROS',
  SET_USUARIOS_OPTIONS = '@@producao/SET_USUARIOS_OPTIONS',
  SET_EQUIPES_OPTIONS = '@@producao/SET_EQUIPES_OPTIONS',
  SET_TABELA_ACOMPANHAMENTO_PRODUCAO = '@@producao/SET_TABELA_ACOMPANHAMENTO_PRODUCAO',
  CLEAN_TABELA_ACOMPANHAMENTO_PRODUCAO = '@@producao/CLEAN_TABELA_ACOMPANHAMENTO_PRODUCAO',
  SET_PAGINATION = '@@producao/SET_PAGINATION',

  FIND_SAGA = '@@producao/FIND_SAGA',
  GET_EQUIPES_SAGA = '@@producao/GET_EQUIPES_SAGA',
  GET_USUARIOS_SAGA = '@@producao/GET_USUARIOS_SAGA'
}

export interface PRODUCAO {
  filtros: FiltrosProducao
  pagination: Paginacao
  tabelaAcompanhamentoProducao: AcompanhamentoProducao[]
  usuarios: Usuario[]
  equipes: Equipe[]
}

interface FiltrosProducao {
  dataInicio: string
  dataFim: string
  equipeId: string
  digitadorId: string
  adesao: string
  cpf: string
}

export interface AcompanhamentoProducao {
  id: string
  cpf: string
  nomeCliente: string
  adesao: string
  dataCriacao: string
  valorTotal: number
  quantidadeParcelas: number
  valorParcela: number
  usuarioInstituicao: any
  status: string
  banco: string
  numeroMatricula: string
  equipe: string
  descricaoTipoOperacao: string
  nomeDigitador: string
  produto: number
  possuiSeguro: boolean
}

export interface Usuario {
  id: string
  nome: string
}

export interface Equipe {
  id: string
  nome: string
}

export interface AcompanhamentoProducaoFieldsTabela
  extends Omit<
    AcompanhamentoProducao,
    'produto' | 'valorParcela' | 'valorTotal'
  > {
  produto: string
  valorParcela: string
  valorTotal: string
}
