import { Reducer } from 'redux'
import { HISTORICO, TYPES } from './types'

const INITIAL_STATE: HISTORICO = {
  activeTab: 'search',
  search: {
    nomeAtendente: '',
    cpfCliente: '',
    Data: ''
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  visualizacao: false,
  listaHistorico: [],
  itemSelectedId: '',
  showConfirmacaoExclusao: false,
  showColunaLoja: false
}

const reducer: Reducer<HISTORICO> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.value }
    case TYPES.SET_ITEM_SELECTED_ID:
      return {
        ...state,
        itemSelectedId: action.value
      }
    case TYPES.SET_PESQUISA:
      return {
        ...state,
        search: { ...state.search, [action.field]: action.value }
      }
    case TYPES.CLEAN_PESQUISA:
      return {
        ...state,
        search: INITIAL_STATE.search
      }
    case TYPES.SET_HISTORICO_LIST:
      return {
        ...state,
        listaHistorico: action.value
      }
    case TYPES.SET_SHOW_COLUNA_LOJA:
      return {
        ...state,
        showColunaLoja: action.value
      }
    case TYPES.SET_PAGINATION:
      return {
        ...state,
        pagination: action.value
      }
    default:
      return state
  }
}

export default reducer
