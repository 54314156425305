import { SagaIterator } from 'redux-saga'
import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { setPin } from './actions'
import { TYPES } from './types'

interface GenericProps {
  type: string
}

interface PinSidebarProps extends GenericProps {
  value: boolean
}

function* pinSidebar({ value }: PinSidebarProps): SagaIterator {
  try {
    sessionStorage.setItem('PIN_SIDEBAR', `${value}`)
    yield put(setPin(value))
  } catch (error) {
    //
  }
}

export function* watchPinSidebar() {
  yield takeEvery(TYPES.PIN_SIDEBAR_SAGA, pinSidebar)
}

function* sidebarSaga() {
  yield all([fork(watchPinSidebar)])
}

export default sidebarSaga
