import { handlerError } from 'util/handlerError'
import { SagaIterator } from 'redux-saga'
import { call, fork, put, all, takeEvery, select } from 'redux-saga/effects'
import { CARTEIRA, TYPES } from './types'
import * as carteiraController from 'controller/carteiraController'
import * as carteiraActions from 'store/modules/backoffice/carteira/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { ApplicationState } from 'store'
import { toast } from 'react-toastify'

interface GenericProps {
  type: string
  id: string
}

interface GetNameConsultorProps extends GenericProps {
  name: string
}

interface GetClientesPorLojaProps extends GenericProps {
  lojaId: string
}

interface GetIdAndNameProps extends GenericProps {
  name: string
}

interface IdsProps extends GenericProps {
  value: {
    lojaId: string
    consultorId: string
  }
}

function stringToBoolean(value: string | null) {
  switch (value) {
    case 'Sim':
      return true
    case 'Nao':
      return false
    case null:
      return null
    default:
      return null
  }
}

function* getAllCarteiras(): SagaIterator {
  const { search, pagination }: CARTEIRA = yield select(
    (state: ApplicationState) => state.carteira
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield put(
      carteiraActions.setPaginacao({
        ...pagination,
        totalRegistros: 0
      })
    )
    yield put(carteiraActions.setAllCarteiras([]))
    const response = search.pesquisa
      ? yield call(
          carteiraController.getAllCarteirasWithName,
          {
            nomeLoja: search.pesquisa,
            clientesSemConsultores: stringToBoolean(
              search.clientesSemConsultores
            ),
            ativo: stringToBoolean(search.ativo)
          },
          pagination.page,
          pagination.pageSize
        )
      : yield call(
          carteiraController.getAllCarteiras,
          {
            nomeLoja: null,
            clientesSemConsultores: stringToBoolean(
              search.clientesSemConsultores
            ),
            ativo: stringToBoolean(search.ativo)
          },
          pagination.page,
          pagination.pageSize
        )
    yield put(
      carteiraActions.setPaginacao({
        ...pagination,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )
    yield put(
      carteiraActions.setAllCarteiras(response?.data?.content?.registros)
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getClientesPorConsultor({ value }: IdsProps): SagaIterator {
  const { consultantsListModal }: CARTEIRA = yield select(
    (state: ApplicationState) => state.carteira
  )
  const { lojaId, consultorId } = value
  try {
    yield put(loadingActions.setLoading(true))
    yield put(
      carteiraActions.setConsultantsListModal({
        ...consultantsListModal,
        consultores: [],
        lojaId,
        desconsiderarConsultorId: consultorId
      })
    )
    const response = yield call(carteiraController.clientConsultant, {
      lojaId,
      desconsiderarConsultorId: consultorId
    })
    yield put(
      carteiraActions.setConsultantsListModal({
        ...consultantsListModal,
        consultores: response?.data?.content,
        lojaId,
        desconsiderarConsultorId: consultorId
      })
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getClientesSemConsultor({ value }: IdsProps): SagaIterator {
  const { clientNoConsultantsModal }: CARTEIRA = yield select(
    (state: ApplicationState) => state.carteira
  )
  const { lojaId } = value
  try {
    yield put(loadingActions.setLoading(true))
    yield put(
      carteiraActions.setClientNoConsultantsModal({
        ...clientNoConsultantsModal,
        consultores: [],
        lojaId
      })
    )
    const response = yield call(carteiraController.clientConsultant, {
      lojaId
    })
    yield put(
      carteiraActions.setClientNoConsultantsModal({
        ...clientNoConsultantsModal,
        consultores: response?.data?.content,
        lojaId
      })
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getClientesPorLoja({
  lojaId
}: GetClientesPorLojaProps): SagaIterator {
  const { shopListModal }: CARTEIRA = yield select(
    (state: ApplicationState) => state.carteira
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield put(
      carteiraActions.setShopListModal({
        ...shopListModal,
        lojaId,
        lojas: []
      })
    )
    const response = yield call(carteiraController.clientStore, {
      lojaId
    })
    yield put(
      carteiraActions.setShopListModal({
        ...shopListModal,
        lojaId,
        lojas: response?.data?.content
      })
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* visualizarCarteira({
  lojaId
}: GetClientesPorLojaProps): SagaIterator {
  try {
    const response = yield call(carteiraController.detailWallet, { lojaId })
    yield put(carteiraActions.setDetailsCarteiras(response?.data?.content))
    yield put(carteiraActions.setActiveTab('edit'))
    yield put(carteiraActions.setVisualizacao(true))
  } catch (error) {
    handlerError(error)
  }
}

function* editarCarteira({ lojaId }: GetClientesPorLojaProps): SagaIterator {
  try {
    const response = yield call(carteiraController.detailWallet, { lojaId })
    yield put(carteiraActions.setDetailsCarteiras(response?.data?.content))
    yield put(carteiraActions.setActiveTab('edit'))
    yield put(carteiraActions.setVisualizacao(false))
  } catch (error) {
    handlerError(error)
  }
}

function* dividirCarteiraConsultor(): SagaIterator {
  const { clientNoConsultantsModal, selectedLoja, idsTransferencia }: CARTEIRA =
    yield select((state: ApplicationState) => state.carteira)

  yield put(loadingActions.setLoading(true))

  try {
    yield call(carteiraController.dividirCarteirasEntreConsultores, {
      ids: idsTransferencia,
      lojaId: selectedLoja.id
    })

    yield put(
      carteiraActions.editarCarteiraDispatchSaga(
        clientNoConsultantsModal.lojaId
      )
    )
    yield put(carteiraActions.setOpenClientNoConsultantsModal(false))
    toast.success('Divisão de carteira efetuada com sucesso!')
    yield put(carteiraActions.setActiveTab('edit'))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* transferirCarteiraConsultor(): SagaIterator {
  const {
    consultantsListModal,
    selectedConsultor,
    selectedLoja,
    idsTransferencia
  }: CARTEIRA = yield select((state: ApplicationState) => state.carteira)
  yield put(loadingActions.setLoading(true))

  try {
    yield call(carteiraController.transferirCarteiraConsultor, {
      ids: idsTransferencia,
      consultorAntigoId: selectedConsultor.id,
      lojaId: selectedLoja.id
    })
    yield put(
      carteiraActions.editarCarteiraDispatchSaga(consultantsListModal.lojaId)
    )
    yield put(carteiraActions.setOpenConsultantsListModal(false))
    toast.success('Transferência de carteira efetuada com sucesso!')
    yield put(carteiraActions.setActiveTab('edit'))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

/*LOJA*/
function* transferirCarteiraLoja(): SagaIterator {
  const {
    shopListModal,
    idsTransferencia,
    selectedLoja,
    pagination
  }: CARTEIRA = yield select((state: ApplicationState) => state.carteira)

  try {
    yield put(loadingActions.setLoading(true))

    yield call(carteiraController.transferirCarteiraLoja, {
      lojaId: selectedLoja.id,
      novaLojaId: idsTransferencia[0]
    })

    yield put(carteiraActions.editarCarteiraDispatchSaga(shopListModal.lojaId))
    yield put(carteiraActions.setOpenShopListModal(false))
    toast.success('Transferência de carteira efetuada com sucesso!')
    yield put(carteiraActions.setActiveTab('edit'))
    yield put(carteiraActions.setPaginacao({ ...pagination, page: 0 }))
    yield put(carteiraActions.getAllCarteirasSaga())
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getIdAndName({ name, id }: GetIdAndNameProps): SagaIterator {
  try {
    yield put(carteiraActions.setIdAndName(name, id))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getNameConsultor({ name, id }: GetNameConsultorProps): SagaIterator {
  try {
    yield put(carteiraActions.setConsultor(name, id))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* atualizaCarteiras(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    yield call(carteiraController.atualizaCarteiras)
    toast.success('Oportunidades atualizadas com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetAllCarteiras() {
  yield takeEvery(TYPES.GET_ALL_CARTEIRAS_SAGA, getAllCarteiras)
}

export function* watchGetClientesPorConsultor() {
  yield takeEvery(
    TYPES.GET_CLIENTES_POR_CONSULTOR_SAGA,
    getClientesPorConsultor
  )
}

export function* watchTransferirCarteiraConsultor() {
  yield takeEvery(
    TYPES.TRANSFERIR_CARTEIRA_CONSULTOR_SAGA,
    transferirCarteiraConsultor
  )
}

export function* watchTransferirCarteiraLoja() {
  yield takeEvery(TYPES.TRANSFERIR_CARTEIRA_LOJA_SAGA, transferirCarteiraLoja)
}

export function* watchGetClientesSemConsultor() {
  yield takeEvery(
    TYPES.GET_CLIENTES_SEM_CONSULTOR_SAGA,
    getClientesSemConsultor
  )
}

export function* watchGetClientesPorLoja() {
  yield takeEvery(TYPES.GET_CLIENTES_POR_LOJA_DISPATCH_SAGA, getClientesPorLoja)
}

export function* watchVisualizarCarteira() {
  yield takeEvery(TYPES.VISUALIZAR_CARTEIRA_DISPATCH_SAGA, visualizarCarteira)
}

export function* watchEditarCarteira() {
  yield takeEvery(TYPES.EDITAR_CARTEIRA_DISPATCH_SAGA, editarCarteira)
}

export function* watchDividirCarteiraConsultor() {
  yield takeEvery(
    TYPES.DIVIDIR_CARTEIRA_CONSULTOR_SAGA,
    dividirCarteiraConsultor
  )
}

export function* watchGetIdAndName() {
  yield takeEvery(TYPES.GET_ID_NAME_DISPATCH_SAGA, getIdAndName)
}

export function* watchGetNameConsultor() {
  yield takeEvery(TYPES.GET_CONSULTOR_DISPATCH_SAGA, getNameConsultor)
}

export function* watchAtualizaCarteiras() {
  yield takeEvery(TYPES.ATUALIZA_CARTEIRAS_SAGA, atualizaCarteiras)
}

function* CarteiraSaga() {
  yield all([
    fork(watchGetAllCarteiras),
    fork(watchVisualizarCarteira),
    fork(watchEditarCarteira),
    fork(watchGetClientesPorConsultor),
    fork(watchGetClientesPorLoja),
    fork(watchDividirCarteiraConsultor),
    fork(watchGetClientesSemConsultor),
    fork(watchTransferirCarteiraConsultor),
    fork(watchTransferirCarteiraLoja),
    fork(watchGetIdAndName),
    fork(watchGetNameConsultor),
    fork(watchAtualizaCarteiras)
  ])
}

export default CarteiraSaga
