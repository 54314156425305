import React from 'react'
import { RiProfileLine } from 'react-icons/ri'
import { SimpleTitleCard } from 'styles/global'
import { CampanhaBtn, MatrizRFMWrapper } from '../style'
import { Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { INDICADORES } from 'store/modules/indicadores/types'
import { useSelector } from 'react-redux'

const MatrizRFM = () => {
  const { matrizRfm } = useSelector<ApplicationState, INDICADORES>(
    (state) => state.indicadores
  )
  return (
    <div className="d-flex flex-column row-gap-10">
      <div className="d-flex align-items-center justify-content-between">
        <SimpleTitleCard>
          <RiProfileLine className="icon" /> Tabela da Matriz
        </SimpleTitleCard>
        <CampanhaBtn>Criar campanha</CampanhaBtn>
      </div>
      <Row className="d-flex align-items-center justify-content-center">
        <MatrizRFMWrapper>
          <div className="axis-y-content">
            <label className="label-axis-y">
              Frequência + monetariedade (média)
            </label>
            <div className="axis-y">
              <div>5</div>
              <div>4</div>
              <div>3</div>
              <div>2</div>
              <div>1</div>
            </div>
          </div>
          <div className="grid">
            <div className="area area-1">
              <div className="info">
                <span>{matrizRfm?.quantidadeNaoPodePerder}</span>
                <label>Não pode perdê-los</label>
              </div>
            </div>
            <div className="area area-2">
              <div className="info">
                <span>{matrizRfm?.quantidadeEmRisco}</span>
                <label>Em risco</label>
              </div>
            </div>
            <div className="area area-3">
              <div className="info">
                <span>{matrizRfm?.quantidadeClientesLeais}</span>
                <label>Cliente leais</label>
              </div>
            </div>
            <div className="area area-4">
              <div className="info">
                <span>{matrizRfm?.quantidadeCampeoes}</span>
                <label>Campeões</label>
              </div>
            </div>
            <div className="area area-5">
              <div className="info">
                <span>{matrizRfm?.quantidadePrecisamAtencao}</span>
                <label>Precisam de atenção</label>
              </div>
            </div>
            <div className="area area-6">
              <div className="info">
                <span>{matrizRfm?.quantidadePotenciaisClientesLeais}</span>
                <label>Potenciais clientes leais</label>
              </div>
            </div>
            <div className="area area-7">
              <div className="info">
                <span>{matrizRfm?.quantidadePerdidos}</span>
                <label>Perdidos</label>
              </div>
            </div>
            <div className="area area-8">
              <div className="info">
                <span>{matrizRfm?.quantidadeHibernando}</span>
                <label>Hibernando</label>
              </div>
            </div>
            <div className="area area-9">
              <div className="info">
                <span>{matrizRfm?.quantidadePrestesDormir}</span>
                <label>Prestes a dormir</label>
              </div>
            </div>
            <div className="area area-10">
              <div className="info">
                <span>{matrizRfm?.quantidadePromissores}</span>
                <label>Promissores</label>
              </div>
            </div>
            <div className="area area-11">
              <div className="info">
                <span>{matrizRfm?.quantidadeClientesRecentes}</span>
                <label>Clientes recentes</label>
              </div>
            </div>
          </div>
          <div className="axis-x-content">
            <div className="axis-x">
              <div>1</div>
              <div>2</div>
              <div>3</div>
              <div>4</div>
              <div>5</div>
            </div>
            <label className="label-axis-x">Recência</label>
          </div>
        </MatrizRFMWrapper>
      </Row>
    </div>
  )
}

export default MatrizRFM
