import styled from 'styled-components'
import bgLogin from 'assets/images/background-login.png'

export const Background = styled.div`
  background-image: url(${bgLogin});
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .version {
    color: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    transform: translate(-50%, 0);
  }
`
