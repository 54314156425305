import React from 'react'
import { HiChevronRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'
import { MenuItem } from '../style'

interface MenuLinkProps {
  menuTitle?: string
  path?: string
  icon?: JSX.Element
}

const MenuLink = ({ menuTitle, path, icon }: MenuLinkProps) => {
  return (
    <Col md={6}>
      <Link
        className="d-flex justify-content-between align-items-center text-decoration-none"
        to={path || ''}
      >
        <MenuItem>
          {icon}
          <span>{menuTitle}</span>
        </MenuItem>
        <HiChevronRight color="#000" />
      </Link>
    </Col>
  )
}

export default MenuLink
