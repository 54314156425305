/* eslint-disable @typescript-eslint/no-explicit-any */
import { Paginacao } from 'models/types'

export enum TYPES {
  SET_ACTIVE_TAB = '@@historico/SET_ACTIVE_TAB',
  SET_ITEM_SELECTED_ID = '@@historico/SET_ITEM_SELECTED_ID',
  SET_PESQUISA = '@@historico/SET_PESQUISA',
  CLEAN_PESQUISA = '@@historico/CLEAN_PESQUISA',
  SET_HISTORICO_LIST = '@@historico/SET_HISTORICO_LIST',
  SET_SHOW_COLUNA_LOJA = '@@historico/SET_SHOW_COLUNA_LOJA',
  SET_PAGINATION = '@@historico/SET_PAGINATION',

  GET_ALL_HISTORICO_SAGA = '@@historico/GET_ALL_HISTORICO_SAGA'
}

interface Search {
  nomeAtendente: string
  cpfCliente: string
  Data: string
}

export interface HISTORICO {
  activeTab: string
  search: Search
  pagination: Paginacao
  visualizacao: boolean
  listaHistorico: HistoricoList[]
  itemSelectedId: string
  showConfirmacaoExclusao: boolean
  showColunaLoja: boolean
}

export interface HistoricoList {
  id: string
  nomeAtendente: string
  nomePessoa: string
  matricula: string
  cpfCliente: string
  dataAtendimento: string
}

export interface Selects {
  produtos: any[]
}

export interface ParametrizacaoList {
  id: string
  descricaoProduto: string
  descricaoBanco: string
  descricaoConvenio: string
  descricaoTipoOperacao: string
  descricaoTipoFormalizacao: string
}
