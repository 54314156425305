import { CREDENCIAIS } from './modules/backoffice/credenciais/types'
import { createStore, Store, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './modules/sagas'
import rootReducer from './modules/rootReducer'
import { SIDEBAR } from './modules/sidebar/types'
import { ATENDIMENTO } from './modules/atendimento/types'
import { LOADING } from './modules/loading/types'
import { TABULACAO } from './modules/backoffice/tabulacao/types'
import { LOGIN } from './modules/login/types'
import { PRODUCAO } from './modules/producao/types'
import { PRODUTO } from './modules/backoffice/produto/types'
import { INICIO } from './modules/inicio/types'
import { HISTORICO } from './modules/historico/types'
import { CARTEIRA } from './modules/backoffice/carteira/types'
import { UPLOAD_ARQUIVOS } from './modules/backoffice/uploadArquivos/types'
import { GRUPOS } from './modules/backoffice/grupos/types'
import { BLACKLIST } from './modules/backoffice/blacklist/types'
import { NAVBAR } from './modules/navbar/types'
import { AGENDA } from './modules/agenda/types'
import { INDICADORES } from './modules/indicadores/types'
import { CAMPANHA } from './modules/backoffice/campanha/types'
import { INDICACAO } from './modules/indicacao/types'

export interface ApplicationState {
  login: LOGIN
  sidebar: SIDEBAR
  atendimento: ATENDIMENTO
  loading: LOADING
  tabulacao: TABULACAO
  uploadArquivos: UPLOAD_ARQUIVOS
  carteira: CARTEIRA
  producao: PRODUCAO
  produto: PRODUTO
  inicio: INICIO
  historico: HISTORICO
  grupos: GRUPOS
  blacklist: BLACKLIST
  navbar: NAVBAR
  agenda: AGENDA
  credenciais: CREDENCIAIS
  indicadores: INDICADORES
  campanha: CAMPANHA
  indicacao: INDICACAO
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]
let store: Store

function configureStore() {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const localstore: Store<ApplicationState> = createStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(...middlewares))
  )

  sagaMiddleware.run(rootSaga)
  store = localstore
  return store
}

export type AppDispatch = typeof store.dispatch

export default configureStore()
