import {
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
  GridValueGetterParams
} from '@mui/x-data-grid'
import { Row } from 'react-data-grid'
import { AiOutlineEye } from 'react-icons/ai'
import { FiTrash } from 'react-icons/fi'
import { HiOutlinePencil } from 'react-icons/hi'
import * as tabulacaoActions from 'store/modules/backoffice/tabulacao/actions'

const headerTheme = 'table-header--purple'

export const COLUMNS: GridColumns<Row> = [
  {
    field: 'descricao',
    headerName: 'Descrição',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'localizacao',
    headerName: 'Localização',
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.localizacao?.descricao || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'polaridade',
    headerName: 'Polaridade',
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.polaridade?.descricao || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'periodoAgendamento',
    headerName: 'Agendamento',
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.periodoAgendamento?.descricao || 'Não informado'}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'actions',
    headerName: 'Ações',
    headerClassName: headerTheme,
    type: 'actions',
    align: 'center',
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key={params.row.id}
        icon={<AiOutlineEye />}
        onClick={() => {
          tabulacaoActions.visualizarTabulacaoSagaDispatch(params.row.id)
        }}
        label="Visualizar"
      />,
      <GridActionsCellItem
        key={params.row.id}
        icon={<HiOutlinePencil />}
        onClick={() => {
          tabulacaoActions.editarTabulacaoSagaDispatch(params.row.id)
        }}
        label="Editar"
      />,
      <GridActionsCellItem
        key={params.row.id}
        icon={<FiTrash />}
        onClick={() => {
          tabulacaoActions.setItemSelectedIdDispatch(params.row.id)
          tabulacaoActions.toggleModalExclusaoDispatch(true)
        }}
        label="Deletar"
      />
    ]
  }
]
