import React from 'react'
import SelecaoMultipla from 'components/SelecaoMultipla'
import { FiTrash } from 'react-icons/fi'
import { RiIncreaseDecreaseLine } from 'react-icons/ri'
import { Col, Collapse, Row } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import CardParametrizacao from './CardParametrizacao'
import { BiChevronDown } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { PRODUTO } from 'store/modules/backoffice/produto/types'
import InputCustom from 'components/Inputs'
import * as produtoActions from 'store/modules/backoffice/produto/actions'
import { ParametrizacaoProdutoReq } from 'models/backoffice/produto/type'
import useRedux from 'hooks/useRedux'

const CardGruposBeneficio = () => {
  const { register, selects, visualizacao, cardsState, invalids } = useSelector<
    ApplicationState,
    PRODUTO
  >((state) => state.produto)

  const { dispatch } = useRedux()

  type Field = keyof ParametrizacaoProdutoReq

  function onChange(value: string | string[], field: Field) {
    dispatch(produtoActions.setRegister({ ...register, [field]: value }))
  }

  function onToggle() {
    dispatch(
      produtoActions.toggleCard(!cardsState.gruposBeneficio, 'gruposBeneficio')
    )
  }

  return (
    <CardParametrizacao>
      <div className="d-flex justify-content-between">
        <SimpleTitleCard color="#000" colorIcon="#000">
          <RiIncreaseDecreaseLine className="icon" />
          Grupos de benefício/matrícula
        </SimpleTitleCard>
        <BiChevronDown onClick={() => onToggle()} className="toggler" />
      </div>
      <Collapse isOpen={cardsState.gruposBeneficio}>
        <div className="d-flex flex-column row-gap-10">
          <Row>
            <Col md={6}>
              <label className="label-12">Grupo</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'descricaoGrupoBeneficio')
                }}
                value={register.descricaoGrupoBeneficio || ''}
                disabled={visualizacao}
                maxLength={100}
                invalid={invalids.descricaoGrupoBeneficio}
              />
            </Col>
          </Row>
          <SelecaoMultipla
            accessorLabel="descricao"
            accessorValue="id"
            options={selects.beneficios}
            value={register.beneficios || []}
            setterValue={(e) => onChange(e, 'beneficios')}
            disabled={visualizacao}
          />
          <div className="linha-horizontal"></div>
          {!visualizacao && (
            <Row>
              <Col className="d-flex justify-content-end">
                <FiTrash
                  onClick={() =>
                    dispatch(produtoActions.cleanGruposBeneficio())
                  }
                  className="icon-trash"
                />
              </Col>
            </Row>
          )}
        </div>
      </Collapse>
    </CardParametrizacao>
  )
}

export default CardGruposBeneficio
