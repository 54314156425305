import styled from 'styled-components'

export const Tab = styled.li`
  font-family: 'Manrope', serif;
  color: #662d91 !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 9px;
  padding: 0.5rem 1rem;

  & .close {
    color: #bfbfbf;
    width: 12px;
    height: 12px;
    text-align: right;
  }

  & a {
    align-items: center;
    color: #353535;
    display: flex;
    font-family: 'Open Sans', serif;
    font-size: 14px;
    font-weight: 400;
    justify-content: space-between;
    line-height: 24px;
    min-width: 110px;
    text-decoration: none;
  }

  & a.active {
    border-bottom: #00ab4f solid 4px;
  }
`

export const Ul = styled.ul`
  border-bottom: 1px solid #cfcfcf;
`
