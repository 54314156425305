import styled from 'styled-components'

export const MenuItem = styled.div`
  align-items: center;
  color: #474a57;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 12px;
  gap: 15px;
  justify-content: start;
  line-height: 24px;

  & .icon {
    font-size: 25px;
    color: #662d91;
  }
`
