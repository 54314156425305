import React, { useEffect, useState } from 'react'
import CustomTable from 'components/CustomTable'
import { FiUser } from 'react-icons/fi'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import { CustomButton } from 'styles/global'
import { COLUMNS_RESUMO_PRODUTOS } from 'views/atendimento/constants/columns'
import { ApresentacaoValores } from 'views/atendimento/style'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { maskMoney, removeMaskMoney } from 'util/masks'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import BeneficiosTabs from '../BeneficiosTabsResumo'
import SimpleTab from 'components/SimpleTab/SimpleTab'
import { generateGuid } from 'util/generateGuid'

interface ResumoContratacaoProps {
  isOpen: boolean
  onToggle: () => void
  index: number
}

const ResumoContratacao = ({
  isOpen,
  onToggle,
  index
}: ResumoContratacaoProps) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  const [beneficios, setBeneficios] = useState<
    {
      id: number
      descricao: string
      component: JSX.Element
    }[]
  >([])

  useEffect(() => {
    dispatch(
      atendimentoActions.setSomatorioResumo(
        atendimentosAbertos[index].tabProduto.produtosSelecionados
          .map((produto) => {
            if (
              produto.servico === 5 ||
              produto.servico === 6 ||
              produto.servico === 7 ||
              produto.servico === 8 ||
              produto.servico === 12
            ) {
              if (!produto.flagSaqueAutorizado && !produto.flagSaqueParcelado) {
                return 0
              } else {
                return Number(removeMaskMoney(produto.valorSaque))
              }
            } else if (produto.servico === 2 && produto.clienteElegivel) {
              const codigoTabelaSelected = atendimentosAbertos[
                index
              ].tabProduto.listCodigoTabelaSelectedByCodigoSimulacao?.find(
                (item) => item.id === produto.codigoSimulacao
              )?.value

              const parcelasSelected = atendimentosAbertos[
                index
              ].tabProduto.listParcelasSelectedByCodigoSimulacao?.find(
                (item) => item.id === produto.codigoSimulacao
              )?.value

              const indexTabelaSelected =
                produto.listProdutos?.findIndex(
                  (p) => p.codigo === codigoTabelaSelected
                ) ?? 0

              const indexParcelaSelected =
                produto.listProdutos?.[
                  indexTabelaSelected
                ]?.listParcelas.findIndex(
                  (p) => p.parcelas === parcelasSelected
                ) ?? 0

              return (
                produto.listProdutos[indexTabelaSelected]?.listParcelas[
                  indexParcelaSelected
                ]?.valorLiberado ?? 0
              )
            } else if (
              produto.servico === 3 &&
              produto.contratosPortabilidade?.some(
                (contrato) => contrato.clienteElegivel
              )
            ) {
              let valorTotalFinanciado = 0
              const { contratosPortabilidade } = produto

              if (contratosPortabilidade) {
                contratosPortabilidade
                  .filter(
                    (contratoPortabilidade) =>
                      contratoPortabilidade.clienteElegivel
                  )
                  .forEach((contratoPortabilidade) => {
                    contratoPortabilidade.listProdutosPortabilidade
                      .filter(
                        (produtoPortabilidade) =>
                          produtoPortabilidade.selecionado
                      )
                      .forEach((produtoPortabilidade) => {
                        valorTotalFinanciado +=
                          produtoPortabilidade.produtosAssociados
                            .filter(
                              (produtoAssociado) => produtoAssociado.selecionado
                            )
                            .reduce((acc, produtoAssociado) => {
                              return (
                                acc +
                                (produtoAssociado.valorTotalFinanciado ?? 0)
                              )
                            }, 0)
                      })
                  })
              }
              return valorTotalFinanciado
            } else {
              return produto.valorTotalFinanciado
            }
          })
          .reduce((prev, curr) => prev + curr, 0),
        index
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen, index])

  useEffect(() => {
    const matriculas: typeof beneficios = []
    atendimentosAbertos[index].tabProduto.infosClienteResumo.matriculas?.map(
      (matricula, i) => {
        matriculas.push({
          id: i,
          descricao: matricula.descricaoPerfil || 'Descrição indisponível',
          component: (
            <BeneficiosTabs
              matricula={
                atendimentosAbertos[index].tabProduto.infosClienteResumo
                  .matriculas?.[i]
              }
              indexPerfil={i}
              index={index}
            />
          )
        })
      }
    )
    setBeneficios(matriculas)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabProduto.infosClienteResumo.matriculas])

  function mudaTabInformacoes() {
    dispatch(atendimentoActions.setTabAtendimentoActive(3, index))
    onToggle()
  }

  useEffect(() => {
    if (isOpen) {
      dispatch(
        atendimentoActions.getResumoContratacaoSaga(
          atendimentosAbertos[index].cpf,
          index
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen])

  function concluirContratacao() {
    dispatch(
      atendimentoActions.finalizacaoContratacao({
        cpf: atendimentosAbertos[index].cpf,
        index: index
      })
    )
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={() => onToggle()}>
      <ModalBody>
        <div className="container-fluid d-flex flex-column row-gap-10">
          <Row className="d-flex justify-content-between">
            <Col md={4}>
              <div
                className="btn-outline-purple justify-content-center"
                onClick={() => {
                  mudaTabInformacoes()
                }}
              >
                Voltar e alterar
              </div>
            </Col>
            <Col md={4}>
              <CustomButton
                onClick={() => concluirContratacao()}
                width={'100%'}
              >
                Concluir contratação
              </CustomButton>
            </Col>
          </Row>
          <ApresentacaoValores>
            <Col lg={1}>
              <FiUser className="icon" />
            </Col>
            <Col className="d-flex flex-column row-gap-10" md={8}>
              <label>Resumo da contratação</label>
              <span>
                {atendimentosAbertos[index].tabProduto.infosClienteResumo.nome}
              </span>
            </Col>
            <Col className="d-flex flex-column align-items-start" md={3}>
              <span className="valor-contratacao">
                R$
                {maskMoney(
                  atendimentosAbertos[index].tabProduto.somatorioResumo.toFixed(
                    2
                  )
                )}
              </span>
              <span>Total da contratação</span>
            </Col>
          </ApresentacaoValores>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Nome</label>
              <span>
                {atendimentosAbertos[index].tabProduto.infosClienteResumo.nome}
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Gênero</label>
              <span>
                {atendimentosAbertos[index].tabProduto.infosClienteResumo.sexo}
              </span>
            </Col>
          </Row>
          <div className="linha-horizontal"></div>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">RG</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .numeroIdentidade
                }
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Orgão emissor</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .emissorIdentidade
                }
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">UF emissor</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .ufIdentidade
                }
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Data de expedição</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .dataEmissao
                }
              </span>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">CPF</label>
              <span>
                {atendimentosAbertos[index].tabProduto.infosClienteResumo.cpf}
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Data de nascimento</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .dataNascimento
                }
              </span>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Cidade de nascimento</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .cidadeNascimento
                }
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">UF nascimento</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .ufNascimento
                }
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Nacionalidade</label>
              <span>Brasileira</span>
            </Col>
          </Row>
          <div className="linha-horizontal"></div>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Escolaridade</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .grauInstrucao
                }
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Estado civil</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .estadoCivil
                }
              </span>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Nome do Cônjuge</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .nomeConjuge
                }
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Nome da mãe</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .nomeMae
                }
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Nome do pai</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .nomePai
                }
              </span>
            </Col>
          </Row>
          <div className="linha-horizontal"></div>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">CEP</label>
              <span>
                {atendimentosAbertos[index].tabProduto.infosClienteResumo.cep}
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Número</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .numeroEndereco
                }
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Complemento</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .complemento
                }
              </span>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Bairro</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .bairro
                }
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Cidade</label>
              <span>
                {
                  atendimentosAbertos[index].tabProduto.infosClienteResumo
                    .cidade
                }
              </span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">UF</label>
              <span>
                {atendimentosAbertos[index].tabProduto.infosClienteResumo.uf}
              </span>
            </Col>
          </Row>
          <SimpleTab tabs={beneficios} />
          <CustomTable
            data={atendimentosAbertos[
              index
            ].tabProduto.produtosSelecionados.flatMap((produto) => {
              if (produto.servico === 2) {
                const parcelasSelected = atendimentosAbertos[
                  index
                ].tabProduto.listParcelasSelectedByCodigoSimulacao.find(
                  (item) => item.id === produto.codigoSimulacao
                )?.value
                const codigoTabelaSelected = atendimentosAbertos[
                  index
                ].tabProduto.listCodigoTabelaSelectedByCodigoSimulacao.find(
                  (item) => item.id === produto.codigoSimulacao
                )?.value

                const indexTabelaSelected =
                  produto.listProdutos?.findIndex(
                    (p) => p.codigo === codigoTabelaSelected
                  ) ?? 0

                const indexParcelaSelected =
                  produto.listProdutos?.[
                    indexTabelaSelected
                  ]?.listParcelas.findIndex(
                    (p) => p.parcelas === parcelasSelected
                  ) ?? 0

                const valorTotalLiberado =
                  produto.listProdutos?.[indexTabelaSelected]?.listParcelas[
                    indexParcelaSelected
                  ]?.valorLiberado

                return [
                  {
                    ...produto,
                    quantidadeParcelas: parcelasSelected ?? 0,
                    valorTotalFinanciado: valorTotalLiberado ?? 0
                  }
                ]
              }
              if (produto.servico === 3) {
                return (
                  produto.contratosPortabilidade
                    ?.filter(
                      (contratoPortabilidade) =>
                        contratoPortabilidade.clienteElegivel
                    )
                    .map((contratoPortabilidade) => {
                      const portabilidade =
                        contratoPortabilidade.listProdutosPortabilidade.find(
                          (produtoPortabilidade) =>
                            produtoPortabilidade.selecionado
                        )

                      return {
                        id: generateGuid(),
                        servico: produto.servico,
                        banco: portabilidade?.nomeBanco ?? '',
                        matricula: produto.matricula,
                        valorTotalFinanciado:
                          portabilidade?.produtosAssociados.find(
                            (produtoAssociado) => produtoAssociado.selecionado
                          )?.valorTotalFinanciado,
                        quantidadeParcelas:
                          portabilidade?.produtosAssociados.find(
                            (produtoAssociado) => produtoAssociado.selecionado
                          )?.listParcelas[0], //Alterar para pegar a parcela selecionada pelo usuário
                        valorParcela: portabilidade?.produtosAssociados.find(
                          (produtoAssociado) => produtoAssociado.selecionado
                        )?.valorParcela
                      }
                    }) || []
                )
              }
              if (produto.servico === 6) {
                const planoSeguro = atendimentosAbertos[
                  index
                ].tabProduto.listPlanoSeguroPrestamistaCartaoConsignadoSelecionadoByMatricula.find(
                  (item) => item.matricula === produto.matricula
                )

                return [
                  {
                    ...produto,
                    valorPremio: planoSeguro?.valorPremio ?? 0
                  }
                ]
              }
              if (produto.servico === 7) {
                const planoSeguro = atendimentosAbertos[
                  index
                ].tabProduto.listPlanoSeguroPrestamistaCartaoBeneficioSelecionadoByMatricula.find(
                  (item) => item.matricula === produto.matricula
                )

                return [
                  {
                    ...produto,
                    valorPremio: planoSeguro?.valorPremio ?? 0
                  }
                ]
              }
              if (produto.servico === 8) {
                const planoSeguro = atendimentosAbertos[
                  index
                ].tabProduto.listPlanoSeguroPrestamistaBeneficioSelecionadoByMatricula.find(
                  (item) => item.matricula === produto.matricula
                )

                return [
                  {
                    ...produto,
                    valorPremio: planoSeguro?.valorPremio ?? 0
                  }
                ]
              }
              return [produto]
            })}
            columns={COLUMNS_RESUMO_PRODUTOS}
            paginationMode="client"
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ResumoContratacao
