import React, { useEffect } from 'react'
import logo from 'assets/images/logo-crm.png'
import jwtDecode from 'jwt-decode'
import { TokenJwt } from 'models/types'
import { Collapse, Input, InputGroup, InputGroupText } from 'reactstrap'
import { GrSecure } from 'react-icons/gr'
import {
  AiOutlineCheckCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible
} from 'react-icons/ai'
import { AtualizacaoTokenWrapper, drawerWidth } from 'styles/global'
import { NAVBAR } from 'store/modules/navbar/types'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import * as navbarActions from 'store/modules/navbar/actions'
import * as yup from 'yup'
import useRedux from 'hooks/useRedux'
import { maskNumber } from 'util/masks'
import { toast } from 'react-toastify'
import CustomSelect from 'components/Select'
import * as loginActions from 'store/modules/login/actions'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { SIDEBAR } from 'store/modules/sidebar/types'
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  styled
} from '@mui/material'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  width: `calc(100% - ${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(100% - ${theme.spacing(8)} + 1px)`,
    position: 'absolute'
  },
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: 'calc(1050 - 1) !important',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px) !important`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

function Navbar() {
  const {
    token,
    openToken,
    visualizaToken,
    nomeUsuario,
    usuariosInstiuicao,
    usuarioSelected
  } = useSelector<ApplicationState, NAVBAR>((state) => state.navbar)

  const { isOpen: isOpenSidebar } = useSelector<ApplicationState, SIDEBAR>(
    (state) => state.sidebar
  )

  const AUTH_SESSION_KEY = 'TOKEN_KEY'

  const { dispatch } = useRedux()

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  useEffect(() => {
    const token = sessionStorage.getItem(AUTH_SESSION_KEY)
    const tokenDecode: TokenJwt = jwtDecode(token || '')
    if (tokenDecode.unique_name) {
      const nomeFormated = tokenDecode.unique_name.split(' ')
      dispatch(
        navbarActions.setNomeUsuario(
          `${nomeFormated[0] || ''} ${nomeFormated[1] || ''}`
        )
      )
    }
    dispatch(navbarActions.getUsuariosInstituicaoSaga())
  }, [dispatch])

  function toggleToken() {
    dispatch(navbarActions.toggleToken())
  }

  function onChangeToken(value: string) {
    dispatch(navbarActions.setToken(value))
  }

  function onChangeUsuario(value: string) {
    dispatch(navbarActions.setUsuarioSelected(value))
  }

  function setVisualizacaoToken() {
    dispatch(navbarActions.visualizaToken())
  }

  function logout() {
    dispatch(loginActions.logout())
  }

  function atualizaToken() {
    schema
      .validate(
        {
          token: token,
          usuarioInstituicao: usuarioSelected
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(navbarActions.atualizaTokenSaga())
      })
      .catch((err) => {
        let invalids = {
          token: false,
          usuarioInstituicao: false
        }
        err.inner.forEach((e: yup.ValidationError) => {
          invalids = {
            ...invalids,
            [e.path as string]: true
          }
        })
        if (invalids.usuarioInstituicao) {
          toast.warn('Selecione o usuário')
        }
        if (invalids.token) {
          toast.warn('Token inválido/incorreto')
        }
      })
  }

  const schema: yup.AnySchema = yup.object().shape({
    token: yup.string().required().length(6),
    usuarioInstituicao: yup.string().required()
  })

  return (
    <header>
      <AppBar
        color="inherit"
        position="fixed"
        open={isOpenSidebar}
        elevation={0}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <a className="navbar-brand" href="/">
              <img src={logo} alt="Logo navbar RiberCred" width={80} />
            </a>
          </Box>
          <Box sx={{ flexGrow: 0, display: 'flex', columnGap: '40px' }}>
            <AtualizacaoTokenWrapper>
              <Tooltip title="Atualização token">
                <IconButton
                  id="btn-toggle-token"
                  role="button"
                  onClick={() => {
                    toggleToken()
                  }}
                >
                  <GrSecure />
                </IconButton>
              </Tooltip>
              <Collapse isOpen={openToken} horizontal>
                <div className="d-flex align-items-center gap-1">
                  <CustomSelect
                    options={usuariosInstiuicao}
                    accessorLabel="usuarioInstituicao"
                    accessorValue="usuarioInstituicao"
                    onChange={(e) => {
                      onChangeUsuario(e)
                    }}
                    value={usuarioSelected}
                    width={400}
                  />
                  <InputGroup>
                    <Input
                      type={visualizaToken ? 'text' : 'password'}
                      placeholder="Digite aqui"
                      onChange={(e) => {
                        onChangeToken(maskNumber(e.target.value))
                      }}
                      value={token}
                      maxLength={6}
                      autoComplete="off"
                    />
                    <InputGroupText
                      onClick={() => {
                        setVisualizacaoToken()
                      }}
                      role="button"
                    >
                      {visualizaToken ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </InputGroupText>
                  </InputGroup>
                  <IconButton onClick={() => atualizaToken()}>
                    <AiOutlineCheckCircle className="btn-envio" />
                  </IconButton>
                </div>
              </Collapse>
            </AtualizacaoTokenWrapper>
            <div>
              <div className="d-flex align-items-center gap-2">
                <Typography
                  sx={{
                    display: {
                      xl: 'initial',
                      lg: 'initial',
                      md: 'initial',
                      sm: 'none',
                      xs: 'none'
                    }
                  }}
                >
                  {nomeUsuario}
                </Typography>
                <Tooltip title="Abrir menu de usuário">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={nomeUsuario}
                      src="*"
                      sx={{ background: 'var(--verdeGradiente-riber)' }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={logout}>
                  <Typography textAlign="center">Sair</Typography>
                </MenuItem>
              </Menu>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </header>
  )
}

export default Navbar
