import { handlerError } from 'util/handlerError'
import { SagaIterator } from 'redux-saga'
import { call, fork, put, all, takeEvery, select } from 'redux-saga/effects'
import {
  AcompanhamentoProducao,
  AcompanhamentoProducaoFieldsTabela,
  PRODUCAO,
  TYPES
} from './types'
import * as producaoController from 'controller/producaoController'
import * as producaoActions from 'store/modules/producao/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { ApplicationState } from 'store'
import { maskMoney, removeMaskCPF } from 'util/masks'
import { EnumProdutos } from 'models/enums'

interface GenericProps {
  type: string
}

function* find(): SagaIterator {
  const { filtros, pagination }: PRODUCAO = yield select(
    (state: ApplicationState) => state.producao
  )
  try {
    yield put(producaoActions.cleanTabelaAcompanhamentoProducao())
    yield put(loadingActions.setLoading(true))
    const response = yield call(
      producaoController.find,
      {
        adesao: filtros.adesao,
        cpf: removeMaskCPF(filtros.cpf),
        dataInicio: filtros.dataInicio,
        dataFim: filtros.dataFim,
        equipeId: filtros.equipeId,
        digitadorId: filtros.digitadorId
      },
      pagination.page,
      pagination.pageSize
    )
    const acompanhamentoProducao: AcompanhamentoProducaoFieldsTabela[] = []

    response?.data?.content?.registros?.map(
      (acompanhamento: AcompanhamentoProducao) => {
        acompanhamentoProducao.push({
          cpf: acompanhamento.cpf,
          nomeCliente: acompanhamento.nomeCliente,
          adesao: acompanhamento.adesao,
          valorTotal: maskMoney(acompanhamento.valorTotal?.toFixed(2)),
          quantidadeParcelas: acompanhamento.quantidadeParcelas,
          valorParcela: maskMoney(acompanhamento.valorParcela?.toFixed(2)),
          usuarioInstituicao: acompanhamento.usuarioInstituicao,
          status: acompanhamento.status,
          banco: acompanhamento.banco,
          numeroMatricula: acompanhamento.numeroMatricula,
          equipe: acompanhamento.equipe,
          id: acompanhamento.id,
          dataCriacao: acompanhamento.dataCriacao,
          descricaoTipoOperacao: acompanhamento.descricaoTipoOperacao,
          nomeDigitador: acompanhamento.nomeDigitador,
          produto:
            EnumProdutos[acompanhamento.produto as keyof typeof EnumProdutos],
          possuiSeguro: acompanhamento.possuiSeguro
        })
      }
    )
    yield put(
      producaoActions.setTabelaAcompanhamentoProducao(acompanhamentoProducao)
    )
    yield put(
      producaoActions.setPaginacao({
        ...pagination,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )
    if (response?.data?.messages?.length > 0) {
      handlerError(response?.data)
    }
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface GetEquipesProps extends GenericProps {
  flagUsuario?: boolean
}

function* getEquipes({ flagUsuario }: GetEquipesProps): SagaIterator {
  try {
    const response = yield call(producaoController.getEquipes, flagUsuario)
    yield put(producaoActions.setEquipesOptions(response?.data?.content))
  } catch (error) {
    //
  }
}

interface GetUsuariosProps extends GenericProps {
  lojaId?: string
}

function* getUsuarios({ lojaId }: GetUsuariosProps): SagaIterator {
  try {
    const response = yield call(producaoController.getUsuarios, lojaId)
    yield put(producaoActions.setUsuariosOptions(response?.data?.content))
  } catch (error) {
    //
  }
}

export function* watchFind() {
  yield takeEvery(TYPES.FIND_SAGA, find)
}

export function* watchGetEquipes() {
  yield takeEvery(TYPES.GET_EQUIPES_SAGA, getEquipes)
}

export function* watchGetUsuarios() {
  yield takeEvery(TYPES.GET_USUARIOS_SAGA, getUsuarios)
}

function* producaoSaga() {
  yield all([fork(watchFind), fork(watchGetEquipes), fork(watchGetUsuarios)])
}

export default producaoSaga
