import React, { ReactNode } from 'react'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'

interface ModalConfirmacaoProps {
  isOpen: boolean
  toggle: (e: boolean) => void
  onConfirm: () => void
  children?: ReactNode
}

const ModalConfirmacao = ({
  isOpen,
  toggle,
  onConfirm,
  children
}: ModalConfirmacaoProps) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={() => toggle(!isOpen)}>
        <SimpleTitleCard colorIcon="F7931E">
          <AiOutlineExclamationCircle className="icon" />
          Atenção!
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody>
        {children ?? <p>Deseja realmente realizar essa ação?</p>}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <Row className="d-flex column-gap-20">
          <CustomButton onClick={() => toggle(!isOpen)} background="#662D91">
            Cancelar
          </CustomButton>
          <CustomButton
            onClick={() => {
              onConfirm()
              toggle(!isOpen)
            }}
          >
            Confirmar
          </CustomButton>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default ModalConfirmacao
