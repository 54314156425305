import { handlerError } from 'util/handlerError'
import { SagaIterator } from 'redux-saga'
import { call, fork, put, all, takeEvery, select } from 'redux-saga/effects'
import { ApplicationState } from 'store'
import { TYPES, UPLOAD_ARQUIVOS } from './types'
import * as uploadController from 'controller/uploadController'
import * as uploadActions from 'store/modules/backoffice/uploadArquivos/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { toast } from 'react-toastify'

function* getAllUploadArquivos(): SagaIterator {
  const { search, pagination }: UPLOAD_ARQUIVOS = yield select(
    (state: ApplicationState) => state.uploadArquivos
  )
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(uploadController.uploadArquivos, {
      pagina: pagination.page,
      registros: pagination.pageSize,
      field: search.filtro,
      value: search.pesquisa
    })
    yield put(
      uploadActions.setPaginacao({
        ...pagination,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )
    yield put(uploadActions.setAllUpload(response?.data?.content?.registros))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getTiposDeArquivos(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(uploadController.tiposDeArquivos)
    yield put(uploadActions.setTiposDeArquivos(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* criarUploadArquivo(): SagaIterator {
  const { register }: UPLOAD_ARQUIVOS = yield select(
    (state: ApplicationState) => state.uploadArquivos
  )
  yield put(uploadActions.setActiveTab('search'))
  yield put(uploadActions.setPesquisa(null, 'filtro'))
  yield put(uploadActions.setPesquisa('', 'pesquisa'))
  try {
    const response = yield call(uploadController.criarArquivo, register)
    if (response?.data?.content.erro) {
      toast.warn(response?.data?.content.message ?? '')
    } else {
      toast.success(response?.data?.content.message ?? '')
    }
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(uploadActions.getAllUploadSaga())
  }
}

function* excluirArquivo(value: { value: string; type: string }): SagaIterator {
  const { search, pagination }: UPLOAD_ARQUIVOS = yield select(
    (state: ApplicationState) => state.uploadArquivos
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(uploadController.excluirArquivo, value.value)
    toast.success('Arquivo excluído com sucesso!')
    const response = yield call(uploadController.uploadArquivos, {
      pagina: pagination.page,
      registros: pagination.pageSize,
      field: search.filtro,
      value: search.pesquisa
    })
    yield put(
      uploadActions.setPaginacao({
        ...pagination,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )
    yield put(uploadActions.setAllUpload(response?.data?.content?.registros))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* downloadArquivo(value: {
  value: string
  type: string
}): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))

    yield call(uploadController.downloadArquivo, value.value)
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* visualizarArquivo(value: {
  value: string
  type: string
}): SagaIterator {
  const { tipos }: UPLOAD_ARQUIVOS = yield select(
    (state: ApplicationState) => state.uploadArquivos
  )
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(uploadController.visualizarArquivo, value.value)
    const tipo = tipos.find(
      (tipo) =>
        tipo.descricao ===
        response?.data?.content.registros[0].descricaoTipoArquivo
    )

    yield put(
      uploadActions.setRegister({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        tipoArquivo: tipo?.value!,
        uploadedFile: null,
        nomeArquivo: response?.data?.content.registros[0].nomeArquivo
      })
    )
    yield put(uploadActions.setActiveTab('register'))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetAllUploadArquivos() {
  yield takeEvery(TYPES.GET_ALL_UPLOAD_SAGA, getAllUploadArquivos)
}

export function* watchGetTiposDeArquivos() {
  yield takeEvery(TYPES.GET_TIPOS_DE_ARQUIVOS_SAGA, getTiposDeArquivos)
}

export function* watchCriarUploadArquivo() {
  yield takeEvery(TYPES.UPLOAD_SAGA, criarUploadArquivo)
}

export function* watchExcluirArquivo() {
  yield takeEvery(TYPES.EXCLUIR_UPLOAD_SAGA, excluirArquivo)
}

export function* watchDownloadArquivo() {
  yield takeEvery(TYPES.DOWNLOAD_ARQUIVO_SAGA_DISPATCH, downloadArquivo)
}

export function* watchVisualizarArquivo() {
  yield takeEvery(TYPES.VISUALIZAR_ARQUIVO, visualizarArquivo)
}

function* uploadSaga() {
  yield all([
    fork(watchGetAllUploadArquivos),
    fork(watchGetTiposDeArquivos),
    fork(watchCriarUploadArquivo),
    fork(watchDownloadArquivo),
    fork(watchExcluirArquivo),
    fork(watchVisualizarArquivo)
  ])
}
export default uploadSaga
