import React from 'react'
import OwlCarousel, { Options } from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

interface Props {
  children: JSX.Element[] | string[]
  autoplay?: boolean
}

function Carousel({ children, autoplay }: Props) {
  const options: Options = {
    loop: false,
    rewind: true,
    margin: 10,
    nav: false,
    autoplay: autoplay,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      800: {
        items: 3
      }
    }
  }
  return (
    <OwlCarousel className="owl-theme" loop margin={10} {...options}>
      {children.map((item, i) => {
        return (
          <div className="item" key={i}>
            {item}
          </div>
        )
      })}
    </OwlCarousel>
  )
}

export default Carousel
