import {
  GridActionsCellItem,
  GridColumns,
  GridRowParams
} from '@mui/x-data-grid'
import { Row } from 'react-data-grid'
import { AiOutlineEye } from 'react-icons/ai'
import { FiTrash } from 'react-icons/fi'
import { HiOutlinePencil } from 'react-icons/hi'
import * as grupoActions from 'store/modules/backoffice/grupos/actions'

const headerTheme = 'table-header--purple'

export const COLUMNS: GridColumns<Row> = [
  {
    field: 'descricao',
    headerName: 'Descrição',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'quantidadeLojas',
    headerName: 'Qtd. Lojas',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'actions',
    headerName: 'Ações',
    headerClassName: headerTheme,
    type: 'actions',
    align: 'center',
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key={params.row.id}
        icon={<AiOutlineEye />}
        onClick={() => {
          grupoActions.visualizarGrupoSagaDispatch(params.row.id)
        }}
        label="Visualizar"
      />,
      <GridActionsCellItem
        key={params.row.id}
        icon={<HiOutlinePencil />}
        onClick={() => {
          grupoActions.editarGrupoSagaDispatch(params.row.id)
        }}
        label="Editar"
      />,
      <GridActionsCellItem
        key={params.row.id}
        icon={<FiTrash />}
        onClick={() => {
          grupoActions.setItemSelectedIdDispatch(params.row.id)
          grupoActions.toggleModalExclusaoDispatch(true)
        }}
        label="Deletar"
      />
    ]
  }
]
