import { APICore } from 'helpers/api/apiCore'
import { APICoreWebSocket } from 'helpers/api/apiCoreWebSocket'
import { RequestEditarAgendamentoDto } from 'models/agenda/types'

const api = new APICore()
const apiWs = new APICoreWebSocket()

export function getAgendaAtendimentosEventos(
  dataInicio: string,
  dataFim: string
) {
  return api.get(`Agenda/agenda-atendimentos`, {
    dataInicio,
    dataFim
  })
}

export function getAgendaAtendimentoDetalhe(
  pessoaId: string,
  agendamentoId: string
) {
  return api.get(`Agenda/agenda-atendimento-detalhe`, {
    pessoaId,
    agendamentoId
  })
}

export function getDiaSemana(data: string) {
  return api.get(`Agenda/agenda-dias-semana`, {
    data
  })
}

export function confirmacaoAgendamento(agendamentoId: string) {
  return api.update(
    `Agenda/confirmacao-agendamento?agendamentoId=${agendamentoId}`,
    null
  )
}

export function getBigNumbers(dataAgendamentos: string) {
  return api.get(`Agenda/big-numbers`, {
    dataAgendamentos
  })
}

export function getBigNumbersAgendados(dataAgendamentos: string) {
  return api.get(`Agenda/big-numbers-agendados`, {
    dataAgendamentos
  })
}

export function getBigNumbersConfirmados(dataAgendamentos: string) {
  return api.get(`Agenda/big-numbers-confirmados`, {
    dataAgendamentos
  })
}

export function getBigNumbersNaoConfirmados(dataAgendamentos: string) {
  return api.get(`Agenda/big-numbers-nao-confirmados`, {
    dataAgendamentos
  })
}

export function getBigNumbersExpirados(dataAgendamentos: string) {
  return api.get(`Agenda/big-numbers-expirados`, {
    dataAgendamentos
  })
}

export function getVisaoSupervisor(params: {
  dataAgendamentos: string
  pagina: number
  registros: number
}) {
  return api.get(`Agenda/grid-supervisor`, {
    ...params,
    pagina: params.pagina + 1
  })
}

export function getVisaoCoordenador(params: {
  dataAgendamentos: string
  pagina: number
  registros: number
}) {
  return api.get(`Agenda/grid-regional`, {
    ...params,
    pagina: params.pagina + 1
  })
}

export function getGravacao(atendimentoId: string) {
  return api.get(`IntegracaoTelecom/buscar-gravacao`, {
    atendimentoId
  })
}

export function iniciarNotificacoes(eventName: string) {
  return apiWs.connectToHub(`Notify`, eventName)
}

export function getTiposAgendamentos() {
  return api.get(`Generic/tipos-atendimento`, null)
}

export function editarAgendamento(value: RequestEditarAgendamentoDto) {
  return api.update(`Agenda/agendamento`, value)
}
