import React from 'react'
import Main from 'layouts/main'
import CustomTab from './components/CustomTab'

function Atendimento() {
  return (
    <Main>
      <CustomTab />
    </Main>
  )
}

export default Atendimento
