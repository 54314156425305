import { Reducer } from 'redux'
import { INDICADORES, TYPES } from './types'

const INITIAL_STATE: INDICADORES = {
  search: {
    consultorId: '',
    empresaId: '',
    equipeId: ''
  },
  optionsSelect: {
    consultores: [],
    empresas: [],
    equipes: []
  },
  loadings: {
    bigNumbers: false,
    topEquipes: false,
    topEquipesConversao: false,
    topConsultores: false,
    topConsultoresConversao: false,
    tabulacoes: false,
    matrizRfm: false,
    esforcoVendas: false
  },
  bigNumbers: {
    quantidadeVendas: 0,
    ticketMedio: 0,
    totalVendas: 0
  },
  topEquipes: [],
  verMaisTopEquipes: false,
  topEquipesConversao: [],
  verMaisTopEquipesConversao: false,
  topConsultores: [],
  verMaisTopConsultores: false,
  topConsultoresConversao: [],
  verMaisTopConsultoresConversao: false,
  tabulacoes: [],
  matrizRfm: {
    quantidadeCampeoes: 0,
    quantidadeClientesLeais: 0,
    quantidadePotenciaisClientesLeais: 0,
    quantidadeClientesRecentes: 0,
    quantidadePromissores: 0,
    quantidadePrecisamAtencao: 0,
    quantidadePrestesDormir: 0,
    quantidadeEmRisco: 0,
    quantidadeNaoPodePerder: 0,
    quantidadeHibernando: 0,
    quantidadePerdidos: 0
  },
  esforcoVendas: {
    agendamentos: {
      agendados: {
        porcentagem: '0%',
        valor: 0
      },
      convertidos: {
        porcentagem: '0%',
        valor: 0
      }
    },
    ativo: {
      acionados: {
        porcentagem: '0%',
        valor: 0
      },
      convertidos: {
        porcentagem: '0%',
        valor: 0
      }
    },
    receptivo: {
      atendidos: {
        porcentagem: '0%',
        valor: 0
      },
      convertidos: {
        porcentagem: '0%',
        valor: 0
      }
    },
    site: {
      recebidos: {
        porcentagem: '0%',
        valor: 0
      },
      convertidos: {
        porcentagem: '0%',
        valor: 0
      }
    },
    sms: {
      enviados: {
        porcentagem: '0%',
        valor: 0
      },
      convertidos: {
        porcentagem: '0%',
        valor: 0
      }
    }
  }
}

const reducer: Reducer<INDICADORES> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_PESQUISA:
      return {
        ...state,
        search: {
          ...state.search,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_PESQUISA:
      return {
        ...state,
        search: INITIAL_STATE.search
      }
    case TYPES.SET_OPTIONS_SELECT:
      return {
        ...state,
        optionsSelect: {
          ...state.optionsSelect,
          [action.field]: action.value
        }
      }
    case TYPES.SET_LOADINGS:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          [action.field]: action.value
        }
      }
    case TYPES.SET_BIG_NUMBERS:
      return {
        ...state,
        bigNumbers: action.value
      }
    case TYPES.CLEAN_BIG_NUMBERS:
      return {
        ...state,
        bigNumbers: INITIAL_STATE.bigNumbers
      }
    case TYPES.SET_TOP_EQUIPES:
      return {
        ...state,
        topEquipes: action.value
      }
    case TYPES.SET_VER_MAIS_TOP_EQUIPES:
      return {
        ...state,
        verMaisTopEquipes: action.value
      }
    case TYPES.SET_TOP_EQUIPES_CONVERSAO:
      return {
        ...state,
        topEquipesConversao: action.value
      }
    case TYPES.SET_VER_MAIS_TOP_EQUIPES_CONVERSAO:
      return {
        ...state,
        verMaisTopEquipesConversao: action.value
      }
    case TYPES.SET_TOP_CONSULTORES:
      return {
        ...state,
        topConsultores: action.value
      }
    case TYPES.SET_VER_MAIS_TOP_CONSULTORES:
      return {
        ...state,
        verMaisTopConsultores: action.value
      }
    case TYPES.SET_TOP_CONSULTORES_CONVERSAO:
      return {
        ...state,
        topConsultoresConversao: action.value
      }
    case TYPES.SET_VER_MAIS_TOP_CONSULTORES_CONVERSAO:
      return {
        ...state,
        verMaisTopConsultoresConversao: action.value
      }
    case TYPES.SET_TABULACOES:
      return {
        ...state,
        tabulacoes: action.value
      }
    case TYPES.SET_MATRIZ_RFM:
      return {
        ...state,
        matrizRfm: action.value
      }
    case TYPES.SET_ESFORCO_VENDAS:
      return {
        ...state,
        esforcoVendas: action.value
      }
    default:
      return state
  }
}

export default reducer
