import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { CustomButton } from 'styles/global'
import { removeMaskCPF } from 'util/masks'
import useRedux from 'hooks/useRedux'
import * as indicacaoActions from 'store/modules/indicacao/actions'

interface ElegibilidadeRefinProps {
  isOpen: boolean
  toggle: () => void
  mensagemInegibilidade: string
  modalInegibilidade: boolean
  cpf: string
}

const ElegibilidadeRefin = ({
  isOpen,
  toggle,
  mensagemInegibilidade,
  modalInegibilidade,
  cpf
}: ElegibilidadeRefinProps) => {
  const { dispatch } = useRedux()

  return (
    <Modal isOpen={isOpen || modalInegibilidade} centered>
      <ModalHeader toggle={toggle} className="text-right">
        <AiOutlineExclamationCircle
          className="icon"
          style={{ color: '#F7931E', fontSize: '1.5rem' }}
        />{' '}
        Cliente não-elegível
      </ModalHeader>
      <ModalBody className="text-center">
        <div>
          {Array.isArray(mensagemInegibilidade) ? (
            mensagemInegibilidade.map((mensagem, index) => (
              <p key={index} className="text-left">
                {mensagem}
              </p>
            ))
          ) : (
            <p className="text-left">
              Nenhuma mensagem de inegibilidade encontrada.
            </p>
          )}
          <div className="d-flex justify-content-center">
            <CustomButton
              onClick={() => {
                dispatch(
                  indicacaoActions.criarPropostaIndicacaoSaga(
                    removeMaskCPF(cpf)
                  )
                )
                toggle()
              }}
            >
              CRIAR INDICAÇÃO
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
export default ElegibilidadeRefin
