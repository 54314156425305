import React from 'react'
import { SimpleTitleCard } from 'styles/global'
import { FiMessageSquare } from 'react-icons/fi'
import { Col, Collapse, Input, Row } from 'reactstrap'
import CustomSelect from 'components/Select'
import InputCustom from 'components/Inputs'
import { maskMoney } from 'util/masks'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { CAMPANHA } from 'store/modules/backoffice/campanha/types'
import * as campanhaActions from 'store/modules/backoffice/campanha/actions'
import useRedux from 'hooks/useRedux'

const RefinanciamentoPortabilidade = () => {
  const {
    register,
    optionsSelect,
    invalidsTabRefinPortabilidade,
    visualizacao
  } = useSelector<ApplicationState, CAMPANHA>((state) => state.campanha)

  const { dispatch } = useRedux()

  type Fields = keyof CAMPANHA['register']
  function onChange<T extends Fields>(
    value: T extends Fields ? CAMPANHA['register'][T] : never,
    field: T
  ) {
    dispatch(campanhaActions.setRegisterFields(value, field))
  }

  return (
    <div className="d-flex flex-column row-gap-10">
      <SimpleTitleCard>
        <FiMessageSquare className="icon" /> Refinanciamento/Portabilidade
      </SimpleTitleCard>
      <Row>
        <Col className="d-flex flex-column justify-content-end" md={6}>
          <label className="label-12">Instituições</label>
          <CustomSelect
            options={optionsSelect.bancos}
            isMulti
            placeholder="Selecione"
            onChange={(e) => {
              onChange(e, 'instituicaoFinanceiraCampanha')
            }}
            value={register.instituicaoFinanceiraCampanha}
            accessorLabel="descricao"
            accessorValue="id"
            invalid={
              invalidsTabRefinPortabilidade.instituicaoFinanceiraCampanha
            }
            disabled={visualizacao}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex align-items-center gap-2">
          <Input
            type="checkbox"
            onChange={(e) => {
              if (!e.target.checked) {
                dispatch(campanhaActions.cleanParcelasPagas())
              }
              onChange(e.target.checked, 'parcelas')
            }}
            checked={register.parcelas}
            disabled={visualizacao}
            invalid={invalidsTabRefinPortabilidade.parcelas}
          />
          <label className="label-12 mt-1">Parcelas pagas</label>
        </Col>
      </Row>
      <Collapse isOpen={register.parcelas}>
        <Row>
          <Col className="d-flex flex-column justify-content-end" md={3}>
            <label className="label-12">Quantidade inicial</label>
            <InputCustom
              type="number"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(Number(e.target.value), 'quantidadeInicialParcelas')
              }}
              value={register.quantidadeInicialParcelas || ''}
              disabled={visualizacao}
              invalid={invalidsTabRefinPortabilidade.quantidadeInicialParcelas}
              max={100}
              min={0}
            />
          </Col>
          <Col className="d-flex flex-column justify-content-end" md={3}>
            <label className="label-12">Quantidade final</label>
            <InputCustom
              type="number"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(Number(e.target.value), 'quantidadeFinalParcelas')
              }}
              value={register.quantidadeFinalParcelas || ''}
              disabled={visualizacao}
              invalid={invalidsTabRefinPortabilidade.quantidadeFinalParcelas}
              max={100}
              min={0}
            />
          </Col>
        </Row>
      </Collapse>
      <Row>
        <Col className="d-flex align-items-center gap-2">
          <Input
            type="checkbox"
            onChange={(e) => {
              if (!e.target.checked) {
                dispatch(campanhaActions.cleanValorParcela())
              }
              onChange(e.target.checked, 'valorParcela')
            }}
            checked={register.valorParcela}
            disabled={visualizacao}
            invalid={invalidsTabRefinPortabilidade.valorParcela}
          />
          <label className="label-12 mt-1">Valor da parcela</label>
        </Col>
      </Row>
      <Collapse isOpen={register.valorParcela}>
        <Row>
          <Col className="d-flex flex-column justify-content-end" md={3}>
            <label className="label-12">Quantidade inicial (R$)</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(
                  maskMoney(e.target.value),
                  'quantidadeInicialValorParcela'
                )
              }}
              value={maskMoney(register.quantidadeInicialValorParcela)}
              disabled={visualizacao}
              invalid={
                invalidsTabRefinPortabilidade.quantidadeInicialValorParcela
              }
              maxLength={14}
            />
          </Col>
          <Col className="d-flex flex-column justify-content-end" md={3}>
            <label className="label-12">Quantidade final (R$)</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(
                  maskMoney(e.target.value),
                  'quantidadeFinalValorParcela'
                )
              }}
              value={maskMoney(register.quantidadeFinalValorParcela)}
              disabled={visualizacao}
              invalid={
                invalidsTabRefinPortabilidade.quantidadeFinalValorParcela
              }
              maxLength={14}
            />
          </Col>
        </Row>
      </Collapse>
    </div>
  )
}

export default RefinanciamentoPortabilidade
