import React from 'react'
import Main from 'layouts/main'
import { TabContent, TabPane } from 'reactstrap'
import Search from './Tabs/Search'
import Register from './Tabs/Register'
import { TABULACAO } from 'store/modules/backoffice/tabulacao/types'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'

const Tabulacao = () => {
  const { activeTab } = useSelector<ApplicationState, TABULACAO>(
    (state) => state.tabulacao
  )
  return (
    <Main>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="search">
          <Search />
        </TabPane>
        <TabPane tabId="register">
          <Register />
        </TabPane>
      </TabContent>
    </Main>
  )
}

export default Tabulacao
