import CustomTable from 'components/CustomTable'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import SimpleCard from 'components/SimpleCard'
import { RiFolderLine } from 'react-icons/ri'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import * as grupoActions from 'store/modules/backoffice/grupos/actions'
import { COLUMNS } from '../constants/columns'
import useRedux from 'hooks/useRedux'
import {
  setActiveTab,
  setPesquisa
} from 'store/modules/backoffice/grupos/actions'
import { ApplicationState } from 'store'
import { GRUPOS } from 'store/modules/backoffice/grupos/types'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import ModalExclusao from 'components/ModalExclusao'

export default function Search() {
  const { grupos, pagination, search, showConfirmacaoExclusao } = useSelector<
    ApplicationState,
    GRUPOS
  >((state) => state.grupos)

  const { dispatch } = useRedux()

  function onChange(value: string, field: string) {
    dispatch(setPesquisa(value, field))
  }

  function setPage(value: number) {
    dispatch(grupoActions.setPaginacao({ ...pagination, page: value }))
    dispatch(grupoActions.getAllGruposSaga())
  }

  function setPageSize(value: number) {
    dispatch(
      grupoActions.setPaginacao({ ...pagination, page: 0, pageSize: value })
    )
    dispatch(grupoActions.getAllGruposSaga())
  }

  function getGrupos() {
    dispatch(grupoActions.setPaginacao({ ...pagination, page: 0 }))
    dispatch(grupoActions.getAllGruposSaga())
  }

  function irParaRegister() {
    dispatch(grupoActions.cleanSearch())
    dispatch(setActiveTab('register'))
  }

  useEffect(() => {
    dispatch(grupoActions.getAllGruposSaga())
  }, [dispatch])

  return (
    <>
      <SimpleCard>
        <Row>
          <Col>
            <SimpleTitleCard>
              <RiFolderLine className="icon" />
              Grupos
            </SimpleTitleCard>
          </Col>
          <Col className="d-flex justify-content-end" md={6}>
            <CustomButton onClick={() => irParaRegister()}>
              Cadastrar nova
            </CustomButton>
          </Col>
        </Row>
        <Row className="my-3">
          <Col md={3}>
            <CustomSelect
              options={[{ label: 'Descrição', value: 'descricao' }]}
              placeholder="Filtrar por"
              onChange={(e) => {
                onChange(e, 'filtro')
              }}
              value={search.filtro}
              accessorLabel="label"
              accessorValue="value"
            />
          </Col>

          <Col md={3}>
            <InputCustom
              type="text"
              placeholder="Pesquise aqui"
              onChange={(e) => {
                onChange(e.target.value, 'pesquisa')
              }}
              value={search.pesquisa}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <CustomButton onClick={() => getGrupos()} background="#662d90">
              Pesquisar
            </CustomButton>
          </Col>
        </Row>

        <Row>
          <CustomTable
            data={grupos}
            columns={COLUMNS}
            paginationMode="server"
            page={pagination.page}
            pageSize={pagination.pageSize}
            setPage={(e) => setPage(e)}
            setPageSize={(e) => setPageSize(e)}
            rowCount={pagination.totalRegistros}
          />
        </Row>

        <ModalExclusao
          isOpen={showConfirmacaoExclusao}
          toggle={(e) => grupoActions.toggleModalExclusaoDispatch(e)}
          onRemove={() => {
            grupoActions.deleteGrupoSagaDispatch()
          }}
        />
      </SimpleCard>
    </>
  )
}
