import LoadingEllipsis from 'components/Loading/LoadindEllipsis'
import React from 'react'
import { AiOutlinePhone } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { SimpleTitleCard } from 'styles/global'

interface ModalDiscagemProps {
  isOpen: boolean
  onToggle: () => void
  index: number
}

const ModalDiscagem = ({ isOpen, onToggle, index }: ModalDiscagemProps) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  return (
    <Modal
      size="md"
      backdrop="static"
      isOpen={isOpen}
      toggle={() => onToggle()}
    >
      <ModalHeader
        toggle={() => {
          onToggle()
        }}
      >
        <SimpleTitleCard>
          <AiOutlinePhone className="icon" />
          Discagem
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody className="d-flex flex-column row-gap-15">
        {atendimentosAbertos[
          index
        ].tabContato.infosChamada.numerosDiscados?.map((numero, i) => {
          return (
            <Row key={i}>
              <Col>
                <span className="fw-bold">{numero}</span>
              </Col>
              <Col>
                <LoadingEllipsis>
                  <span className="ellipsis">Discando</span>
                </LoadingEllipsis>
              </Col>
            </Row>
          )
        })}
      </ModalBody>
    </Modal>
  )
}

export default ModalDiscagem
