import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { ApplicationState } from 'store'
import { BLACKLIST, TYPES } from './types'
import * as loadingActions from 'store/modules/loading/actions'
import * as blacklistController from 'controller/blacklistController'
import * as blacklistActions from 'store/modules/backoffice/blacklist/actions'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'

function* getAllBlacklist(): SagaIterator {
  const { pagination, limiteMaximoRegistros, search }: BLACKLIST = yield select(
    (state: ApplicationState) => state.blacklist
  )

  try {
    yield put(loadingActions.setLoading(true))

    const response = yield call(blacklistController.getAllBlacklist, {
      pagina: pagination.page,
      registros: pagination.pageSize,
      limiteMaximoRegistros: limiteMaximoRegistros,
      field: search.filtro,
      value: search.pesquisa
    })

    yield put(
      blacklistActions.setPaginacao({
        ...pagination,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )

    yield put(
      blacklistActions.setAllBlacklist(response?.data?.content?.registros)
    )

    yield put(blacklistActions.cleanRegister())
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* createBlacklist(): SagaIterator {
  const { register }: BLACKLIST = yield select(
    (state: ApplicationState) => state.blacklist
  )

  try {
    yield put(loadingActions.setLoading(true))

    yield call(blacklistController.createBlacklist, register)

    yield put(
      blacklistActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )
    yield put(blacklistActions.getAllBlacklistSaga())
    yield put(blacklistActions.cleanRegister())
    yield put(blacklistActions.cleanSearch())

    toast.success('Blacklist salvo com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* deleteBlacklist(): SagaIterator {
  const { itemSelectedId }: BLACKLIST = yield select(
    (state: ApplicationState) => state.blacklist
  )

  try {
    yield put(loadingActions.setLoading(true))
    yield call(blacklistController.deleteBlacklist, itemSelectedId)

    yield put(
      blacklistActions.setPaginacao({
        page: 0,
        pageSize: 10,
        totalRegistros: 0
      })
    )
    yield put(blacklistActions.getAllBlacklistSaga())
    yield put(blacklistActions.setItemSelectedIdDispatch(''))
    toast.success('Blacklist excluído com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetAllBlacklist() {
  yield takeEvery(TYPES.GET_ALL_BLACKLIST_SAGA, getAllBlacklist)
}

export function* watchCreateBlacklist() {
  yield takeEvery(TYPES.POST_BLACKLIST_SAGA, createBlacklist)
}

export function* watchDeleteBlacklist() {
  yield takeEvery(TYPES.DELETE_BLACKLIST_SAGA_DISPATCH, deleteBlacklist)
}

function* blacklistSaga() {
  yield all([
    fork(watchGetAllBlacklist),
    fork(watchCreateBlacklist),
    fork(watchDeleteBlacklist)
  ])
}

export default blacklistSaga
