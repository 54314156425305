import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import routes from 'routes'
import { ApplicationState } from 'store'
import { SIDEBAR } from 'store/modules/sidebar/types'
import { Background } from './style'
import { styled } from '@mui/material'
import { drawerWidth } from 'styles/global'

const BackgroundBreadcrumbs = styled(Background)(({ theme, isOpen }) => ({
  width: `calc(100% - ${theme.spacing(7)})`,
  left: `calc(${theme.spacing(7)})`,
  paddingLeft: '30px',
  [theme.breakpoints.up('sm')]: {
    width: `calc(100% - ${theme.spacing(8)})`,
    left: `calc(${theme.spacing(8)})`
  },
  zIndex: 'calc(1049 - 1) !important',
  transition: theme.transitions.create(['left', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(isOpen && {
    width: `calc(100% - ${drawerWidth}px) !important`,
    left: `calc(${drawerWidth}px) !important`,
    transition: theme.transitions.create(['left', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

function Breadcrumbs() {
  const { isOpen } = useSelector<ApplicationState, SIDEBAR>(
    (state) => state.sidebar
  )

  const [breadcrumb, setBreadcrumb] = useState<
    { title: string; icon: JSX.Element; path: string } | undefined
  >({
    title: '',
    icon: <></>,
    path: ''
  })

  const location = useLocation()

  const getLocation = () => {
    const simpleRoutes: { title: string; icon: JSX.Element; path: string }[] =
      []
    routes.forEach((route) => {
      simpleRoutes.push({
        title: route.title,
        icon: route.icon,
        path: route.path
      })
    })
    const pathNameSplited = location.pathname.split('/')
    const newBreadcrumb = simpleRoutes.find(
      (simple) =>
        simple.path.toUpperCase() === '/' + pathNameSplited[1].toUpperCase()
    )
    setBreadcrumb(newBreadcrumb)
  }

  useEffect(() => {
    getLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <BackgroundBreadcrumbs isOpen={isOpen}>
      <span>
        {breadcrumb?.icon}
        {breadcrumb?.title} /
      </span>
    </BackgroundBreadcrumbs>
  )
}

export default Breadcrumbs
