import React, { useEffect } from 'react'
import { NotFoundWrapper } from './style'
import notFound from 'assets/images/page_not_found.png'
import { APICore, setAuthorization } from 'helpers/api/apiCore'
import history from 'util/history'

const NotFound = () => {
  const api = new APICore()

  useEffect(() => {
    const isAuthenticated = api.isAuthenticated()
    if (!isAuthenticated) {
      setAuthorization(null)
      history.push('/Login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function logout() {
    setAuthorization(null)
    history.push('/Login')
  }

  return (
    <NotFoundWrapper>
      <div onClick={() => logout()} role="button" className="btn-logout">
        <span>Sair</span>
      </div>
      <div className="image-not-found">
        <img src={notFound} />
        <h2>Oops..</h2>
      </div>
    </NotFoundWrapper>
  )
}

export default NotFound
