import React from 'react'
import { BiAward } from 'react-icons/bi'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import { Line, Rank } from '../style'
import { ApplicationState } from 'store'
import { INDICADORES } from 'store/modules/indicadores/types'
import { useSelector } from 'react-redux'
import { maskMoney } from 'util/masks'
import useRedux from 'hooks/useRedux'
import * as indicadoresActions from 'store/modules/indicadores/actions'
import LoadingComponent from './LoadingComponent'

const TopEquipes = () => {
  const {
    topEquipes,
    topEquipesConversao,
    loadings,
    verMaisTopEquipes,
    verMaisTopEquipesConversao
  } = useSelector<ApplicationState, INDICADORES>((state) => state.indicadores)

  const { dispatch } = useRedux()

  function toggleTopEquipes() {
    verMaisTopEquipes
      ? dispatch(indicadoresActions.getTopEquipesSaga())
      : dispatch(indicadoresActions.getVerMaisTopEquipesSaga())
  }

  function toggleTopEquipesConversao() {
    verMaisTopEquipesConversao
      ? dispatch(indicadoresActions.getTopEquipesConversaoSaga())
      : dispatch(indicadoresActions.getVerMaisTopEquipesConversaoSaga())
  }

  return (
    <div className="d-flex flex-column">
      <SimpleTitleCard>
        <BiAward className="icon" /> Top Equipes
      </SimpleTitleCard>
      <Row className="d-flex row-gap-15">
        <Col md={6}>
          <Rank>
            <h1>5 Maiores equipes</h1>
            <Line />
            <LoadingComponent isLoading={loadings.topEquipes}>
              <ul>
                {topEquipes?.map((e, i) => {
                  const itemList = (
                    <li key={i}>
                      <span id={`topEquipeNome` + i}>{`${i + 1} - ${
                        e.nome
                      }`}</span>
                      <label id={`topEquipeValor` + i}>
                        {maskMoney(e.totalVendas?.toFixed(2))}
                      </label>
                      <UncontrolledTooltip
                        placement="left"
                        target={`topEquipeNome` + i}
                      >
                        {e.nome}
                      </UncontrolledTooltip>
                      <UncontrolledTooltip
                        placement="right"
                        target={`topEquipeValor` + i}
                      >
                        {maskMoney(e.totalVendas?.toFixed(2))}
                      </UncontrolledTooltip>
                    </li>
                  )
                  if (verMaisTopEquipes) {
                    return itemList
                  } else {
                    if (i < 5) {
                      return itemList
                    }
                  }
                })}
              </ul>
              <div className="d-flex justify-content-center">
                <span
                  onClick={() => {
                    toggleTopEquipes()
                  }}
                  role="button"
                  className="ver-mais"
                >
                  {topEquipes.length
                    ? verMaisTopEquipes
                      ? 'Ver menos'
                      : 'Ver mais'
                    : ''}
                </span>
              </div>
            </LoadingComponent>
          </Rank>
        </Col>
        <Col md={6}>
          <Rank>
            <h1>5 Maiores equipes em conversão</h1>
            <Line />
            <LoadingComponent isLoading={loadings.topEquipesConversao}>
              <ul>
                {topEquipesConversao?.map((e, i) => {
                  const itemList = (
                    <li key={i}>
                      <span id={`topEquipeConversaoNome` + i}>{`${i + 1} - ${
                        e.nome
                      }`}</span>
                      <label id={`topEquipeConversaoValor` + i}>
                        {e.quantidadeVendas}
                      </label>
                      <UncontrolledTooltip
                        placement="left"
                        target={`topEquipeConversaoNome` + i}
                      >
                        {e.nome}
                      </UncontrolledTooltip>
                      <UncontrolledTooltip
                        placement="right"
                        target={`topEquipeConversaoValor` + i}
                      >
                        {e.quantidadeVendas}
                      </UncontrolledTooltip>
                    </li>
                  )
                  if (verMaisTopEquipesConversao) {
                    return itemList
                  } else {
                    if (i < 5) {
                      return itemList
                    }
                  }
                })}
              </ul>
              <div className="d-flex justify-content-center">
                <span
                  onClick={() => {
                    toggleTopEquipesConversao()
                  }}
                  role="button"
                  className="ver-mais"
                >
                  {topEquipesConversao.length
                    ? verMaisTopEquipesConversao
                      ? 'Ver menos'
                      : 'Ver mais'
                    : ''}
                </span>
              </div>
            </LoadingComponent>
          </Rank>
        </Col>
      </Row>
    </div>
  )
}

export default TopEquipes
