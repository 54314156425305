import { useEffect, useState } from 'react'
import { FiChevronRight, FiDollarSign } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { maskMoney, removeMaskMoney } from 'util/masks'
import {
  CardProdutoContainer,
  Checkbox,
  CheckboxProduto,
  ContentProduto
} from '../style'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { ProdutosSimulacao } from 'models/types'
import { EnumServicos } from 'models/enums'
import useRedux from 'hooks/useRedux'
import { Range } from 'styles/global'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { InvisibleInputDebounced } from './InvisibleInputDebounced'

interface CardProdutoProps {
  produto: ProdutosSimulacao
  checked: boolean
  onCheck: (e: ProdutosSimulacao) => void
  index: number
}

function CardProduto({ produto, checked, onCheck, index }: CardProdutoProps) {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )
  const { dispatch } = useRedux()
  const [invalid, setInvalid] = useState(false)

  const [customValue, setCustomValue] = useState(0)

  useEffect(() => {
    if (
      ((produto.servico === 5 ||
        produto.servico === 6 ||
        produto.servico === 8 ||
        produto.servico === 12) &&
        Number(removeMaskMoney(produto.valorSaque)) <
          produto.valorSaqueMinimo) ||
      Number(removeMaskMoney(produto.valorSaque)) > produto.valorSaqueMaximo
    ) {
      setInvalid(true)
    } else {
      setInvalid(false)
    }
  }, [
    produto.servico,
    produto.valorSaque,
    produto.valorSaqueMaximo,
    produto.valorSaqueMinimo
  ])

  useEffect(() => {
    if (produto.servico === 3) {
      const portabilidade = atendimentosAbertos[
        index
      ].tabProduto.produtosSelecionados.find(
        (produtoSelecionado) =>
          produtoSelecionado.matricula === produto.matricula &&
          produtoSelecionado.servico === 3
      )

      if (portabilidade && portabilidade.contratosPortabilidade) {
        let valorTotalFinanciado = 0
        const { contratosPortabilidade } = portabilidade
        const contratosElegiveis = contratosPortabilidade.filter(
          (contrato) => contrato.clienteElegivel
        )
        if (contratosElegiveis?.length > 0) {
          contratosElegiveis.forEach((contrato) => {
            contrato.listProdutosPortabilidade
              .filter(
                (produtoPortabilidade) => produtoPortabilidade.selecionado
              )
              .forEach((produtoPortabilidade) => {
                valorTotalFinanciado += produtoPortabilidade.produtosAssociados
                  .filter((produtoAssociado) => produtoAssociado.selecionado)
                  .reduce((acc, produtoAssociado) => {
                    return acc + (produtoAssociado.valorTotalFinanciado ?? 0)
                  }, 0)
              })
          })
          console.log(valorTotalFinanciado)
          setCustomValue(valorTotalFinanciado)
        } else {
          setCustomValue(0)
        }
      } else {
        setCustomValue(0)
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    atendimentosAbertos[index].tabProduto.produtosSelecionados,
    index,
    ...atendimentosAbertos[index].tabProduto.produtosSelecionados.flatMap(
      (produtoSelecionado) => [
        ...(produtoSelecionado.contratosPortabilidade?.flatMap(
          (contratoPortabilidade) => [
            ...(contratoPortabilidade.listProdutosPortabilidade?.flatMap(
              (produtoPortabilidade) => [
                produtoPortabilidade.selecionado,
                ...(produtoPortabilidade.produtosAssociados?.map(
                  (produtoAssociado) => produtoAssociado.selecionado
                ) || [])
              ]
            ) || [])
          ]
        ) || [])
      ]
    )
  ])
  /* eslint-enable react-hooks/exhaustive-deps */
  const produtosSelecionados = [
    ...atendimentosAbertos[index].tabProduto.produtosSelecionados
  ]

  const produtosDisponiveisRefinItauElegiveis = [
    ...atendimentosAbertos[index].tabProduto.produtosDisponiveis
  ].filter((p) => {
    if (p.servico === 2 && p.clienteElegivel) {
      return p.matricula === produto.matricula ? true : false
    } else {
      return false
    }
  })
  const contratosAptosPortabilidade = [
    ...atendimentosAbertos[index].tabProduto.produtosDisponiveis
  ].filter((p) => {
    if (p.servico === 3) {
      return p.matricula === produto.matricula
    } else {
      return false
    }
  })

  const isChecked =
    produtosSelecionados.filter((p) => {
      if (p.servico === 2) {
        return p.matricula === produto.matricula ? true : false
      } else {
        return false
      }
    }).length > 0

  const isCheckedPortabilidade =
    produtosSelecionados.filter((p) => {
      if (p.servico === 3) {
        return p.matricula === produto.matricula ? true : false
      } else {
        return false
      }
    }).length > 0

  function onPlanoChangeConsignado(
    codigoPlano: number | null,
    valorPremio: number
  ) {
    const planoSelecionado = [
      ...atendimentosAbertos[index].tabProduto
        .listPlanoSeguroPrestamistaConsignadoSelecionadoByMatricula
    ].find((p) => p.matricula === produto.matricula)

    if (
      planoSelecionado !== undefined &&
      planoSelecionado !== null &&
      planoSelecionado.value === codigoPlano
    ) {
      dispatch(
        atendimentoActions.setInfosProduto(
          [
            ...atendimentosAbertos[
              index
            ].tabProduto.listPlanoSeguroPrestamistaConsignadoSelecionadoByMatricula.filter(
              (p) => p.matricula !== produto.matricula
            )
          ],
          'listPlanoSeguroPrestamistaConsignadoSelecionadoByMatricula',
          index
        )
      )
    } else {
      dispatch(
        atendimentoActions.setInfosProduto(
          [
            ...atendimentosAbertos[
              index
            ].tabProduto.listPlanoSeguroPrestamistaConsignadoSelecionadoByMatricula.filter(
              (p) => p.matricula !== produto.matricula
            ),
            {
              matricula: produto.matricula,
              value: codigoPlano,
              valorPremio: valorPremio
            }
          ],
          'listPlanoSeguroPrestamistaConsignadoSelecionadoByMatricula',
          index
        )
      )
    }
  }

  function onPlanoChangeBeneficio(
    codigoPlano: number | null,
    valorPremio: number
  ) {
    const planoSelecionado = [
      ...atendimentosAbertos[index].tabProduto
        .listPlanoSeguroPrestamistaBeneficioSelecionadoByMatricula
    ].find((p) => p.matricula === produto.matricula)

    if (
      planoSelecionado !== undefined &&
      planoSelecionado !== null &&
      planoSelecionado.value === codigoPlano
    ) {
      dispatch(
        atendimentoActions.setInfosProduto(
          [
            ...atendimentosAbertos[
              index
            ].tabProduto.listPlanoSeguroPrestamistaBeneficioSelecionadoByMatricula.filter(
              (p) => p.matricula !== produto.matricula
            )
          ],
          'listPlanoSeguroPrestamistaBeneficioSelecionadoByMatricula',
          index
        )
      )
    } else {
      dispatch(
        atendimentoActions.setInfosProduto(
          [
            ...atendimentosAbertos[
              index
            ].tabProduto.listPlanoSeguroPrestamistaBeneficioSelecionadoByMatricula.filter(
              (p) => p.matricula !== produto.matricula
            ),
            {
              matricula: produto.matricula,
              value: codigoPlano,
              valorPremio: valorPremio
            }
          ],
          'listPlanoSeguroPrestamistaBeneficioSelecionadoByMatricula',
          index
        )
      )
    }
  }

  const isDetalhamentoOpen =
    atendimentosAbertos[index].tabProduto?.detalhamentoIsOpen
  function realizarSimulacao(
    valorTotalFinanciado: number,
    tipoServico: number
  ) {
    dispatch(
      atendimentoActions.recalculoPropostaSaga(
        produto.cpf,
        produto.matricula,
        0,
        valorTotalFinanciado,
        0,
        tipoServico,
        0,
        0,
        null,
        0,
        0,
        0,
        index
      )
    )
    dispatch(
      atendimentoActions.getMatriculasModalIndicacaoSaga(
        atendimentosAbertos[index].cpf,
        index
      )
    )
  }

  function onPlanoChangeCartaoBeneficio(
    codigoPlano: number | null,
    valorPremio: number
  ) {
    const planoSelecionado = [
      ...atendimentosAbertos[index].tabProduto
        .listPlanoSeguroPrestamistaCartaoBeneficioSelecionadoByMatricula
    ].find((p) => p.matricula === produto.matricula)

    if (
      planoSelecionado !== undefined &&
      planoSelecionado !== null &&
      planoSelecionado.value === codigoPlano
    ) {
      dispatch(
        atendimentoActions.setInfosProduto(
          [
            ...atendimentosAbertos[
              index
            ].tabProduto.listPlanoSeguroPrestamistaCartaoBeneficioSelecionadoByMatricula.filter(
              (p) => p.matricula !== produto.matricula
            )
          ],
          'listPlanoSeguroPrestamistaCartaoBeneficioSelecionadoByMatricula',
          index
        )
      )
    } else {
      dispatch(
        atendimentoActions.setInfosProduto(
          [
            ...atendimentosAbertos[
              index
            ].tabProduto.listPlanoSeguroPrestamistaCartaoBeneficioSelecionadoByMatricula.filter(
              (p) => p.matricula !== produto.matricula
            ),
            {
              matricula: produto.matricula,
              value: codigoPlano,
              valorPremio: valorPremio
            }
          ],
          'listPlanoSeguroPrestamistaCartaoBeneficioSelecionadoByMatricula',
          index
        )
      )
    }
  }

  function onPlanoChangeCartaoConsignado(
    codigoPlano: number | null,
    valorPremio: number
  ) {
    const planoSelecionado = [
      ...atendimentosAbertos[index].tabProduto
        .listPlanoSeguroPrestamistaCartaoConsignadoSelecionadoByMatricula
    ].find((p) => p.matricula === produto.matricula)

    if (
      planoSelecionado !== undefined &&
      planoSelecionado !== null &&
      planoSelecionado.value === codigoPlano
    ) {
      dispatch(
        atendimentoActions.setInfosProduto(
          [
            ...atendimentosAbertos[
              index
            ].tabProduto.listPlanoSeguroPrestamistaCartaoConsignadoSelecionadoByMatricula.filter(
              (p) => p.matricula !== produto.matricula
            )
          ],
          'listPlanoSeguroPrestamistaCartaoConsignadoSelecionadoByMatricula',
          index
        )
      )
    } else {
      dispatch(
        atendimentoActions.setInfosProduto(
          [
            ...atendimentosAbertos[
              index
            ].tabProduto.listPlanoSeguroPrestamistaCartaoConsignadoSelecionadoByMatricula.filter(
              (p) => p.matricula !== produto.matricula
            ),
            {
              matricula: produto.matricula,
              value: codigoPlano,
              valorPremio: valorPremio
            }
          ],
          'listPlanoSeguroPrestamistaCartaoConsignadoSelecionadoByMatricula',
          index
        )
      )
    }
  }

  switch (produto.servico) {
    case 2:
      return (
        <CardProdutoContainer isDetalhamentoOpen={isDetalhamentoOpen}>
          <ContentProduto className="d-flex justify-content-between">
            <div className="d-flex flex-column row-gap-20">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center column-gap-10">
                  <FiDollarSign className="icon" />
                  {produto.matricula && (
                    <label className="matricula">M: {produto.matricula}</label>
                  )}
                </div>
                {produtosDisponiveisRefinItauElegiveis.length > 0 && (
                  <CheckboxProduto
                    checked={isChecked}
                    onChange={() => {
                      if (isChecked) {
                        dispatch(
                          atendimentoActions.setInfosProduto(
                            produtosSelecionados.filter((p) => {
                              if (p.servico === 2) {
                                return p.matricula === produto.matricula
                                  ? false
                                  : true
                              } else {
                                return true
                              }
                            }),
                            'produtosSelecionados',
                            index
                          )
                        )
                      } else {
                        dispatch(
                          atendimentoActions.setInfosProduto(
                            [
                              ...produtosSelecionados.filter((p) => {
                                if (p.servico === 2) {
                                  return p.matricula === produto.matricula
                                    ? false
                                    : true
                                } else {
                                  return true
                                }
                              }),
                              ...produtosDisponiveisRefinItauElegiveis
                            ],
                            'produtosSelecionados',
                            index
                          )
                        )
                      }
                    }}
                  />
                )}
              </div>
              <div className="d-flex flex-row justify-content-between">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
                <FiChevronRight
                  onClick={() => {
                    if (
                      atendimentosAbertos[index].tabProduto.produtoSelected
                        .servico === 0 ||
                      (atendimentosAbertos[index].tabProduto.produtoSelected
                        .servico === produto.servico &&
                        atendimentosAbertos[index].tabProduto.produtoSelected
                          .matricula === produto.matricula)
                    ) {
                      dispatch(atendimentoActions.toggleDetalhamento(index))
                    }
                    if (
                      atendimentosAbertos[index].tabProduto.detalhamentoIsOpen
                    ) {
                      dispatch(
                        atendimentoActions.selectProduto(
                          {
                            servico: produto.servico,
                            matricula: produto.matricula
                          },
                          index
                        )
                      )
                    }
                  }}
                  role="button"
                  className="icon"
                />
              </div>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column">
                  <label className="label-12">
                    Quantidade de contratos aptos:
                  </label>
                  <span className="valor-produto-secondary">
                    {
                      atendimentosAbertos[
                        index
                      ].tabProduto?.produtosDisponiveis.filter(
                        (p) =>
                          p.servico === 2 &&
                          p.clienteElegivel &&
                          p.matricula === produto.matricula
                      ).length
                    }
                  </span>
                </div>
              </div>
            </div>
            <span className="valor-produto">
              R${' '}
              {maskMoney(
                atendimentosAbertos[index].tabProduto?.produtosSelecionados
                  .filter(
                    (p) =>
                      p.servico === 2 &&
                      p.clienteElegivel &&
                      p.matricula === produto.matricula
                  )
                  .reduce((accumulator, actualValue) => {
                    const codigoTabelaSelecionado = atendimentosAbertos[
                      index
                    ].tabProduto.listCodigoTabelaSelectedByCodigoSimulacao?.find(
                      (item) => item.id === actualValue.codigoSimulacao
                    )?.value
                    const parcelasSelecionado = atendimentosAbertos[
                      index
                    ].tabProduto.listParcelasSelectedByCodigoSimulacao?.find(
                      (item) => item.id === actualValue.codigoSimulacao
                    )?.value

                    const getValorLiberado = () => {
                      if (
                        codigoTabelaSelecionado !== undefined &&
                        parcelasSelecionado !== undefined
                      ) {
                        return (
                          actualValue.listProdutos
                            .find((p) => p.codigo === codigoTabelaSelecionado)
                            ?.listParcelas.find(
                              (p) => p.parcelas === parcelasSelecionado
                            )?.valorLiberado ?? 0
                        )
                      } else {
                        return 0
                      }
                    }

                    return accumulator + getValorLiberado()
                  }, 0)
                  .toFixed(2)
              )}
            </span>
          </ContentProduto>
        </CardProdutoContainer>
      )
    case 3:
      return (
        <CardProdutoContainer isDetalhamentoOpen={isDetalhamentoOpen}>
          <ContentProduto className="d-flex justify-content-between">
            <div className="d-flex flex-column row-gap-20">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center column-gap-10">
                  <FiDollarSign className="icon" />
                  {produto.matricula && (
                    <label className="matricula">M: {produto.matricula}</label>
                  )}
                </div>
                {contratosAptosPortabilidade.length > 0 && (
                  <CheckboxProduto
                    checked={isCheckedPortabilidade}
                    onChange={() => {
                      if (isCheckedPortabilidade) {
                        dispatch(
                          atendimentoActions.setInfosProduto(
                            produtosSelecionados.filter((p) => {
                              if (p.servico === 3) {
                                return p.matricula === produto.matricula
                                  ? false
                                  : true
                              } else {
                                return true
                              }
                            }),
                            'produtosSelecionados',
                            index
                          )
                        )
                      } else {
                        dispatch(
                          atendimentoActions.setInfosProduto(
                            [
                              ...produtosSelecionados.filter((p) => {
                                if (p.servico === 3) {
                                  return p.matricula === produto.matricula
                                    ? false
                                    : true
                                } else {
                                  return true
                                }
                              }),
                              ...contratosAptosPortabilidade
                            ],
                            'produtosSelecionados',
                            index
                          )
                        )
                      }
                    }}
                  />
                )}
              </div>
              <div className="d-flex flex-row justify-content-between">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
                <FiChevronRight
                  onClick={() => {
                    if (
                      atendimentosAbertos[index].tabProduto.produtoSelected
                        .servico === 0 ||
                      (atendimentosAbertos[index].tabProduto.produtoSelected
                        .servico === produto.servico &&
                        atendimentosAbertos[index].tabProduto.produtoSelected
                          .matricula === produto.matricula)
                    ) {
                      dispatch(atendimentoActions.toggleDetalhamento(index))
                    }
                    if (
                      atendimentosAbertos[index].tabProduto.detalhamentoIsOpen
                    ) {
                      dispatch(
                        atendimentoActions.selectProduto(
                          {
                            servico: produto.servico,
                            matricula: produto.matricula
                          },
                          index
                        )
                      )
                    }
                  }}
                  role="button"
                  className="icon"
                />
              </div>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column">
                  <label className="label-12">
                    Quantidade de contratos aptos:
                  </label>
                  <span className="valor-produto-secondary">
                    {
                      produto.contratosPortabilidade?.filter(
                        (contrato) => contrato.clienteElegivel
                      )?.length
                    }
                  </span>
                </div>
              </div>
            </div>
            <span className="valor-produto">
              R$ {maskMoney(customValue.toFixed(2))}
            </span>
          </ContentProduto>
        </CardProdutoContainer>
      )
    case 5:
      return (
        <CardProdutoContainer isDetalhamentoOpen={isDetalhamentoOpen}>
          <ContentProduto className="d-flex justify-content-between">
            <div className="d-flex flex-column row-gap-20">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center column-gap-10">
                  <FiDollarSign className="icon" />
                  {produto.matricula && (
                    <label className="matricula">M: {produto.matricula}</label>
                  )}
                </div>
                <CheckboxProduto
                  checked={checked}
                  onChange={() => {
                    onCheck(produto)
                  }}
                />
              </div>
              <div className="d-flex flex-row">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
              </div>
            </div>
            <Row>
              <Col xl={5} className="d-flex flex-column gap-3">
                <div className="d-flex flex-column">
                  <label className="label-12">RMC:</label>
                  <span className="valor-produto-secondary">
                    R$ {maskMoney(produto.valorMargem?.toFixed(2))}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <label className="label-12">Limite cartão:</label>
                  <span className="valor-produto-secondary">
                    R$ {maskMoney(produto.limiteCartao?.toFixed(2))}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <label className="label-12">Limite disponível:</label>
                  <span className="valor-produto-secondary">
                    R$ {maskMoney(produto.limiteDisponivel?.toFixed(2))}
                  </span>
                </div>
              </Col>
              <Col xl={7} className="d-flex flex-column row-gap-20">
                {produto.planosSeguroPrestamista?.planosComLimiteSaque && (
                  <>
                    <label className="label-12">Seguro Prestamista:</label>
                    {produto.planosSeguroPrestamista?.planosComLimiteSaque.map(
                      (plano) => {
                        return (
                          <div
                            key={plano.codigoPlano}
                            className="d-flex column-gap-10"
                          >
                            <Checkbox
                              onChange={() => {
                                onPlanoChangeConsignado(
                                  plano.codigoPlano,
                                  plano.valorPremio
                                )
                              }}
                              checked={
                                plano.codigoPlano ===
                                atendimentosAbertos[
                                  index
                                ].tabProduto.listPlanoSeguroPrestamistaConsignadoSelecionadoByMatricula.find(
                                  (p) => p.matricula === produto.matricula
                                )?.value
                              }
                            />
                            <label className="label-12">
                              {plano?.nomePlano.includes('Mega')
                                ? 'Mega'
                                : plano?.nomePlano.includes('Super')
                                ? 'Super'
                                : plano?.nomePlano}{' '}
                              - Valor: {maskMoney(plano.valorPremio.toFixed(2))}
                            </label>
                            <AiOutlineExclamationCircle
                              style={{ cursor: 'pointer' }}
                              className="iconInformation"
                              onClick={() => {
                                dispatch(
                                  atendimentoActions.setInfosProduto(
                                    true,
                                    'modalCoberturasSeguroPrestamista',
                                    index
                                  )
                                )
                                dispatch(
                                  atendimentoActions.setModalCoberturaSeguroPrestamista(
                                    {
                                      codigoPlano: plano.codigoPlano,
                                      coberturas: plano.coberturas?.map(
                                        (cobertura) => {
                                          return {
                                            nomeCobertura:
                                              cobertura.nomeCobertura,
                                            valorBeneficio:
                                              cobertura.valorBeneficio
                                          }
                                        }
                                      )
                                    }
                                  )
                                )
                              }}
                            />
                          </div>
                        )
                      }
                    )}
                  </>
                )}
              </Col>
            </Row>
            <div className="d-flex flex-column">
              <label className="label-12">Valor saque:</label>
              <span className="valor-produto d-flex gap-2">
                R$
                <InvisibleInputDebounced
                  onChange={(e) => {
                    dispatch(
                      atendimentoActions.setInfosSaqueComplementarConsignado(
                        maskMoney(e.target.value),
                        'valorSaque',
                        index,
                        produto.matricula
                      )
                    )
                  }}
                  onDebounce={() => {
                    if (
                      Number(removeMaskMoney(produto.valorSaque)) <=
                        produto.valorSaqueMaximo &&
                      Number(removeMaskMoney(produto.valorSaque)) >=
                        produto.valorSaqueMinimo
                    ) {
                      realizarSimulacao(
                        Number(removeMaskMoney(produto.valorSaque)),
                        produto.servico
                      )
                    }
                  }}
                  value={maskMoney(produto.valorSaque)}
                  delay={2000}
                />
              </span>
              <Range
                type="range"
                step={0.01}
                min={produto.valorSaqueMinimo}
                max={produto.valorSaqueMaximo}
                onChange={(e) => {
                  dispatch(
                    atendimentoActions.setInfosSaqueComplementarConsignado(
                      maskMoney(Number(e.target.value)?.toFixed(2)),
                      'valorSaque',
                      index,
                      produto.matricula
                    )
                  )
                }}
                onMouseUp={(event) => {
                  realizarSimulacao(
                    Number(event.currentTarget.value),
                    produto.servico
                  )
                }}
                value={Number(removeMaskMoney(produto.valorSaque))?.toFixed(2)}
              />
              {invalid && (
                <p className="mensagem-erro">
                  O valor deve estar entre R${' '}
                  {maskMoney(produto.valorSaqueMinimo?.toFixed(2))} e R${' '}
                  {maskMoney(produto.valorSaqueMaximo?.toFixed(2))}
                </p>
              )}
            </div>
          </ContentProduto>
        </CardProdutoContainer>
      )
    case 6:
      return (
        <CardProdutoContainer isDetalhamentoOpen={isDetalhamentoOpen}>
          <ContentProduto>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center column-gap-10">
                <FiDollarSign className="icon" />
                {produto.matricula && (
                  <label className="matricula">M: {produto.matricula}</label>
                )}
              </div>
              <CheckboxProduto
                checked={checked}
                onChange={() => {
                  onCheck(produto)
                }}
              />
            </div>
            <div className="d-flex flex-column row-gap-15 justify-content-between">
              <div className="d-flex flex-row justify-content-between">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
              </div>
              <Row>
                <Col xl={6} className="d-flex flex-column row-gap-10">
                  <div className="d-flex flex-column">
                    <label className="label-12">Valor limite</label>
                    <span className="valor-produto">
                      R$ {maskMoney(produto.valorLimite?.toFixed(2))}
                    </span>
                  </div>
                </Col>
                <Col xl={6} className="d-flex flex-column row-gap-10">
                  {produto.planosSeguroPrestamista?.planosComLimiteSaque && (
                    <>
                      <label className="label-12">Seguro Prestamista:</label>
                      {produto.planosSeguroPrestamista?.planosComLimiteSaque.map(
                        (plano) => {
                          return (
                            <div
                              key={plano.codigoPlano}
                              className="d-flex column-gap-10"
                            >
                              <Checkbox
                                onChange={() => {
                                  onPlanoChangeCartaoConsignado(
                                    plano.codigoPlano,
                                    plano.valorPremio
                                  )
                                }}
                                checked={
                                  plano.codigoPlano ===
                                  atendimentosAbertos[
                                    index
                                  ].tabProduto.listPlanoSeguroPrestamistaCartaoConsignadoSelecionadoByMatricula.find(
                                    (p) => p.matricula === produto.matricula
                                  )?.value
                                }
                                disabled={!produto.flagSaqueParcelado}
                                style={{
                                  opacity: produto.flagSaqueParcelado ? 1 : 0.5,
                                  cursor: produto.flagSaqueParcelado
                                    ? 'default'
                                    : 'not-allowed'
                                }}
                              />
                              <label className="label-12">
                                {plano?.nomePlano.includes('Mega')
                                  ? 'Mega'
                                  : plano?.nomePlano.includes('Super')
                                  ? 'Super'
                                  : plano?.nomePlano}{' '}
                                - Valor:{' '}
                                {maskMoney(plano.valorPremio.toFixed(2))}
                              </label>
                              <AiOutlineExclamationCircle
                                style={{ cursor: 'pointer' }}
                                className="iconInformation"
                                onClick={() => {
                                  dispatch(
                                    atendimentoActions.setInfosProduto(
                                      true,
                                      'modalCoberturasSeguroPrestamista',
                                      index
                                    )
                                  )
                                  dispatch(
                                    atendimentoActions.setModalCoberturaSeguroPrestamista(
                                      {
                                        codigoPlano: plano.codigoPlano,
                                        coberturas: plano.coberturas?.map(
                                          (cobertura) => {
                                            return {
                                              nomeCobertura:
                                                cobertura.nomeCobertura,
                                              valorBeneficio:
                                                cobertura.valorBeneficio
                                            }
                                          }
                                        )
                                      }
                                    )
                                  )
                                }}
                              />
                            </div>
                          )
                        }
                      )}
                    </>
                  )}
                </Col>
                <Col xl={6} className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column">
                    <label className="label-12">Quantidade de parcelas:</label>
                    <span className="valor-produto-secondary">
                      {produto.quantidadeParcelas}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <label className="label-12">Valor parcelas:</label>
                    <span className="valor-produto-secondary">
                      R$ {maskMoney(produto.valorParcela?.toFixed(2))}
                    </span>
                  </div>
                </Col>
                <Col className="d-flex flex-column row-gap-10 saque-parcelado">
                  {/* <div className="d-flex column-gap-10">
                    <Checkbox
                      onChange={(e) => {
                        dispatch(
                          atendimentoActions.setInfosCartaoConsig(
                            e.target.checked,
                            'flagAberturaConta',
                            index,
                            produto.matricula
                          )
                        )
                      }}
                      checked={produto.flagAberturaConta}
                    />
                    <label className="label-12">Abrir conta</label>
                  </div> */}
                  <div className="d-flex column-gap-10">
                    <Checkbox
                      onChange={(e) => {
                        dispatch(
                          atendimentoActions.setInfosCartaoConsig(
                            e.target.checked,
                            'flagSaqueParcelado',
                            index,
                            produto.matricula
                          )
                        )
                        if (e.target.checked === true) {
                          const codigoPlanoCartaoConsignado: {
                            matricula: string
                            value: number
                            valorPremio: number
                            flagSaqueParcelado: boolean
                          }[] = []
                          const maiorValorPremioSeguroPrestamista =
                            produto.planosSeguroPrestamista?.planosComLimiteSaque?.reduce(
                              (max: number, plano) => {
                                return plano.valorPremio > max
                                  ? plano.valorPremio
                                  : max
                              },
                              produto.planosSeguroPrestamista
                                ?.planosComLimiteSaque[0].valorPremio
                            )
                          const plano =
                            produto.planosSeguroPrestamista?.planosComLimiteSaque?.find(
                              (plano) =>
                                plano.valorPremio ===
                                maiorValorPremioSeguroPrestamista
                            )

                          if (produto.servico === 6) {
                            codigoPlanoCartaoConsignado.push({
                              matricula: produto.matricula,
                              value: plano?.codigoPlano ?? 0,
                              valorPremio:
                                maiorValorPremioSeguroPrestamista ?? 0,
                              flagSaqueParcelado: e.target.checked
                            })
                          }

                          dispatch(
                            atendimentoActions.setInfosProduto(
                              codigoPlanoCartaoConsignado,
                              'listPlanoSeguroPrestamistaCartaoConsignadoSelecionadoByMatricula',
                              index
                            )
                          )
                          return
                        }
                        dispatch(
                          atendimentoActions.setInfosProduto(
                            [],
                            'listPlanoSeguroPrestamistaCartaoConsignadoSelecionadoByMatricula',
                            index
                          )
                        )
                      }}
                      checked={produto.flagSaqueParcelado}
                    />
                    <label className="label-12">Saque parcelado</label>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="d-flex flex-column">
              <label className="label-12">Valor saque:</label>
              <span className="valor-produto d-flex gap-2">
                R$
                <InvisibleInputDebounced
                  onChange={(e) => {
                    dispatch(
                      atendimentoActions.setInfosCartaoConsig(
                        maskMoney(e.target.value),
                        'valorSaque',
                        index,
                        produto.matricula
                      )
                    )
                  }}
                  onDebounce={() => {
                    if (
                      Number(removeMaskMoney(produto.valorSaque)) <=
                        produto.valorSaqueMaximo &&
                      Number(removeMaskMoney(produto.valorSaque)) >=
                        produto.valorSaqueMinimo
                    ) {
                      realizarSimulacao(
                        Number(removeMaskMoney(produto.valorSaque)),
                        produto.servico
                      )
                    }
                  }}
                  value={maskMoney(produto.valorSaque)}
                  delay={2000}
                />
              </span>
              <Range
                type="range"
                step={0.01}
                min={produto.valorSaqueMinimo}
                max={produto.valorSaqueMaximo}
                onChange={(e) => {
                  dispatch(
                    atendimentoActions.setInfosCartaoConsig(
                      maskMoney(Number(e.target.value)?.toFixed(2)),
                      'valorSaque',
                      index,
                      produto.matricula
                    )
                  )
                }}
                onMouseUp={(event) => {
                  realizarSimulacao(
                    Number(event.currentTarget.value),
                    produto.servico
                  )
                }}
                value={Number(removeMaskMoney(produto.valorSaque))?.toFixed(2)}
              />
              {invalid && (
                <p className="mensagem-erro">
                  O valor deve estar entre R${' '}
                  {maskMoney(produto.valorSaqueMinimo?.toFixed(2))} e R${' '}
                  {maskMoney(produto.valorSaqueMaximo?.toFixed(2))}
                </p>
              )}
            </div>
          </ContentProduto>
        </CardProdutoContainer>
      )
    case 7:
      return (
        <CardProdutoContainer isDetalhamentoOpen={isDetalhamentoOpen}>
          <ContentProduto>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center column-gap-10">
                <FiDollarSign className="icon" />
                {produto.matricula && (
                  <label className="matricula">M: {produto.matricula}</label>
                )}
              </div>
              <CheckboxProduto
                checked={checked}
                onChange={() => {
                  onCheck(produto)
                }}
              />
            </div>
            <div className="d-flex flex-column row-gap-15 justify-content-between">
              <div className="d-flex flex-row justify-content-between">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
                <FiChevronRight
                  onClick={() => {
                    if (
                      atendimentosAbertos[index].tabProduto.produtoSelected
                        .servico === 0 ||
                      atendimentosAbertos[index].tabProduto.produtoSelected
                        .servico === produto.servico
                    ) {
                      dispatch(atendimentoActions.toggleDetalhamento(index))
                    }
                    if (
                      atendimentosAbertos[index].tabProduto.detalhamentoIsOpen
                    ) {
                      dispatch(
                        atendimentoActions.selectProduto(
                          {
                            servico: produto.servico,
                            matricula: produto.matricula
                          },
                          index
                        )
                      )
                    }
                  }}
                  role="button"
                  className="icon"
                />
              </div>
              <Row>
                <Col xl={6} className="d-flex flex-comun row-gap-10">
                  <div className="d-flex flex-column">
                    <label className="label-12">Valor limite</label>
                    <span className="valor-produto">
                      R$ {maskMoney(produto.valorLimite?.toFixed(2))}
                    </span>
                  </div>
                </Col>
                <Col xl={6} className="d-flex flex-column row-gap-10">
                  {produto.planosSeguroPrestamista?.planosComLimiteSaque && (
                    <>
                      <label className="label-12">Seguro Prestamista:</label>
                      {produto.planosSeguroPrestamista?.planosComLimiteSaque.map(
                        (plano) => {
                          return (
                            <div
                              key={plano.codigoPlano}
                              className="d-flex column-gap-10"
                            >
                              <Checkbox
                                onChange={() => {
                                  onPlanoChangeCartaoBeneficio(
                                    plano.codigoPlano,
                                    plano.valorPremio
                                  )
                                }}
                                checked={
                                  plano.codigoPlano ===
                                  atendimentosAbertos[
                                    index
                                  ].tabProduto.listPlanoSeguroPrestamistaCartaoBeneficioSelecionadoByMatricula.find(
                                    (p) => p.matricula === produto.matricula
                                  )?.value
                                }
                                disabled={!produto.flagSaqueParcelado}
                                style={{
                                  opacity: produto.flagSaqueParcelado ? 1 : 0.5,
                                  cursor: produto.flagSaqueParcelado
                                    ? 'default'
                                    : 'not-allowed'
                                }}
                              />
                              <label className="label-12">
                                {plano?.nomePlano.includes('Mega')
                                  ? 'Mega'
                                  : plano?.nomePlano.includes('Super')
                                  ? 'Super'
                                  : plano?.nomePlano}{' '}
                                - Valor:{' '}
                                {maskMoney(plano.valorPremio.toFixed(2))}
                              </label>
                              <AiOutlineExclamationCircle
                                style={{ cursor: 'pointer' }}
                                className="iconInformation"
                                onClick={() => {
                                  dispatch(
                                    atendimentoActions.setInfosProduto(
                                      true,
                                      'modalCoberturasSeguroPrestamista',
                                      index
                                    )
                                  )
                                  dispatch(
                                    atendimentoActions.setModalCoberturaSeguroPrestamista(
                                      {
                                        codigoPlano: plano.codigoPlano,
                                        coberturas: plano.coberturas?.map(
                                          (cobertura) => {
                                            return {
                                              nomeCobertura:
                                                cobertura.nomeCobertura,
                                              valorBeneficio:
                                                cobertura.valorBeneficio
                                            }
                                          }
                                        )
                                      }
                                    )
                                  )
                                }}
                              />
                            </div>
                          )
                        }
                      )}
                    </>
                  )}
                </Col>
                <Col xl={6} className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column">
                    <label className="label-12">Quantidade de parcelas:</label>
                    <span className="valor-produto-secondary">
                      {produto.quantidadeParcelas}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <label className="label-12">Valor parcelas:</label>
                    <span className="valor-produto-secondary">
                      R$ {maskMoney(produto.valorParcela?.toFixed(2))}
                    </span>
                  </div>
                </Col>
                <Col className="d-flex flex-column row-gap-10 saque-parcelado">
                  {/* <div className="d-flex column-gap-10">
                    <Checkbox
                      onChange={(e) => {
                        dispatch(
                          atendimentoActions.setInfosCartaoBeneficio(
                            e.target.checked,
                            'flagAberturaConta',
                            index,
                            produto.matricula
                          )
                        )
                      }}
                      checked={produto.flagAberturaConta}
                    />
                    <label className="label-12">Abrir conta</label>
                  </div> */}
                  <div className="d-flex column-gap-10">
                    <Checkbox
                      onChange={(e) => {
                        dispatch(
                          atendimentoActions.setInfosCartaoBeneficio(
                            e.target.checked,
                            'flagSaqueParcelado',
                            index,
                            produto.matricula
                          )
                        )
                        if (e.target.checked === true) {
                          const codigoPlanoCartaoBeneficio: {
                            matricula: string
                            value: number
                            valorPremio: number
                          }[] = []
                          const maiorValorPremioSeguroPrestamista =
                            produto.planosSeguroPrestamista?.planosComLimiteSaque?.reduce(
                              (max: number, plano) => {
                                return plano.valorPremio > max
                                  ? plano.valorPremio
                                  : max
                              },
                              produto.planosSeguroPrestamista
                                ?.planosComLimiteSaque[0].valorPremio
                            )
                          const plano =
                            produto.planosSeguroPrestamista?.planosComLimiteSaque?.find(
                              (plano) =>
                                plano.valorPremio ===
                                maiorValorPremioSeguroPrestamista
                            )

                          if (produto.servico === 7) {
                            codigoPlanoCartaoBeneficio.push({
                              matricula: produto.matricula,
                              value: plano?.codigoPlano ?? 0,
                              valorPremio:
                                maiorValorPremioSeguroPrestamista ?? 0
                            })
                          }
                          dispatch(
                            atendimentoActions.setInfosProduto(
                              codigoPlanoCartaoBeneficio,
                              'listPlanoSeguroPrestamistaCartaoBeneficioSelecionadoByMatricula',
                              index
                            )
                          )
                          return
                        }
                        dispatch(
                          atendimentoActions.setInfosProduto(
                            [],
                            'listPlanoSeguroPrestamistaCartaoBeneficioSelecionadoByMatricula',
                            index
                          )
                        )
                      }}
                      checked={produto.flagSaqueParcelado}
                    />
                    <label className="label-12">Saque parcelado</label>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="d-flex flex-column">
              <label className="label-12">Valor saque:</label>
              <span className="valor-produto">
                R$ {maskMoney(produto.valorSaque)}
              </span>
            </div>
          </ContentProduto>
        </CardProdutoContainer>
      )
    case 8:
      return (
        <CardProdutoContainer isDetalhamentoOpen={isDetalhamentoOpen}>
          <ContentProduto className="d-flex justify-content-between">
            <div className="d-flex flex-column row-gap-20">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center column-gap-10">
                  <FiDollarSign className="icon" />
                  {produto.matricula && (
                    <label className="matricula">M: {produto.matricula}</label>
                  )}
                </div>
                <CheckboxProduto
                  checked={checked}
                  onChange={() => {
                    onCheck(produto)
                  }}
                />
              </div>
              <div className="d-flex flex-row">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
              </div>
            </div>
            <Row>
              <Col xl={5} className="d-flex flex-column gap-3">
                <div className="d-flex flex-column">
                  <label className="label-12">RCC:</label>
                  <span className="valor-produto-secondary">
                    R$ {maskMoney(produto.valorMargem?.toFixed(2))}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <label className="label-12">Limite cartão:</label>
                  <span className="valor-produto-secondary">
                    R$ {maskMoney(produto.limiteCartao?.toFixed(2))}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <label className="label-12">Limite disponível:</label>
                  <span className="valor-produto-secondary">
                    R$ {maskMoney(produto.limiteDisponivel?.toFixed(2))}
                  </span>
                </div>
              </Col>
              <Col xl={7} className="d-flex flex-column row-gap-20">
                {produto.planosSeguroPrestamista?.planosComLimiteSaque && (
                  <>
                    <label className="label-12">Seguro Prestamista:</label>
                    {produto.planosSeguroPrestamista?.planosComLimiteSaque.map(
                      (plano) => {
                        return (
                          <div
                            key={plano.codigoPlano}
                            className="d-flex column-gap-10"
                          >
                            <Checkbox
                              onChange={() => {
                                onPlanoChangeBeneficio(
                                  plano.codigoPlano,
                                  plano.valorPremio
                                )
                              }}
                              checked={
                                plano.codigoPlano ===
                                atendimentosAbertos[
                                  index
                                ].tabProduto.listPlanoSeguroPrestamistaBeneficioSelecionadoByMatricula.find(
                                  (p) => p.matricula === produto.matricula
                                )?.value
                              }
                            />
                            <label className="label-12">
                              {plano?.nomePlano.includes('Mega')
                                ? 'Mega'
                                : plano?.nomePlano.includes('Super')
                                ? 'Super'
                                : plano?.nomePlano}{' '}
                              - Valor: {maskMoney(plano.valorPremio.toFixed(2))}
                            </label>
                            <AiOutlineExclamationCircle
                              style={{ cursor: 'pointer' }}
                              className="iconInformation"
                              onClick={() => {
                                dispatch(
                                  atendimentoActions.setInfosProduto(
                                    true,
                                    'modalCoberturasSeguroPrestamista',
                                    index
                                  )
                                )
                                dispatch(
                                  atendimentoActions.setModalCoberturaSeguroPrestamista(
                                    {
                                      codigoPlano: plano.codigoPlano,
                                      coberturas: plano.coberturas?.map(
                                        (cobertura) => {
                                          return {
                                            nomeCobertura:
                                              cobertura.nomeCobertura,
                                            valorBeneficio:
                                              cobertura.valorBeneficio
                                          }
                                        }
                                      )
                                    }
                                  )
                                )
                              }}
                            />
                          </div>
                        )
                      }
                    )}
                  </>
                )}
              </Col>
            </Row>
            <div className="d-flex flex-column">
              <label className="label-12">Valor saque:</label>
              <span className="valor-produto d-flex gap-2">
                R$
                <InvisibleInputDebounced
                  onChange={(e) => {
                    dispatch(
                      atendimentoActions.setInfosSaqueComplementarBeneficio(
                        maskMoney(e.target.value),
                        'valorSaque',
                        index,
                        produto.matricula
                      )
                    )
                  }}
                  onDebounce={() => {
                    if (
                      Number(removeMaskMoney(produto.valorSaque)) <=
                        produto.valorSaqueMaximo &&
                      Number(removeMaskMoney(produto.valorSaque)) >=
                        produto.valorSaqueMinimo
                    ) {
                      realizarSimulacao(
                        Number(removeMaskMoney(produto.valorSaque)),
                        produto.servico
                      )
                    }
                  }}
                  value={maskMoney(produto.valorSaque)}
                  delay={2000}
                />
              </span>
              <Range
                type="range"
                step={0.01}
                min={produto.valorSaqueMinimo}
                max={produto.valorSaqueMaximo}
                onChange={(e) => {
                  dispatch(
                    atendimentoActions.setInfosSaqueComplementarBeneficio(
                      maskMoney(Number(e.target.value)?.toFixed(2)),
                      'valorSaque',
                      index,
                      produto.matricula
                    )
                  )
                }}
                onMouseUp={(event) => {
                  realizarSimulacao(
                    Number(event.currentTarget.value),
                    produto.servico
                  )
                }}
                value={Number(removeMaskMoney(produto.valorSaque))?.toFixed(2)}
              />
              {invalid && (
                <p className="mensagem-erro">
                  O valor deve estar entre R${' '}
                  {maskMoney(produto.valorSaqueMinimo?.toFixed(2))} e R${' '}
                  {maskMoney(produto.valorSaqueMaximo?.toFixed(2))}
                </p>
              )}
            </div>
          </ContentProduto>
        </CardProdutoContainer>
      )
    case 9:
      return (
        <CardProdutoContainer isDetalhamentoOpen={isDetalhamentoOpen}>
          <ContentProduto className="d-flex justify-content-between">
            <div className="d-flex flex-column row-gap-20">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center column-gap-10">
                  <FiDollarSign className="icon" />
                </div>
                <CheckboxProduto
                  checked={checked}
                  onChange={() => {
                    onCheck(produto)
                  }}
                />
              </div>
              <div className="d-flex flex-row justify-content-between">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
                <FiChevronRight
                  onClick={() => {
                    if (
                      atendimentosAbertos[index].tabProduto.produtoSelected
                        .servico === 0 ||
                      atendimentosAbertos[index].tabProduto.produtoSelected
                        .servico === produto.servico
                    ) {
                      dispatch(atendimentoActions.toggleDetalhamento(index))
                    }
                    if (
                      atendimentosAbertos[index].tabProduto.detalhamentoIsOpen
                    ) {
                      dispatch(
                        atendimentoActions.selectProduto(
                          {
                            servico: produto.servico,
                            matricula: produto.matricula
                          },
                          index
                        )
                      )
                    }
                  }}
                  role="button"
                  className="icon"
                />
              </div>
            </div>
            <span className="valor-produto">
              R$ {maskMoney(produto.valorTotalFinanciado?.toFixed(2))}
            </span>
          </ContentProduto>
        </CardProdutoContainer>
      )
    case 12:
      return (
        <CardProdutoContainer isDetalhamentoOpen={isDetalhamentoOpen}>
          <ContentProduto className="d-flex justify-content-between">
            <div className="d-flex flex-column row-gap-20">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center column-gap-10">
                  <FiDollarSign className="icon" />
                  {produto.matricula && (
                    <label className="matricula">M: {produto.matricula}</label>
                  )}
                </div>
                <CheckboxProduto
                  checked={checked}
                  onChange={() => {
                    onCheck(produto)
                  }}
                />
              </div>
              <div className="d-flex flex-row align-items-center">
                <label className="nome-produto mb-0 w-auto">
                  {EnumServicos[produto.servico]}
                </label>
                <AiOutlineExclamationCircle
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                  className="iconInformation"
                  onClick={() => {
                    dispatch(
                      atendimentoActions.setInfosProduto(
                        true,
                        'modalSeguroSindnapi',
                        index
                      )
                    )
                    dispatch(
                      atendimentoActions.setModalSeguroSindnapi({
                        coberturasField:
                          produto.produtosSINDNAP?.planos?.flatMap((planos) =>
                            planos.coberturasField.map((cobertura) => {
                              return {
                                nomeCoberturaField:
                                  cobertura.nomeCoberturaField,
                                valorBeneficioField:
                                  cobertura.valorBeneficioField
                              }
                            })
                          )
                      })
                    )
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <label className="label-12">Valor mensalidade:</label>
              <span className="valor-produto">
                R${' '}
                {maskMoney(
                  produto.produtosSINDNAP?.planos[0].valorPremioAux?.toFixed(2)
                )}
              </span>
            </div>
          </ContentProduto>
        </CardProdutoContainer>
      )

    default:
      return (
        <CardProdutoContainer isDetalhamentoOpen={isDetalhamentoOpen}>
          <ContentProduto className="d-flex justify-content-between">
            <div className="d-flex flex-column row-gap-20">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center column-gap-10">
                  <FiDollarSign className="icon" />
                  {produto.matricula && (
                    <label className="matricula">M: {produto.matricula}</label>
                  )}
                </div>
                <CheckboxProduto
                  checked={checked}
                  onChange={() => {
                    onCheck(produto)
                  }}
                />
              </div>
              <div className="d-flex flex-row justify-content-between">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
                <FiChevronRight
                  onClick={() => {
                    if (
                      atendimentosAbertos[index].tabProduto.produtoSelected
                        .servico === 0 ||
                      (atendimentosAbertos[index].tabProduto.produtoSelected
                        .servico === produto.servico &&
                        atendimentosAbertos[index].tabProduto.produtoSelected
                          .matricula === produto.matricula)
                    ) {
                      dispatch(atendimentoActions.toggleDetalhamento(index))
                    }
                    if (
                      atendimentosAbertos[index].tabProduto.detalhamentoIsOpen
                    ) {
                      dispatch(
                        atendimentoActions.selectProduto(
                          {
                            servico: produto.servico,
                            matricula: produto.matricula
                          },
                          index
                        )
                      )
                    }
                  }}
                  role="button"
                  className="icon"
                />
              </div>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column">
                  <label className="label-12">Quantidade de parcelas:</label>
                  <span className="valor-produto-secondary">
                    {produto.quantidadeParcelas}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <label className="label-12">Valor parcelas:</label>
                  <span className="valor-produto-secondary">
                    R$ {maskMoney(produto.valorParcela?.toFixed(2))}
                  </span>
                </div>
              </div>
            </div>
            <span className="valor-produto">
              R$ {maskMoney(produto.valorTotalFinanciado?.toFixed(2))}
            </span>
          </ContentProduto>
        </CardProdutoContainer>
      )
  }
}

export default CardProduto
