/* eslint-disable @typescript-eslint/no-explicit-any */
import { ParametrizacaoProduto } from 'models/backoffice/produto/type'
import { Paginacao } from 'models/types'

export enum TYPES {
  SET_ACTIVE_TAB = '@@produto/SET_ACTIVE_TAB',
  SET_ITEM_SELECTED_ID = '@@produto/SET_ITEM_SELECTED_ID',
  TOGGLE_MODAL_EXCLUSAO = '@@produto/TOGGLE_MODAL_EXCLUSAO',
  SET_PESQUISA = '@@produto/SET_PESQUISA',
  SET_PARAMETRIZACAO_LIST = '@@produto/SET_PARAMETRIZACAO_LIST',
  SET_REGISTER = '@@produto/SET_REGISTER',
  SET_VISUALIZACAO = '@@produto/SET_VISUALIZACAO',
  SET_PAGINATION = '@@produto/SET_PAGINATION',
  SET_INVALIDS = '@@produto/SET_INVALIDS',
  SET_INVALIDS_ARRAY = '@@produto/SET_INVALIDS_ARRAY',
  CLEAN_INVALIDS = '@@produto/CLEAN_INVALIDS',
  CLEAN_REGISTER = '@@produto/CLEAN_REGISTER',
  SET_OPTIONS_SELECT = '@@produto/SET_OPTIONS_SELECT',

  GET_ALL_PARAMETRIZACAO_SAGA = '@@produto/GET_ALL_PARAMETRIZACAO_SAGA',
  GET_PARAMETRIZACAO_SAGA = '@@produto/GET_PARAMETRIZACAO_SAGA',
  PUT_PARAMETRIZACAO_SAGA = '@@produto/PUT_PARAMETRIZACAO_SAGA',
  POST_PARAMETRIZACAO_SAGA = '@@produto/POST_PARAMETRIZACAO_SAGA',
  GET_PRODUTOS_SAGA = '@@produto/GET_PRODUTOS_SAGA',
  GET_INSTITUICOES_SAGA = '@@produto/GET_INSTITUICOES_SAGA',
  GET_CONVENIOS_SAGA = '@@produto/GET_CONVENIOS_SAGA',
  GET_TIPO_OPERACAO_SAGA = '@@produto/GET_TIPO_OPERACAO_SAGA',
  GET_TIPO_FORMALIZACAO_SAGA = '@@produto/GET_TIPO_FORMALIZACAO_SAGA',
  GET_TAXAS_SAGA = '@@produto/GET_TAXAS_SAGA',
  GET_TABELAS_BANCO_SAGA = '@@produto/GET_TABELAS_BANCO_SAGA',
  GET_BENEFICIOS_SAGA = '@@produto/GET_BENEFICIOS_SAGA',
  ADD_TABELA_COMISSAO = '@@produto/ADD_TABELA_COMISSAO',
  REMOVE_TABELA_COMISSAO = '@@produto/REMOVE_TABELA_COMISSAO',
  REMOVE_ALL_TABELA_COMISSAO = '@@produto/REMOVE_ALL_TABELA_COMISSAO',
  TOGGLE_CARD = '@@produto/TOGGLE_CARD',
  TOGGLE_ALL_CARDS = '@@produto/TOGGLE_ALL_CARDS',
  CLEAN_BANCOS_NAO_PORTADOS = '@@produto/CLEAN_BANCOS_NAO_PORTADOS',
  CLEAN_CARTAO = '@@produto/CLEAN_CARTAO',
  CLEAN_GRUPOS_BENEFICIO = '@@produto/CLEAN_GRUPOS_BENEFICIO',
  CLEAN_IDADE = '@@produto/CLEAN_IDADE',
  CLEAN_PMT_PAGA = '@@produto/CLEAN_PMT_PAGA',
  CLEAN_SALDO_DEVEDOR = '@@produto/CLEAN_SALDO_DEVEDOR',
  CLEAN_TAXA_ORIGINAL = '@@produto/CLEAN_TAXA_ORIGINAL',
  CLEAN_TROCO_MINIMO = '@@produto/CLEAN_TROCO_MINIMO',
  CLEAN_VALOR_CONTRATO = '@@produto/CLEAN_VALOR_CONTRATO',
  CLEAN_VALOR_MARGEM = '@@produto/CLEAN_VALOR_MARGEM',
  VISUALIZAR_PARAMETRIZACAO_DISPATCH_SAGA = '@@produto/VISUALIZAR_PARAMETRIZACAO_DISPATCH_SAGA',
  EDITAR_PARAMETRIZACAO_DISPATCH_SAGA = '@@produto/EDITAR_PARAMETRIZACAO_DISPATCH_SAGA',
  DELETE_PARAMETRIZACAO_DISPATCH_SAGA = '@@produto/DELETE_PARAMETRIZACAO_DISPATCH_SAGA'
}

interface Search {
  pesquisa: string
  filtro: string | null
}

export interface PRODUTO {
  activeTab: string
  search: Search
  register: ParametrizacaoProduto
  pagination: Paginacao
  invalids: InvalidsParametrizacaoProduto
  visualizacao: boolean
  selects: Selects
  listaParametrizacao: ParametrizacaoList[]
  itemSelectedId: string
  showConfirmacaoExclusao: boolean
  cardsState: CardsState
}

interface Produto {
  id: string
  nome: string
}

interface Banco {
  id: string
  descricao: string
}

interface Convenio {
  id: string
  descricao: string
}

interface TipoOperacao {
  id: string
  descricao: string
}
interface TipoFormalizacao {
  id: string
  descricao: string
}
interface Taxas {
  valor: number
  parametrizacaoProdutos: any
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: any
  idUsuarioUltimaAlteracao: any
  dataUltimaAlteracao: any
}

interface Tabelas {
  descricao: string
  bancoId: string
  banco: any
  produtoBancoId: string
  produtoBanco: any
  convenioBancoId: string
  convenioBanco: any
  tipoOperacaoBancoId: string
  tipoOperacaoBanco: any
  dtInicioVig: string
  dtFimVig: string
  codigoTabela: string
  tabelaComissaoEventos: any
  parametrizacaoProdutosValoresTabelas: any
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: any
  idUsuarioUltimaAlteracao: any
  dataUltimaAlteracao: any
}

interface Beneficio {
  codigo: number
  descricao: string
  parametrizacaoProdutoGruposBeneficio: any
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: any
  idUsuarioUltimaAlteracao: any
  dataUltimaAlteracao: any
}
export interface Selects {
  produtos: Produto[]
  instituicoes: Banco[]
  convenios: Convenio[]
  tipoOperacao: TipoOperacao[]
  tipoFormalizacao: TipoFormalizacao[]
  taxas: Taxas[]
  tabelas: Tabelas[]
  beneficios: Beneficio[]
}

export interface InvalidsParametrizacaoProduto {
  id: boolean
  produtoId: boolean
  bancoId: boolean
  convenioId: boolean
  tipoOperacaoId: boolean
  tipoFormalizacaoId: boolean
  margemMin: boolean
  margemMax: boolean
  idadeDe: boolean
  idadeAte: boolean
  mesesDe: boolean
  mesesAte: boolean
  diasDe: boolean
  diasAte: boolean
  prazo: boolean
  trocoMin: boolean
  trocoMax: boolean
  pmtPaga: boolean
  saldoDevedorMin: boolean
  saldoDevedorMax: boolean
  valorContrato: boolean
  cartaoInstituicaoId: boolean
  taxas: boolean
  bancosNaoPortados: boolean
  descricaoGrupoBeneficio: boolean
  beneficios: []
  tabelas: TabelasInvalids[]
}

export interface TabelasInvalids {
  id: boolean
  tabelasComissao: boolean
  rangePrazoInicial: boolean
  rangePrazoFinal: boolean
}

export interface CardsState {
  bancosNaoPortados: boolean
  cartao: boolean
  gruposBeneficio: boolean
  idade: boolean
  pmtPaga: boolean
  saldoDevedor: boolean
  tabelasServicos: boolean
  taxaOriginal: boolean
  trocoMinimo: boolean
  valorContrato: boolean
  valorMargem: boolean
}

export interface ParametrizacaoList {
  id: string
  descricaoProduto: string
  descricaoBanco: string
  descricaoConvenio: string
  descricaoTipoOperacao: string
  descricaoTipoFormalizacao: string
}
