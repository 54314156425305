import Main from 'layouts/main'
import { TabContent, TabPane } from 'reactstrap'
import SearchAndRegister from './Tabs/SearchAndRegister'

const Blacklist = () => {
  return (
    <Main>
      <TabContent>
        <TabPane>
          <SearchAndRegister />
        </TabPane>
      </TabContent>
    </Main>
  )
}

export default Blacklist
