import React from 'react'
import { FiPieChart } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { INDICADORES } from 'store/modules/indicadores/types'
import { SimpleTitleCard } from 'styles/global'
import { maskMoney } from 'util/masks'
import { CardBigNumbers, Colors } from '../style'
import LoadingComponent from './LoadingComponent'

const BigNumbers = () => {
  const { bigNumbers, loadings } = useSelector<ApplicationState, INDICADORES>(
    (state) => state.indicadores
  )

  return (
    <div className="d-flex flex-column">
      <SimpleTitleCard>
        <FiPieChart className="icon" /> Big Numbers
      </SimpleTitleCard>
      <Row className="d-flex justify-content-evenly">
        <LoadingComponent isLoading={loadings.bigNumbers}>
          <CardBigNumbers background={Colors.purple}>
            <div>
              <label>Total de vendas</label>
              <span>R$ {maskMoney(bigNumbers.totalVendas?.toFixed(2))}</span>
            </div>
          </CardBigNumbers>
          <CardBigNumbers background={Colors.cyano}>
            <div>
              <label>Quantidade de vendas</label>
              <span>{bigNumbers.quantidadeVendas}</span>
            </div>
          </CardBigNumbers>
          <CardBigNumbers background={Colors.green}>
            <div>
              <label>Ticket Médio</label>
              <span>R$ {maskMoney(bigNumbers.ticketMedio?.toFixed(2))}</span>
            </div>
          </CardBigNumbers>
        </LoadingComponent>
      </Row>
    </div>
  )
}

export default BigNumbers
