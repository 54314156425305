import React from 'react'
import CustomSelect from 'components/Select'
import { FiTrash } from 'react-icons/fi'
import { RiIncreaseDecreaseLine } from 'react-icons/ri'
import { Col, Collapse, Row } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import CardParametrizacao from './CardParametrizacao'
import { BiChevronDown } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { PRODUTO } from 'store/modules/backoffice/produto/types'
import * as produtoActions from 'store/modules/backoffice/produto/actions'
import { ParametrizacaoProdutoReq } from 'models/backoffice/produto/type'
import useRedux from 'hooks/useRedux'

const CardBancosNaoPortados = () => {
  const { register, selects, visualizacao, cardsState, invalids } = useSelector<
    ApplicationState,
    PRODUTO
  >((state) => state.produto)

  const { dispatch } = useRedux()

  type Field = keyof ParametrizacaoProdutoReq

  function onChange(value: string, field: Field) {
    dispatch(produtoActions.setRegister({ ...register, [field]: value }))
  }

  function onToggle() {
    dispatch(
      produtoActions.toggleCard(
        !cardsState.bancosNaoPortados,
        'bancosNaoPortados'
      )
    )
  }

  return (
    <CardParametrizacao>
      <div className="d-flex justify-content-between">
        <SimpleTitleCard color="#000" colorIcon="#000">
          <RiIncreaseDecreaseLine className="icon" />
          Bancos não portados
        </SimpleTitleCard>
        <BiChevronDown onClick={() => onToggle()} className="toggler" />
      </div>
      <Collapse isOpen={cardsState.bancosNaoPortados}>
        <div className="d-flex flex-column row-gap-10">
          <Row>
            <Col md={6}>
              <label className="label-12">Banco</label>
              <CustomSelect
                options={selects.instituicoes}
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'bancosNaoPortados')
                }}
                isMulti
                value={register.bancosNaoPortados}
                accessorLabel="descricao"
                accessorValue="id"
                disabled={visualizacao}
                invalid={invalids.bancosNaoPortados}
              />
            </Col>
          </Row>
          <div className="linha-horizontal"></div>
          {!visualizacao && (
            <Row>
              <Col className="d-flex justify-content-end">
                <FiTrash
                  className="icon-trash"
                  onClick={() =>
                    dispatch(produtoActions.cleanBancosNaoPortados())
                  }
                />
              </Col>
            </Row>
          )}
        </div>
      </Collapse>
    </CardParametrizacao>
  )
}

export default CardBancosNaoPortados
