import moment from 'moment'
import { maskDate } from 'util/masks'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)
export const schema = yup.object().shape({
  atendimentoAgendadoId: yup.string().required(),
  confirmado: yup.bool().nullable().optional().label('Confirmado'),
  dataAtendimento: yup
    .date()
    .min(
      moment().format('YYYY-MM-DD'),
      ({ min }) =>
        `Data do atendimento deve ser igual ou posterior a ${maskDate(
          min as string,
          'DD/MM/YYYY'
        )}`
    )
    .required()
    .label('Data do atendimento'),
  horario: yup
    .string()
    .required()
    .test(
      'e-maior',
      `A data e o horário devem ser, respectivamente, posteriores aos atuais`,
      function (value) {
        const { dataAtendimento } = this.parent
        const data = moment(dataAtendimento).format('YYYY-MM-DD')
        const dataHora = moment(data + ' ' + value).format('YYYY-MM-DD HH:mm')
        return moment(dataHora).isSameOrAfter(moment())
      }
    )
    .label('Horário'),
  tipoAtendimentoId: yup
    .string()
    .transform((v) => (v ? v : ''))
    .required()
    .label('Tipo de agendamento')
})
