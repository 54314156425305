import React, { useCallback, useEffect } from 'react'
import FullCalendar, { EventInput } from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import ptLocale from '@fullcalendar/core/locales/pt'
import SimpleCard from 'components/SimpleCard'
import {
  CalendarWrapper,
  Colors,
  EventWrapper,
  IconEventWrapper,
  LegendasWrapper
} from '../style'
import * as agendaActions from 'store/modules/agenda/actions'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { AGENDA } from 'store/modules/agenda/types'
import useRedux from 'hooks/useRedux'
import { maskDate } from 'util/masks'
import { AiOutlinePhone, AiOutlineShop } from 'react-icons/ai'

const Calendario = () => {
  const { agendaAtendimentosEventos, activeTab, dataReferencia } = useSelector<
    ApplicationState,
    AGENDA
  >((state) => state.agenda)

  const { dispatch } = useRedux()

  function getEvents(
    dataInicio: string,
    dataFim: string,
    callback: (events: EventInput[]) => void
  ) {
    if (
      dataReferencia.dataInicio !== dataInicio &&
      dataReferencia.dataFim !== dataFim
    ) {
      dispatch(
        agendaActions.getAgendaAtendimentosEventosSaga(
          maskDate(dataInicio, 'YYYY-MM-DD'),
          maskDate(dataFim, 'YYYY-MM-DD')
        )
      )
      dispatch(
        agendaActions.setDataReferencia({
          dataInicio,
          dataFim
        })
      )
    }
    callback(
      agendaAtendimentosEventos?.map((evento): EventInput => {
        return {
          start: evento.data,
          title: evento.nomePessoa,
          extendedProps: {
            tipoAgendamento: evento.tipoAtendimento,
            confirmado: evento.confirmado,
            atendimentoConcluidoId: evento.atendimentoConcluidoId
          }
        }
      }) || []
    )
  }

  function onDateClick(data: string) {
    dispatch(agendaActions.setDiaDetalhamento(maskDate(data, 'YYYY-MM-DD')))
    dispatch(agendaActions.cleanFiltroBigNumbers())
    dispatch(agendaActions.getAgendaAtendimentosDataSaga())
  }

  const calendarRef = React.createRef<FullCalendar>()

  const rerender = useCallback(() => {
    if (calendarRef && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.updateSize()
    }
  }, [calendarRef])

  useEffect(() => {
    rerender()
  }, [activeTab, rerender])

  function getBackgroundColor(confirmado: boolean, concluido: string) {
    if (concluido) {
      return Colors.cinzaClaro
    }
    if (confirmado) {
      return Colors.verdeGradienteRiber
    }
    return Colors.laranjaGradienteRiber
  }

  return (
    <SimpleCard>
      <LegendasWrapper>
        <div>
          <IconEventWrapper background={Colors.verdeGradienteRiber} />
          <label>Confirmado</label>
        </div>
        <div>
          <IconEventWrapper background={Colors.laranjaGradienteRiber} />
          <label>Não confirmado</label>
        </div>
        <div>
          <IconEventWrapper background={Colors.cinzaRiber}>
            <AiOutlinePhone />
          </IconEventWrapper>
          <label>Telefônico</label>
        </div>
        <div>
          <IconEventWrapper background={Colors.cinzaRiber}>
            <AiOutlineShop />
          </IconEventWrapper>
          <label>Presencial</label>
        </div>
      </LegendasWrapper>
      <CalendarWrapper>
        <FullCalendar
          ref={calendarRef}
          locale={ptLocale}
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          events={(e, callback) => {
            getEvents(e.startStr, e.endStr, callback)
          }}
          dayMaxEventRows={2}
          dateClick={(e) => onDateClick(e.dateStr)}
          eventClick={(e) => onDateClick(e.event.startStr)}
          headerToolbar={{
            right: 'today prev,next',
            center: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          eventContent={(arg) => {
            switch (arg.event.extendedProps.tipoAgendamento) {
              case 'Presencial':
                return (
                  <EventWrapper>
                    <IconEventWrapper
                      background={getBackgroundColor(
                        arg.event.extendedProps.confirmado,
                        arg.event.extendedProps.atendimentoConcluidoId
                      )}
                    >
                      <AiOutlineShop />
                    </IconEventWrapper>
                    <div className="fc-event-time">{arg.timeText}</div>
                    <div className="fc-event-title">{arg.event.title}</div>
                  </EventWrapper>
                )
              case 'Telefone':
                return (
                  <EventWrapper>
                    <IconEventWrapper
                      background={getBackgroundColor(
                        arg.event.extendedProps.confirmado,
                        arg.event.extendedProps.atendimentoConcluidoId
                      )}
                    >
                      <AiOutlinePhone />
                    </IconEventWrapper>
                    <div className="fc-event-time">{arg.timeText}</div>
                    <div className="fc-event-title">{arg.event.title}</div>
                  </EventWrapper>
                )
              default:
                return (
                  <EventWrapper>
                    <IconEventWrapper
                      background={getBackgroundColor(
                        arg.event.extendedProps.confirmado,
                        arg.event.extendedProps.atendimentoConcluidoId
                      )}
                    >
                      <AiOutlinePhone />
                    </IconEventWrapper>
                    <div className="fc-event-time">{arg.timeText}</div>
                    <div className="fc-event-title">{arg.event.title}</div>
                  </EventWrapper>
                )
            }
          }}
        />
      </CalendarWrapper>
    </SimpleCard>
  )
}

export default Calendario
