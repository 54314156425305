import { GridColumns } from '@mui/x-data-grid'
import { Row } from 'react-data-grid'

const headerTheme = 'table-header--purple'

export const COLUMNS: GridColumns<Row> = [
  {
    field: 'nomeLoja',
    headerName: 'Loja',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'qtdClientesTotal',
    headerName: 'Total de clientes',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  }
]
