import React from 'react'
import { RiUser2Line } from 'react-icons/ri'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import { Rank, Line } from '../style'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { INDICADORES } from 'store/modules/indicadores/types'
import { maskMoney } from 'util/masks'
import useRedux from 'hooks/useRedux'
import * as indicadoresActions from 'store/modules/indicadores/actions'
import LoadingComponent from './LoadingComponent'

const TopConsultores = () => {
  const {
    topConsultores,
    topConsultoresConversao,
    loadings,
    verMaisTopConsultores,
    verMaisTopConsultoresConversao
  } = useSelector<ApplicationState, INDICADORES>((state) => state.indicadores)

  const { dispatch } = useRedux()

  function toggleTopConsultores() {
    verMaisTopConsultores
      ? dispatch(indicadoresActions.getTopConsultoresSaga())
      : dispatch(indicadoresActions.getVerMaisTopConsultoresSaga())
  }

  function toggleTopConsultoresConversao() {
    verMaisTopConsultoresConversao
      ? dispatch(indicadoresActions.getTopConsultoresConversaoSaga())
      : dispatch(indicadoresActions.getVerMaisTopConsultoresConversaoSaga())
  }

  return (
    <div className="d-flex flex-column">
      <SimpleTitleCard>
        <RiUser2Line className="icon" /> Top Consultores
      </SimpleTitleCard>
      <Row className="d-flex row-gap-15">
        <Col md={6}>
          <Rank>
            <h1>5 Maiores consultores</h1>
            <Line />
            <LoadingComponent isLoading={loadings.topConsultores}>
              <ul>
                {topConsultores?.map((e, i) => {
                  const itemList = (
                    <li key={i}>
                      <span id={`topConsultorNome` + i}>{`${i + 1} - ${
                        e.nome
                      }`}</span>
                      <label id={`topConsultorValor` + i}>
                        {maskMoney(e.totalVendas?.toFixed(2))}
                      </label>
                      <UncontrolledTooltip
                        placement="left"
                        target={`topConsultorNome` + i}
                      >
                        {e.nome}
                      </UncontrolledTooltip>
                      <UncontrolledTooltip
                        placement="right"
                        target={`topConsultorValor` + i}
                      >
                        {maskMoney(e.totalVendas?.toFixed(2))}
                      </UncontrolledTooltip>
                    </li>
                  )
                  if (verMaisTopConsultores) {
                    return itemList
                  } else {
                    if (i < 5) {
                      return itemList
                    }
                  }
                })}
              </ul>
              <div className="d-flex justify-content-center">
                <span
                  onClick={() => {
                    toggleTopConsultores()
                  }}
                  role="button"
                  className="ver-mais"
                >
                  {topConsultores.length
                    ? verMaisTopConsultores
                      ? 'Ver menos'
                      : 'Ver mais'
                    : ''}
                </span>
              </div>
            </LoadingComponent>
          </Rank>
        </Col>
        <Col md={6}>
          <Rank>
            <h1>5 Melhores consultores em conversão</h1>
            <Line />
            <LoadingComponent isLoading={loadings.topConsultoresConversao}>
              <ul>
                {topConsultoresConversao?.map((e, i) => {
                  const itemList = (
                    <li key={i}>
                      <span id={`topConsultorConversaoNome` + i}>{`${i + 1} - ${
                        e.nome
                      }`}</span>
                      <label id={`topConsultorConversaoValor` + i}>
                        {e.quantidadeVendas}
                      </label>
                      <UncontrolledTooltip
                        placement="left"
                        target={`topConsultorConversaoNome` + i}
                      >
                        {e.nome}
                      </UncontrolledTooltip>
                      <UncontrolledTooltip
                        placement="right"
                        target={`topConsultorConversaoValor` + i}
                      >
                        {e.quantidadeVendas}
                      </UncontrolledTooltip>
                    </li>
                  )
                  if (verMaisTopConsultoresConversao) {
                    return itemList
                  } else {
                    if (i < 5) {
                      return itemList
                    }
                  }
                })}
              </ul>
              <div className="d-flex justify-content-center">
                <span
                  onClick={() => {
                    toggleTopConsultoresConversao()
                  }}
                  role="button"
                  className="ver-mais"
                >
                  {topConsultoresConversao.length
                    ? verMaisTopConsultoresConversao
                      ? 'Ver menos'
                      : 'Ver mais'
                    : ''}
                </span>
              </div>
            </LoadingComponent>
          </Rank>
        </Col>
      </Row>
    </div>
  )
}

export default TopConsultores
