import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import SimpleCard from 'components/SimpleCard'
import { RiFolderLine } from 'react-icons/ri'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import * as yup from 'yup'
import useRedux from 'hooks/useRedux'
import {
  cleanRegister,
  setActiveTab,
  setRegisterFields
} from 'store/modules/backoffice/grupos/actions'
import * as grupoActions from 'store/modules/backoffice/grupos/actions'
import { GrupoReq } from 'models/backoffice/grupos/type'
import { GRUPOS } from 'store/modules/backoffice/grupos/types'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { Lojas } from 'models/types'

export default function Register() {
  const { register, invalids, lojas, visualizacao } = useSelector<
    ApplicationState,
    GRUPOS
  >((state) => state.grupos)

  const { dispatch } = useRedux()

  const schema: yup.SchemaOf<GrupoReq> = yup.object().shape({
    descricao: yup.string().required().min(3).max(100),
    lojas: yup.array().min(1).required(),
    id: yup.string()
  })

  const lojasOptions = lojas.map((item: Lojas) => ({
    label: item.nome,
    value: item.id
  }))

  function onSave() {
    dispatch(grupoActions.cleanInvalids())
    schema
      .validate(
        {
          descricao: register.descricao,
          lojas: register.lojas
        },
        { abortEarly: false }
      )
      .then(() => {
        if (!register.id) {
          dispatch(grupoActions.criarGrupoSaga())
        } else {
          dispatch(grupoActions.atualizarGrupoSaga())
        }
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(grupoActions.setInvalids(true, e.path || ''))
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  function onChange(value: string | number | boolean, field: string) {
    dispatch(setRegisterFields(value, field))
  }

  function voltaParaSearch() {
    dispatch(cleanRegister())
    dispatch(grupoActions.cleanInvalids())
    dispatch(grupoActions.setVisualizacao(false))
    dispatch(setActiveTab('search'))
  }

  useEffect(() => {
    dispatch(grupoActions.getAllLojasSaga())
  }, [dispatch])

  return (
    <>
      <SimpleCard>
        <Row className="mb-3">
          <Col md={6} className="d-flex">
            <CustomButton
              background="#662d90"
              letterSpacing={1}
              onClick={() => voltaParaSearch()}
            >
              Voltar
            </CustomButton>
          </Col>
          {!visualizacao && (
            <Col className="d-flex justify-content-end" md={6}>
              <CustomButton onClick={() => onSave()}>Salvar</CustomButton>
            </Col>
          )}
        </Row>

        <Row>
          <Col>
            <SimpleTitleCard>
              <RiFolderLine className="icon" />
              Criação e gerenciamento de grupos
            </SimpleTitleCard>
          </Col>
        </Row>

        <Row className="mt-4">
          <label className="label-12 mb-1">Descrição*</label>

          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(e.target.value, 'descricao')
            }}
            value={register.descricao}
            invalid={invalids.descricao}
            disabled={visualizacao}
          />
        </Row>

        <Row className="mt-4">
          <label className="label-12 mb-1">Lojas participantes do grupo</label>
          <CustomSelect
            options={lojasOptions}
            placeholder="Selecione a loja"
            isMulti
            onChange={(e) => {
              onChange(e, 'lojas')
            }}
            value={register.lojas}
            accessorLabel="label"
            accessorValue="value"
            invalid={invalids.lojas}
            disabled={visualizacao}
          />
        </Row>
      </SimpleCard>
    </>
  )
}
