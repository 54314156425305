import React from 'react'
import { RiUserSearchLine } from 'react-icons/ri'
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'

interface EnriquecimentoLemitProps {
  isOpen: boolean
  onToggle: () => void
  onEnriquecer: () => void
}

const EnriquecimentoLemit = ({
  isOpen,
  onToggle,
  onEnriquecer
}: EnriquecimentoLemitProps) => {
  return (
    <Modal size="md" isOpen={isOpen} toggle={() => onToggle()}>
      <ModalHeader>
        <SimpleTitleCard>
          <RiUserSearchLine className="icon" />
          Enriquecimento Lemit
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody>
        <p>Deseja enriquecer a base de dados?</p>
      </ModalBody>
      <ModalFooter>
        <Row className="d-flex gap-3">
          <CustomButton onClick={() => onToggle()} background="#662D91">
            Não
          </CustomButton>
          <CustomButton
            onClick={() => {
              onEnriquecer()
              onToggle()
            }}
          >
            Sim
          </CustomButton>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default EnriquecimentoLemit
