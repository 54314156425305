import React from 'react'
import Main from 'layouts/main'
import { useSelector } from 'react-redux'
import { TabContent, TabPane } from 'reactstrap'
import { ApplicationState } from 'store'
import { PRODUTO } from 'store/modules/backoffice/produto/types'
import Register from './Tabs/Register'
import Search from './Tabs/Search'

const Produto = () => {
  const { activeTab } = useSelector<ApplicationState, PRODUTO>(
    (state) => state.produto
  )
  return (
    <Main>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="search">
          <Search />
        </TabPane>
        <TabPane tabId="register">
          <Register />
        </TabPane>
      </TabContent>
    </Main>
  )
}

export default Produto
