import React, { useEffect } from 'react'
import CustomSelect from 'components/Select'
import SimpleCard from 'components/SimpleCard'
import BigNumbers from './components/BigNumbers'
import EsforcoVenda from './components/EsforcoVenda'
import MatrizRFM from './components/MatrizRFM'
import Tabulacao from './components/Tabulacao'
import TopConsultores from './components/TopConsultores'
import TopEquipes from './components/TopEquipes'
import Main from 'layouts/main'
import useRedux from 'hooks/useRedux'
import { AiOutlineFilter } from 'react-icons/ai'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { useSelector } from 'react-redux'
import { INDICADORES } from 'store/modules/indicadores/types'
import { ApplicationState } from 'store'
import * as indicadoresActions from 'store/modules/indicadores/actions'

const Indicadores = () => {
  const { search, optionsSelect } = useSelector<ApplicationState, INDICADORES>(
    (state) => state.indicadores
  )

  const { dispatch } = useRedux()

  type Fields = keyof INDICADORES['search']
  function onChange(value: string, field: Fields) {
    dispatch(indicadoresActions.setPesquisa(value, field))
  }

  useEffect(() => {
    dispatch(indicadoresActions.getEmpresasSaga())
  }, [dispatch])

  useEffect(() => {
    if (search.empresaId) {
      dispatch(indicadoresActions.setOptionsSelect([], 'equipes'))
      dispatch(indicadoresActions.setOptionsSelect([], 'consultores'))
      dispatch(indicadoresActions.getEquipesSaga())
    }
  }, [dispatch, search.empresaId])

  useEffect(() => {
    if (search.equipeId) {
      dispatch(indicadoresActions.setOptionsSelect([], 'consultores'))
      dispatch(indicadoresActions.getConsultoresSaga())
    }
  }, [dispatch, search.equipeId])

  function getIndicadores() {
    dispatch(indicadoresActions.getBigNumbersSaga())
    dispatch(indicadoresActions.getTopEquipesSaga())
    dispatch(indicadoresActions.getTopEquipesConversaoSaga())
    dispatch(indicadoresActions.getTopConsultoresSaga())
    dispatch(indicadoresActions.getTopConsultoresConversaoSaga())
    dispatch(indicadoresActions.getTabulacoesSaga())
    dispatch(indicadoresActions.getMatrizRfmSaga())
    dispatch(indicadoresActions.getEsforcoVendasSaga())
  }

  function cleanPesquisa() {
    dispatch(indicadoresActions.cleanPesquisa())
  }

  return (
    <Main>
      <SimpleCard>
        <div className="container-fluid d-flex flex-column row-gap-10">
          <Row>
            <Col md={6}>
              <SimpleTitleCard>
                <AiOutlineFilter className="icon" />
                Filtro
              </SimpleTitleCard>
            </Col>
          </Row>
          <Row className="d-flex row-gap-15">
            <Col md={4}>
              <label className="label-12">Empresa</label>
              <CustomSelect
                options={optionsSelect.empresas}
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'empresaId')
                }}
                value={search.empresaId}
                accessorLabel="descricao"
                accessorValue="id"
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Equipe</label>
              <CustomSelect
                options={optionsSelect.equipes}
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'equipeId')
                }}
                value={search.equipeId}
                accessorLabel="descricao"
                accessorValue="id"
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Consultor</label>
              <CustomSelect
                options={optionsSelect.consultores}
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'consultorId')
                }}
                value={search.consultorId}
                accessorLabel="descricao"
                accessorValue="id"
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex gap-3">
              <CustomButton
                letterSpacing={2}
                background="#662d91"
                justifyContent="center"
                onClick={() => {
                  cleanPesquisa()
                  getIndicadores()
                }}
              >
                Limpar filtros
              </CustomButton>
              <CustomButton
                letterSpacing={2}
                justifyContent="center"
                onClick={() => {
                  getIndicadores()
                }}
              >
                Pesquisar
              </CustomButton>
            </Col>
          </Row>
        </div>
      </SimpleCard>
      <SimpleCard className="d-flex flex-column row-gap-15">
        <BigNumbers />
        <TopEquipes />
        <TopConsultores />
      </SimpleCard>
      <SimpleCard>
        <EsforcoVenda />
      </SimpleCard>
      <SimpleCard className="d-flex flex-column row-gap-15">
        <Tabulacao />
        <MatrizRFM />
      </SimpleCard>
    </Main>
  )
}
export default Indicadores
