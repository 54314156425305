import InputCustom from 'components/Inputs'
import SimpleCard from 'components/SimpleCard'
import useRedux from 'hooks/useRedux'
import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { CREDENCIAIS } from 'store/modules/backoffice/credenciais/types'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import * as credenciaisActions from 'store/modules/backoffice/credenciais/actions'
import { toast } from 'react-toastify'

const CredenciaisAgente = () => {
  const { credenciaisAgente } = useSelector<ApplicationState, CREDENCIAIS>(
    (state) => state.credenciais
  )

  const { dispatch } = useRedux()

  type CredenciaisAgenteFields = keyof CREDENCIAIS['credenciaisAgente']
  function onChange<T extends CredenciaisAgenteFields>(
    value: T extends keyof CREDENCIAIS['credenciaisAgente']
      ? CREDENCIAIS['credenciaisAgente'][T]
      : never,
    field: T
  ) {
    dispatch(credenciaisActions.setCredenciaisAgenteFields(value, field))
  }

  function onSave() {
    if (credenciaisAgente.usuarioAgente && credenciaisAgente.senha) {
      return dispatch(credenciaisActions.salvarCredenciaisAgenteSaga())
    }
    toast.warn('Preencha os campos corretamente para salvar')
  }

  return (
    <SimpleCard>
      <SimpleTitleCard>Credenciais Agente Telecom</SimpleTitleCard>
      <div className="d-flex flex-column row-gap-15">
        <Row className="d-flex flex-column align-items-center row-gap-15">
          <Col md={8}>
            <label className="label-12">Usuário</label>
            <InputCustom
              type="text"
              placeholder="Digite seu usuário"
              onChange={(e) => {
                onChange(e.target.value, 'usuarioAgente')
              }}
              value={credenciaisAgente.usuarioAgente}
            />
          </Col>
        </Row>
        <Row className="d-flex flex-column align-items-center row-gap-15">
          <Col md={8}>
            <label className="label-12">Senha</label>
            <InputCustom
              type="password"
              placeholder="Digite sua senha"
              onChange={(e) => {
                onChange(e.target.value, 'senha')
              }}
              value={credenciaisAgente.senha}
            />
          </Col>
        </Row>
        <Row className="d-flex flex-column align-items-center row-gap-15">
          <Col md={4} className="d-flex justify-content-center">
            <CustomButton
              background="#662d90"
              onClick={() => {
                onSave()
              }}
            >
              Salvar
            </CustomButton>
          </Col>
        </Row>
      </div>
    </SimpleCard>
  )
}

export default CredenciaisAgente
