import React, { useEffect } from 'react'
import Breadcrumbs from 'components/Breadcrumbs'
import BlurLoading from 'components/Loading/BlurLoading'
import Navbar from 'components/Navbar'
import Sidebar from 'components/Sidebar'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { LOADING } from 'store/modules/loading/types'
import { CorpoConteudo } from './style'
import * as loginActions from 'store/modules/login/actions'
import useRedux from 'hooks/useRedux'
import { LOGIN } from 'store/modules/login/types'
import { Box, styled, useTheme } from '@mui/material'

type Props = {
  children?: JSX.Element | JSX.Element[]
}

function Main({ children }: Props) {
  const { isLoading } = useSelector<ApplicationState, LOADING>(
    (state) => state.loading
  )

  const { webSockets } = useSelector<ApplicationState, LOGIN>(
    (state) => state.login
  )

  const { dispatch } = useRedux()

  useEffect(() => {
    if (!webSockets.notificacaoInitialized) {
      dispatch(loginActions.setStatusWebSockets(true, 'notificacaoInitialized'))
      dispatch(loginActions.iniciarNotificacoes())
    }
    if (!webSockets.retornoDiscagemTelecomInitialized) {
      dispatch(
        loginActions.setStatusWebSockets(
          true,
          'retornoDiscagemTelecomInitialized'
        )
      )
      dispatch(loginActions.iniciarRetornoDiscagemTelecom())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const theme = useTheme()

  const NavbarMargin = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    minHeight: 'calc(64px + 50px)'
  }))

  return (
    <Box
      sx={{
        display: {
          md: '-webkit-box',
          xs: 'initial'
        }
      }}
    >
      <BlurLoading isLoading={isLoading} />
      <Navbar />
      <Breadcrumbs />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: {
            md: 'initial',
            xs: `calc(${theme.spacing(8)})`
          }
        }}
      >
        <NavbarMargin />
        <CorpoConteudo>{children}</CorpoConteudo>
      </Box>
    </Box>
  )
}

export default Main
