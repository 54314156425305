import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { Actions, AllowedActions, LOGIN } from 'store/modules/login/types'

interface PrivateComponentProps {
  children: React.ReactNode
  typeActions: keyof Actions
  screen: keyof AllowedActions
}

const PrivateComponent = ({
  children,
  typeActions,
  screen
}: PrivateComponentProps) => {
  const { allowedActions } = useSelector<ApplicationState, LOGIN>(
    (state) => state.login
  )
  return allowedActions[screen][typeActions] ? <>{children}</> : <></>
}

export default PrivateComponent
