import { Paginacao } from 'models/types'
import store from 'store'
import { Blacklist, TYPES } from './types'

export const getAllBlacklistSaga = () => ({
  type: TYPES.GET_ALL_BLACKLIST_SAGA
})

export const setAllBlacklist = (value: Blacklist[]) => ({
  type: TYPES.SET_ALL_BLACKLIST,
  value
})

export const setPaginacao = (value: Paginacao) => ({
  type: TYPES.SET_PAGINATION,
  value
})

export const criarBlacklistSaga = () => ({
  type: TYPES.POST_BLACKLIST_SAGA
})

export const setRegisterFields = (value: string, field: string) => ({
  type: TYPES.SET_REGISTER_FIELDS,
  value,
  field
})

export const cleanRegister = () => ({
  type: TYPES.CLEAN_REGISTER
})

export const cleanSearch = () => ({
  type: TYPES.CLEAN_SEARCH
})

export const setPesquisa = (value: string | number | null, field: string) => ({
  type: TYPES.SET_PESQUISA,
  value,
  field
})

export const deleteBlacklistSagaDispatch = () =>
  store.dispatch({
    type: TYPES.DELETE_BLACKLIST_SAGA_DISPATCH
  })

export const setItemSelectedIdDispatch = (value: string) =>
  store.dispatch({
    type: TYPES.SET_ITEM_SELECTED_ID_DISPATCH,
    value
  })

export const toggleModalExclusaoDispatch = (value: boolean) =>
  store.dispatch({
    type: TYPES.TOGGLE_MODAL_EXCLUSAO_DISPATCH,
    value
  })
