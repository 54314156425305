import { Reducer } from 'redux'
import { BLACKLIST, TYPES } from './types'

const INITIAL_STATE: BLACKLIST = {
  blacklist: [],
  pagination: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  limiteMaximoRegistros: 500,
  register: {
    cpf: ''
  },
  search: {
    filtro: null,
    pesquisa: ''
  },
  itemSelectedId: '',
  showConfirmacaoExclusao: false
}

const reducer: Reducer<BLACKLIST> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_ALL_BLACKLIST:
      return { ...state, blacklist: action.value }
    case TYPES.SET_PAGINATION:
      return { ...state, pagination: action.value }
    case TYPES.SET_REGISTER_FIELDS:
      return {
        ...state,
        register: { ...state.register, [action.field]: action.value }
      }
    case TYPES.CLEAN_REGISTER:
      return { ...state, register: INITIAL_STATE.register }
    case TYPES.SET_PESQUISA:
      return {
        ...state,
        search: { ...state.search, [action.field]: action.value }
      }
    case TYPES.CLEAN_SEARCH:
      return { ...state, search: INITIAL_STATE.search }

    case TYPES.TOGGLE_MODAL_EXCLUSAO_DISPATCH:
      return {
        ...state,
        showConfirmacaoExclusao: action.value
      }
    case TYPES.SET_ITEM_SELECTED_ID_DISPATCH:
      return {
        ...state,
        itemSelectedId: action.value
      }
    default:
      return state
  }
}

export default reducer
