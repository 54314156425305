import React, { useEffect, useState } from 'react'
import { FiGlobe } from 'react-icons/fi'
import { TabPane } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import SimpleCard from 'components/SimpleCard'
import SimpleTab from 'components/SimpleTab/SimpleTab'
import HistoricoBeneficio from '../HistoricoBeneficio'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'

/**
 * Histórico
 * TabId = 4
 * **/

interface Props {
  index: number
}

const Historico = ({ index }: Props) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  const [beneficios, setBeneficios] = useState<
    {
      id: number
      descricao: string
      component: JSX.Element
    }[]
  >([])

  useEffect(() => {
    dispatch(atendimentoActions.setTabsHistorico(index))
    cleanBeneficios().then(() => {
      const matriculas: typeof beneficios = []
      atendimentosAbertos[index].tabProduto.beneficios?.map((matricula, i) => {
        matriculas.push({
          id: i,
          descricao:
            matricula.numero && matricula.numero !== '0'
              ? `${
                  matricula.clientePerfil.descricao || 'Descrição indisponível'
                }: ${matricula.numero}`
              : matricula.clientePerfil.descricao || 'Descrição indisponível',
          component: (
            <HistoricoBeneficio
              indexBeneficio={i}
              index={index}
              tipoPerfil={matricula.clientePerfil.tipoPerfil}
            />
          )
        })
      })
      setBeneficios(matriculas)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabProduto.beneficios, dispatch])

  async function cleanBeneficios() {
    await setBeneficios([])
  }

  return (
    <TabPane tabId={4}>
      <SimpleCard>
        <SimpleTitleCard>
          <FiGlobe className="icon" /> Benefícios
        </SimpleTitleCard>
        <SimpleTab tabs={beneficios} />
      </SimpleCard>
    </TabPane>
  )
}

export default Historico
