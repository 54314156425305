import { Paginacao } from 'models/types'

export enum TYPES {
  GET_ALL_BLACKLIST_SAGA = '@@blacklist/GET_ALL_BLACKLIST_SAGA',
  SET_ALL_BLACKLIST = '@@blacklist/SET_ALL_BLACKLIST',
  SET_PAGINATION = '@@blacklist/SET_PAGINATION',
  SET_REGISTER_FIELDS = '@@blacklist/SET_REGISTER_FIELDS',
  CLEAN_REGISTER = '@@blacklist/CLEAN_REGISTER',
  POST_BLACKLIST_SAGA = '@@blacklist/POST_BLACKLIST_SAGA',
  SET_PESQUISA = '@@blacklist/SET_PESQUISA',
  CLEAN_SEARCH = '@@blacklist/CLEAN_SEARCH',
  DELETE_BLACKLIST_SAGA_DISPATCH = '@@blacklist/DELETE_BLACKLIST_SAGA_DISPATCH',
  TOGGLE_MODAL_EXCLUSAO_DISPATCH = '@@blacklist/TOGGLE_MODAL_EXCLUSAO',
  SET_ITEM_SELECTED_ID_DISPATCH = '@@blacklist/SET_ITEM_SELECTED_ID_DISPATCH'
}

interface Search {
  pesquisa: string
  filtro: string | null
}
export interface BLACKLIST {
  blacklist: Blacklist[]
  pagination: Paginacao
  limiteMaximoRegistros: number
  register: ParametrizacaoBlacklist
  search: Search
  itemSelectedId: string
  showConfirmacaoExclusao: boolean
}

export interface ParametrizacaoBlacklist {
  cpf: string
}

export interface Blacklist {
  cpf: string
  nome: string
  dataInclusao: string
  nomePessoaExclusao: string
}
