import InputCustom from 'components/Inputs'
import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { Checkbox } from 'views/inicio/style'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import { AnswersFormularioNecessidade } from 'models/types'
import { toast } from 'react-toastify'

const Motivo = () => {
  const { formularioNecessidade } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  type Fields = keyof AnswersFormularioNecessidade
  function onChange(value: string | boolean, field: Fields) {
    dispatch(atendimentoActions.setInfosFormulario(value, field))
  }

  function changeTab(tab: number) {
    dispatch(atendimentoActions.setTabFormulario(tab))
  }

  function onSave() {
    if (
      formularioNecessidade.answers.pagarDividas ||
      formularioNecessidade.answers.despesasMedicas ||
      formularioNecessidade.answers.reformas ||
      formularioNecessidade.answers.adquirirBem ||
      formularioNecessidade.answers.emprestarFamiliarAmigo ||
      formularioNecessidade.answers.investirNegocio ||
      formularioNecessidade.answers.ferias ||
      formularioNecessidade.answers.outros
    ) {
      dispatch(
        atendimentoActions.salvarFormularioAtendimento30Saga(
          formularioNecessidade.answers.cpf
        )
      )
    } else {
      toast.warn('Necessário selecionar pelo menos uma opção')
    }
  }

  return (
    <div className="container-fluid">
      <div className="container-formulario">
        <Row>
          <Col className="d-flex flex-column row-gap-10">
            <div className="d-flex align-items-center">
              <Checkbox
                checked={formularioNecessidade.answers.pagarDividas}
                onChange={(e) => onChange(e.target.checked, 'pagarDividas')}
              />
              <label>Pagar dívidas</label>
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                checked={formularioNecessidade.answers.despesasMedicas}
                onChange={(e) => onChange(e.target.checked, 'despesasMedicas')}
              />
              <label>Despesas médicas</label>
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                checked={formularioNecessidade.answers.reformas}
                onChange={(e) => onChange(e.target.checked, 'reformas')}
              />
              <label>Reformas</label>
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                checked={formularioNecessidade.answers.adquirirBem}
                onChange={(e) => onChange(e.target.checked, 'adquirirBem')}
              />
              <label>Adquirir um bem (carro ou moto)</label>
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                checked={formularioNecessidade.answers.emprestarFamiliarAmigo}
                onChange={(e) =>
                  onChange(e.target.checked, 'emprestarFamiliarAmigo')
                }
              />
              <label>Emprestar para familiar ou amigo</label>
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                checked={formularioNecessidade.answers.investirNegocio}
                onChange={(e) => onChange(e.target.checked, 'investirNegocio')}
              />
              <label>Investir em um negócio</label>
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                checked={formularioNecessidade.answers.ferias}
                onChange={(e) => onChange(e.target.checked, 'ferias')}
              />
              <label>Férias</label>
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                checked={formularioNecessidade.answers.outros}
                onChange={(e) => onChange(e.target.checked, 'outros')}
              />
              <label>Outros</label>
            </div>
            <div className="d-flex flex-column">
              <label className="label-12">Observação</label>
              <InputCustom
                onChange={(e) => onChange(e.target.value, 'observacao')}
                type="textarea"
                placeholder="Digite aqui"
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-center column-gap-20">
        <div
          className="btn-outline-purple justify-content-center"
          onClick={() => changeTab(3)}
        >
          Voltar
        </div>
        <div
          className="btn-purple justify-content-center"
          onClick={() => onSave()}
        >
          Salvar
        </div>
      </div>
    </div>
  )
}

export default Motivo
