import { maskCPF, maskDate } from 'util/masks'
import {
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import { Row } from 'react-data-grid'

const headerTheme = 'table-header--purple'

export const COLUMNS: GridColumns<Row> = [
  {
    field: 'cpfCliente',
    headerName: 'CPF',
    valueGetter: (params: GridValueGetterParams) => `${maskCPF(params?.value)}`,
    headerClassName: headerTheme,
    flex: 1,
    renderCell: (params) => (
      <div
        role="button"
        onDoubleClick={() => {
          atendimentoActions.getFormularioAtendimento30SagaDispatch(
            maskCPF(params?.value)
          )
        }}
      >
        {maskCPF(params?.value)}
      </div>
    )
  },
  {
    field: 'nomePessoa',
    headerName: 'Nome',
    headerClassName: headerTheme,
    flex: 1
  },
  {
    field: 'matricula',
    headerName: 'Matricula',
    headerClassName: headerTheme,
    flex: 1
  },
  {
    field: 'nomeAtendente',
    headerName: 'Atendente',
    headerClassName: headerTheme,
    flex: 1
  },
  {
    field: 'dataAtendimento',
    headerName: 'Data e hora',
    type: 'datetime',
    renderCell: (params: GridRenderCellParams) => (
      <span className="table-cell-trucate">
        {params?.value ? maskDate(params?.value, 'DD/MM/YYYY HH:mm') : ''}
      </span>
    ),
    headerClassName: headerTheme,
    flex: 1
  }
]

export const COLUMNS_VISAO_COM_LOJA: GridColumns<Row> = [
  {
    field: 'cpfCliente',
    headerName: 'CPF',
    valueGetter: (params: GridValueGetterParams) => `${maskCPF(params?.value)}`,
    headerClassName: headerTheme,
    flex: 1,
    renderCell: (params) => (
      <div
        role="button"
        onDoubleClick={() => {
          atendimentoActions.getFormularioAtendimento30SagaDispatch(
            maskCPF(params?.value)
          )
        }}
      >
        {maskCPF(params?.value)}
      </div>
    )
  },
  {
    field: 'nomePessoa',
    headerName: 'Nome',
    headerClassName: headerTheme,
    flex: 1
  },
  {
    field: 'matricula',
    headerName: 'Matricula',
    headerClassName: headerTheme,
    flex: 1
  },
  {
    field: 'nomeLoja',
    headerName: 'Loja',
    headerClassName: headerTheme,
    flex: 1
  },
  {
    field: 'nomeAtendente',
    headerName: 'Atendente',
    headerClassName: headerTheme,
    flex: 1
  },
  {
    field: 'dataAtendimento',
    headerName: 'Data e hora',
    type: 'datetime',
    renderCell: (params: GridRenderCellParams) => (
      <span className="table-cell-trucate">
        {params?.value ? maskDate(params?.value, 'DD/MM/YYYY HH:mm') : ''}
      </span>
    ),
    headerClassName: headerTheme,
    flex: 1
  }
]
