import Main from 'layouts/main'
import { TabContent, TabPane } from 'reactstrap'
import Register from './Tabs/Register'
import Search from './Tabs/Search'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { GRUPOS } from 'store/modules/backoffice/grupos/types'

const Grupos = () => {
  const { activeTab } = useSelector<ApplicationState, GRUPOS>(
    (state) => state.grupos
  )

  return (
    <Main>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="search">
          <Search />
        </TabPane>

        <TabPane tabId="register">
          <Register />
        </TabPane>
      </TabContent>
    </Main>
  )
}

export default Grupos
