import React, { useState, ChangeEvent } from 'react'
import { InputGroup, Input, InputGroupText } from 'reactstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import './PasswordInput.css'

interface PasswordInputProps {
  placeholder: string
  value: string
  onChange: (value: string) => void
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  placeholder,
  value,
  onChange
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  return (
    <InputGroup>
      <Input
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
      />
      <InputGroupText
        onClick={togglePasswordVisibility}
        style={{ cursor: 'pointer' }}
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </InputGroupText>
    </InputGroup>
  )
}

export default PasswordInput
