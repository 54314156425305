import { useEffect, useState } from 'react'
import CustomTable from 'components/CustomTable'
import InputCustom from 'components/Inputs'
import useRedux from 'hooks/useRedux'
import { FiAlertCircle, FiCheck, FiShuffle } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { ApplicationState } from 'store'
import * as carteiraActions from 'store/modules/backoffice/carteira/actions'
import { CustomButton } from 'styles/global'
import { COLUMNS } from '../../constants/columnsConsultants'
import {
  HeaderModalContainer,
  HeaderModalSubTitle,
  HeaderModalTitle
} from '../../style'
import { CARTEIRA } from 'store/modules/backoffice/carteira/types'

interface ClientNoConsultantsTypes {
  isOpen: boolean
  onToggle: () => void
  onSelect: () => void
}

const ClientNoConsultantsModal = ({
  isOpen,
  onToggle,
  onSelect
}: ClientNoConsultantsTypes) => {
  const { clientNoConsultantsModal, selectedLoja, idsTransferencia } =
    useSelector<ApplicationState, CARTEIRA>((state) => state.carteira)

  const { dispatch } = useRedux()

  const [openNestedModal, setOpenNestedModal] = useState(false)
  const [search, setSearch] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formattedColumns, setformattedColumns] = useState<any>()

  function onChange(value: string) {
    setSearch(value)
  }

  function cleanSearch() {
    setSearch('')
  }

  function aplicarFiltros() {
    dispatch(
      carteiraActions.getClientesSemConsultor({
        lojaId: clientNoConsultantsModal.lojaId
      })
    )
  }

  function handleOpenNestedModal() {
    if (idsTransferencia.length === 0) {
      return
    }

    setOpenNestedModal(true)
  }

  function closeModal() {
    onToggle()
    setOpenNestedModal(false)
    dispatch(carteiraActions.cleanClientNoConsultantsModal())
    cleanSearch()
    dispatch(carteiraActions.setIdsTransferencia([]))
  }

  function submitTransfer() {
    onSelect()
  }

  useEffect(() => {
    if (isOpen) {
      setOpenNestedModal(false)
      cleanSearch()
      dispatch(carteiraActions.setIdsTransferencia([]))
    }
  }, [dispatch, isOpen])

  useEffect(() => {
    setformattedColumns(
      clientNoConsultantsModal.consultores?.map((item) => ({
        id: item.consultorId,
        nomeConsultor: item.nomeConsultor,
        qtdClientes: item.qtdClientes
      }))
    )
  }, [clientNoConsultantsModal.consultores])

  return (
    <Modal size="xl" isOpen={isOpen} toggle={() => onToggle()}>
      <ModalHeader toggle={() => closeModal()}>
        <Row className="mb-4">
          <HeaderModalContainer>
            <FiShuffle size={24} color="var(--roxo-riber)" />
            <HeaderModalTitle>
              Transferir carteira de {selectedLoja.nome} para:
            </HeaderModalTitle>
          </HeaderModalContainer>
        </Row>

        <Row>
          <HeaderModalSubTitle>
            Os clientes serão divididos igualmente entre os selecionados
          </HeaderModalSubTitle>
        </Row>
      </ModalHeader>

      <ModalBody>
        <div className="container-fluid d-flex flex-column row-gap-10">
          <Row>
            <Col md={6}>
              <label className="label-12">Nome do Consultor</label>
              <InputCustom
                type="text"
                placeholder="Pesquise aqui"
                onChange={(e) => {
                  onChange(e.target.value)
                }}
                value={search}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <CustomButton
                onClick={() => handleOpenNestedModal()}
                isDisabled={idsTransferencia.length === 0}
              >
                <FiCheck size={20} />
                Transferir
              </CustomButton>
            </Col>
          </Row>

          {/* Nested Modal */}
          <Modal
            isOpen={openNestedModal}
            toggle={() => setOpenNestedModal(!openNestedModal)}
          >
            <ModalHeader>
              <FiAlertCircle color="var(--amarelo-riber)" />
              <HeaderModalTitle>Atenção!</HeaderModalTitle>
            </ModalHeader>

            <ModalBody>Deseja realmente realizar essa ação?</ModalBody>
            <ModalFooter>
              <CustomButton
                background="var(--roxo-riber)"
                onClick={() => setOpenNestedModal(false)}
              >
                Cancelar
              </CustomButton>
              <CustomButton onClick={() => submitTransfer()}>
                Confirmar
              </CustomButton>
            </ModalFooter>
          </Modal>
          {/* Nested Modal */}

          <Row className="my-2">
            <Col>
              <CustomButton
                onClick={() => aplicarFiltros()}
                background="var(--roxo-riber)"
              >
                Pesquisar
              </CustomButton>
            </Col>
          </Row>
          <Row>
            <CustomTable
              data={formattedColumns}
              columns={COLUMNS}
              paginationMode="client"
              checkboxSelection={true}
              selectionModel={(e) =>
                dispatch(carteiraActions.setIdsTransferencia(e))
              }
              disableSelectionOnClick={false}
              disableColumnFilter
              filterModel={{
                items: [
                  {
                    columnField: 'nomeConsultor',
                    operatorValue: 'contains',
                    value: search
                  }
                ]
              }}
            />
          </Row>
        </div>
      </ModalBody>
    </Modal>
  )
}

export { ClientNoConsultantsModal }
