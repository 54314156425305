import { handlerError } from 'util/handlerError'
import { SagaIterator } from 'redux-saga'
import { call, fork, put, all, takeEvery, select } from 'redux-saga/effects'
import * as credenciaisController from 'controller/credenciaisController'
import * as credenciaisActions from 'store/modules/backoffice/credenciais/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { CREDENCIAIS, TYPES } from './types'
import { toast } from 'react-toastify'
import { ApplicationState } from 'store'

function* getUsuariosInstituicaoBmg(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(credenciaisController.usuarioInstituicaoBmg)
    yield put(
      credenciaisActions.setUsuariosInstituicaoBmg(response?.data?.content)
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getUsuariosInstituicaoItau(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(credenciaisController.usuarioInstituicaoItau)
    yield put(
      credenciaisActions.setUsuariosInstituicaoItau(response?.data?.content)
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* salvarCredenciaisBmg(params: {
  type: string
  usuario: string
  senha: string
}): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    yield call(
      credenciaisController.credenciaisBmg,
      params.usuario,
      params.senha
    )
    toast.success('Credenciais salvas com sucesso!')
    yield put(credenciaisActions.getUsuarioPrincipalBMGSaga())
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* salvarCredenciaisItau(params: {
  type: string
  usuario: string
}): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    yield call(credenciaisController.credenciaisItau, params.usuario)
    toast.success('Credenciais salvas com sucesso!')
    yield put(credenciaisActions.getUsuarioPrincipalItauSaga())
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getUsuarioPrincipalBmg(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(credenciaisController.getUsuarioPrincipalBmg)
    yield put(
      credenciaisActions.setLoginBmg(response?.data?.content, 'usuario')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getUsuarioPrincipalItau(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(credenciaisController.getUsuarioPrincipalItau)
    yield put(
      credenciaisActions.setLoginItau(response?.data?.content, 'usuario')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getCredenciaisAgente(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(credenciaisController.getCredenciaisAgente)
    yield put(credenciaisActions.setCredenciaisAgente(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* salvarCredenciaisAgente(): SagaIterator {
  const { credenciaisAgente }: CREDENCIAIS = yield select(
    (state: ApplicationState) => state.credenciais
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(credenciaisController.salvarCredenciaisAgente, {
      usuarioAgente: credenciaisAgente.usuarioAgente,
      senha: credenciaisAgente.senha
    })
    yield put(credenciaisActions.cleanCredenciaisAgente())
    toast.success('Credenciais salvas com sucesso!')
    yield put(credenciaisActions.getCredenciaisAgenteSaga())
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetUsuariosInstituicaoBmg() {
  yield takeEvery(
    TYPES.GET_USUARIOS_INSTITUICAO_BMG_SAGA,
    getUsuariosInstituicaoBmg
  )
}

export function* watchGetUsuariosInstituicaoItau() {
  yield takeEvery(
    TYPES.GET_USUARIOS_INSTITUICAO_ITAU_SAGA,
    getUsuariosInstituicaoItau
  )
}

export function* watchSalvarCredenciaisBmg() {
  yield takeEvery(TYPES.SALVAR_CREDENCIAIS_BMG_SAGA, salvarCredenciaisBmg)
}

export function* watchSalvarCredenciaisItau() {
  yield takeEvery(TYPES.SALVAR_CREDENCIAIS_ITAU_SAGA, salvarCredenciaisItau)
}

export function* watchGetUsuarioPrincipalBmg() {
  yield takeEvery(TYPES.GET_USUARIO_PRINCIPAL_BMG_SAGA, getUsuarioPrincipalBmg)
}

export function* watchGetUsuarioPrincipalItau() {
  yield takeEvery(
    TYPES.GET_USUARIO_PRINCIPAL_ITAU_SAGA,
    getUsuarioPrincipalItau
  )
}

export function* watchGetCredenciaisAgente() {
  yield takeEvery(TYPES.GET_CREDENCIAIS_AGENTE_SAGA, getCredenciaisAgente)
}

export function* watchSalvarCredenciaisAgente() {
  yield takeEvery(TYPES.SALVAR_CREDENCIAIS_AGENTE_SAGA, salvarCredenciaisAgente)
}

function* credenciaisSaga() {
  yield all([
    fork(watchGetUsuariosInstituicaoBmg),
    fork(watchGetUsuariosInstituicaoItau),
    fork(watchSalvarCredenciaisBmg),
    fork(watchSalvarCredenciaisItau),
    fork(watchGetUsuarioPrincipalBmg),
    fork(watchGetUsuarioPrincipalItau),
    fork(watchGetCredenciaisAgente),
    fork(watchSalvarCredenciaisAgente)
  ])
}

export default credenciaisSaga
