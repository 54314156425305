import React, { useEffect } from 'react'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import SimpleCard from 'components/SimpleCard'
import { FiServer } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { SimpleTitleCard, CustomButton } from 'styles/global'
import CustomTable from 'components/CustomTable'
import {
  setAtiveTab,
  setPesquisa
} from 'store/modules/backoffice/produto/actions'
import { useSelector } from 'react-redux'
import { PRODUTO } from 'store/modules/backoffice/produto/types'
import { ApplicationState } from 'store'
import useRedux from 'hooks/useRedux'
import * as produtoActions from 'store/modules/backoffice/produto/actions'
import { COLUMNS } from '../constants/columns'
import ModalExclusao from 'components/ModalExclusao'

const Search = () => {
  const { search, pagination, listaParametrizacao, showConfirmacaoExclusao } =
    useSelector<ApplicationState, PRODUTO>((state) => state.produto)

  const { dispatch } = useRedux()

  function onChange(value: string, field: string) {
    dispatch(setPesquisa(value, field))
  }

  function setPage(value: number) {
    dispatch(produtoActions.setPaginacao({ ...pagination, page: value }))
    dispatch(produtoActions.getAllParametrizacaoSaga())
  }

  function setPageSize(value: number) {
    dispatch(
      produtoActions.setPaginacao({ ...pagination, page: 0, pageSize: value })
    )
    dispatch(produtoActions.getAllParametrizacaoSaga())
  }

  function getParametrizacoes() {
    dispatch(produtoActions.setPaginacao({ ...pagination, page: 0 }))
    dispatch(produtoActions.getAllParametrizacaoSaga())
  }

  useEffect(() => {
    dispatch(produtoActions.getAllParametrizacaoSaga())
  }, [dispatch])

  return (
    <SimpleCard>
      <div className="container-fluid d-flex flex-column row-gap-10">
        <Row>
          <Col md={6}>
            <SimpleTitleCard>
              <FiServer className="icon" />
              Parametrização de produtos
            </SimpleTitleCard>
          </Col>
          <Col className="d-flex justify-content-end" md={6}>
            <CustomButton
              onClick={() => {
                dispatch(setAtiveTab('register'))
                dispatch(produtoActions.toggleAllCards(false))
              }}
            >
              Cadastrar nova
            </CustomButton>
          </Col>
        </Row>
        <Row className="d-flex row-gap-15">
          <Col md={8}>
            <Row className="d-flex row-gap-15">
              <Col md={5}>
                <label className="label-12">Filtros</label>
                <CustomSelect
                  options={[
                    { label: 'Produto', value: 'descricaoProduto' },
                    { label: 'Instituição', value: 'descricaoBanco' },
                    { label: 'Convênio', value: 'descricaoConvenio' },
                    {
                      label: 'Tipo operação Riber',
                      value: 'descricaoTipoOperacao'
                    },
                    {
                      label: 'Tipo de formalização',
                      value: 'descricaoTipoFormalizacao'
                    }
                  ]}
                  placeholder="Filtrar por"
                  onChange={(e) => {
                    onChange(e, 'filtro')
                  }}
                  value={search.filtro}
                  accessorLabel="label"
                  accessorValue="value"
                />
              </Col>
              <Col md={7}>
                <label className="label-12">Pesquisa</label>
                <InputCustom
                  type="text"
                  placeholder="Pesquise aqui"
                  onChange={(e) => {
                    onChange(e.target.value, 'pesquisa')
                  }}
                  value={search.pesquisa}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomButton
              onClick={() => getParametrizacoes()}
              background="#662d90"
            >
              Pesquisar
            </CustomButton>
          </Col>
        </Row>
        <Row>
          <CustomTable
            data={listaParametrizacao}
            columns={COLUMNS}
            paginationMode="server"
            rowCount={pagination.totalRegistros || 0}
            page={pagination.page}
            pageSize={pagination.pageSize}
            setPage={(e) => setPage(e)}
            setPageSize={(e) => setPageSize(e)}
          />
        </Row>
      </div>
      <ModalExclusao
        isOpen={showConfirmacaoExclusao}
        toggle={(e) => produtoActions.toggleModalExclusaoDispatch(e)}
        onRemove={() => {
          produtoActions.deleteParametrizacaoDispatchSaga()
        }}
      />
    </SimpleCard>
  )
}

export default Search
