import { APICoreSatelite, setAuthorization } from 'helpers/api/apiCoreSatelite'
import { APICore } from 'helpers/api/apiCore'

const api = new APICoreSatelite()
const apiCrm = new APICore()

interface Login {
  email: string
  senha: string
  lembrar?: boolean
}
export function loginCrm(login: Login) {
  return api.create(`Autenticacao/Logar`, {
    email: login.email,
    senha: login.senha
  })
}

export function logoutSistema() {
  setAuthorization(null)
  !api.isAuthenticated() && window.location.replace('/')
}

export function recuperarAcesso(email: string) {
  return api.create(`Autenticacao/solicitacao-recuperar-senha`, {
    email
  })
}

export function validaGuidRecuperacao(id: string) {
  return api.get(`Autenticacao/link-valido/${id}`, null)
}

export function redefinirSenha(id: string, senha: string) {
  return api.update(`Autenticacao/recuperar-senha/${id}`, {
    senha
  })
}

export function permissoesUsuario() {
  return apiCrm.get(`Login/permissoes-usuario`, null)
}
