import { APICore } from 'helpers/api/apiCore'
import { ParametrizacaoProdutoReq } from 'models/backoffice/produto/type'

const api = new APICore()

export function getAllParametrizacao(params: {
  pagina: number
  registros: number
  field?: string | null
  value?: string | number | null
}) {
  if (params.field) {
    return api.get(`ParametrizacaoProduto/lista-parametrizacao-produtos`, {
      pagina: params.pagina + 1,
      registros: params.registros,
      [params.field]: params.value
    })
  }
  return api.get(`ParametrizacaoProduto/lista-parametrizacao-produtos`, {
    pagina: params.pagina + 1,
    registros: params.registros
  })
}

export function getParametrizacao(id: string) {
  return api.get(`ParametrizacaoProduto/parametrizacao-by-id/${id}`, {
    id: id
  })
}

export function criarParametrizacao(params: ParametrizacaoProdutoReq) {
  return api.create(`ParametrizacaoProduto/parametrizacao-produtos`, params)
}

export function atualizarParametrizacao(params: ParametrizacaoProdutoReq) {
  return api.create(`ParametrizacaoProduto/parametrizacao-produtos`, params)
}

export function deletarParametrizacao(id: string) {
  return api.delete(`ParametrizacaoProduto/deletar?id=${id}`)
}

export function getProdutos() {
  return api.get(`Generic/produtos`, null)
}

export function getInstituicoes() {
  return api.get(`Generic/bancos`, null)
}

export function getConvenios() {
  return api.get(`Generic/convenios`, null)
}

export function getTipoOperacao() {
  return api.get(`Generic/tipos-operacao`, null)
}

export function getTipoFormalizacao() {
  return api.get(`Generic/tipos-formalizacao`, null)
}

export function getTaxas() {
  return api.get(`Generic/taxas`, null)
}

export function getTabelasBanco(bancoId: string) {
  return api.get(`Generic/tabelas-comissao-by-banco`, { bancoId })
}

export function getBeneficios() {
  return api.get(`Generic/beneficios`, null)
}
