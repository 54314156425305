/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Dropzone from 'react-dropzone'
import { DropContainer, UploadArquivoWrapper } from './style'
import {
  FileIcon,
  defaultStyles,
  DefaultExtensionType,
  FileIconProps
} from 'react-file-icon'
import { filesize } from 'filesize'
import { BsCloudUpload } from 'react-icons/bs'

interface UploadArquivoProps {
  onChange: (e: any) => any
  disabled?: boolean
  value: File | null
  invalid?: boolean
}

export const UploadArquivo = ({
  onChange,
  disabled,
  value,
  invalid = false
}: UploadArquivoProps) => {
  function getExtension(fileName: string) {
    return (fileName.split('.').pop() || '') as keyof Record<
      DefaultExtensionType,
      Partial<FileIconProps>
    >
  }
  return (
    <UploadArquivoWrapper>
      <Dropzone
        disabled={disabled}
        multiple={false}
        onDropAccepted={(e) => {
          onChange(e[0])
        }}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            invalid={invalid}
          >
            <input {...getInputProps()} />
            <BsCloudUpload className="icon" />
            <label>Arraste e solte ou clique para selecionar</label>
          </DropContainer>
        )}
      </Dropzone>
      {value && (
        <label className="preview">
          <div className="icon">
            <FileIcon
              extension={getExtension(value.name)}
              {...defaultStyles[getExtension(value.name)]}
            />
          </div>
          <span>{filesize(value.size)}</span>
          {value.name}
        </label>
      )}
    </UploadArquivoWrapper>
  )
}
