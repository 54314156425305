import { Lojas, Paginacao } from 'models/types'
import store from 'store'
import { CAMPANHA, TYPES } from './types'
import {
  AbastecimentoCampanhaDto,
  ReabastecimentoCampanhaDto
} from 'models/backoffice/campanha/type'

type ActiveTab = 'search' | 'register'
export const setActiveTab = (value: ActiveTab) => ({
  type: TYPES.SET_ACTIVE_TAB,
  value
})

type ActiveTabRegister = 1 | 2 | 3 | 4
export const setActiveTabRegister = (value: ActiveTabRegister) => ({
  type: TYPES.SET_ACTIVE_TAB_REGISTER,
  value
})

export const setAllCampanhas = (value: CAMPANHA['campanhas']) => ({
  type: TYPES.SET_ALL_CAMPANHAS,
  value
})

export const cleanAllCampanhas = () => ({
  type: TYPES.CLEAN_ALL_CAMPANHAS
})

export const setItemSelectedIdDispatch = (value: string) =>
  store.dispatch({
    type: TYPES.SET_ITEM_SELECTED_ID,
    value
  })

export const toggleModalExclusaoDispatch = (value: boolean) =>
  store.dispatch({
    type: TYPES.TOGGLE_MODAL_EXCLUSAO,
    value
  })

type FieldsSearch = keyof CAMPANHA['search']
export const setPesquisa = (value: string, field: FieldsSearch) => ({
  type: TYPES.SET_PESQUISA,
  value,
  field
})

export const cleanPesquisa = () => ({
  type: TYPES.CLEAN_PESQUISA
})

type FieldsRegister = keyof CAMPANHA['register']
export const setRegisterFields = <T extends FieldsRegister>(
  value: T extends FieldsRegister ? CAMPANHA['register'][T] : never,
  field: T
) => ({
  type: TYPES.SET_REGISTER_FIELDS,
  value,
  field
})

export const setRegister = (value: CAMPANHA['register']) => ({
  type: TYPES.SET_REGISTER,
  value
})

export const setVisualizacao = (value: boolean) => ({
  type: TYPES.SET_VISUALIZACAO,
  value
})

export const setPaginacao = (value: Paginacao) => ({
  type: TYPES.SET_PAGINATION,
  value
})

export const cleanPagination = () => ({
  type: TYPES.CLEAN_PAGINATION
})

export const setInvalidsTabDadosGerais = (value: boolean, field: string) => ({
  type: TYPES.SET_INVALIDS_TAB_DADOS_GERAIS,
  value,
  field
})

export const cleanInvalidsTabDadosGerais = () => ({
  type: TYPES.CLEAN_INVALIDS_TAB_DADOS_GERAIS
})

export const setInvalidsTabParametros = (value: boolean, field: string) => ({
  type: TYPES.SET_INVALIDS_TAB_PARAMETROS,
  value,
  field
})

export const cleanInvalidsTabParametros = () => ({
  type: TYPES.CLEAN_INVALIDS_TAB_PARAMETROS
})

export const setInvalidsTabBancos = (value: boolean, field: string) => ({
  type: TYPES.SET_INVALIDS_TAB_BANCOS,
  value,
  field
})

export const cleanInvalidsTabBancos = () => ({
  type: TYPES.CLEAN_INVALIDS_TAB_BANCOS
})

export const setInvalidsTabRefinPortabilidade = (
  value: boolean,
  field: string
) => ({
  type: TYPES.SET_INVALIDS_TAB_REFIN_PORTABILIDADE,
  value,
  field
})

export const cleanInvalidsTabRefinsetInvalidsTabRefinPortabilidade = () => ({
  type: TYPES.CLEAN_INVALIDS_TAB_REFIN_PORTABILIDADE
})

export const cleanQuantidadeEmprestimo = () => ({
  type: TYPES.CLEAN_QUANTIDADE_EMPRESTIMO
})

export const cleanSalario = () => ({
  type: TYPES.CLEAN_SALARIO
})

export const cleanMargemLivre = () => ({
  type: TYPES.CLEAN_MARGEM_LIVRE
})

export const cleanMargemCartao = () => ({
  type: TYPES.CLEAN_MARGEM_CARTAO
})

export const cleanParcelasPagas = () => ({
  type: TYPES.CLEAN_PARCELAS_PAGAS
})

export const cleanValorParcela = () => ({
  type: TYPES.CLEAN_VALOR_PARCELA
})

export const cleanRegister = () => ({
  type: TYPES.CLEAN_REGISTER
})

export const getAllCampanhasSaga = () => ({
  type: TYPES.GET_ALL_CAMPANHAS_SAGA
})

export const getCampanhaSaga = (id: string) => ({
  type: TYPES.GET_CAMPANHA_SAGA,
  id
})

export const visualizarCampanhaSagaDispatch = (id: string) =>
  store.dispatch({
    type: TYPES.VISUALIZAR_CAMPANHA_SAGA_DISPATCH,
    id
  })

export const editarCampanhaSagaDispatch = (id: string) =>
  store.dispatch({
    type: TYPES.EDITAR_CAMPANHA_SAGA_DISPATCH,
    id
  })

export const criarCampanhaSaga = () => ({
  type: TYPES.POST_CAMPANHA_SAGA
})

export const atualizarCampanhaSaga = () => ({
  type: TYPES.PUT_CAMPANHA_SAGA
})

export const deleteCampanhaSaga = (id: string) => ({
  type: TYPES.DELETE_CAMPANHA_SAGA,
  id
})

export const deleteCampanhaSagaDispatch = () =>
  store.dispatch({
    type: TYPES.DELETE_CAMPANHA_SAGA
  })

type FieldsOptionsSelect = keyof CAMPANHA['optionsSelect']
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setOptionsSelect = (value: any, field: FieldsOptionsSelect) => ({
  type: TYPES.SET_OPTIONS_SELECT,
  value,
  field
})

export const getCidadesSaga = () => ({
  type: TYPES.GET_CIDADES_SAGA
})

export const getEstadosSaga = () => ({
  type: TYPES.GET_ESTADOS_SAGA
})

export const getGenerosSaga = () => ({
  type: TYPES.GET_GENEROS_SAGA
})

export const getTiposOperacaoSaga = () => ({
  type: TYPES.GET_TIPOS_OPERACO_SAGA
})

export const getBancosPagadoresSaga = () => ({
  type: TYPES.GET_BANCOS_SAGA
})

export const getGruposLojasSaga = () => ({
  type: TYPES.GET_GRUPOS_LOJAS_SAGA
})

export const getBancosCartaoSaga = () => ({
  type: TYPES.GET_BANCOS_CARTAO_SAGA
})

export const setStatusCampanhaGrid = (
  campanhaCrmId: string,
  value: boolean
) => ({
  type: TYPES.SET_STATUS_CAMPANHA_GRID,
  campanhaCrmId,
  value
})

export const alterarStatusCampanhaDispatchSaga = (
  campanhaCrmId: string,
  ativo: boolean
) =>
  store.dispatch({
    type: TYPES.ALTERAR_STATUS_CAMPANHA_DISPATCH_SAGA,
    campanhaCrmId,
    ativo
  })

export const abastecimentoClientesSaga = (value: AbastecimentoCampanhaDto) => ({
  type: TYPES.ABASTECIMENTO_CLIENTES_SAGA,
  value
})
export const reabastecimentoClientesSaga = (
  value: ReabastecimentoCampanhaDto
) => ({
  type: TYPES.REABASTECIMENTO_CLIENTES_SAGA,
  value
})

export const getEspeciesBeneficioSaga = () => ({
  type: TYPES.GET_ESPECIES_BENEFICIO_SAGA
})

export const setLojas = (value: Lojas[]) => ({
  type: TYPES.SET_LOJAS,
  value
})

export const getAllLojasSaga = () => ({
  type: TYPES.GET_ALL_LOJAS_SAGA
})

export const setCampanhasSelectedIds = (value: string[]) => ({
  type: TYPES.SET_CAMPANHAS_SELECTED_IDS,
  value
})

export const deleteCampanhasSelecionadasSagaDispatch = () =>
  store.dispatch({
    type: TYPES.DELETE_CAMPANHAS_SELECIONADAS_SAGA
  })

export const toggleModalExclusaoSelecionadosDispatch = (value: boolean) =>
  store.dispatch({
    type: TYPES.TOGGLE_MODAL_EXCLUSAO_SELECIONADOS,
    value
  })

export const cleanCampanhasSelectedIds = () => ({
  type: TYPES.CLEAN_CAMPANHAS_SELECTED_IDS
})
