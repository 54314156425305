import { TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as navbarController from 'controller/navbarController'
import * as navbarActions from 'store/modules/navbar/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { NAVBAR } from './types'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'

function* atualizaToken(): SagaIterator {
  const { token, usuarioSelected }: NAVBAR = yield select(
    (state: ApplicationState) => state.navbar
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(navbarController.atualizaToken, token, usuarioSelected)
    yield put(navbarActions.cleanToken())
    yield put(navbarActions.cleanUsuarioSelected())
    toast.success('Token atualizado com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getUsuariosInstituicao(): SagaIterator {
  try {
    const response = yield call(navbarController.getUsuariosInstituicao)
    yield put(navbarActions.setUsuariosInstituicao(response?.data?.content))
  } catch (error) {
    handlerError(error)
  }
}

export function* watchAtualizaToken() {
  yield takeEvery(TYPES.ATUALIZA_TOKEN_SAGA, atualizaToken)
}

export function* watchGetUsuariosInstituicao() {
  yield takeEvery(TYPES.GET_USUARIOS_INSTITUICAO_SAGA, getUsuariosInstituicao)
}

function* navbarSaga() {
  yield all([fork(watchAtualizaToken), fork(watchGetUsuariosInstituicao)])
}

export default navbarSaga
