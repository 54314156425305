import { all } from 'redux-saga/effects'
import atendimentoSaga from './atendimento/saga'
import loginSaga from './login/saga'
import producaoSaga from './producao/saga'
import tabulacaoSaga from './backoffice/tabulacao/saga'
import grupoSaga from './backoffice/grupos/saga'
import produtoSaga from './backoffice/produto/saga'
import historicoSaga from './historico/saga'
import carteiraSaga from './backoffice/carteira/saga'
import blacklistSaga from './backoffice/blacklist/saga'
import navbarSaga from './navbar/saga'
import agendaSaga from './agenda/saga'
import uploadSaga from './backoffice/uploadArquivos/saga'
import credenciaisSaga from './backoffice/credenciais/saga'
import indicadoresSaga from './indicadores/saga'
import campanhaSaga from './backoffice/campanha/saga'
import indicacaoSaga from './indicacao/saga'
import inicioSaga from './inicio/saga'
import sidebarSaga from './sidebar/saga'

export default function* rootSaga() {
  yield all([
    atendimentoSaga(),
    loginSaga(),
    sidebarSaga(),
    producaoSaga(),
    tabulacaoSaga(),
    grupoSaga(),
    blacklistSaga(),
    produtoSaga(),
    historicoSaga(),
    carteiraSaga(),
    navbarSaga(),
    uploadSaga(),
    agendaSaga(),
    credenciaisSaga(),
    indicadoresSaga(),
    campanhaSaga(),
    indicacaoSaga(),
    inicioSaga()
  ])
}
