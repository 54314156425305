import InputCustom from 'components/Inputs'
import React, { useEffect, useState } from 'react'
import { CustomButton } from 'styles/global'
import { BntValidaBeneficio } from '../style'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'

interface ValidaBeneficioBmgProps {
  cpf: string
  matricula: string
  onValidate: (e: string, matricula: string) => void
  onResend: () => void
}

function ValidaBeneficioBmg({
  cpf,
  matricula,
  onValidate,
  onResend
}: ValidaBeneficioBmgProps) {
  const [value, setValue] = useState('')
  const { dispatch } = useRedux()

  useEffect(() => {
    dispatch(atendimentoActions.getSolicitacoesIN100Saga(cpf))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="d-flex align-items-end column-gap-10">
        <div className="d-flex flex-column">
          <label className="label-12">Insira o código aqui*</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              setValue(e.target.value.toUpperCase())
            }}
            value={value}
          />
        </div>
        <CustomButton
          onClick={() => {
            onValidate(value, matricula)
            setValue('')
          }}
          justifyContent="center"
          letterSpacing={2}
          width={100}
        >
          Validar
        </CustomButton>
      </div>
      <BntValidaBeneficio
        onClick={() => {
          onResend()
        }}
        role="button"
      >
        Não recebeu o código?
      </BntValidaBeneficio>
    </div>
  )
}

export default ValidaBeneficioBmg
