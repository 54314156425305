import React, { useEffect } from 'react'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import SimpleCard from 'components/SimpleCard'
import { FiServer } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { SimpleTitleCard, CustomButton } from 'styles/global'
import CustomTable from 'components/CustomTable'
import {
  setAtiveTab,
  setPesquisa
} from 'store/modules/backoffice/tabulacao/actions'
import { useSelector } from 'react-redux'
import { TABULACAO } from 'store/modules/backoffice/tabulacao/types'
import { ApplicationState } from 'store'
import useRedux from 'hooks/useRedux'
import * as tabulacaoActions from 'store/modules/backoffice/tabulacao/actions'
import { COLUMNS } from '../constants/columns'
import ModalExclusao from 'components/ModalExclusao'

const Search = () => {
  const { search, tabulacoes, pagination, showConfirmacaoExclusao } =
    useSelector<ApplicationState, TABULACAO>((state) => state.tabulacao)

  const { dispatch } = useRedux()

  function onChange(value: string, field: string) {
    dispatch(setPesquisa(value, field))
  }

  function setPage(value: number) {
    dispatch(tabulacaoActions.setPaginacao({ ...pagination, page: value }))
    dispatch(tabulacaoActions.getAllTabulacoesSaga())
  }

  function setPageSize(value: number) {
    dispatch(
      tabulacaoActions.setPaginacao({ ...pagination, page: 0, pageSize: value })
    )
    dispatch(tabulacaoActions.getAllTabulacoesSaga())
  }

  function getTabulacoes() {
    dispatch(tabulacaoActions.setPaginacao({ ...pagination, page: 0 }))
    dispatch(tabulacaoActions.getAllTabulacoesSaga())
  }

  useEffect(() => {
    dispatch(tabulacaoActions.getAllTabulacoesSaga())
  }, [dispatch])

  return (
    <SimpleCard>
      <div className="container-fluid d-flex flex-column row-gap-10">
        <Row>
          <Col md={6}>
            <SimpleTitleCard>
              <FiServer className="icon" />
              Tabulações
            </SimpleTitleCard>
          </Col>
          <Col className="d-flex justify-content-end" md={6}>
            <CustomButton onClick={() => dispatch(setAtiveTab('register'))}>
              Cadastrar nova
            </CustomButton>
          </Col>
        </Row>
        <Row className="d-flex row-gap-15">
          <Col md={8}>
            <Row className="d-flex row-gap-15">
              <Col md={5}>
                <label className="label-12">Filtros</label>
                <CustomSelect
                  options={[
                    { label: 'Descrição', value: 'descricao' },
                    { label: 'Localização', value: 'localizacao' },
                    { label: 'Polaridade', value: 'polaridade' },
                    { label: 'Agendamento', value: 'agendamento' }
                  ]}
                  placeholder="Filtrar por"
                  onChange={(e) => {
                    onChange(e, 'filtro')
                  }}
                  value={search.filtro}
                  accessorLabel="label"
                  accessorValue="value"
                />
              </Col>
              <Col md={7}>
                <label className="label-12">Pesquisa</label>
                <InputCustom
                  type="text"
                  placeholder="Pesquise aqui"
                  onChange={(e) => {
                    onChange(e.target.value, 'pesquisa')
                  }}
                  value={search.pesquisa}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomButton onClick={() => getTabulacoes()} background="#662d90">
              Pesquisar
            </CustomButton>
          </Col>
        </Row>
        <Row>
          <CustomTable
            data={tabulacoes}
            columns={COLUMNS}
            paginationMode="server"
            page={pagination.page}
            pageSize={pagination.pageSize}
            setPage={(e) => setPage(e)}
            setPageSize={(e) => setPageSize(e)}
            rowCount={pagination.totalRegistros}
            autoHeight
          />
        </Row>
      </div>
      <ModalExclusao
        isOpen={showConfirmacaoExclusao}
        toggle={(e) => tabulacaoActions.toggleModalExclusaoDispatch(e)}
        onRemove={() => {
          tabulacaoActions.deleteTabulacaoSagaDispatch()
        }}
      />
    </SimpleCard>
  )
}

export default Search
