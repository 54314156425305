import React from 'react'
import { Inss } from './Inss'
import { Servidores } from './Servidores'
import { Default } from './Default'
import { MatriculaResumo } from 'models/atendimento/types'
import { CardBeneficio } from './style'

interface BeneficiosTabsProps {
  index: number
  matricula?: MatriculaResumo
  indexPerfil: number
}

const BeneficiosTabs = ({
  matricula,
  indexPerfil,
  index
}: BeneficiosTabsProps) => {
  switch (matricula?.tipoPerfil) {
    case 1:
      return (
        <CardBeneficio className="my-2">
          <div className="container-fluid">
            <label className="label-12">{matricula?.descricaoPerfil}</label>
            <Inss indexPerfil={indexPerfil} index={index} />
          </div>
        </CardBeneficio>
      )
    case 4:
      return (
        <CardBeneficio className="my-2">
          <div className="container-fluid">
            <label className="label-12">{matricula?.descricaoPerfil}</label>
            <Servidores indexPerfil={indexPerfil} index={index} />
          </div>
        </CardBeneficio>
      )
    case 3:
      return (
        <CardBeneficio className="my-2">
          <div className="container-fluid">
            <label className="label-12">{matricula?.descricaoPerfil}</label>
            <Servidores indexPerfil={indexPerfil} index={index} />
          </div>
        </CardBeneficio>
      )
    case 5:
      return (
        <CardBeneficio className="my-2">
          <div className="container-fluid">
            <label className="label-12">{matricula?.descricaoPerfil}</label>
            <Servidores indexPerfil={indexPerfil} index={index} />
          </div>
        </CardBeneficio>
      )
    default:
      return (
        <CardBeneficio className="my-2">
          <div className="container-fluid">
            <label className="label-12">{matricula?.descricaoPerfil}</label>
            <Default indexPerfil={indexPerfil} index={index} />
          </div>
        </CardBeneficio>
      )
  }
}

export default BeneficiosTabs
