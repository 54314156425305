import React, { useEffect } from 'react'
import { Login as LoginLayout } from 'layouts/login'
import { Button, CardLogin, Checkbox, Label, Link } from './style'
import logoRiber from 'assets/images/logo-crm.png'
import { FormGroup } from 'reactstrap'
import InputCustom from 'components/Inputs'
import { FormLogin, LOGIN } from 'store/modules/login/types'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import * as loginActions from 'store/modules/login/actions'
import useRedux from 'hooks/useRedux'
import { APICore } from 'helpers/api/apiCore'
import { useNavigate } from 'react-router-dom'
import TopLoading from 'components/Loading/TopLoading'
import infoPkg from '../../../package.json'
import config from 'config'

function Login() {
  const { login, loading } = useSelector<ApplicationState, LOGIN>(
    (state) => state.login
  )

  const { dispatch } = useRedux()

  function entrarSistema() {
    dispatch(loginActions.login())
  }

  type LoginFields = keyof FormLogin
  function onChange(value: string | boolean, field: LoginFields) {
    dispatch(loginActions.setLogin(value, field))
  }

  const api = new APICore()

  const navigate = useNavigate()

  useEffect(() => {
    const isAuthenticated = api.isAuthenticated()
    isAuthenticated && navigate('/inicio')
    const email = sessionStorage.getItem('email_login')
    if (email) {
      dispatch(loginActions.setLogin(email, 'email'))
      dispatch(loginActions.setLogin(true, 'lembrar'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LoginLayout>
      <CardLogin>
        <div className="d-flex justify-content-center">
          <img src={logoRiber} width="100" alt="Logo verde Ribercred" />
        </div>
        <FormGroup className="d-flex flex-column gap-3">
          <div className="d-flex flex-column">
            <Label htmlFor="email">E-mail</Label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value?.trim(), 'email')
              }}
              value={login.email}
            />
          </div>
          <div className="d-flex flex-column">
            <Label htmlFor="senha">Senha</Label>
            <InputCustom
              type="password"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'senha')
              }}
              value={login.senha}
            />
          </div>
          <Button
            onClick={() => {
              entrarSistema()
            }}
          >
            Entrar
          </Button>
          <div className="d-flex justify-content-center">
            <Link
              onClick={() => {
                dispatch(loginActions.recuperarAcessoSaga())
              }}
            >
              Esqueci minha senha
            </Link>
          </div>
          <div className="d-flex align-items-center gap-2">
            <Checkbox
              onChange={(e) => {
                onChange(e.target.checked, 'lembrar')
              }}
              checked={login.lembrar}
            />
            <span>Lembrar de mim</span>
          </div>
        </FormGroup>
      </CardLogin>
      {loading ? <TopLoading /> : <></>}
      <Version />
    </LoginLayout>
  )
}

export default Login

function Version() {
  switch (config.AMBIENTE) {
    case 'DESENV':
      return <p className="version">Ambiente de desenvolvimento</p>
    case 'HML':
      return <p className="version">{`v${infoPkg.versionHml}`}</p>
    case 'PROD':
      return <p className="version">{`v${infoPkg.versionProd}`}</p>
    default:
      return <p className="version">{`v${infoPkg.version}`}</p>
  }
}
