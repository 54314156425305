import React from 'react'
import { ErrorMessageWrapper } from '../style'
import { Tooltip } from '@mui/material'

interface ErrorMessageProps {
  children: string
}

const ErrorMessage = ({ children }: ErrorMessageProps) => {
  return (
    <Tooltip title={children} placement="bottom-start">
      <ErrorMessageWrapper>{children}</ErrorMessageWrapper>
    </Tooltip>
  )
}

export default ErrorMessage
