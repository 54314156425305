import { TYPES } from './types'

export const toggleSidebar = () => ({
  type: TYPES.TOGGLE_SIDEBAR
})

export const setPin = (value: boolean) => ({
  type: TYPES.SET_PIN,
  value
})

export const pinSidebarSaga = (value: boolean) => ({
  type: TYPES.PIN_SIDEBAR_SAGA,
  value
})
