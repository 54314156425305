import { AGENDA, Evento, TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects'
import * as agendaController from 'controller/agendaController'
import * as agendaActions from 'store/modules/agenda/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { handlerError } from 'util/handlerError'
import { maskDate } from 'util/masks'
import { ApplicationState } from 'store'
import { toast } from 'react-toastify'

interface GenericProps {
  type: string
}

interface GetAgendaAtendimentosProps extends GenericProps {
  dataInicio: string
  dataFim: string
}

function* getAgendaAtendimentos({
  dataInicio,
  dataFim
}: GetAgendaAtendimentosProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(
      agendaController.getAgendaAtendimentosEventos,
      dataInicio,
      dataFim
    )
    yield put(
      agendaActions.setAgendaAtendimentosEventos(response?.data?.content)
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getAgendaAtendimentosData(): SagaIterator {
  const { diaDetalhamento, filtroBigNumbers }: AGENDA = yield select(
    (state: ApplicationState) => state.agenda
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield put(agendaActions.cleanAgendaAtendimentosData())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let response: any
    switch (filtroBigNumbers) {
      case 'agendados':
        response = yield call(
          agendaController.getBigNumbersAgendados,
          maskDate(diaDetalhamento, 'YYYY-MM-DD')
        )
        break
      case 'confirmados':
        response = yield call(
          agendaController.getBigNumbersConfirmados,
          maskDate(diaDetalhamento, 'YYYY-MM-DD')
        )
        break
      case 'nao-confirmado':
        response = yield call(
          agendaController.getBigNumbersNaoConfirmados,
          maskDate(diaDetalhamento, 'YYYY-MM-DD')
        )
        break
      case 'expirado':
        response = yield call(
          agendaController.getBigNumbersExpirados,
          maskDate(diaDetalhamento, 'YYYY-MM-DD')
        )
        break
      default:
        response = yield call(
          agendaController.getBigNumbersAgendados,
          maskDate(diaDetalhamento, 'YYYY-MM-DD')
        )
        break
    }
    yield put(agendaActions.cleanDetalheAgendamento())
    yield put(agendaActions.cleanAgendaAtendimentosData())
    yield put(agendaActions.setAtiveTab('agendamento-diario'))
    const agrupamentoHorario = response?.data?.content?.reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (prev: any, item: Evento) => {
        const horario: string = item.horario
        if (!prev[horario]) {
          prev[horario] = []
        }
        prev[horario].push(item)
        return prev
      },
      []
    )
    const groupArrays = Object.keys(agrupamentoHorario).map(
      (horario: string) => {
        return {
          horario,
          agendamentos: agrupamentoHorario[horario]
        }
      }
    )
    yield put(agendaActions.setAgendaAtendimentosData(groupArrays))
  } catch (error) {
    handlerError(error)
    yield put(agendaActions.setAtiveTab('calendario'))
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface GetAgendaAtendimentoDetalheProps extends GenericProps {
  pessoaId: string
  atendimentoId: string
}

function* getAgendaAtendimentoDetalhe({
  pessoaId,
  atendimentoId
}: GetAgendaAtendimentoDetalheProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    yield put(agendaActions.cleanDetalheAgendamento())
    const response = yield call(
      agendaController.getAgendaAtendimentoDetalhe,
      pessoaId,
      atendimentoId
    )
    yield put(agendaActions.setDetalheAgendamento(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface DataProps extends GenericProps {
  data: string
}

function* getDiasSemana({ data }: DataProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    yield put(agendaActions.cleanDiasSemana())
    const response = yield call(agendaController.getDiaSemana, data)
    yield put(agendaActions.setDiasSemana(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getBigNumbers({ data }: DataProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    yield put(agendaActions.cleanBigNumbers())
    const response = yield call(agendaController.getBigNumbers, data)
    yield put(agendaActions.setBigNumbers(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getVisaoSupervisor({ data }: DataProps): SagaIterator {
  const { paginacaoVisaoSupervisor }: AGENDA = yield select(
    (state: ApplicationState) => state.agenda
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield put(agendaActions.cleanVisaoSupervisor())
    const response = yield call(agendaController.getVisaoSupervisor, {
      dataAgendamentos: data,
      pagina: paginacaoVisaoSupervisor.page,
      registros: paginacaoVisaoSupervisor.pageSize
    })
    yield put(
      agendaActions.setPaginacaoVisaoSupervisor({
        ...paginacaoVisaoSupervisor,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )
    yield put(
      agendaActions.setVisaoSupervisor(response?.data?.content?.registros)
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getVisaoCoordenador({ data }: DataProps): SagaIterator {
  const { paginacaoVisaoCoordenador }: AGENDA = yield select(
    (state: ApplicationState) => state.agenda
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield put(agendaActions.cleanVisaoCoordenador())
    const response = yield call(agendaController.getVisaoCoordenador, {
      dataAgendamentos: data,
      pagina: paginacaoVisaoCoordenador.page,
      registros: paginacaoVisaoCoordenador.pageSize
    })
    yield put(
      agendaActions.setPaginacaoVisaoCoordenador({
        ...paginacaoVisaoCoordenador,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )
    yield put(
      agendaActions.setVisaoCoordenador(response?.data?.content?.registros)
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface ConfirmacaoAgendamentoProps extends GenericProps {
  agendamentoId: string
}

function* confirmacaoAgendamento({
  agendamentoId
}: ConfirmacaoAgendamentoProps): SagaIterator {
  const { dataReferencia, diaDetalhamento }: AGENDA = yield select(
    (state: ApplicationState) => state.agenda
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(agendaController.confirmacaoAgendamento, agendamentoId)
    const reponseEventos = yield call(
      agendaController.getAgendaAtendimentosEventos,
      dataReferencia.dataInicio,
      dataReferencia.dataFim
    )
    yield put(
      agendaActions.setAgendaAtendimentosEventos(reponseEventos?.data?.content)
    )
    yield put(agendaActions.getAgendaAtendimentosDataSaga())
    yield put(agendaActions.getBigNumbersSaga(diaDetalhamento))
    yield put(agendaActions.getVisaoCoordenadorSaga(diaDetalhamento))
    yield put(agendaActions.getVisaoSupervisorSaga(diaDetalhamento))
    toast.success('Agendamendo confirmado com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface GetGravacaoProps extends GenericProps {
  atendimentoId: string
}

function* getGravacao({ atendimentoId }: GetGravacaoProps): SagaIterator {
  const { detalheAgendamento }: AGENDA = yield select(
    (state: ApplicationState) => state.agenda
  )
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(agendaController.getGravacao, atendimentoId)
    const byteCharacters = window.atob(response?.data?.content?.arquivo)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'audio/mp3' })

    const blobURL = URL.createObjectURL(blob)

    yield put(
      agendaActions.setDetalheAgendamento({
        ...detalheAgendamento,
        gravacoes: blobURL
      })
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getTiposAgendamento(): SagaIterator {
  try {
    const response = yield call(agendaController.getTiposAgendamentos)
    yield put(agendaActions.setTiposAgendamentos(response?.data?.content))
  } catch (error) {
    //
  }
}

function* editarAgendamento(): SagaIterator {
  const { modalReagendamento, dataReferencia, diaDetalhamento }: AGENDA =
    yield select((state: ApplicationState) => state.agenda)
  try {
    yield put(loadingActions.setLoading(true))
    yield call(agendaController.editarAgendamento, {
      atendimentoAgendadoId: modalReagendamento.atendimentoAgendadoId,
      confirmado: modalReagendamento.confirmado,
      dataAtendimento: new Date(Date.parse(modalReagendamento.dataAtendimento)),
      horario: modalReagendamento.horario,
      tipoAtendimentoId: modalReagendamento.tipoAtendimentoId
    })
    yield put(agendaActions.cleanModalReagendamento())
    toast.success('Atendimento reagendado com sucesso!')
    yield put(agendaActions.setIsOpenModalReagendamento(false))
    const reponseEventos = yield call(
      agendaController.getAgendaAtendimentosEventos,
      dataReferencia.dataInicio,
      dataReferencia.dataFim
    )
    yield put(
      agendaActions.setAgendaAtendimentosEventos(reponseEventos?.data?.content)
    )
    yield put(agendaActions.getAgendaAtendimentosDataSaga())
    yield put(agendaActions.getBigNumbersSaga(diaDetalhamento))
  } catch (error) {
    handlerError(error, 'Não foi possível realizar o reagendamento')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetAgendaAtendimentos() {
  yield takeEvery(
    TYPES.GET_AGENDA_ATENDIMENTOS_EVENTOS_SAGA,
    getAgendaAtendimentos
  )
}

export function* watchGetAgendaAtendimentosData() {
  yield takeEvery(
    TYPES.GET_AGENDA_ATENDIMENTOS_DATA_SAGA,
    getAgendaAtendimentosData
  )
}

export function* watchGetAgendaAtendimentoDetalhe() {
  yield takeEvery(
    TYPES.GET_AGENDA_ATENDIMENTO_DETALHE_SAGA,
    getAgendaAtendimentoDetalhe
  )
}

export function* watchGetDiasSemana() {
  yield takeEvery(TYPES.GET_DIAS_SEMANA_SAGA, getDiasSemana)
}

export function* watchGetBigNumbers() {
  yield takeEvery(TYPES.GET_BIGNUMBERS_SAGA, getBigNumbers)
}

export function* watchGetVisaoSupervisor() {
  yield takeEvery(TYPES.GET_VISAO_SUPERVISOR_SAGA, getVisaoSupervisor)
}

export function* watchGetVisaoCoordenador() {
  yield takeEvery(TYPES.GET_VISAO_COORDENADOR_SAGA, getVisaoCoordenador)
}

export function* watchConfirmacaoAgendamento() {
  yield takeEvery(TYPES.CONFIRMACAO_AGENDAMENTO_SAGA, confirmacaoAgendamento)
}

export function* watchGetGravacao() {
  yield takeEvery(TYPES.GET_GRAVACAO_SAGA, getGravacao)
}

export function* watchGetTiposAgendamento() {
  yield takeEvery(TYPES.GET_TIPOS_AGENDAMENTOS_SAGA, getTiposAgendamento)
}

export function* watchEditarAgendamento() {
  yield takeEvery(TYPES.EDITAR_AGENDAMENTO_SAGA, editarAgendamento)
}

function* agendaSaga() {
  yield all([
    fork(watchGetAgendaAtendimentos),
    fork(watchGetAgendaAtendimentosData),
    fork(watchGetAgendaAtendimentoDetalhe),
    fork(watchGetDiasSemana),
    fork(watchGetBigNumbers),
    fork(watchGetVisaoSupervisor),
    fork(watchGetVisaoCoordenador),
    fork(watchConfirmacaoAgendamento),
    fork(watchGetGravacao),
    fork(watchGetTiposAgendamento),
    fork(watchEditarAgendamento)
  ])
}

export default agendaSaga
