import { maskDate } from 'util/masks'
import * as yup from 'yup'
import { IndicacaoPropostaReq } from 'models/indicacao/types'

type SchemaProps = Omit<IndicacaoPropostaReq, 'dataProposta'> & {
  dataProposta: Date
}

export const schema: yup.SchemaOf<SchemaProps> = yup.object().shape({
  propostaIndicacaoId: yup.string().required(),
  dataProposta: yup
    .date()
    .required()
    .max(maskDate(new Date() as unknown as string, 'YYYY-MM-DD')),
  cpf: yup.string().required(),
  numeroAdesao: yup.string().required(),
  bancoId: yup.string().required(),
  statusPropostaId: yup.string().required(),
  tipoOperacaoId: yup.string().required(),
  usuarioBancoId: yup.string().required(),
  lojaId: yup.string().required(),
  centroCustoId: yup.string().required()
})
