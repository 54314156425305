import { ParametrizacaoGrupo, TYPES } from './types'
import { Lojas, Paginacao } from 'models/types'
import { Grupo } from 'models/backoffice/grupos/type'

import store from 'store'

type ActiveTab = 'search' | 'register'

export const setActiveTab = (value: ActiveTab) => ({
  type: TYPES.SET_ACTIVE_TAB,
  value
})

export const setItemSelectedIdDispatch = (value: string) =>
  store.dispatch({
    type: TYPES.SET_ITEM_SELECTED_ID,
    value
  })

export const setGrupos = (value: Grupo[]) => ({
  type: TYPES.SET_GRUPOS,
  value
})

export const setLojas = (value: Lojas[]) => ({
  type: TYPES.SET_LOJAS,
  value
})

export const setPaginacao = (value: Paginacao) => ({
  type: TYPES.SET_PAGINATION,
  value
})

export const getAllGruposSaga = () => ({
  type: TYPES.GET_ALL_GRUPOS_SAGA
})

export const getAllLojasSaga = () => ({
  type: TYPES.GET_ALL_LOJAS_SAGA
})

export const setPesquisa = (value: string | number | null, field: string) => ({
  type: TYPES.SET_PESQUISA,
  value,
  field
})

export const setRegisterFields = (
  value: string | number | boolean,
  field: string
) => ({
  type: TYPES.SET_REGISTER_FIELDS,
  value,
  field
})

export const setRegister = (value: ParametrizacaoGrupo) => ({
  type: TYPES.SET_REGISTER,
  value
})

export const cleanRegister = () => ({
  type: TYPES.CLEAN_REGISTER
})

export const cleanInvalids = () => ({
  type: TYPES.CLEAN_INVALIDS
})

export const cleanSearch = () => ({
  type: TYPES.CLEAN_SEARCH
})

export const setInvalids = (value: boolean, field: string) => ({
  type: TYPES.SET_INVALIDS,
  value,
  field
})

export const criarGrupoSaga = () => ({
  type: TYPES.POST_GRUPO_SAGA
})

export const atualizarGrupoSaga = () => ({
  type: TYPES.PUT_GRUPO_SAGA
})

export const editarGrupoSagaDispatch = (id: string) =>
  store.dispatch({
    type: TYPES.EDITAR_GRUPO_SAGA_DISPATCH,
    id
  })

export const visualizarGrupoSagaDispatch = (id: string) =>
  store.dispatch({
    type: TYPES.VISUALIZAR_GRUPO_SAGA_DISPATCH,
    id
  })

export const deleteGrupoSaga = (id: string) => ({
  type: TYPES.DELETE_GRUPO_SAGA,
  id
})

export const deleteGrupoSagaDispatch = () =>
  store.dispatch({
    type: TYPES.DELETE_GRUPO_SAGA
  })

export const setVisualizacao = (value: boolean) => ({
  type: TYPES.SET_VISUALIZACAO,
  value
})

export const toggleModalExclusaoDispatch = (value: boolean) =>
  store.dispatch({
    type: TYPES.TOGGLE_MODAL_EXCLUSAO,
    value
  })
