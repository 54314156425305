import InputCustom from 'components/Inputs'
import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import { AnswersFormularioNecessidade } from 'models/types'
import { maskMoney, removeMaskMoney } from 'util/masks'
import * as yup from 'yup'
import { toast } from 'react-toastify'

const ValorDesejado = () => {
  const { formularioNecessidade } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  type Fields = keyof AnswersFormularioNecessidade
  function onChange(value: string, field: Fields) {
    dispatch(atendimentoActions.setInfosFormulario(value, field))
  }

  function changeTab(tab: number) {
    dispatch(atendimentoActions.setTabFormulario(tab))
  }

  const schema = yup.object().shape({
    valorDesejado: yup
      .number()
      .required('O campo valor desejado é obrigatório')
      .moreThan(0, 'O campo valor desejado deve ser maior que 0,00')
      .lessThan(
        1000000000.0,
        'O campo valor desejado deve ser menor que 1.000.000.000,00'
      )
  })

  function proximo() {
    schema
      .validate(
        {
          valorDesejado: Number(
            removeMaskMoney(formularioNecessidade.answers.valorDesejado)
          )
        },
        { abortEarly: false }
      )
      .then(() => {
        changeTab(2)
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          toast.warn(e.message)
        })
      })
  }

  return (
    <div className="container-fluid">
      <div className="container-formulario">
        <Row>
          <Col>
            <label className="label-12">Valor desejado*</label>
            <InputCustom
              onChange={(e) =>
                onChange(maskMoney(e.target.value), 'valorDesejado')
              }
              type="text"
              placeholder="R$ 50.000,00"
              value={maskMoney(formularioNecessidade.answers.valorDesejado)}
              maxLength={14}
            />
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-center column-gap-20">
        <div
          className="btn-purple justify-content-center"
          onClick={() => proximo()}
        >
          Próximo
        </div>
      </div>
    </div>
  )
}

export default ValorDesejado
