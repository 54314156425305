import styled from 'styled-components'

export enum Colors {
  blue = 'blue',
  green = 'green',
  gray = 'gray',
  orange = 'orange',
  purpleGradiente = 'purpleGradiente'
}

function handleColorType(color: Colors | undefined) {
  switch (color) {
    case Colors.blue:
      return 'background-color: #1FB2CE;'
    case Colors.green:
      return 'background-color: #46C647;'
    case Colors.gray:
      return 'background-color: #8E8E8E;'
    case Colors.orange:
      return 'background-color: #F47820;'
    case Colors.purpleGradiente:
      return 'background: linear-gradient(43.84deg, #662D91 -5.32%, #A64DFF 97.27%);'
    default:
      return 'background-color: #FFFFFF;'
  }
}

interface ColorProps {
  background?: Colors
  color?: string
}

interface CardBigNumbersProps extends ColorProps {
  active?: boolean
}

export const CardBigNumbers = styled.button<CardBigNumbersProps>`
  color: #fff;
  cursor: pointer;
  margin-top: 15px;
  min-width: 160px;
  width: 22.5%;
  height: 90px;
  border-radius: 15px;
  border: none;
  box-shadow: ${(props) =>
    props.active
      ? '0 0 0 0.25rem rgba(13 110 253 / 25%)'
      : '0 2px 5px rgba(0, 0, 0, 0.1)'};
  ${({ background }) => handleColorType(background)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & div {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    padding: 0 5px;

    span {
      font-size: 28px;
    }

    label {
      font-size: 12px;
    }
  }

  & .icon {
    height: 40px;
    width: 40px;
  }
`

export const ContentBoard = styled.div<ColorProps>`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 20px;
  ${({ background }) => handleColorType(background)};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  width: calc(50% - 0.5rem);

  & label {
    color: ${(props) => (props.color ? props.color : '#353535')};
    font-family: Open Sans;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  }

  & span {
    color: ${(props) => (props.color ? props.color : '#353535')};
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`
export const Carteira = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 24px;
  background: #fafafa;
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  border-radius: 5px;
  row-gap: 20px;
  min-width: 273.5px;

  & .green-text,
  & .purple-text,
  & .red-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }

  & .green-text {
    color: var(--verde-riber);
  }

  & .purple-text {
    color: var(--roxo-riber);
  }

  & .red-text {
    color: #c40233;
  }
`

export const CampanhaBoard = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
  background: #fafafa;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  width: 90%;

  & div.banner {
    background-color: blue;
    height: 143.52px;

    img {
      object-fit: cover;
      height: 143.52px;
    }
  }

  & .titulo-campanha {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }

  & .informacoes-campanha {
    padding: 10px;
  }

  & .green-text.md,
  & .purple-text.md,
  & .red-text.md {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  & .green-text.lg,
  & .purple-text.lg,
  & .red-text.lg {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }

  & .green-text {
    color: var(--verde-riber);
  }

  & .purple-text {
    color: var(--roxo-riber);
  }

  & .red-text {
    color: #c40233;
  }
`

export const FormularioNecessidadeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & .container-formulario {
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;
    min-height: 200px;
    margin: 20px 0;
  }

  & .switch {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 34px;
  }

  & .switch.md {
    width: 125px;
  }

  & .switch input {
    display: none;
  }

  & .sliderCheck {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c8c8c8;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  & .sliderCheck:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  & input:checked + .sliderCheck {
    background-color: #662d91;
  }

  & input:focus + .sliderCheck {
    box-shadow: 0 0 1px #2196f3;
  }

  & input:checked + .sliderCheck:before {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(55px);
  }

  & .switch.md input:checked + .sliderCheck:before {
    -webkit-transform: translateX(90px);
    -ms-transform: translateX(90px);
    transform: translateX(90px);
  }

  & .on {
    display: none;
  }

  & .on,
  & .off {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
  }

  & input:checked + .sliderCheck .on {
    display: block;
  }

  & input:checked + .sliderCheck .off {
    display: none;
  }

  & .sliderCheck.round {
    border-radius: 34px;
  }

  & .sliderCheck.round:before {
    border-radius: 50%;
  }
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  cursor: pointer;
  transform: scale(0.8);
  margin: 0 10px 0 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -2px;
    left: -2px;
    background-color: #ffffff;
    border: solid 2px #662d91;
    border-radius: 5px;
  }
  &:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -2px;
    left: -2px;
    border: solid 2px #662d91;
  }
  &:checked:after {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 10px;
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    background-color: #662d91;
    position: absolute;
    top: 3px;
    left: 3px;
  }
`
export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`
