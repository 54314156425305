import {
  Localizacao,
  PeriodoAgendamento,
  Polaridade,
  Tabulacao
} from 'models/backoffice/tabulacao/type'
import { Paginacao } from 'models/types'

export enum TYPES {
  SET_ACTIVE_TAB = '@@tabulacao/SET_ACTIVE_TAB',
  SET_ITEM_SELECTED_ID = '@@tabulacao/SET_ITEM_SELECTED_ID',
  TOGGLE_MODAL_EXCLUSAO = '@@tabulacao/TOGGLE_MODAL_EXCLUSAO',
  SET_PESQUISA = '@@tabulacao/SET_PESQUISA',
  SET_REGISTER_FIELDS = '@@tabulacao/SET_REGISTER_FIELDS',
  SET_REGISTER = '@@tabulacao/SET_REGISTER',
  SET_VISUALIZACAO = '@@tabulacao/SET_VISUALIZACAO',
  SET_PAGINATION = '@@tabulacao/SET_PAGINATION',
  SET_INVALIDS = '@@tabulacao/SET_INVALIDS',
  CLEAN_INVALIDS = '@@tabulacao/CLEAN_INVALIDS',
  CLEAN_REGISTER = '@@tabulacao/CLEAN_REGISTER',
  SET_TABULACOES = '@@tabulacao/SET_TABULACOES',
  SET_POLARIDADES = '@@tabulacao/SET_POLARIDADES',
  SET_LOCALIZACOES = '@@tabulacao/SET_LOCALIZACOES',
  SET_AGENDAMENTOS = '@@tabulacao/SET_AGENDAMENTOS',

  GET_ALL_TABULACOES_SAGA = '@@tabulacao/GET_ALL_TABULACOES_SAGA',
  GET_POLARIDADES_SAGA = '@@tabulacao/GET_POLARIDADES_SAGA',
  GET_LOCALIZACOES_SAGA = '@@tabulacao/GET_LOCALIZACOES_SAGA',
  GET_AGENDAMENTOS_SAGA = '@@tabulacao/GET_AGENDAMENTOS_SAGA',
  GET_TABULACAO_SAGA = '@@tabulacao/GET_TABULACAO_SAGA',
  VISUALIZAR_TABULACAO_SAGA_DISPATCH = '@@tabulacao/VISUALIZAR_TABULACAO_SAGA_DISPATCH',
  EDITAR_TABULACAO_SAGA_DISPATCH = '@@tabulacao/EDITAR_TABULACAO_SAGA_DISPATCH',
  PUT_TABULACAO_SAGA = '@@tabulacao/PUT_TABULACAO_SAGA',
  POST_TABULACAO_SAGA = '@@tabulacao/POST_TABULACAO_SAGA',
  DELETE_TABULACAO_SAGA = '@@tabulacao/DELETE_TABULACAO_SAGA'
}

interface Search {
  pesquisa: string
  filtro: string | null
}

export interface ParametrizacaoTabulacao {
  id: string
  descricao: string
  retirarClienteCampanha: boolean
  condenarTelefone: boolean
  pularParaProximoTelefone: boolean
  finalizarAtendimento: boolean
  polaridadeId: string
  localizacaoId: string
  periodoAgendamentoId: string
  observacoes: string
}

export interface TABULACAO {
  activeTab: string
  search: Search
  register: ParametrizacaoTabulacao
  pagination: Paginacao
  invalids: InvalidsParametrizacaoTabulacao
  tabulacoes: Tabulacao[]
  polaridades: Polaridade[]
  localizacoes: Localizacao[]
  agendamentos: PeriodoAgendamento[]
  visualizacao: boolean
  itemSelectedId: string
  showConfirmacaoExclusao: boolean
}

export interface InvalidsParametrizacaoTabulacao {
  id: boolean
  descricao: boolean
  retirarClienteCampanha: boolean
  condenarTelefone: boolean
  pularParaProximoTelefone: boolean
  finalizarAtendimento: boolean
  polaridadeId: boolean
  localizacaoId: boolean
  periodoAgendamentoId: boolean
  bloquearAtendimentoDurantePeriodo: boolean
  observacoes: boolean
}
