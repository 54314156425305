import { APICore } from 'helpers/api/apiCore'

const api = new APICore()

export function atualizaToken(token: string, usuarioInstituicao: string) {
  return api.create(`Credencial/token`, {
    token,
    usuarioInstituicao
  })
}

export function getUsuariosInstituicao() {
  return api.get(`Credencial/usuario-instituicao-bmg`, null)
}
