import React, { useEffect, useState } from 'react'
import InputCustom from 'components/Inputs'
import { Col, Row } from 'reactstrap'
import { CustomButton } from 'styles/global'
import { maskMoney, removeMaskMoney } from 'util/masks'
import { ContentDetalhamentoProduto } from '../style'
import { EnumServicos } from 'models/enums'
import CustomSelect from 'components/Select'
import { BiChevronRight } from 'react-icons/bi'
import DetalhamentoParcelasFgts from './Modals/DetalhamentoParcelasFgts'
import { ProdutosSimulacao } from 'models/types'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import { toast } from 'react-toastify'
import * as yup from 'yup'

interface DetalhamentoProdutoProps {
  cpf: string
  produto: ProdutosSimulacao
  index: number
}

function DetalhamentoProduto({
  cpf,
  produto,
  index
}: DetalhamentoProdutoProps) {
  const [show, setShow] = useState(false)
  const [tabelaState, setTabelaState] = useState<number | null>(
    produto.codigoProduto
  )
  const [parcelasState, setParcelasState] = useState(produto.quantidadeParcelas)
  const [valorState, setValorState] = useState(
    produto.servico === 7
      ? produto.valorSaque
      : maskMoney(produto.valorTotalFinanciado?.toFixed(2))
  )
  const [invalid, setInvalid] = useState({
    tabela: {
      invalid: false,
      message: ''
    },
    valorTotal: {
      invalid: false,
      message: ''
    },
    valorParcela: {
      invalid: false,
      message: ''
    },
    parcelas: {
      invalid: false,
      message: ''
    }
  })
  const [valorParcelaState, setValorParcelaState] = useState(
    maskMoney(produto.valorParcela?.toFixed(2))
  )
  const onToggle = () => setShow(!show)
  const { dispatch } = useRedux()

  const schemaEmprestimo = yup.object().shape({
    tabela: yup.string().required('Uma tabela deve ser selecionada'),
    valorParcela: yup
      .number()
      .min(0, `O valor deve ser maior ou igual a ${maskMoney(0)}`)
      .max(
        produto.margemDisponivel,
        `O valor deve ser menor ou igual a ${maskMoney(
          produto.margemDisponivel?.toFixed(2)
        )}`
      ),
    valorTotal: yup
      .number()
      .min(0, `O valor deve ser maior ou igual a ${maskMoney(0)}`),
    parcelas: yup.number().required('Uma parcela deve ser selecionada')
  })

  const schemaCartaoBeneficio = yup.object().shape({
    valorParcela: yup
      .number()
      .min(0, `O valor deve ser maior ou igual a ${maskMoney(0)}`)
      .max(
        produto.margemDisponivelCartaoBeneficio,
        `O valor deve ser menor ou igual a ${maskMoney(
          produto.margemDisponivelCartaoBeneficio?.toFixed(2)
        )}`
      ),
    valorTotal: yup.number().when('valorParcela', {
      is: (valorParcela: number) => valorParcela === 0,
      then: yup
        .number()
        .min(
          produto.valorSaqueMinimo,
          `O valor deve ser maior ou igual a ${maskMoney(
            produto.valorSaqueMinimo?.toFixed(2)
          )}`
        )
        .max(
          produto.valorSaqueMaximo,
          `O valor deve ser menor ou igual a ${maskMoney(
            produto.valorSaqueMaximo?.toFixed(2)
          )}`
        )
    })
  })

  const schema = yup.object().shape({
    tabela: yup.string().required('Uma tabela deve ser selecionada'),
    valorParcela: yup
      .number()
      .min(0, `O valor deve ser maior ou igual a ${maskMoney(0)}`),
    valorTotal: yup
      .number()
      .min(0, `O valor deve ser maior ou igual a ${maskMoney(0)}`),
    parcelas: yup.number().required('Uma parcela deve ser selecionada')
  })

  const schemaPortabilidade = yup.object().shape({
    tabela: yup.string().required('Uma tabela deve ser selecionada'),
    valorParcela: yup
      .number()
      .min(0, `O valor deve ser maior ou igual a ${maskMoney(0)}`)
      .max(
        produto.margemDisponivel,
        `O valor deve ser menor ou igual a ${maskMoney(
          produto.margemDisponivel?.toFixed(2)
        )}`
      ),
    valorTotal: yup
      .number()
      .min(0, `O valor deve ser maior ou igual a ${maskMoney(0)}`),
    parcelas: yup.number().required('Uma parcela deve ser selecionada')
  })

  useEffect(() => {
    setParcelasState(produto.quantidadeParcelas)
    setValorState(
      produto.servico === 7
        ? produto.valorSaque
        : maskMoney(produto.valorTotalFinanciado?.toFixed(2))
    )
    setValorParcelaState(maskMoney(produto.valorParcela?.toFixed(2)))
  }, [produto])

  useEffect(() => {
    cleanInvalids()
  }, [
    produto.servico,
    produto.quantidadeParcelas,
    produto.valorTotalFinanciado,
    produto.valorParcela
  ])

  function recalculoPropostaCrm() {
    cleanInvalids()
    switch (produto.servico) {
      case 1:
        return schemaEmprestimo
          .validate(
            {
              tabela: tabelaState,
              valorParcela: Number(removeMaskMoney(valorParcelaState)),
              valorTotal: Number(removeMaskMoney(valorState)),
              parcelas: parcelasState
            },
            { abortEarly: false }
          )
          .then(() => {
            const tabela = produto.listProdutos?.find(
              (tabela) => tabela.codigo === tabelaState
            )
            dispatch(
              atendimentoActions.recalculoPropostaSaga(
                cpf,
                produto.matricula, // string
                parcelasState, // number
                Number(removeMaskMoney(valorState)), // number
                Number(removeMaskMoney(valorParcelaState)), // number
                produto.servico, // number
                tabelaState || 0, // number
                tabela?.banco || 0, // number
                null, // numeroProposta (opcional)
                null, // taxaOrigem (opcional)
                0, // quantidadeTotalParcelas (opcional)
                null, // saldoDevedor (opcional)
                index // number
              )
            )
          })
          .catch((err) => {
            err.inner.forEach((e: yup.ValidationError) => {
              setInvalids(true, e.path || '', e.message)
            })
            toast.warn('Preencha os campos corretamente para recalcular')
          })
      case 3:
        return schemaPortabilidade
          .validate(
            {
              tabela: tabelaState,
              valorParcela: Number(removeMaskMoney(valorParcelaState)),
              valorTotal: Number(removeMaskMoney(valorState)),
              parcelas: parcelasState
            },
            { abortEarly: false }
          )
          .then(() => {
            const tabela = produto.listProdutos?.find(
              (tabela) => tabela.codigo === tabelaState
            )
            dispatch(
              atendimentoActions.recalculoPropostaSaga(
                cpf,
                produto.matricula,
                parcelasState,
                Number(removeMaskMoney(valorState)),
                Number(removeMaskMoney(valorParcelaState)),
                produto.servico,
                tabelaState || 0,
                tabela?.banco || 0,
                null,
                null,
                0,
                null,
                index
              )
            )
          })
          .catch((err) => {
            err.inner.forEach((e: yup.ValidationError) => {
              setInvalids(true, e.path || '', e.message)
            })
            toast.warn('Preencha os campos corretamente para recalcular')
          })
      case 7:
        return schemaCartaoBeneficio
          .validate(
            {
              valorParcela: Number(removeMaskMoney(valorParcelaState)),
              valorTotal: Number(removeMaskMoney(valorState))
            },
            { abortEarly: false }
          )
          .then(() => {
            dispatch(
              atendimentoActions.recalculoPropostaSaga(
                cpf,
                produto.matricula, // string
                parcelasState, // number
                Number(removeMaskMoney(valorState)), // number
                Number(removeMaskMoney(valorParcelaState)), // number
                produto.servico, // number
                tabelaState || 0, // number
                0, // number
                null, // numeroProposta (opcional)
                null, // taxaOrigem (opcional)
                0, // quantidadeTotalParcelas (opcional)
                null, // saldoDevedor (opcional)
                index // number
              )
            )
          })
          .catch((err) => {
            err.inner.forEach((e: yup.ValidationError) => {
              setInvalids(true, e.path || '', e.message)
            })
            toast.warn('Preencha os campos corretamente para recalcular')
          })
      default:
        return schema
          .validate(
            {
              tabela: tabelaState,
              valorParcela: Number(removeMaskMoney(valorParcelaState)),
              valorTotal: Number(removeMaskMoney(valorState)),
              parcelas: parcelasState
            },
            { abortEarly: false }
          )
          .then(() => {
            const tabela = produto.listProdutos?.find(
              (tabela) => tabela.codigo === tabelaState
            )
            dispatch(
              atendimentoActions.recalculoPropostaSaga(
                cpf,
                produto.matricula, // string
                parcelasState, // number
                Number(removeMaskMoney(valorState)), // number
                Number(removeMaskMoney(valorParcelaState)), // number
                produto.servico, // number
                tabelaState || 0, // number
                tabela?.banco || 0, // number
                null, // numeroProposta (opcional)
                null, // taxaOrigem (opcional)
                0, // quantidadeTotalParcelas (opcional)
                null, // saldoDevedor (opcional)
                index // number
              )
            )
          })
          .catch((err) => {
            err.inner.forEach((e: yup.ValidationError) => {
              setInvalids(true, e.path || '', e.message)
            })
            toast.warn('Preencha os campos corretamente para recalcular')
          })
    }
  }

  function alteraComboTabelas(tabela: number) {
    setTabelaState(tabela)
    const parcelas = produto.listProdutos.find((element) => {
      return element.codigo === tabela
    })?.listParcelas

    if (parcelas !== null) {
      const parcelasFiltradas = parcelas?.map((p) => {
        return p.parcelas
      })

      produto.listParcelas = parcelasFiltradas ?? []
      setValorState(
        maskMoney(
          parcelas
            ?.find((element) => {
              return element.parcelas === parcelasState
            })
            ?.valorTotalFinanciado.toFixed(2)
        )
      )
    }
  }

  function alteraComboParcelas(parcela: number) {
    setParcelasState(parcela)
    const tabela = tabelaState
    const parcelas = produto.listProdutos.find((element) => {
      return element.codigo === tabela
    })?.listParcelas

    if (parcelas !== null) {
      const parcelasFiltradas = parcelas?.map((p) => {
        return p.parcelas
      })

      produto.listParcelas = parcelasFiltradas ?? []
      setValorState(
        maskMoney(
          parcelas
            ?.find((element) => {
              return element.parcelas === parcela
            })
            ?.valorTotalFinanciado.toFixed(2)
        )
      )
    }
  }

  function setInvalids(invalid: boolean, field: string, message: string) {
    setInvalid((prev) => ({
      ...prev,
      [field]: {
        invalid,
        message
      }
    }))
  }

  function cleanInvalids() {
    setInvalid({
      tabela: {
        invalid: false,
        message: ''
      },
      valorTotal: {
        invalid: false,
        message: ''
      },
      valorParcela: {
        invalid: false,
        message: ''
      },
      parcelas: {
        invalid: false,
        message: ''
      }
    })
  }

  switch (produto.servico) {
    case 1:
      return (
        <ContentDetalhamentoProduto>
          <Row>
            <h2>{EnumServicos[produto.servico]}</h2>
            <div className="linha-horizontal"></div>
            <Col className="mt-4 mb-4">
              <div className="d-flex justify-content-center column-gap-20 row-gap-10">
                <div>
                  <label className="label-12">Benefício:</label>{' '}
                  {produto.matricula}
                </div>
                <div>
                  <label className="label-12">Banco:</label> {produto.banco}
                </div>
              </div>
            </Col>
            <div className="linha-horizontal"></div>
          </Row>
          <Row className="d-flex flex-column row-gap-20">
            <div>
              <label className="label-12">Tabelas:</label>
              <CustomSelect
                options={produto.listProdutos || []}
                placeholder="Selecione"
                onChange={(e) => {
                  alteraComboTabelas(e)
                }}
                value={tabelaState}
                accessorLabel="descricao"
                accessorValue="codigo"
                invalid={invalid.tabela.invalid}
              />
              {invalid.tabela && (
                <p className="mensagem-erro">{invalid.tabela.message}</p>
              )}
            </div>
            <div>
              <label className="label-12">Parcelas:</label>
              <CustomSelect
                options={
                  produto.listParcelas?.map((parcela) => {
                    return {
                      label: parcela,
                      value: parcela
                    }
                  }) || []
                }
                placeholder="Selecione"
                onChange={(e) => {
                  alteraComboParcelas(e)
                }}
                value={parcelasState}
                accessorLabel="label"
                accessorValue="value"
                invalid={invalid.parcelas.invalid}
              />
              {invalid.parcelas && (
                <p className="mensagem-erro">{invalid.parcelas.message}</p>
              )}
            </div>
            <div>
              <label className="label-12">Valor total:</label>
              <InputCustom
                type="text"
                onChange={(e) => {
                  setValorState(maskMoney(e.target.value))
                  setValorParcelaState(maskMoney(0))
                }}
                value={maskMoney(valorState)}
                maxLength={14}
                placeholder="0,00"
                invalid={invalid.valorTotal.invalid}
              />
              {invalid.valorTotal && (
                <p className="mensagem-erro">{invalid.valorTotal.message}</p>
              )}
            </div>
            <div>
              <label className="label-12">Valor parcela:</label>
              <InputCustom
                type="text"
                onChange={(e) => {
                  setValorParcelaState(maskMoney(e.target.value))
                  setValorState(maskMoney(0))
                }}
                value={maskMoney(valorParcelaState)}
                maxLength={14}
                placeholder="0,00"
                invalid={invalid.valorParcela.invalid}
              />
              {invalid.valorParcela && (
                <p className="mensagem-erro">{invalid.valorParcela.message}</p>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <CustomButton
                onClick={() => recalculoPropostaCrm()}
                background="#662D91"
              >
                RECALCULAR
              </CustomButton>
            </div>
          </Row>
        </ContentDetalhamentoProduto>
      )
    case 3:
      return (
        <ContentDetalhamentoProduto>
          <Row>
            <h2>{EnumServicos[produto.servico]}</h2>
            <div className="linha-horizontal"></div>
            <Col className="mt-4 mb-4">
              <div className="d-flex justify-content-center column-gap-20 row-gap-10">
                <div>
                  <label className="label-12">Benefício:</label>{' '}
                  {produto.matricula}
                </div>
                <div>
                  <label className="label-12">Banco:</label> {produto.banco}
                </div>
              </div>
            </Col>
            <div className="linha-horizontal"></div>
          </Row>
          <Row className="d-flex flex-column row-gap-20">
            <div>
              <label className="label-12">Tabelas:</label>
              <CustomSelect
                options={produto.listProdutos || []}
                placeholder="Selecione"
                onChange={(e) => {
                  alteraComboTabelas(e)
                }}
                value={tabelaState}
                accessorLabel="descricao"
                accessorValue="codigo"
                invalid={invalid.tabela.invalid}
              />
              {invalid.tabela && (
                <p className="mensagem-erro">{invalid.tabela.message}</p>
              )}
            </div>
            <div>
              <label className="label-12">Parcelas:</label>
              <CustomSelect
                options={
                  produto.listParcelas?.map((parcela) => {
                    return {
                      label: parcela,
                      value: parcela
                    }
                  }) || []
                }
                placeholder="Selecione"
                onChange={(e) => {
                  alteraComboParcelas(e)
                }}
                value={parcelasState}
                accessorLabel="label"
                accessorValue="value"
                invalid={invalid.parcelas.invalid}
              />
              {invalid.parcelas && (
                <p className="mensagem-erro">{invalid.parcelas.message}</p>
              )}
            </div>
            <div>
              <label className="label-12">Valor total:</label>
              <InputCustom
                type="text"
                onChange={(e) => {
                  setValorState(maskMoney(e.target.value))
                  setValorParcelaState(maskMoney(0))
                }}
                value={maskMoney(valorState)}
                maxLength={14}
                placeholder="0,00"
                invalid={invalid.valorTotal.invalid}
              />
              {invalid.valorTotal && (
                <p className="mensagem-erro">{invalid.valorTotal.message}</p>
              )}
            </div>
            <div>
              <label className="label-12">Valor parcela:</label>
              <InputCustom
                type="text"
                onChange={(e) => {
                  setValorParcelaState(maskMoney(e.target.value))
                  setValorState(maskMoney(0))
                }}
                value={maskMoney(valorParcelaState)}
                maxLength={14}
                placeholder="0,00"
                invalid={invalid.valorParcela.invalid}
              />
              {invalid.valorParcela && (
                <p className="mensagem-erro">{invalid.valorParcela.message}</p>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <CustomButton
                onClick={() => recalculoPropostaCrm()}
                background="#662D91"
              >
                RECALCULAR
              </CustomButton>
            </div>
          </Row>
        </ContentDetalhamentoProduto>
      )
    case 7:
      return (
        <ContentDetalhamentoProduto>
          <Row>
            <h2>{EnumServicos[produto.servico]}</h2>
            <div className="linha-horizontal"></div>
            <Col className="mt-4 mb-4">
              <div className="d-flex justify-content-center column-gap-20 row-gap-10">
                <div>
                  <label className="label-12">Benefício:</label>{' '}
                  {produto.matricula}
                </div>
                <div>
                  <label className="label-12">Banco:</label> {produto.banco}
                </div>
              </div>
            </Col>
            <div className="linha-horizontal"></div>
          </Row>
          <Row className="d-flex flex-column row-gap-20">
            <div>
              <label className="label-12">Valor total:</label>
              <InputCustom
                type="text"
                onChange={(e) => {
                  setValorState(maskMoney(e.target.value))
                  setValorParcelaState(maskMoney(0))
                }}
                value={maskMoney(valorState)}
                maxLength={14}
                placeholder="0,00"
                invalid={invalid.valorTotal.invalid}
              />
              {invalid.valorTotal && (
                <p className="mensagem-erro">{invalid.valorTotal.message}</p>
              )}
            </div>
            <div>
              <label className="label-12">Valor parcela:</label>
              <InputCustom
                type="text"
                onChange={(e) => {
                  setValorParcelaState(maskMoney(e.target.value))
                  setValorState(maskMoney(0))
                }}
                value={maskMoney(valorParcelaState)}
                maxLength={14}
                placeholder="0,00"
                invalid={invalid.valorParcela.invalid}
                disabled
              />
              {invalid.valorParcela && (
                <p className="mensagem-erro">{invalid.valorParcela.message}</p>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <CustomButton
                onClick={() => recalculoPropostaCrm()}
                background="#662D91"
              >
                RECALCULAR
              </CustomButton>
            </div>
          </Row>
        </ContentDetalhamentoProduto>
      )
    case 9:
      return (
        <ContentDetalhamentoProduto>
          <Row>
            <h2>{EnumServicos[produto.servico]}</h2>
            <div className="linha-horizontal"></div>
          </Row>
          <Row className="d-flex flex-column row-gap-20">
            <div onClick={() => setShow(true)} className="detalhamento-fgts">
              <span>Detalhamento das parcelas</span>
              <BiChevronRight className="icon" />
            </div>
          </Row>
          <DetalhamentoParcelasFgts
            isOpen={show}
            onToggle={() => onToggle()}
            index={index}
            parcelasFgts={produto.parcelaFgtsItem}
            parcela={produto.quantidadeParcelas}
            valorTotal={produto.valorTotalFinanciado}
          />
        </ContentDetalhamentoProduto>
      )
    default:
      return (
        <ContentDetalhamentoProduto>
          <Row>
            <h2>{EnumServicos[produto.servico]}</h2>
            <div className="linha-horizontal"></div>
            <Col className="mt-4 mb-4">
              <div className="d-flex justify-content-center column-gap-20 row-gap-10">
                <div>
                  <label className="label-12">Benefício:</label>{' '}
                  {produto.matricula}
                </div>
                <div>
                  <label className="label-12">Banco:</label> {produto.banco}
                </div>
              </div>
            </Col>
            <div className="linha-horizontal"></div>
          </Row>
          <Row className="d-flex flex-column row-gap-20">
            <div>
              <label className="label-12">Tabelas:</label>
              <CustomSelect
                options={produto.listProdutos || []}
                placeholder="Selecione"
                onChange={(e) => {
                  setTabelaState(e)
                }}
                value={tabelaState}
                accessorLabel="descricao"
                accessorValue="codigo"
                invalid={invalid.tabela.invalid}
              />
            </div>
            {invalid.tabela && (
              <p className="mensagem-erro">{invalid.tabela.message}</p>
            )}
            <div>
              <label className="label-12">Parcelas:</label>
              <CustomSelect
                options={
                  produto.listParcelas?.map((parcela) => {
                    return {
                      label: parcela,
                      value: parcela
                    }
                  }) || []
                }
                placeholder="Selecione"
                onChange={(e) => {
                  setParcelasState(e)
                }}
                value={parcelasState}
                accessorLabel="label"
                accessorValue="value"
                invalid={invalid.parcelas.invalid}
              />
              {invalid.parcelas && (
                <p className="mensagem-erro">{invalid.parcelas.message}</p>
              )}
            </div>
            <div>
              <label className="label-12">Valor total:</label>
              <InputCustom
                type="text"
                onChange={(e) => {
                  setValorState(maskMoney(e.target.value))
                  setValorParcelaState(maskMoney(0))
                }}
                value={maskMoney(valorState)}
                maxLength={14}
                placeholder="0,00"
                invalid={invalid.valorTotal.invalid}
              />
              {invalid.valorTotal && (
                <p className="mensagem-erro">{invalid.valorTotal.message}</p>
              )}
            </div>
            <div>
              <label className="label-12">Valor parcela:</label>
              <InputCustom
                type="text"
                onChange={(e) => {
                  setValorParcelaState(maskMoney(e.target.value))
                  setValorState(maskMoney(0))
                }}
                value={maskMoney(valorParcelaState)}
                maxLength={14}
                placeholder="0,00"
                invalid={invalid.valorParcela.invalid}
              />
              {invalid.valorParcela && (
                <p className="mensagem-erro">{invalid.valorParcela.message}</p>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <CustomButton
                onClick={() => recalculoPropostaCrm()}
                background="#662D91"
              >
                RECALCULAR
              </CustomButton>
            </div>
          </Row>
        </ContentDetalhamentoProduto>
      )
  }
}

export default DetalhamentoProduto
