import React, { useEffect, useState } from 'react'
import { Login as LoginLayout } from 'layouts/login'
import { Button, CardLogin } from './style'
import logoRiber from 'assets/images/logo-crm.png'
import { FormFeedback, FormGroup } from 'reactstrap'
import TopLoading from 'components/Loading/TopLoading'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { FormRecuperacao, LOGIN } from 'store/modules/login/types'
import useRedux from 'hooks/useRedux'
import * as loginActions from 'store/modules/login/actions'
import { useParams } from 'react-router-dom'
import './styles.css'
import PasswordInput from 'components/Inputs/inputPassWord'

const NovaSenha = () => {
  const { recuperacao, loading } = useSelector<ApplicationState, LOGIN>(
    (state) => state.login
  )

  const { dispatch } = useRedux()
  const { id } = useParams<{ id: string }>()
  const [idState, setIdState] = useState<string | undefined>(id)
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    number: false,
    upper: false,
    lower: false,
    special: false
  })

  type RecuperacaoFields = keyof FormRecuperacao

  function onChange(value: string, field: RecuperacaoFields) {
    dispatch(loginActions.setRecuperacao(value, field))
    if (field === 'senha') {
      validatePassword(value)
    }
  }

  const validatePassword = (password: string) => {
    const validations = {
      length: password.length >= 10,
      number: /\d/.test(password),
      upper: /[A-Z]/.test(password),
      lower: /[a-z]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    }
    setPasswordValidations(validations)
    return Object.values(validations).every(Boolean)
  }

  useEffect(() => {
    setIdState(id)
    dispatch(loginActions.validaGuidRecuperacaoSaga(idState || ''))
  }, [dispatch, id, idState])

  return (
    <LoginLayout>
      <CardLogin>
        <div className="d-flex justify-content-center">
          <img src={logoRiber} width="100" alt="Logo verde Ribercred" />
        </div>
        <h1 className="text-center mb-1">Nova senha</h1>
        <FormGroup>
          <label>Nova senha</label>
          <PasswordInput
            placeholder="Digite aqui"
            value={recuperacao.senha}
            onChange={(value) => onChange(value, 'senha')}
          />
          <ul className="password-criteria">
            <li className={passwordValidations.length ? 'valid' : ''}>
              Senha deve conter no mínimo 10 caracteres
            </li>
            <li className={passwordValidations.number ? 'valid' : ''}>
              Senha deve conter no mínimo 1 número
            </li>
            <li className={passwordValidations.upper ? 'valid' : ''}>
              Senha deve conter no mínimo 1 letra maiúscula
            </li>
            <li className={passwordValidations.lower ? 'valid' : ''}>
              Senha deve conter no mínimo 1 letra minúscula
            </li>
            <li className={passwordValidations.special ? 'valid' : ''}>
              Senha deve conter no mínimo 1 caractere especial
            </li>
          </ul>
        </FormGroup>
        <FormGroup>
          <label>Confirme a nova senha</label>
          <PasswordInput
            placeholder="Digite aqui"
            value={recuperacao.confirmacaoSenha}
            onChange={(value) => onChange(value, 'confirmacaoSenha')}
          />
          {recuperacao.senha !== recuperacao.confirmacaoSenha && (
            <FormFeedback style={{ display: 'block' }}>
              As senhas não coincidem.
            </FormFeedback>
          )}
        </FormGroup>
        <Button
          onClick={() => {
            dispatch(loginActions.redefinirSenhaSaga(idState || ''))
          }}
        >
          Enviar
        </Button>
        <Button onClick={() => dispatch(loginActions.logout())}>Voltar</Button>
      </CardLogin>
      {loading ? <TopLoading /> : <></>}
    </LoginLayout>
  )
}

export default NovaSenha
