export enum TYPES {
  SET_PESQUISA = '@@indicadores/SET_PESQUISA',
  CLEAN_PESQUISA = '@@indicadores/CLEAN_PESQUISA',
  SET_OPTIONS_SELECT = '@@indicadores/SET_OPTIONS_SELECT',
  SET_LOADINGS = '@@indicadores/SET_LOADINGS',
  SET_BIG_NUMBERS = '@@indicadores/SET_BIG_NUMBERS',
  CLEAN_BIG_NUMBERS = '@@indicadores/CLEAN_BIG_NUMBERS',
  SET_TOP_EQUIPES = '@@indicadores/SET_TOP_EQUIPES',
  SET_VER_MAIS_TOP_EQUIPES = '@@indicadores/SET_VER_MAIS_TOP_EQUIPES',
  SET_TOP_EQUIPES_CONVERSAO = '@@indicadores/SET_TOP_EQUIPES_CONVERSAO',
  SET_VER_MAIS_TOP_EQUIPES_CONVERSAO = '@@indicadores/SET_VER_MAIS_TOP_EQUIPES_CONVERSAO',
  SET_TOP_CONSULTORES = '@@indicadores/SET_TOP_CONSULTORES',
  SET_VER_MAIS_TOP_CONSULTORES = '@@indicadores/SET_VER_MAIS_TOP_CONSULTORES',
  SET_TOP_CONSULTORES_CONVERSAO = '@@indicadores/SET_TOP_CONSULTORES_CONVERSAO',
  SET_VER_MAIS_TOP_CONSULTORES_CONVERSAO = '@@indicadores/SET_VER_MAIS_TOP_CONSULTORES_CONVERSAO',
  SET_TABULACOES = '@@indicadores/SET_TABULACOES',
  SET_MATRIZ_RFM = '@@indicadores/SET_MATRIZ_RFM',
  SET_ESFORCO_VENDAS = '@@indicadores/SET_ESFORCO_VENDAS',

  GET_EMPRESAS_SAGA = '@@indicadores/GET_EMPRESAS_SAGA',
  GET_EQUIPES_SAGA = '@@indicadores/GET_EQUIPES_SAGA',
  GET_CONSULTORES_SAGA = '@@indicadores/GET_CONSULTORES_SAGA',
  GET_BIG_NUMBERS_SAGA = '@@indicadores/GET_BIG_NUMBERS_SAGA',
  GET_TOP_EQUIPES_SAGA = '@@indicadores/GET_TOP_EQUIPES_SAGA',
  GET_VER_MAIS_TOP_EQUIPES_SAGA = '@@indicadores/GET_VER_MAIS_TOP_EQUIPES_SAGA',
  GET_TOP_EQUIPES_CONVERSAO_SAGA = '@@indicadores/GET_TOP_EQUIPES_CONVERSAO_SAGA',
  GET_VER_MAIS_TOP_EQUIPES_CONVERSAO_SAGA = '@@indicadores/GET_VER_MAIS_TOP_EQUIPES_CONVERSAO_SAGA',
  GET_TOP_CONSULTORES_SAGA = '@@indicadores/GET_TOP_CONSULTORES_SAGA',
  GET_VER_MAIS_TOP_CONSULTORES_SAGA = '@@indicadores/GET_VER_MAIS_TOP_CONSULTORES_SAGA',
  GET_TOP_CONSULTORES_CONVERSAO_SAGA = '@@indicadores/GET_TOP_CONSULTORES_CONVERSAO_SAGA',
  GET_VER_MAIS_TOP_CONSULTORES_CONVERSAO_SAGA = '@@indicadores/GET_VER_MAIS_TOP_CONSULTORES_CONVERSAO_SAGA',
  GET_TABULACOES_SAGA = '@@indicadores/GET_TABULACOES_SAGA',
  GET_MATRIZ_RFM_SAGA = '@@indicadores/GET_MATRIZ_RFM_SAGA',
  GET_ESFORCO_VENDA_SAGA = '@@indicadores/GET_ESFORCO_VENDA_SAGA'
}

export interface INDICADORES {
  search: Search
  optionsSelect: OptionsSelect
  loadings: Loadings
  bigNumbers: BigNumbers
  topEquipes: RankingMontante[]
  verMaisTopEquipes: boolean
  topEquipesConversao: RankingQtdeVendas[]
  verMaisTopEquipesConversao: boolean
  topConsultores: RankingMontante[]
  verMaisTopConsultores: boolean
  topConsultoresConversao: RankingQtdeVendas[]
  verMaisTopConsultoresConversao: boolean
  tabulacoes: Tabulacao[]
  matrizRfm: MatrizRfm
  esforcoVendas: EsforcoVendas
}

interface Search {
  empresaId: string
  equipeId: string
  consultorId: string
}

interface OptionsSelect {
  empresas: Empresa[]
  equipes: Equipes[]
  consultores: Consultores[]
}

interface Empresa {
  id: string
  descricao: string
}

interface Equipes {
  id: string
  descricao: string
}

interface Consultores {
  id: string
  descricao: string
}

interface BigNumbers {
  quantidadeVendas: number
  ticketMedio: number
  totalVendas: number
}

interface RankingMontante {
  nome: string
  totalVendas: number
}

interface RankingQtdeVendas {
  nome: string
  quantidadeVendas: number
}

export interface Tabulacao {
  descricao: string
  quantidadeUsuario: number
  mediaRiber: number
}

export interface MatrizRfm {
  quantidadeCampeoes?: number
  quantidadeClientesLeais?: number
  quantidadePotenciaisClientesLeais?: number
  quantidadeClientesRecentes?: number
  quantidadePromissores?: number
  quantidadePrecisamAtencao?: number
  quantidadePrestesDormir?: number
  quantidadeEmRisco?: number
  quantidadeNaoPodePerder?: number
  quantidadeHibernando?: number
  quantidadePerdidos?: number
}

interface Loadings {
  bigNumbers: boolean
  topEquipes: boolean
  topEquipesConversao: boolean
  topConsultores: boolean
  topConsultoresConversao: boolean
  tabulacoes: boolean
  matrizRfm: boolean
  esforcoVendas: boolean
}

export interface EsforcoVendas {
  ativo?: Ativo
  receptivo?: Receptivo
  agendamentos?: Agendamentos
  site?: Site
  sms?: Sms
}

interface Ativo {
  acionados: Valores
  convertidos: Valores
}
interface Receptivo {
  atendidos: Valores
  convertidos: Valores
}
interface Agendamentos {
  agendados: Valores
  convertidos: Valores
}
interface Site {
  recebidos: Valores
  convertidos: Valores
}
interface Sms {
  enviados: Valores
  convertidos: Valores
}

interface Valores {
  valor: number
  porcentagem: string
}
