import React from 'react'
import { Switch as SwitchMui, SwitchProps } from '@mui/material'

interface SwitchCustomProps extends SwitchProps {
  invalid?: boolean
}

const Switch = ({ invalid, ...props }: SwitchCustomProps) => {
  return (
    <SwitchMui
      {...props}
      sx={
        invalid
          ? {
              '& .Mui-checked+.MuiSwitch-track': {
                border: 'solid 1px #dc3545 !important',
                backgroundColor: '#dc3545 !important'
              },
              '& .MuiSwitch-track': {
                border: 'solid 1px #dc3545 !important',
                backgroundColor: '#dc3545 !important'
              },
              '& .MuiSwitch-thumb': {
                border: 'solid 1px #dc3545 !important'
              },
              '& .MuiSwitch-colorPrimary': {
                color: '#dc3545 !important'
              }
            }
          : undefined
      }
    />
  )
}

export { Switch }
