import React, { useEffect } from 'react'
import CustomTable from 'components/CustomTable'
import InputCustom from 'components/Inputs'
import SimpleCard from 'components/SimpleCard'
import Main from 'layouts/main'
import { AiOutlineHistory } from 'react-icons/ai'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { COLUMNS, COLUMNS_VISAO_COM_LOJA } from './constants/columns'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { HISTORICO } from 'store/modules/historico/types'
import * as historicoActions from 'store/modules/historico/actions'
import useRedux from 'hooks/useRedux'
import { maskCPF } from 'util/masks'

const Historico = () => {
  const { search, listaHistorico, showColunaLoja, pagination } = useSelector<
    ApplicationState,
    HISTORICO
  >((state) => state.historico)

  const { dispatch } = useRedux()

  type Fields = keyof HISTORICO['search']

  function onChange(value: string, field: Fields) {
    dispatch(historicoActions.setPesquisa(value, field))
  }

  function setPage(value: number) {
    dispatch(historicoActions.setPaginacao({ ...pagination, page: value }))
    dispatch(historicoActions.getAllHistoricoSaga())
  }

  function setPageSize(value: number) {
    dispatch(
      historicoActions.setPaginacao({ ...pagination, page: 0, pageSize: value })
    )
    dispatch(historicoActions.getAllHistoricoSaga())
  }

  function getHistorico() {
    dispatch(historicoActions.setPaginacao({ ...pagination, page: 0 }))
    dispatch(historicoActions.getAllHistoricoSaga())
  }

  useEffect(() => {
    dispatch(historicoActions.cleanPesquisa())
    dispatch(historicoActions.getAllHistoricoSaga())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
  return (
    <Main>
      <SimpleCard>
        <div className="container-fluid d-flex flex-column row-gap-10">
          <SimpleTitleCard>
            <AiOutlineHistory className="icon" />
            Histórico de atendimentos
          </SimpleTitleCard>
          <Row className="d-flex row-gap-15">
            <Col md={4}>
              <label className="label-12">CPF</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(maskCPF(e.target.value), 'cpfCliente')
                }}
                value={search.cpfCliente}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Data</label>
              <InputCustom
                type="date"
                onChange={(e) => {
                  onChange(e.target.value, 'Data')
                }}
                value={search.Data}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Atendente</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'nomeAtendente')
                }}
                value={search.nomeAtendente}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex gap-3">
              <CustomButton
                letterSpacing={2}
                background="#662d91"
                justifyContent="center"
                onClick={() => {
                  dispatch(historicoActions.cleanPesquisa())
                  getHistorico()
                }}
              >
                Limpar filtros
              </CustomButton>
              <CustomButton
                letterSpacing={2}
                justifyContent="center"
                onClick={() => {
                  getHistorico()
                }}
              >
                Pesquisar
              </CustomButton>
            </Col>
          </Row>
          <CustomTable
            data={listaHistorico}
            columns={showColunaLoja ? COLUMNS_VISAO_COM_LOJA : COLUMNS}
            paginationMode="server"
            rowCount={pagination.totalRegistros}
            page={pagination.page}
            pageSize={pagination.pageSize}
            setPage={(e) => setPage(e)}
            setPageSize={(e) => setPageSize(e)}
            autoHeight
          />
        </div>
      </SimpleCard>
    </Main>
  )
}

export default Historico
