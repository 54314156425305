import React, { useEffect } from 'react'
import SimpleCard from 'components/SimpleCard'
import Main from 'layouts/main'
import {
  RiBookOpenLine,
  RiChatCheckLine,
  RiChatDeleteLine,
  RiFeedbackLine,
  RiQuestionnaireLine
} from 'react-icons/ri'
import { SimpleTitleCard } from 'styles/global'
import { CardBigNumbers, Colors, ContentBoard } from './style'
import {
  FiBriefcase,
  FiChevronsRight,
  FiMonitor,
  FiShoppingBag,
  FiUsers
} from 'react-icons/fi'
import CardCarteira from './components/CardCarteira'
import Carousel from 'components/Carousel'
import CardCampanha from './components/CardCampanha'
import { Card, Col, UncontrolledCollapse } from 'reactstrap'
import InputCustom from 'components/Inputs'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { INICIO } from 'store/modules/inicio/types'
import useRedux from 'hooks/useRedux'
import * as inicioActions from 'store/modules/inicio/actions'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import { maskCPF, removeMaskCPF } from 'util/masks'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { validaCpf } from 'util/validations'
import { toast } from 'react-toastify'
import LoadingComponent from './components/LoadingComponent'
import moment from 'moment'
import { MdOpenInFull } from 'react-icons/md'

function Inicio(): JSX.Element {
  const {
    cpfAtendimento,
    quantidadeLeads,
    carteiras,
    campanhas,
    loading,
    bigNumbers
  } = useSelector<ApplicationState, INICIO>((state) => state.inicio)

  const { modalCadastroCliente } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  function iniciarAtendimento() {
    if (validaCpf(removeMaskCPF(cpfAtendimento))) {
      dispatch(inicioActions.cleanCpfAtendimento())
      dispatch(atendimentoActions.cleanInfosFormulario())
      dispatch(
        atendimentoActions.getFormularioAtendimento30Saga(cpfAtendimento)
      )
      dispatch(
        atendimentoActions.setInfosCadastroClienteModal({
          ...modalCadastroCliente,
          cpf: cpfAtendimento
        })
      )
    } else {
      toast.warn('CPF inválido')
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      iniciarAtendimento()
    }
  }

  useEffect(() => {
    dispatch(inicioActions.getQuantidadeLeadsSaga())
    dispatch(inicioActions.getCarteirasSaga())
    dispatch(inicioActions.getCampanhasSaga())
    dispatch(inicioActions.getBigNumbersSaga(moment().format('YYYY-MM-DD')))
  }, [dispatch])

  return (
    <Main>
      <SimpleCard>
        <SimpleTitleCard>
          <RiBookOpenLine className="icon" /> Bem-vindo, esses são seus
          agendamentos hoje
        </SimpleTitleCard>
        <LoadingComponent isLoading={loading.bigNumbers}>
          <div className="row d-flex justify-content-evenly">
            <CardBigNumbers background={Colors.blue}>
              <RiFeedbackLine className="icon" />
              <div>
                <span>{bigNumbers.agendamentos}</span>
                <label>Agendados</label>
              </div>
            </CardBigNumbers>
            <CardBigNumbers background={Colors.green}>
              <RiChatCheckLine className="icon" />
              <div>
                <span>{bigNumbers.confirmados}</span>
                <label>Confirmados</label>
              </div>
            </CardBigNumbers>
            <CardBigNumbers background={Colors.gray}>
              <RiQuestionnaireLine className="icon" />
              <div>
                <span>{bigNumbers.naoConfirmados}</span>
                <label>Não confirmados</label>
              </div>
            </CardBigNumbers>
            <CardBigNumbers background={Colors.orange}>
              <RiChatDeleteLine className="icon" />
              <div>
                <span>{bigNumbers.expirados}</span>
                <label>Expirados</label>
              </div>
            </CardBigNumbers>
          </div>
        </LoadingComponent>
      </SimpleCard>
      <div className="d-flex flex-wrap gap-3">
        <ContentBoard background={Colors.purpleGradiente} color="#FFFFFF">
          <SimpleTitleCard color="#FFFFFF" colorIcon="#FFFFFF">
            <FiMonitor className="icon" /> Campanha site
          </SimpleTitleCard>
          <div className="d-flex flex-column row-gap-20">
            <label>Iniciar um atendimento de algum lead do site</label>
            <div>
              <span>{quantidadeLeads || 0}</span> Leads
            </div>
            <div
              onClick={() => {
                if (quantidadeLeads > 0) {
                  return dispatch(inicioActions.iniciarAtendimentoLeadSaga())
                }
                return toast.warn('Não há leads para atendimento')
              }}
              className="btn-outline-white"
            >
              Iniciar <FiChevronsRight />
            </div>
          </div>
        </ContentBoard>
        <ContentBoard>
          <SimpleTitleCard>
            <FiBriefcase className="icon" /> Iniciar um atendimento
          </SimpleTitleCard>
          <div className="d-flex flex-column row-gap-20">
            <label>Inicie um atendimento padrão</label>
            <Col md={6} className="d-flex flex-column">
              <label className="label-12">CPF do cliente</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  dispatch(
                    inicioActions.setCpfAtendimento(maskCPF(e.target.value))
                  )
                }}
                value={maskCPF(cpfAtendimento)}
                onKeyDown={handleKeyDown}
              />
            </Col>
            <div
              onClick={() => iniciarAtendimento()}
              className="btn-outline-purple"
            >
              Iniciar <FiChevronsRight />
            </div>
          </div>
        </ContentBoard>
      </div>
      <SimpleCard>
        <SimpleTitleCard>
          <FiUsers className="icon" /> Sua carteira
        </SimpleTitleCard>
        <LoadingComponent isLoading={loading.carteiras}>
          <div className="d-flex gap-4 flex-wrap">
            {carteiras?.map((carteira, iConsultor) => {
              return (
                <Card
                  className="d-flex w-100 flex-column gap-4 p-4"
                  key={`card-${iConsultor}`}
                >
                  <div className="d-flex justify-content-between">
                    <label className="label-12">{carteira.nomeConsultor}</label>
                    <MdOpenInFull
                      id={`toggler-${iConsultor}`}
                      role="button"
                      size={20}
                    />
                  </div>
                  <UncontrolledCollapse toggler={`toggler-${iConsultor}`}>
                    <div className="d-flex gap-4 flex-wrap">
                      {carteira.carteirasConsultor?.map(
                        (carteiraConsultor, i) => {
                          return (
                            <CardCarteira
                              key={i}
                              nomeConsultor={carteiraConsultor.nomeConsultor}
                              tipoProduto={carteiraConsultor.tipoProduto}
                              produto={carteiraConsultor.produto}
                              totalClientes={carteiraConsultor.totalClientes}
                              totalClientesAcionar={
                                carteiraConsultor.totalClientesAcionar
                              }
                              totalClientesNovos={
                                carteiraConsultor.totalClientesNovos
                              }
                              totalClientesPerdidos={
                                carteiraConsultor.totalClientesPerdidos
                              }
                              valorOportunidade={
                                carteiraConsultor.valorOportunidade
                              }
                              consultorId={carteiraConsultor.consultorId}
                            />
                          )
                        }
                      )}
                    </div>
                  </UncontrolledCollapse>
                </Card>
              )
            })}
          </div>
        </LoadingComponent>
      </SimpleCard>
      <SimpleCard>
        <SimpleTitleCard>
          <FiShoppingBag className="icon" /> Iniciar um atendimento de campanha
        </SimpleTitleCard>
        <LoadingComponent isLoading={loading.campanhas}>
          {campanhas && campanhas?.length > 0 && (
            <Carousel autoplay>
              {campanhas?.map((campanha, i) => {
                return (
                  <CardCampanha
                    key={i}
                    id={campanha.id}
                    nomeCampanha={campanha.nomeCampanha}
                    dataInicial={campanha.dataInicio}
                    dataFinal={campanha.dataFim}
                    tipoOperacao={campanha.descricaoTipoOperacao}
                    totalClientes={campanha.totalClientes}
                    totalClientesAcionar={campanha.totalClientesAcionar}
                    contato={campanha.contatoPositivo}
                    conversao={campanha.conversao}
                  />
                )
              })}
            </Carousel>
          )}
        </LoadingComponent>
      </SimpleCard>
    </Main>
  )
}

export default Inicio
