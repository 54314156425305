import { Paginacao } from 'models/types'
import {
  ParametrizacaoUploadArquivo,
  TipoArquivo,
  TYPES,
  UploadsFormat,
  UPLOAD_ARQUIVOS
} from './types'
import store from 'store'

type ActiveTab = 'search' | 'register'
export const setActiveTab = (value: ActiveTab) => ({
  type: TYPES.SET_ACTIVE_TAB,
  value
})

export const getAllUploadSaga = () => ({
  type: TYPES.GET_ALL_UPLOAD_SAGA
})

export const setAllUpload = (value: UploadsFormat[]) => ({
  type: TYPES.SET_ALL_UPLOAD,
  value
})
export const setPesquisa = (value: string | number | null, field: string) => ({
  type: TYPES.SET_PESQUISA,
  value,
  field
})

export const setPaginacao = (value: Paginacao) => ({
  type: TYPES.SET_PAGINACAO,
  value
})

export const getTiposDeArquivosSaga = () => ({
  type: TYPES.GET_TIPOS_DE_ARQUIVOS_SAGA
})

export const setTiposDeArquivos = (value: TipoArquivo[]) => ({
  type: TYPES.SET_TIPOS_DE_ARQUIVOS,
  value
})

export const criarUploadSaga = (value: ParametrizacaoUploadArquivo) => ({
  type: TYPES.UPLOAD_SAGA,
  value
})

export const excluirUploadSaga = (value: string) => ({
  type: TYPES.EXCLUIR_UPLOAD_SAGA,
  value
})

export const downloadArquivoSagaDispatch = (value: string) =>
  store.dispatch({
    type: TYPES.DOWNLOAD_ARQUIVO_SAGA_DISPATCH,
    value
  })

export const setModalExclusaoDispatch = (value: boolean) =>
  store.dispatch({
    type: TYPES.TOGGLE_MODAL_EXCLUSAO_DISPATCH,
    value
  })

export const setItemSelectedIdDispatch = (value: string) =>
  store.dispatch({
    type: TYPES.SET_ITEM_SELECTED_ID_DISPATCH,
    value
  })

type FieldsRegister = keyof UPLOAD_ARQUIVOS['register']
export const setRegisterFields = (
  value: string | File,
  field: FieldsRegister
) => ({
  type: TYPES.SET_REGISTER_FIELDS,
  value,
  field
})

export const setRegister = (value: ParametrizacaoUploadArquivo) => ({
  type: TYPES.SET_REGISTER,
  value
})

export const cleanRegister = () => ({
  type: TYPES.CLEAN_REGISTER
})

export const cleanInvalids = () => ({
  type: TYPES.CLEAN_INVALIDS
})

export const setInvalids = (value: boolean, field: string) => ({
  type: TYPES.SET_INVALIDS,
  value,
  field
})

export const cleanSearch = () => ({
  type: TYPES.CLEAN_SEARCH
})

export const setArquivo = (value: UploadsFormat) => ({
  type: TYPES.SET_ARQUIVO,
  value
})

export const cleanArquivo = () => ({
  type: TYPES.CLEAN_ARQUIVO
})

export const visualizarArquivoSagaDispatch = (value: string) =>
  store.dispatch({
    type: TYPES.VISUALIZAR_ARQUIVO,
    value
  })
export const setCreating = (value: boolean) => ({
  type: TYPES.SET_CREATING,
  value
})
