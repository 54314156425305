import CustomTable from 'components/CustomTable'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import SimpleCard from 'components/SimpleCard'
import { RiChatOffLine } from 'react-icons/ri'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { COLUMNS } from '../constants/columns'
import useRedux from 'hooks/useRedux'
import { maskCPF, maskDate } from 'util/masks'
import { useCallback, useEffect, useMemo } from 'react'
import { filterOptions } from '../constants/filterOptions'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { BLACKLIST } from 'store/modules/backoffice/blacklist/types'
import * as blacklistActions from 'store/modules/backoffice/blacklist/actions'
import { schema } from '../constants/schema'
import { toast } from 'react-toastify'
import ModalExclusao from 'components/ModalExclusao'

type FieldsRegister = keyof BLACKLIST['register']
type FieldsSearch = keyof BLACKLIST['search']

export default function SearchAndRegister() {
  const {
    blacklist: blacklistStore,
    register,
    search,
    pagination,
    showConfirmacaoExclusao
  } = useSelector<ApplicationState, BLACKLIST>((state) => state.blacklist)

  const { dispatch } = useRedux()

  function onChangeRegister(value: string, field: FieldsRegister) {
    if (field === 'cpf') {
      value = maskCPF(value)
    }

    dispatch(blacklistActions.setRegisterFields(value, field))
  }

  function onChangeSearch(value: string, field: FieldsSearch) {
    if (search.filtro === 'cpf') {
      value = maskCPF(value)
    }

    dispatch(blacklistActions.setPesquisa(value, field))
  }

  const getAllBlacklist = useCallback(() => {
    dispatch(blacklistActions.setPaginacao({ ...pagination, page: 0 }))
    dispatch(blacklistActions.getAllBlacklistSaga())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const cleanAllFields = useCallback(() => {
    dispatch(blacklistActions.cleanSearch())
    dispatch(blacklistActions.cleanRegister())
  }, [dispatch])

  function setPage(value: number) {
    dispatch(blacklistActions.setPaginacao({ ...pagination, page: value }))
    dispatch(blacklistActions.getAllBlacklistSaga())
  }

  function setPageSize(value: number) {
    dispatch(
      blacklistActions.setPaginacao({ ...pagination, page: 0, pageSize: value })
    )
    dispatch(blacklistActions.getAllBlacklistSaga())
  }

  const blacklist = useMemo(() => {
    const data = blacklistStore?.map((item) => ({
      id: item.cpf,
      cpf: maskCPF(item.cpf),
      nome: item.nome,
      dataInclusao: maskDate(item.dataInclusao, 'DD/MM/YYYY'),
      nomePessoaExclusao: item.nomePessoaExclusao
    }))

    return data
  }, [blacklistStore])

  function onSave() {
    schema
      .validate(
        {
          cpf: register.cpf
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(blacklistActions.criarBlacklistSaga())
      })
      .catch(() => {
        toast.warn('Preencha o campo corretamente para salvar!')
      })
  }

  useEffect(() => {
    cleanAllFields()
    getAllBlacklist()
  }, [cleanAllFields, getAllBlacklist])

  return (
    <SimpleCard>
      <Row>
        <Col>
          <SimpleTitleCard>
            <RiChatOffLine className="icon" />
            Blacklist
          </SimpleTitleCard>
        </Col>
      </Row>
      <Row className="mb-4">
        <label className="label-12 mb-3">CPF do cliente</label>

        <Col md={3}>
          <InputCustom
            type="text"
            placeholder="Adicione a Blacklist"
            onChange={(e) => {
              onChangeRegister(e.target.value, 'cpf')
            }}
            value={register.cpf}
          />
        </Col>

        <Col md={3}>
          <CustomButton onClick={() => onSave()}>Adicionar</CustomButton>
        </Col>
      </Row>

      <Row className="my-3">
        <Col md={3}>
          <CustomSelect
            options={filterOptions}
            placeholder="Filtrar por"
            onChange={(e) => {
              onChangeSearch(e, 'filtro')
            }}
            value={search.filtro}
            accessorLabel="label"
            accessorValue="value"
          />
        </Col>

        <Col md={3}>
          <InputCustom
            type={search.filtro === 'dataInclusao' ? 'date' : 'text'}
            placeholder="Pesquise aqui"
            onChange={(e) => {
              onChangeSearch(e.target.value, 'pesquisa')
            }}
            value={search.pesquisa}
          />
        </Col>
      </Row>

      <Row className="my-3">
        <Col>
          <CustomButton
            onClick={() => getAllBlacklist()}
            background="var(--roxo-riber)"
          >
            Pesquisar
          </CustomButton>
        </Col>
      </Row>

      <Row>
        <CustomTable
          data={blacklist}
          columns={COLUMNS}
          paginationMode="server"
          rowCount={pagination.totalRegistros}
          page={pagination.page}
          pageSize={pagination.pageSize}
          setPage={(e) => setPage(e)}
          setPageSize={(e) => setPageSize(e)}
        />
      </Row>

      <ModalExclusao
        isOpen={showConfirmacaoExclusao}
        toggle={(e) => blacklistActions.toggleModalExclusaoDispatch(e)}
        onRemove={() => {
          blacklistActions.deleteBlacklistSagaDispatch()
        }}
      />
    </SimpleCard>
  )
}
