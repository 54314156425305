import { APICore } from 'helpers/api/apiCore'

const api = new APICore()

export interface FindProps {
  dataInicio: string | null
  dataFim: string | null
  equipeId: string | null
  digitadorId: string | null
  adesao: string
  cpf: string
}

export function find(params: FindProps, pagina: number, registros: number) {
  return api.getWithoutUndefined(`Producao/find`, {
    pagina: pagina + 1,
    registros,
    ...params
  })
}

export function getEquipes(flagUsuario?: boolean) {
  return api.getWithoutUndefined(`Generic/lojas`, {
    flagUsuario
  })
}

export function getUsuarios(lojaId?: string) {
  return api.getWithoutUndefined(`Generic/usuarios`, {
    lojaId
  })
}
