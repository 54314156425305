import { Tabulacao } from 'models/backoffice/tabulacao/type'
import {
  Localizacao,
  PeriodoAgendamento,
  Polaridade
} from 'models/backoffice/tabulacao/type'
import { Paginacao } from 'models/types'
import store from 'store'
import { ParametrizacaoTabulacao, TYPES } from './types'

type ActiveTab = 'search' | 'register'
export const setAtiveTab = (value: ActiveTab) => ({
  type: TYPES.SET_ACTIVE_TAB,
  value
})

export const setItemSelectedIdDispatch = (value: string) =>
  store.dispatch({
    type: TYPES.SET_ITEM_SELECTED_ID,
    value
  })

export const toggleModalExclusaoDispatch = (value: boolean) =>
  store.dispatch({
    type: TYPES.TOGGLE_MODAL_EXCLUSAO,
    value
  })

export const setPesquisa = (value: string | number | null, field: string) => ({
  type: TYPES.SET_PESQUISA,
  value,
  field
})

export const setRegisterFields = (
  value: string | number | boolean,
  field: string
) => ({
  type: TYPES.SET_REGISTER_FIELDS,
  value,
  field
})

export const setRegister = (value: ParametrizacaoTabulacao) => ({
  type: TYPES.SET_REGISTER,
  value
})

export const setVisualizacao = (value: boolean) => ({
  type: TYPES.SET_VISUALIZACAO,
  value
})

export const setPaginacao = (value: Paginacao) => ({
  type: TYPES.SET_PAGINATION,
  value
})

export const setInvalids = (value: boolean, field: string) => ({
  type: TYPES.SET_INVALIDS,
  value,
  field
})

export const cleanInvalids = () => ({
  type: TYPES.CLEAN_INVALIDS
})

export const cleanRegister = () => ({
  type: TYPES.CLEAN_REGISTER
})

export const setTabulacoes = (value: Tabulacao[]) => ({
  type: TYPES.SET_TABULACOES,
  value
})

export const setPolaridades = (value: Polaridade[]) => ({
  type: TYPES.SET_POLARIDADES,
  value
})

export const setLocalizacoes = (value: Localizacao[]) => ({
  type: TYPES.SET_LOCALIZACOES,
  value
})

export const setAgendamentos = (value: PeriodoAgendamento[]) => ({
  type: TYPES.SET_AGENDAMENTOS,
  value
})

export const getAllTabulacoesSaga = () => ({
  type: TYPES.GET_ALL_TABULACOES_SAGA
})

export const getPolaridadesSaga = () => ({
  type: TYPES.GET_POLARIDADES_SAGA
})

export const getLocalizacoesSaga = () => ({
  type: TYPES.GET_LOCALIZACOES_SAGA
})

export const getAgendamentosSaga = () => ({
  type: TYPES.GET_AGENDAMENTOS_SAGA
})

export const getTabulacaoSaga = (id: string) => ({
  type: TYPES.GET_TABULACAO_SAGA,
  id
})

export const visualizarTabulacaoSagaDispatch = (id: string) =>
  store.dispatch({
    type: TYPES.VISUALIZAR_TABULACAO_SAGA_DISPATCH,
    id
  })

export const editarTabulacaoSagaDispatch = (id: string) =>
  store.dispatch({
    type: TYPES.EDITAR_TABULACAO_SAGA_DISPATCH,
    id
  })

export const criarTabulacaoSaga = () => ({
  type: TYPES.POST_TABULACAO_SAGA
})

export const atualizarTabulacaoSaga = () => ({
  type: TYPES.PUT_TABULACAO_SAGA
})

export const deleteTabulacaoSaga = (id: string) => ({
  type: TYPES.DELETE_TABULACAO_SAGA,
  id
})

export const deleteTabulacaoSagaDispatch = () =>
  store.dispatch({
    type: TYPES.DELETE_TABULACAO_SAGA
  })
