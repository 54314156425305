import { Reducer } from 'redux'
import { CAMPANHA, TYPES } from './types'

const INITIAL_STATE: CAMPANHA = {
  activeTab: 'search',
  activeTabRegister: 1,
  search: {
    descricao: '',
    dataInicio: '',
    dataFim: '',
    lojaId: null
  },
  register: {
    id: null,
    codigo: null,
    descricao: '',
    empresasGrupo: [],
    tipoOperacaoId: null,
    dataInicioCampanha: '',
    dataFimCampanha: '',
    quantidadeClientes: 0,
    ufCampanha: [],
    cidadeCampanha: [],
    redudancia: false,
    indisponibilidade: false,
    naoPertube: false,
    discadorAutomatico: false,
    telefone: null,
    idadeInicial: null,
    idadeFinal: null,
    formaRecebimentoBeneficio: null,
    cartaoInss: null,
    bancosCartaoCampanha: [],
    despachoAnoInicial: null,
    despachoAnoFinal: null,
    especies: [],
    bancosPagadoresConsiderarCampanha: [],
    quantidadeEmprestimo: false,
    quantidadeInicialEmprestimo: null,
    quantidadeFinalEmprestimo: null,
    salario: true,
    quantidadeInicialSalario: null,
    quantidadeFinalSalario: null,
    margemLivre: false,
    quantidadeInicialMargem: null,
    quantidadeFinalMargem: null,
    margemCartao: false,
    quantidadeInicialMargemCartao: null,
    quantidadeFinalMargemCartao: null,
    instituicaoFinanceiraCampanha: [],
    parcelas: false,
    quantidadeInicialParcelas: null,
    quantidadeFinalParcelas: null,
    valorParcela: false,
    quantidadeInicialValorParcela: null,
    quantidadeFinalValorParcela: null
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  visualizacao: false,
  itemSelectedId: '',
  showConfirmacaoExclusao: false,
  campanhas: [],
  optionsSelect: {
    bancos: [],
    bancosCartao: [],
    cidades: [],
    empresasGrupos: [],
    formasRecebimento: [
      { value: 1, label: 'Conta Corrente' },
      { value: 2, label: 'Cartão Magnético' }
    ],
    generos: [],
    meiosComunicacao: [
      { value: 'Fixo', label: 'Fixo' },
      { value: 'Celular', label: 'Celular' },
      { value: 'Sem telefone', label: 'Sem telefone' },
      { value: 'Fixo e Celular', label: 'Fixo e Celular' }
    ],
    opcoesBinarias: [
      { value: true, label: 'Sim' },
      { value: false, label: 'Não' }
    ],
    qtdeEmprestimos: [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
      { value: 6, label: '6' },
      { value: 7, label: '7' },
      { value: 8, label: '8' },
      { value: 9, label: '9' }
    ],
    tiposOperacao: [],
    ufs: [],
    especies: []
  },
  invalidsTabDadosGerais: {
    cidadeCampanha: false,
    dataFimCampanha: false,
    dataInicioCampanha: false,
    descricao: false,
    empresasGrupo: false,
    quantidadeClientes: false,
    codigo: false,
    tipoOperacaoId: false,
    ufCampanha: false
  },
  invalidsTabParametros: {
    bancosCartaoCampanha: false,
    cartaoInss: false,
    despachoAnoInicial: false,
    despachoAnoFinal: false,
    especies: false,
    discadorAutomatico: false,
    formaRecebimentoBeneficio: false,
    idadeFinal: false,
    idadeInicial: false,
    indisponibilidade: false,
    margemCartao: false,
    margemLivre: false,
    naoPertube: false,
    quantidadeEmprestimo: false,
    quantidadeFinalEmprestimo: false,
    quantidadeFinalMargem: false,
    quantidadeFinalMargemCartao: false,
    quantidadeFinalSalario: false,
    quantidadeInicialEmprestimo: false,
    quantidadeInicialMargem: false,
    quantidadeInicialMargemCartao: false,
    quantidadeInicialSalario: false,
    redudancia: false,
    salario: false,
    telefone: false
  },
  invalidsTabBancos: {
    bancosPagadoresConsiderarCampanha: false
  },
  invalidsTabRefinPortabilidade: {
    instituicaoFinanceiraCampanha: false,
    parcelas: false,
    quantidadeFinalParcelas: false,
    quantidadeFinalValorParcela: false,
    quantidadeInicialParcelas: false,
    quantidadeInicialValorParcela: false,
    valorParcela: false
  },
  lojas: [],
  campanhasSelectedIds: [],
  showConfirmacaoExclusaoSelecionados: false
}

const reducer: Reducer<CAMPANHA> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.value }
    case TYPES.SET_ACTIVE_TAB_REGISTER:
      return { ...state, activeTabRegister: action.value }
    case TYPES.SET_ALL_CAMPANHAS:
      return { ...state, campanhas: action.value }
    case TYPES.CLEAN_ALL_CAMPANHAS:
      return { ...state, campanhas: INITIAL_STATE.campanhas }
    case TYPES.SET_ITEM_SELECTED_ID:
      return {
        ...state,
        itemSelectedId: action.value
      }
    case TYPES.TOGGLE_MODAL_EXCLUSAO:
      return {
        ...state,
        showConfirmacaoExclusao: action.value
      }
    case TYPES.SET_PESQUISA:
      return {
        ...state,
        search: { ...state.search, [action.field]: action.value }
      }
    case TYPES.CLEAN_PESQUISA:
      return {
        ...state,
        search: INITIAL_STATE.search
      }
    case TYPES.SET_REGISTER_FIELDS:
      return {
        ...state,
        register: { ...state.register, [action.field]: action.value }
      }
    case TYPES.SET_REGISTER:
      return {
        ...state,
        register: action.value
      }
    case TYPES.SET_VISUALIZACAO:
      return {
        ...state,
        visualizacao: action.value
      }
    case TYPES.SET_PAGINATION:
      return {
        ...state,
        pagination: action.value
      }
    case TYPES.CLEAN_PAGINATION:
      return {
        ...state,
        pagination: INITIAL_STATE.pagination
      }
    case TYPES.CLEAN_REGISTER:
      return { ...state, register: INITIAL_STATE.register }
    case TYPES.SET_OPTIONS_SELECT:
      return {
        ...state,
        optionsSelect: {
          ...state.optionsSelect,
          [action.field]: action.value
        }
      }
    case TYPES.SET_INVALIDS_TAB_DADOS_GERAIS:
      return {
        ...state,
        invalidsTabDadosGerais: {
          ...state.invalidsTabDadosGerais,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_INVALIDS_TAB_DADOS_GERAIS:
      return {
        ...state,
        invalidsTabDadosGerais: INITIAL_STATE.invalidsTabDadosGerais
      }
    case TYPES.SET_INVALIDS_TAB_PARAMETROS:
      return {
        ...state,
        invalidsTabParametros: {
          ...state.invalidsTabParametros,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_INVALIDS_TAB_PARAMETROS:
      return {
        ...state,
        invalidsTabParametros: INITIAL_STATE.invalidsTabParametros
      }
    case TYPES.SET_INVALIDS_TAB_BANCOS:
      return {
        ...state,
        invalidsTabBancos: {
          ...state.invalidsTabBancos,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_INVALIDS_TAB_BANCOS:
      return {
        ...state,
        invalidsTabBancos: INITIAL_STATE.invalidsTabBancos
      }
    case TYPES.SET_INVALIDS_TAB_REFIN_PORTABILIDADE:
      return {
        ...state,
        invalidsTabRefinPortabilidade: {
          ...state.invalidsTabRefinPortabilidade,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_INVALIDS_TAB_REFIN_PORTABILIDADE:
      return {
        ...state,
        invalidsTabRefinPortabilidade:
          INITIAL_STATE.invalidsTabRefinPortabilidade
      }
    case TYPES.CLEAN_QUANTIDADE_EMPRESTIMO:
      return {
        ...state,
        register: {
          ...state.register,
          quantidadeInicialEmprestimo:
            INITIAL_STATE.register.quantidadeInicialEmprestimo,
          quantidadeFinalEmprestimo:
            INITIAL_STATE.register.quantidadeFinalEmprestimo
        }
      }
    case TYPES.CLEAN_SALARIO:
      return {
        ...state,
        register: {
          ...state.register,
          quantidadeInicialSalario:
            INITIAL_STATE.register.quantidadeInicialSalario,
          quantidadeFinalSalario: INITIAL_STATE.register.quantidadeFinalSalario
        }
      }
    case TYPES.CLEAN_MARGEM_LIVRE:
      return {
        ...state,
        register: {
          ...state.register,
          quantidadeInicialMargem:
            INITIAL_STATE.register.quantidadeInicialMargem,
          quantidadeFinalMargem: INITIAL_STATE.register.quantidadeFinalMargem
        }
      }
    case TYPES.CLEAN_MARGEM_CARTAO:
      return {
        ...state,
        register: {
          ...state.register,
          quantidadeInicialMargemCartao:
            INITIAL_STATE.register.quantidadeInicialMargemCartao,
          quantidadeFinalMargemCartao:
            INITIAL_STATE.register.quantidadeFinalMargemCartao
        }
      }
    case TYPES.CLEAN_PARCELAS_PAGAS:
      return {
        ...state,
        register: {
          ...state.register,
          quantidadeInicialParcelas:
            INITIAL_STATE.register.quantidadeInicialParcelas,
          quantidadeFinalParcelas:
            INITIAL_STATE.register.quantidadeFinalParcelas
        }
      }
    case TYPES.CLEAN_VALOR_PARCELA:
      return {
        ...state,
        register: {
          ...state.register,
          quantidadeInicialValorParcela:
            INITIAL_STATE.register.quantidadeInicialValorParcela,
          quantidadeFinalValorParcela:
            INITIAL_STATE.register.quantidadeFinalValorParcela
        }
      }
    case TYPES.SET_STATUS_CAMPANHA_GRID: {
      const campanhas = [...state.campanhas]
      campanhas.forEach((campanha) => {
        if (campanha.id === action.campanhaCrmId) {
          campanha.habilitado = action.value
        }
      })
      return {
        ...state,
        campanhas: [...campanhas]
      }
    }
    case TYPES.SET_LOJAS:
      return { ...state, lojas: action.value }
    case TYPES.SET_CAMPANHAS_SELECTED_IDS:
      return {
        ...state,
        campanhasSelectedIds: action.value
      }
    case TYPES.TOGGLE_MODAL_EXCLUSAO_SELECIONADOS:
      return {
        ...state,
        showConfirmacaoExclusaoSelecionados: action.value
      }
    case TYPES.CLEAN_CAMPANHAS_SELECTED_IDS:
      return {
        ...state,
        campanhasSelectedIds: INITIAL_STATE.campanhasSelectedIds
      }
    default:
      return state
  }
}

export default reducer
