import { INDICACAO } from 'store/modules/indicacao/types'
import { Paginacao } from 'models/types'
import { Equipe, Indicacoes, TYPES } from './types'
import store from 'store'

export const setFiltros = (value: string, field: string) => ({
  type: TYPES.SET_FILTROS,
  value,
  field
})

export const cleanFiltros = () => ({
  type: TYPES.CLEAN_FILTROS
})

export const getAllPropostasIndicacaoSaga = () => ({
  type: TYPES.GET_ALL_PROPOSTAS_INDICACAO_SAGA
})

export const getPropostaIndicacaoDispatchSaga = (id: string) =>
  store.dispatch({
    type: TYPES.GET_PROPOSTA_INDICACAO_DISPATCH_SAGA,
    id
  })

export const criarPropostaIndicacaoSaga = (cpf: string) => ({
  type: TYPES.CRIAR_PROPOSTA_INDICACAO_SAGA,
  cpf
})

export const criarPropostaIndicacaoManualSaga = (cpf: string) => ({
  type: TYPES.CRIAR_PROPOSTA_INDICACAO_MANUAL_SAGA,
  cpf
})

export const duplicarPropostaIndicacaoDispatchSaga = (idProposta: string) =>
  store.dispatch({
    type: TYPES.DUPLICAR_PROPOSTA_INDICACAO_DISPATCH_SAGA,
    idProposta
  })

export const editarPropostaIndicacaoSaga = () => ({
  type: TYPES.EDITAR_PROPOSTA_INDICACAO_SAGA
})

export const deletarPropostaIndicacaoSaga = () => ({
  type: TYPES.DELETAR_PROPOSTA_INDICACAO_SAGA
})

export const setPaginacao = (value: Paginacao) => ({
  type: TYPES.SET_PAGINATION,
  value
})
export const setEquipesOptions = (value: Equipe[]) => ({
  type: TYPES.SET_EQUIPES_OPTIONS,
  value
})

export const setTabelaIndicacao = (value: Indicacoes[]) => ({
  type: TYPES.SET_TABELA_INDICACAO,
  value
})

export const cleanTabelaIndicacao = () => ({
  type: TYPES.CLEAN_TABELA_INDICACAO
})

export const toggleModalEdicaoIndicacaoDispatch = (value: boolean) =>
  store.dispatch({
    type: TYPES.TOGGLE_MODAL_EDICAO_INDICACAO_DISPATCH,
    value
  })

export const toggleModalEdicaoIndicacaoManualDispatch = (value: boolean) =>
  store.dispatch({
    type: TYPES.TOGGLE_MODAL_EDICAO_INDICACAO_MANUAL_DISPATCH,
    value
  })

export const toggleModalEdicaoIndicacao = (value: boolean) => ({
  type: TYPES.TOGGLE_MODAL_EDICAO_INDICACAO,
  value
})

export const setInfosModalEdicaoIndicacao = (
  value: INDICACAO['modalEdicaoIndicacao']
) => ({
  type: TYPES.SET_INFOS_MODAL_EDICAO_INDICACAO,
  value
})

export const cleanInfosModalEdicaoIndicacao = () => ({
  type: TYPES.CLEAN_INFOS_MODAL_EDICAO_INDICACAO
})

export const toggleModalExclusaoDispatch = (value: boolean) =>
  store.dispatch({
    type: TYPES.TOGGLE_MODAL_EXCLUSAO_DISPATCH,
    value
  })

type FieldsSelects = keyof INDICACAO['optionsSelect']
export const setOptionsSelects = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  field: FieldsSelects
) => ({
  type: TYPES.SET_OPTIONS_SELECTS,
  value,
  field
})

export const setEquipes = (value: INDICACAO['equipes']) => ({
  type: TYPES.SET_EQUIPES,
  value
})

export const getUsuariosSaga = () => ({
  type: TYPES.GET_USUARIOS_SAGA
})

export const getEquipesModalSaga = (digitadorId: string | undefined) => ({
  type: TYPES.GET_EQUIPES_MODAL_SAGA,
  digitadorId
})

export const getBancosSaga = (digitadorId: string | undefined) => ({
  type: TYPES.GET_BANCOS_SAGA,
  digitadorId
})
export const getEquipesSaga = (
  digitadorId?: string,
  usuarioBancoId?: string
) => ({
  type: TYPES.GET_EQUIPES_SAGA,
  digitadorId,
  usuarioBancoId
})

export const getCentrosCustosSaga = () => ({
  type: TYPES.GET_CENTROS_CUSTOS_SAGA
})

export const getCentrosCustosModalSaga = () => ({
  type: TYPES.GET_CENTROS_CUSTOS_MODAL_SAGA
})

export const getStatusSaga = () => ({
  type: TYPES.GET_STATUS_SAGA
})

export const getTipoOperacaoSaga = () => ({
  type: TYPES.GET_TIPO_OPERACAO_SAGA
})

export const getUsuariosBancoByBancoLojaAndDigitadorSaga = (
  bancoId: string,
  lojaId: string,
  digitadorId: string
) => ({
  type: TYPES.GET_USUARIOS_BANCO_BY_BANCO_LOJA_AND_DIGITADOR_SAGA,
  bancoId,
  lojaId,
  digitadorId
})

export const setInvalids = (value: boolean, field: string) => ({
  type: TYPES.SET_INVALIDS,
  value,
  field
})

export const cleanInvalids = () => ({
  type: TYPES.CLEAN_INVALIDS
})

export const setItemSelectedIdDispatch = (value: string) =>
  store.dispatch({
    type: TYPES.SET_ITEM_SELECTED_ID_DISPATCH,
    value
  })
